import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ResolveStart } from '@angular/router'

import { MainTabs } from '../../../../models/creation/maintabs.model'
// import { FORMS } from '../../../../app.forms'

import { CreationService } from '../../../../services/creation.service'
import { FormsService } from '../../../../services/forms.service'

@Component({
  selector: 'app-main-timeline',
  templateUrl: './main-timeline.component.html',
  styleUrls: ['./main-timeline.component.scss']
})
export class MainTimelineComponent implements OnInit {

	public currentMainStep: string 
	public main: MainTabs	

	constructor(
		private router: Router,
        private route: ActivatedRoute,
        public _creation: CreationService,
		public _Forms: FormsService
	) { }

	ngOnInit() {
		this._creation.Get()

		let currentPath: string
			currentPath = this.router.url

	  	if(currentPath !== "") {
	  		this.currentMainStep = currentPath.slice(1, currentPath.length).split("/")[1]
	  	}

		this.router.events.subscribe(e => { 
		  if (e instanceof ResolveStart) {	
		  	currentPath = e.urlAfterRedirects

		  	if(currentPath !== "") {
		  		this.currentMainStep = currentPath.slice(1, currentPath.length).split("/")[1]
		  	}

		  }
		})
	}

	GoToMainStep(page: string){		
		let mainStep: string = page.split("/")[2]

		if(this.currentMainStep !== mainStep){
			let stp: string = mainStep == "project" ? "quotation" : mainStep
			
			if(this._creation.Get()[stp].isEnabled === true) {
				if(this._creation.IsStepEnable(stp, 0)){
					this._creation.SetCurrentFormModifiedFieldCount(0)	
					this._creation.SetFormSubIndex(0)
					this._creation.SetFormSubDetailsIndex(0)

					this.router.navigate([page])					
				}
			}			
		}
	}
}

