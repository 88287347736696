import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map, delay } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { of } from 'rxjs'

import { RemoteCustomers, CustomerEditableDatas, RemoteCustomerContracts, RemoteCustomerEvents } from '../models/customers/customer.model'

import { CONFIG } from '../app.constants'


@Injectable({
	providedIn: 'root'
})
export class CustomersService {

	constructor(
		private _http: HttpClient
    ) { }

    // HTTP

    // Get customers listing
    GetCustomers(): Observable<RemoteCustomers> {
         return this._http.get<any>(CONFIG.API[CONFIG.ENV] + "/customers")
            .pipe(
                map(
                    data => {
                        return data
                    }
                )
            )
    }


    // Get customer details
    GetCustomerDetails(customerId: string): Observable<any> {
           return this._http.get<any>(CONFIG.API[CONFIG.ENV] + "/customers/details/" + customerId)
            .pipe(
                map(
                    data => {
                        return data
                    }
                )
            )
    }

    // Update customer details
    UpdateCustomerDetails(customerId: string, customerEditableDatas: CustomerEditableDatas): Observable<any> {
        return this._http.put<any>(CONFIG.API[CONFIG.ENV] + "/customers/details/" + customerId + "/update",customerEditableDatas)
            .pipe(
                map(
                    data => {
                        return data
                    }
                )
            )
    }

    // Get Cities by PostCode
    GetCities(Postcode: string): Observable<any> {
        return this._http.get<any>(CONFIG.API[CONFIG.ENV] + "/search/cities/" + Postcode)
            .pipe(
                map(
                    data => {
                        return data
                    }
                )
            )
    }

    // Get Countries
    GetCountries(): Observable<any> {
        return this._http.get<any>(CONFIG.API[CONFIG.ENV] + "/search/countries")
            .pipe(
                map(
                    data => {
                        return data
                    }
                )
            )
    }
    
     // Get customers contracts
    GetCustomerContracts(customerId: string): Observable<RemoteCustomerContracts> {

        return this._http.get<any>(CONFIG.API[CONFIG.ENV] + "/customers/contracts/" + customerId)
            .pipe(
                map(
                    data => {
                        return data
                    }
                )
            )    
    }

     // Get customers events
    GetCustomerEvents(customerId: string): Observable<RemoteCustomerEvents> {
        return this._http.get<any>(CONFIG.API[CONFIG.ENV] + "/customers/events/" + customerId)
            .pipe(
                map(
                    data => {
                        return data
                    }
                )
            )       
    }
}
