import { Component, OnInit, Input, forwardRef, Output, EventEmitter, HostListener } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators } from '@angular/forms'
import { FormGroup } from '@angular/forms'
import { ModalComponent } from '../modal/modal.component'
import { SimpleModalService } from "ngx-simple-modal"
import { DatePipe } from '@angular/common'
import { Observable, Subject, of, interval  } from 'rxjs'
import { distinctUntilChanged, debounceTime, tap, switchMap, filter, catchError, debounce } from 'rxjs/operators'
// import { FileUploader } from 'ng2-file-upload'

import { formModel, formControl } from '../../models/forms/form.model'

import { UtilitiesService } from '../../services/utilities.service'
import { DurationMinusParam, FormsService } from '../../services/forms.service'
import { CreationService } from '../../services/creation.service'


@Component({
	selector: 'app-form-input',
	templateUrl: './form-input.component.html',
	styleUrls: ['./form-input.component.scss'],
	providers: [ 
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FormInputComponent),
			multi: true
		}
	]
})
export class FormInputComponent implements OnInit {

	@Output() changing = new EventEmitter<{value: any, itemName: string}>()
	@Input() item: formControl
	@Input() moduleName: string
  	@Input() form: FormGroup  	
  	@Input() formIndex: number
  	@Input() detailsIdx: number
  	@Input() formConfig: any
  	@Input() globalForm: Array<FormGroup>
  	@Input() globalFormConfig: Array<formModel>
	@Input()
		set isSubmit(isSubmit: boolean) {
			this.wasChanged = false
			this.InCtrl = false
			this._isSubmit = isSubmit
		}
		get isSubmit() { return this._isSubmit }

  	private _isSubmit: boolean = false
  	private wasChanged: boolean = false
  	private InCtrl: boolean = false
  	private file: File = null
  	public fakeDropdownVisible: boolean = false
  	public searchValue = new Subject<string>()
	  private sommeMontant:any;
	  private sommeDuree:any;
	  ValuesDurée:any
	  ValuesMontant:any
	_value: any = ''
	up:any;

	constructor(
		private _utilities: UtilitiesService,
		private _creation: CreationService,
		public _Forms: FormsService
	) {}

	get f() { return this.form.controls }
	
	ngOnInit() {
		
		//this.sommeMontant="55555"
		//this.sommeDuree="77777"
	
		this.wasChanged = false
		this.InCtrl = false
		this.ValuesDurée = localStorage.getItem('Durée');
		this.ValuesMontant = localStorage.getItem('Montant');
	



		if(this.item.Type == "file_upload") { 
			let frmIdx: number = this._Forms.GetFormStepIndex(this.moduleName, this.formConfig.Name)
			let frmStepIdx: number = this._creation.GetFormSubIndex()
			let frmDetailsIdx: number = this._creation.GetFormSubDetailsIndex()

			let frmFieldValue: any = this._creation.GetFormFieldValue(this.moduleName, frmIdx, frmStepIdx, frmDetailsIdx, this.item.Name)

			if(frmFieldValue && frmFieldValue != "" && frmFieldValue != [] && frmFieldValue !== null && frmFieldValue !== undefined) {
				this.file = frmFieldValue
			}
		}

		if(this.item.Type == "select2" && this.item.Searchable === true) {
			this.searchValue.pipe(
				
				filter(val => !!val),
				switchMap(term => this.SearchListApi(term)),
				catchError(() => {
					return of({
						error: 'no todo found'
					})
				})
		    )		    
		}
	}

  	propagateChange: any = () => { }
  
	writeValue(value: any) {
		if( value ){
			this._value = value 
		}
	}

	registerOnChange(fn) {
		this.propagateChange = fn
	}
	registerOnTouched(fn: () => void): void { }

	onChange(event){
		this.wasChanged = true



		if(this.item.Type == "text_autocomplete") {
			this.fakeDropdownVisible = false
		}

		if(event !== undefined && event.target) {
			let valLength = event.target.value.length
			if(event.target.type == "number") {

				if(event.target.maxLength > 0 && valLength >=  event.target.maxLength){
					event.target.value = event.target.value.slice(0,event.target.maxLength)
					this.f[this.item["Name"]].patchValue(event.target.value)
					return false
				}
			}

			if(event.target !== undefined) {
				this.propagateChange(event.target.value)				
			}
		}
		let currentValue = this.f[this.item["Name"]] !== undefined ? this.f[this.item["Name"]].value : null
		if(this.item["Type"] !== "card_select" && this.item["Type"] !== 'radio' && this.item["Type"] !== 'btn_radio'){			
			this.changing.emit({ value: currentValue, itemName: this.item.Name })
		}

		if(this.item["Type"] == "btn_check") {
			let vals: Array<any> = this.item.Values.filter( val => val.Checked === true)
			this.f[this.item["Name"]].patchValue(vals.length>0 ? true : "")
			this.changing.emit({ value: vals.length>0 ? true : "", itemName: this.item.Name })
		}

		this.ApplyAction(currentValue, this.item)
	}

	ApplyAction(currentValue: any, item: any) {	
		if(item["Actions"] && item["Actions"].length > 0 ) {			
			item["Actions"].forEach(
				action => {
					if(action && action.Type == "ApiCall_whenChange") {

						let currentCtrl: formControl = this.formConfig.Controls.find( ctlr => { return ctlr.Name == action.Target} )

						if(currentCtrl) {
							currentCtrl.isLoading = true
							currentCtrl.Values = []
						}

						if(typeof currentValue == "object") {
							currentValue = currentValue.Id
						}
						if(action.MinLength == 0 || (currentValue.length >= action.MinLength)) {
							let currentCtrl: formControl = this.formConfig.Controls.find( ctlr => { return ctlr.Name == action.Target} )

							this._utilities.formActionApiCall(action, this.form, currentValue, currentCtrl.Pagination)								
								.subscribe(
									data => { 
										if(action.ResultAction == "FillSelect"){     					

											if(currentCtrl) {
												let frmCtrl = this.form.controls[action.Target]

													if(action.ReplaceMethod == "Always"){
														frmCtrl.patchValue("")														
													}

												currentCtrl.Pagination = data.Pagination
												currentCtrl.Values = data.Items

												if(data &&  data.Items && data.Items.length == 0){
													currentCtrl.Values = []
													if(this.f[action.Target]) {
														if(action.ReplaceMethod == "Always"){

															this.f[action.Target].patchValue("")
															this.changing.emit({ value: "", itemName: action.Target })
														}													
													}
												}
												else {

													if(data &&  data.Items && action.AutoSelectFirst === true) {
														this.f[action.Target].patchValue(data[0])
														this.changing.emit({ value: data.Items[0], itemName: action.Target })
														
														let targetCtrl: any = this._Forms.getFormControl("quotation", action.TargetStep, action.Target)

														if(targetCtrl !== null) {
															this.ApplyAction(data.Items[0], targetCtrl)
														}
													}
												}	

												currentCtrl.isLoading = false		
											}
										}
									},
									Error => { console.log(Error) }
								)							
						}
						else {
							this.f[action.Target].patchValue("")
							this.changing.emit({ value: "", itemName: action.Target })

							currentCtrl.isLoading = false
						}
					}
				
					if(action && action.ResultAction == "SetValue_whenChange_fromSelect") {

						let currentCtrl: any = this.f[action.Target]
						currentValue = this.f[item["Name"]] !== undefined ? this.f[item["Name"]].value : null

						if(currentCtrl) {
							if(currentValue && currentValue[action.Mapping.Source] !== null && currentValue[action.Mapping.Source] !== undefined) {
								let tempValue: string = currentValue[action.Mapping.Source]
								currentCtrl.patchValue(tempValue)
								this.changing.emit({ value: tempValue, itemName: action.Target })								
							}
							else {
								if(action.Target != "Postcode") {
									currentCtrl.patchValue("")
									this.changing.emit({ value: "", itemName: action.Target })									
								}
							}
						}						
					}

					if(action && action.Type == "OnChange") {
						if(currentValue && typeof currentValue == "object") {
							currentValue = currentValue.Id
						}

						if(action && action.ResultAction == "HideField_whenChange") {
							let compareValue: any = this._utilities.getCompareValue(action, this.globalFormConfig, this.globalForm, this.form, item)
							let ruleResult: boolean = this._utilities.checkRule(compareValue, action.Params["Operator"], action.Params["Value"])
							let currentCtrl: any = this.formConfig.Controls.find( ctlr => { return ctlr.Name == action.Target} )

							if(ruleResult === true) {
								currentCtrl.ToHide = ruleResult		
								this.form.controls[action.Target].clearValidators()
								this.form.controls[action.Target].disable()
							}	
							else {
								if(currentCtrl.ToHide === false) {
									this.form.controls[action.Target].enable()
								}
							}	
						}						

						if(action && action.ResultAction == "ShowField_whenChange") {
							let compareValue: any = this._utilities.getCompareValue(action, this.globalFormConfig, this.globalForm, this.form, item)
							let ruleResult: boolean = this._utilities.checkRule(compareValue, action.Params["Operator"], action.Params["CompareValue"])
							let currentCtrl: any = this.formConfig.Controls.find( ctlr => { return ctlr.Name == action.Target} )
							
							currentCtrl.ToHide = !ruleResult		
							if(ruleResult === false) {
								this.form.controls[action.Target].disable()
							}	
							else {								 
								if(action.Params.Validators && action.Params.Validators.length > 0) {
									let valids: Array<any> = []
							        action.Params.Validators
							            .forEach(
							                v => {
							                    valids.push(this._Forms.getValidator(v.Type, v.Params))
							                }
							            )                       
							        this.form.controls[action.Target].setValidators(Validators.compose(valids))
							        this.form.controls[action.Target].updateValueAndValidity()

								}

								if(currentCtrl.Default != "" && currentCtrl.Default != null && currentCtrl.Default != undefined) {
									this.form.controls[action.Target].patchValue(currentCtrl.Default)
									this.changing.emit({ value: currentCtrl.Default, itemName: action.Target })

								}

								this.form.controls[action.Target].enable()
							}	
						}	

						if(action && action.ResultAction == "DisableField_whenChange") {
							let compareValue: any = this._utilities.getCompareValue(action, this.globalFormConfig, this.globalForm, this.form, item)
							let ruleResult: boolean = this._utilities.checkRule(compareValue, action.Params["Operator"], action.Params["Value"])
							let currentCtrl: any = this.formConfig.Controls.find( ctlr => { return ctlr.Name == action.Target} )
							
							currentCtrl.Disable = ruleResult			
							if(ruleResult === true) {
								this.form.controls[action.Target].disable()
							}	
							else {
								this.form.controls[action.Target].enable()
							}
						}

						if(action && action.ResultAction == "EnableField_whenChange") {
							let compareValue: any = this._utilities.getCompareValue(action, this.globalFormConfig, this.globalForm, this.form, item)
							let ruleResult: boolean = this._utilities.checkRule(compareValue, action.Params["Operator"], action.Params["Value"])
							let currentCtrl: any = this.formConfig.Controls.find( ctlr => { return ctlr.Name == action.Target} )
							
							currentCtrl.Disable = ruleResult			

							if(ruleResult === true) {
								this.form.controls[action.Target].enable()
							}	
							else {
								this.form.controls[action.Target].disable()
							}
						}

						if(action && action.ResultAction == "SetValue_whenChange") {
							let compareValue: any = this._utilities.getCompareValue(action, this.globalFormConfig, this.globalForm, this.form, item)
							let ruleResult: boolean = this._utilities.checkRule(compareValue, action.Params["Operator"], action.Params["Value"])
							let currentCtrl: any = this.formConfig.Controls.find( ctlr => { return ctlr.Name == action.Target} )

							if(ruleResult) {
								if(action.Params && action.Params.CompareValueType == "Fixe") {
									this.f[action.Target].patchValue(action.Params["CompareValue"])	
									this.changing.emit({ value: action.Params["CompareValue"], itemName: action.Target })

									if(action.Target == "Increments") {
										let step: number = this._Forms.GetFormStepIndex("quotation", action.TargetStep)
										let index: number = this._creation.GetFormSubIndex()
										let subDetailsIdx: number = this._creation.GetFormSubDetailsIndex()
										this._creation.SetFormFieldValue("quotation", step, index, subDetailsIdx, "Increments", action.Params["CompareValue"])		
										this._creation.Log()
									}
								}

								if(action.Params && action.Params.CompareValueType == "fromCurrentStep") {
									this.f[action.Target].patchValue(action.Params["CompareValue"])	
									this.changing.emit({ value: action.Params["CompareValue"], itemName: action.Target })
								}

								if(action.Params && action.Params.CompareValueType == "Date+XYears") {
									let datePipe = new DatePipe('en-US')
									// let dateSplit = currentValue.split('/')

									// let tmpCurrentValue = dateSplit[1] + "-" + dateSplit[0] + "-" + dateSplit[2]
									
									let tempDate: any = this._Forms.getDate(currentValue)

									if(tempDate != "Invalid Date") {
										let tempDateToStr: string = datePipe.transform(tempDate, 'dd/MM/yyyy')										
									
										tempDate =  new Date(tempDate.setUTCFullYear(tempDate.getUTCFullYear() + action.Params["Value"]))

										tempDateToStr = datePipe.transform(tempDate, 'dd/MM/yyyy')

										this.f[action.Target].patchValue(tempDateToStr)	
										this.changing.emit({ value: tempDateToStr, itemName: action.Target })										
									}

								}
							}						
						}

						if(action && action.ResultAction == "ResetValue_FromCurrentStep") {
							let compareValue: any = this._utilities.getCompareValue(action, this.globalFormConfig, this.globalForm, this.form, item)
							let ruleResult: boolean = this._utilities.checkRule(compareValue, action.Params["Operator"], action.Params["CompareValue"])
														
							if(ruleResult) {
								if(action.Params && action.Params.CompareValueType == "Fixe") {
									this.form.controls[action.Target].patchValue("")
									this.changing.emit({ value: "", itemName: action.Target })
								}
							}						
						}	

						if(action && action.ResultAction == "ResetValue_ToNextStep") {
							let compareValue: any = this._utilities.getCompareValue(action, this.globalFormConfig, this.globalForm, this.form, item)
							let ruleResult: boolean = this._utilities.checkRule(compareValue, action.Params["Operator"], action.Params["CompareValue"])
														
							if(ruleResult) {
								if(action.Params && action.Params.CompareValueType == "Fixe") {
									let frmIdx: number = this._Forms.GetFormStepIndex(action.TargetModule, action.TargetStep)

									this._creation.SetFormFieldValue(action.TargetModule, frmIdx, 0 , 0, action.Target, action.Params.Value)
								}
							}						
						}	

						if(action && action.ResultAction == "SetValue_whenChangeWithCombinedParams") {
							if(action.Params) {
								let result: boolean = true

								action.Params.forEach(
									param => {
										let sourceFrmIdx = this.globalFormConfig.findIndex( f => f.Name ==  param.SourceStep)
										let sourceValue: any = this._creation.GetFormFieldValue(param.SourceModule, sourceFrmIdx, this._creation.GetFormSubIndex(), this._creation.GetFormSubDetailsIndex(), param.SourceField)

										if(typeof sourceValue == "object") {
											sourceValue = sourceValue.Value
										}

										let ruleResult: boolean = this._utilities.checkRule(sourceValue, param["Operator"], param["Value"])

										if(!ruleResult) {
											result = false
										}
									}
								)

								if(result) {
									if(action.ReplaceValueMode == "fixe") {
										this.f[action.Target].patchValue(action["ReplaceValue"])	
										this.changing.emit({ value: action["ReplaceValue"], itemName: action.Target })
									}
								}
							}					
						}

						if(action && action.ResultAction == "HideField_whenChangeWithCombinedParams") {
							if(action.Params) {
								let result: boolean = true

								action.Params.forEach(
									param => {
										let sourceFrmIdx = this.globalFormConfig.findIndex( f => f.Name ==  param.SourceStep)
										let sourceValue: any = this._creation.GetFormFieldValue(param.SourceModule, sourceFrmIdx, this._creation.GetFormSubIndex(), this._creation.GetFormSubDetailsIndex(), param.SourceField)

										if(typeof sourceValue == "object") {
											sourceValue = sourceValue.Value
										}

										let ruleResult: boolean = this._utilities.checkRule(sourceValue, param["Operator"], param["Value"])

										if(!ruleResult) {
											result = false
										}
									}
								)

								let currentCtrl: any = this.formConfig.Controls.find( ctlr => { return ctlr.Name == action.Target} )
							
								if(currentCtrl) {
									if(result) {									
										currentCtrl.ToHide = true		
										this.form.controls[action.Target].clearValidators()
										this.form.controls[action.Target].disable()
									}								
									else if(currentCtrl.ToHide === false) {
										this.form.controls[action.Target].enable()
									}
								}
							}		
						}

						if(action && action.ResultAction == "DisableField_whenChangeWithCombinedParams") {
							if(action.Params) {
								let result: boolean = true

								action.Params.forEach(
									param => {
										let sourceFrmIdx = this.globalFormConfig.findIndex( f => f.Name ==  param.SourceStep)
										let sourceValue: any = this._creation.GetFormFieldValue(param.SourceModule, sourceFrmIdx, this._creation.GetFormSubIndex(), this._creation.GetFormSubDetailsIndex(), param.SourceField)

										if(typeof sourceValue == "object") {
											sourceValue = sourceValue.Value
										}

										let ruleResult: boolean = this._utilities.checkRule(sourceValue, param["Operator"], param["Value"])

										if(!ruleResult) {
											result = false
										}
									}
								)

								let currentCtrl: any = this.formConfig.Controls.find( ctlr => { return ctlr.Name == action.Target} )

								if(currentCtrl) {
									if(result) {									
										this.form.controls[action.Target].disable()
									}						
								}
							}		
						}

						if(action && action.ResultAction == "EnableField_whenChangeWithCombinedParams") {
							if(action.Params) {
								let result: boolean = true

								action.Params.forEach(
									param => {
										let sourceFrmIdx = this.globalFormConfig.findIndex( f => f.Name ==  param.SourceStep)
										let sourceValue: any = this._creation.GetFormFieldValue(param.SourceModule, sourceFrmIdx, this._creation.GetFormSubIndex(), this._creation.GetFormSubDetailsIndex(), param.SourceField)

										if(typeof sourceValue == "object") {
											sourceValue = sourceValue.Value
										}

										let ruleResult: boolean = this._utilities.checkRule(sourceValue, param["Operator"], param["Value"])

										if(!ruleResult) {
											result = false
										}
									}
								)

								let currentCtrl: any = this.formConfig.Controls.find( ctlr => { return ctlr.Name == action.Target} )

								if(currentCtrl) {
									if(result) {									
										this.form.controls[action.Target].enable()
									}						
								}
							}		
						}

						if(action && action.ResultAction == "SetProperty_whenChangeWithCombinedParams") {
							if(action.Params) {
								let result: boolean = true

								action.Params.forEach(
									param => {
										let sourceFrmIdx = this.globalFormConfig.findIndex( f => f.Name ==  param.SourceStep)
										let sourceValue: any = this._creation.GetFormFieldValue(param.SourceModule, sourceFrmIdx, this._creation.GetFormSubIndex(), this._creation.GetFormSubDetailsIndex(), param.SourceField)

										if(typeof sourceValue == "object") {
											sourceValue = sourceValue.Value
										}

										let ruleResult: boolean = this._utilities.checkRule(sourceValue, param["Operator"], param["Value"])

										if(!ruleResult) {
											result = false
										}
									}
								)

								if(result) {	
									let formToSearch: any = this.globalFormConfig.find( elt => elt.Name == action.TargetStep )

									if(formToSearch) {
										let itemToSet: any = formToSearch.Controls.find( elt => elt.Name == action.Target )

										if(itemToSet) {
											if(itemToSet[action.TargetProperty] !== null && itemToSet[action.TargetProperty] !== undefined) {
												itemToSet[action.TargetProperty] = action.PropertyValue
											}										
										}
									}
								}
							}		
						}

						if(action && action.ResultAction == "SetOtherFieldValidatorsWithCombinedParams") {
							let checkRuleResult: boolean = true

							let formIdx: number = this.globalFormConfig.findIndex( frm => { return frm.Name == action.TargetStep } )

							if(formIdx) {
								let frmCtrl = this.globalForm[formIdx].controls[action.Target]
								if(frmCtrl) {

									if(action.Params && action.Params.length > 0) {
										action.Params.forEach(
											param => {
												let compareValue: any
												let tmpCheckRuleResult: boolean

												if(param.CompareValueType == "fromPreviousStep") {
													let compareFrmIdx = this.globalFormConfig.findIndex( f => f.Name ==  param.CompareValueForm)

													if(compareFrmIdx !== null && compareFrmIdx !== undefined && compareFrmIdx !== -1) {
														compareValue = this._creation.GetFormFieldValue(param.CompareValueModule, compareFrmIdx, 0, 0, param.CompareValueField)

														if(compareValue) {
															tmpCheckRuleResult = this._utilities.checkRule(compareValue, param.Operator, param.Value)
														}
													}
												}
												else {
													let compareFrmIdx = this.globalFormConfig.findIndex( f => f.Name ==  param.CompareValueForm)													

													compareValue = this._creation.GetFormFieldValue(param.CompareValueModule, compareFrmIdx, this._creation.GetFormSubIndex(), this._creation.GetFormSubDetailsIndex(), param.CompareValueField)
													if(compareValue && typeof compareValue == "object") {
														compareValue = compareValue.Value
													}

													tmpCheckRuleResult = this._utilities.checkRule(compareValue, param.Operator, param.Value)
												}

												if(tmpCheckRuleResult === false) {
													checkRuleResult = false
												}
											}
										)
										
										if(checkRuleResult === true) {
											if(action.SetValidators && action.SetValidators.length > 0) {
												frmCtrl.clearValidators()
												let valids: Array<any> = []
							                    action.SetValidators
							                        .forEach(
							                            v => {	
							                            	if(v.Type && v.Type != "clearValidators"){
							                            		switch (v.Type) {
							                            			case "minValueWithParams":
							                            				let SourceFormIdx1: number = this.globalFormConfig.findIndex( frm => { return frm.Name == v.Params.SourceStep } )
									                            		let SourceForm1: FormGroup = this.globalForm[SourceFormIdx1]
									                            		
									                            		if(SourceForm1) {
									                            			let SourceField = this.form.controls[v.Params.SourceField]
									                            			let Variation: number = v.Params.VariationValue !== null && v.Params.VariationValue !== undefined ? v.Params.VariationValue : 0
									                            			if(SourceField) {
									                            				let ValidatorValue: number = parseFloat(SourceField.value) + Variation
									                            				valids.push(this._Forms.getValidator(v.Type, ValidatorValue))
									                            			}
									                            		}
							                            				break

							                            			case "maxValueWithParams":
							                            				let SourceFormIdx2: number = this.globalFormConfig.findIndex( frm => { return frm.Name == v.Params.SourceStep } )
									                            		let SourceForm2: FormGroup = this.globalForm[SourceFormIdx2]
									                            		if(SourceForm2) {
									                            			let SourceField = this.form.controls[v.Params.SourceField]
									                            			let Variation: number = v.Params.VariationValue !== null && v.Params.VariationValue !== undefined ? v.Params.VariationValue : 0
									                            			if(SourceField) {
									                            				let ValidatorValue: number = parseFloat(SourceField.value) + Variation
									                            				valids.push(this._Forms.getValidator(v.Type, ValidatorValue))
									                            			}
									                            		}
							                            				break

																	case "minParams":
																		valids.push(this._Forms.getValidator(v.Type, v.Params))
																		break

							                            			case "maxParams":
									                            		valids.push(this._Forms.getValidator(v.Type, v.Params))
							                            				break

						                            				case "EqualsTo":
						                            					valids.push(this._Forms.getValidator(v.Type, v.Params))
							                            				break

						                            				case "NotEqualsTo":
									                            		valids.push(this._Forms.getValidator(v.Type, v.Params))
							                            				break

							                            			case "CheckAllShare100":
							                            				let frmIndex: number = this._Forms.GetFormStepIndex(action.TargetModule, action.TargetStep)
																		let formSubIndex: number = this._creation.GetFormSubIndex()
							                            				let frmLength: number = this._creation.GetFormSubStepDetailsLength(action.TargetModule, frmIndex, formSubIndex)

							                            				v.Params = []

							                            				for (var i = 0; i < frmLength; i++) {
							                            					v.Params.push(this._creation.GetFormFieldValue(action.TargetModule, frmIndex, formSubIndex, i, action.Target))
							                            				}

							                            				valids.push(this._Forms.getValidator(v.Type, v.Params))
							                            				break
							                            			
							                            			case "DateBourquin":
							                            			case "DateHamon":
							                            			case "LowerThan85YearsOld":
							                            			case "GreaterThan18Y":
							                            			case "LowerThan85Y":
							                            			case "GreaterThan18YearsOld":
							                            			case "DateGreaterThanDateDeblocage":
							                            			case "DateLowerThanNow":
							                            			case "LowerThanHamon":
							                            			case "IsDateValid":
																		let compareFrmIdx = this.globalFormConfig.findIndex( f => f.Name ==  v.Params.CompareValueForm)

																		if(compareFrmIdx !== null && compareFrmIdx !== undefined && compareFrmIdx !== -1) {
																			let compareValue: any = this._creation.GetFormFieldValue(v.Params.CompareValueModule, compareFrmIdx, this.formIndex, this.detailsIdx, v.Params.CompareValueField)

																			if(v.Type == "GreaterThan18Y" || v.Type == "LowerThan85Y") {
																				compareValue = new Date(Date.now())
																			} 
																			else if(v.Type == "IsDateValid"){
																				valids.push(this._Forms.getValidator(v.Type, v.Params))
																			}

																			if(compareValue && v.Type != "IsDateValid") {
																				if(compareValue) {
																					v.Params.compareValue = this._Forms.CalculateCompareDate(compareValue, v.Params.Unit, v.Params.Val)
																					v.Params.Control = this.form.controls[action.Target]
																					v.Params.ValidatorType = v.Type

																					let validation = this._Forms.getValidator(v.Type, v.Params)
																					valids.push(validation)
																				}
																			}
																		}
													

																		valids.push(this._Forms.getValidator(v.Type, v.Params))
							                            				break

							                            			case "HamonEffectiveDateValidity":
							                            				let today = new Date(Date.now())
																		today.setHours(0,0,0,0)

																		v.Params.compareValue = this._Forms.CalculateCompareDate(today, v.Params.Unit, v.Params.Val)
																		v.Params.Control = this.form.controls[action.Target]
																		v.Params.ValidatorType = v.Type

																		let validation = this._Forms.getValidator(v.Type, v.Params)
																		valids.push(validation)
							                            				break

							                            			case "DurationMinusParam":
							                            				let formIndex: number = this._Forms.GetFormStepIndex(action.TargetModule, action.TargetStep)

							                            				let duration = this._creation.GetFormFieldValue(action.TargetModule, formIndex, this._creation.GetFormSubIndex(), this._creation.GetFormSubDetailsIndex(), "Duration")
						                            					if(duration) {
								                            				v.Params.duration = Number(duration)

																			valids.push(this._Forms.getValidator(v.Type, v.Params))						                            						
						                            					}
																		break

							                            			default:	
							                            				valids.push(this._Forms.getValidator(v.Type))						                            				
							                            				break
							                            		}									                            											                            
							                            	}
							                            }
							                        )       

							                    this.form.controls[action.Target].setValidators(Validators.compose(valids))
							                    this.form.controls[action.Target].patchValue(this.form.controls[action.Target].value)
											}
										}
									}
								}
							}
						}

						if(action && action.ResultAction == "SetOtherFieldValidators") {
							let formIdx: number = this.globalFormConfig.findIndex( frm => { return frm.Name == action.TargetStep } )
							if(formIdx) {
								let frmCtrl = this.globalForm[formIdx].controls[action.Target]
								if(frmCtrl) {

									if(action.Params && action.Params.length > 0) {
										action.Params.forEach(
											param => {
												let compareValue: any
												let checkRuleResult: boolean

												if(param.CompareValueType == "fromPreviousStep") {
													let compareFrmIdx = this.globalFormConfig.findIndex( f => f.Name ==  param.CompareValueForm)

													if(compareFrmIdx !== null && compareFrmIdx !== undefined && compareFrmIdx !== -1) {
														compareValue = this._creation.GetFormFieldValue(param.CompareValueModule, compareFrmIdx, 0, 0, param.CompareValueField)

														if(compareValue) {
															checkRuleResult = this._utilities.checkRule(compareValue, param.Operator, param.Value)
														}
													}
												}
												else {
													let compareValue: any

													checkRuleResult = this._utilities.checkRule(currentValue, param.Operator, param.Value)
												}

												if(checkRuleResult === true) {
													if(action.SetValidators && action.SetValidators.length > 0) {
														frmCtrl.clearValidators()
														let valids: Array<any> = []
									                    action.SetValidators
									                        .forEach(
									                            v => {	
									                            	if(v.Type && v.Type != "clearValidators"){
									                            		switch (v.Type) {
									                            			case "minValueWithParams":
									                            				let SourceFormIdx1: number = this.globalFormConfig.findIndex( frm => { return frm.Name == v.Params.SourceStep } )
											                            		let SourceForm1: FormGroup = this.globalForm[SourceFormIdx1]
											                            		
											                            		if(SourceForm1) {
											                            			let SourceField = this.form.controls[v.Params.SourceField]
											                            			let Variation: number = v.Params.VariationValue !== null && v.Params.VariationValue !== undefined ? v.Params.VariationValue : 0
											                            			if(SourceField) {
											                            				let ValidatorValue: number = parseFloat(SourceField.value) + Variation
											                            				valids.push(this._Forms.getValidator(v.Type, ValidatorValue))
											                            			}
											                            		}
									                            				break

									                            			case "maxValueWithParams":
									                            				let SourceFormIdx2: number = this.globalFormConfig.findIndex( frm => { return frm.Name == v.Params.SourceStep } )
											                            		let SourceForm2: FormGroup = this.globalForm[SourceFormIdx2]
											                            		if(SourceForm2) {
											                            			let SourceField = this.form.controls[v.Params.SourceField]
											                            			let Variation: number = v.Params.VariationValue !== null && v.Params.VariationValue !== undefined ? v.Params.VariationValue : 0
											                            			if(SourceField) {
											                            				let ValidatorValue: number = parseFloat(SourceField.value) + Variation
											                            				valids.push(this._Forms.getValidator(v.Type, ValidatorValue))
											                            			}
											                            		}
									                            				break
																			
																			case "minParams":
																				valids.push(this._Forms.getValidator(v.Type, v.Params))
																				break
																				
									                            			case "maxParams":
											                            		valids.push(this._Forms.getValidator(v.Type, v.Params))
									                            				break

									                            			case "CheckAllShare100":
									                            				let frmIndex: number = this._Forms.GetFormStepIndex(action.TargetModule, action.TargetStep)
																				let formSubIndex: number = this._creation.GetFormSubIndex()
									                            				let frmLength: number = this._creation.GetFormSubStepDetailsLength(action.TargetModule, frmIndex, formSubIndex)

									                            				v.Params = []

									                            				for (var i = 0; i < frmLength; i++) {
									                            					v.Params.push(this._creation.GetFormFieldValue(action.TargetModule, frmIndex, formSubIndex, i, action.Target))
									                            				}

									                            				valids.push(this._Forms.getValidator(v.Type, v.Params))
									                            				break
									                            			
									                            			case "DateBourquin":
									                            			case "DateHamon":
									                            			case "LowerThan85YearsOld":
									                            			case "GreaterThan18Y":
									                            			case "LowerThan85Y":
									                            			case "GreaterThan18YearsOld":
									                            			case "DateGreaterThanDateDeblocage":
									                            			case "DateLowerThanNow":
									                            			case "LowerThanHamon":
									                            			case "IsDateValid":
																				let compareFrmIdx = this.globalFormConfig.findIndex( f => f.Name ==  v.Params.CompareValueForm)

																				if(compareFrmIdx !== null && compareFrmIdx !== undefined && compareFrmIdx !== -1) {
																					let compareValue: any = this._creation.GetFormFieldValue(v.Params.CompareValueModule, compareFrmIdx, this.formIndex, this.detailsIdx, v.Params.CompareValueField)

																					if(v.Type == "GreaterThan18Y" || v.Type == "LowerThan85Y") {
																						compareValue = new Date(Date.now())
																					} 
																					else if(v.Type == "IsDateValid"){
																						valids.push(this._Forms.getValidator(v.Type, v.Params))
																					}

																					if(compareValue && v.Type != "IsDateValid") {
																						if(compareValue) {
																							v.Params.compareValue = this._Forms.CalculateCompareDate(compareValue, v.Params.Unit, v.Params.Val)
																							v.Params.Control = this.form.controls[action.Target]
																							v.Params.ValidatorType = v.Type

																							let validation = this._Forms.getValidator(v.Type, v.Params)
																							valids.push(validation)
																						}
																					}
																				}
															

																				valids.push(this._Forms.getValidator(v.Type, v.Params))
									                            				break

									                            			case "HamonEffectiveDateValidity":
									                            				let today = new Date(Date.now())
																				today.setHours(0,0,0,0)

																				v.Params.compareValue = this._Forms.CalculateCompareDate(today, v.Params.Unit, v.Params.Val)
																				v.Params.Control = this.form.controls[action.Target]
																				v.Params.ValidatorType = v.Type

																				let validation = this._Forms.getValidator(v.Type, v.Params)
																				valids.push(validation)
									                            				break

									                            			default:	
									                            				valids.push(this._Forms.getValidator(v.Type))						                            				
									                            				break
									                            		}									                            											                            
									                            	}
									                            }
									                        )       

									                    this.form.controls[action.Target].setValidators(Validators.compose(valids))
									                    this.form.controls[action.Target].patchValue(this.form.controls[action.Target].value)
													}
												}
											}
										)
									}
								}
							}
						}

						if(action && action.ResultAction == "SetAllSubStepDetailsFieldValue_whenChange") {

							let ApplyAction: boolean = true

							if(action.Params) {
								
								action.Params.forEach(
									param => {
										if(param.Operator !== "NO"){
											let frmIdx: number = this._Forms.GetFormStepIndex(param.CompareValueModule, param.CompareValueForm)
																	
											if(param.CompareValueType == "fromPreviousStep") {
												let frmValue: any = this._creation.GetFormFieldValue(param.CompareValueModule, frmIdx, 0, 0,  param.CompareValueField)
												if(!this._utilities.checkRule(frmValue, param.Operator, param.CompareValue)) {
													ApplyAction = false												
												}
											}
										}												
									}
								)
							}


							if(ApplyAction === true) {
								let frmIdx: number = this._Forms.GetFormStepIndex(action.TargetModule, action.TargetStep)
								let frmSubIndex: number = this._creation.GetFormSubIndex()
								let frmSubStepDetailsLength: number = this._creation.GetFormSubStepDetailsLength(action.TargetModule, frmIdx, frmSubIndex)

								if(currentValue && currentValue !== null && currentValue !== undefined && currentValue.length >= action.Action.MinLengthToApply) {
									if(action.Action && action.Action.CompareValueType == "Bourquin_Date+XYears") {
										let datePipe = new DatePipe('en-US')

										let tempDate: any = this._Forms.getDate(currentValue)
										if(tempDate != "Invalid Date") {
											let tempDateToStr: string									
											let today: any = new Date(Date.now()).toLocaleDateString('en-US')
											let ctrlDateToActualYearDate: Date = new Date(new Date(tempDate).setFullYear(new Date(Date.now()).getFullYear()))

											let Preavis: string

											switch (action.Action.NotcePeriodUnit) {
												case "month":
													Preavis = new Date(ctrlDateToActualYearDate.setMonth(ctrlDateToActualYearDate.getMonth() + action.Action.NotcePeriodValue)).toLocaleDateString('en-US')
													break

												case "year":
													Preavis = new Date(ctrlDateToActualYearDate.setFullYear(ctrlDateToActualYearDate.getFullYear() + action.Action.NotcePeriodValue)).toLocaleDateString('en-US')
													break
												
												default:
													break
											}
											
											let SignEndDate: string = new Date(new Date(tempDate).setMonth(tempDate.getMonth()+12)).toLocaleDateString('en-US')
											let YearIncrease: number = 0

											if(new Date(SignEndDate) >= new Date(today)) {
												YearIncrease++
											}

											if(new Date(today) > new Date(Preavis)) {
												YearIncrease++
											}

											tempDate = new Date(tempDate).setFullYear(new Date(Date.now()).getFullYear() + YearIncrease)
											tempDateToStr = datePipe.transform(tempDate, 'dd/MM/yyyy')									

											if(frmSubStepDetailsLength > 1) {
												for (var i = 0; i < frmSubStepDetailsLength; i++) {
												this._creation.SetFormFieldValue(action.TargetModule, frmIdx, frmSubIndex , i, action.Target, tempDateToStr)
												}
											}

											this.f[action.Target].patchValue(tempDateToStr)	
											this.changing.emit({ value: tempDateToStr, itemName: action.Target })			
										}
									}	
								}
								else {
									if(frmSubStepDetailsLength > 1) {
										for (var i = 0; i < frmSubStepDetailsLength; i++) {
											this._creation.SetFormFieldValue(action.TargetModule, frmIdx, frmSubIndex , i, action.Target, "")
										}
									}

									this.f[action.Target].patchValue("")	
									this.changing.emit({ value: "", itemName: action.Target })
								}
							}
						}

						if(action && action.ResultAction == "SetAllStepDetailsFieldValue") {
							let frmIdx: number = this._Forms.GetFormStepIndex(action.TargetModule, action.TargetStep)
							let frmLength: number = this._creation.GetFormSubStepLength(action.TargetModule, frmIdx)
							let currentSubDetailsStepIdx: number = this._creation.GetFormSubDetailsIndex()

							if(frmLength > 1) {
								for (var i = 0; i < frmLength; i++) {
									this._creation.SetFormFieldValue(action.TargetModule, frmIdx, i , currentSubDetailsStepIdx, action.Target, currentValue)
								}
							}							
						}

						if(action && action.ResultAction == "SetAllSubStepDetailsFieldValue") {
							let frmIdx: number = this._Forms.GetFormStepIndex(action.TargetModule, action.TargetStep)
							let frmSubIndex: number = this._creation.GetFormSubIndex()
							let frmSubStepDetailsLength: number = this._creation.GetFormSubStepDetailsLength(action.TargetModule, frmIdx, frmSubIndex)

							if(frmSubStepDetailsLength > 1) {
								for (var i = 0; i < frmSubStepDetailsLength; i++) {
									this._creation.SetFormFieldValue(action.TargetModule, frmIdx, frmSubIndex , i, action.Target, currentValue)
								}
							}							
						}

						if(action && action.ResultAction == "ExecuteCSP") {
							let csp: string = this._Forms.getCSP(this.form)
							this.form.controls[action.Target].patchValue(csp)

							this.changing.emit({ value: csp, itemName: action.Target })							
						}	

						if(action && action.ResultAction == "OpenIncrementsModal") {
							let compareValue: any = this._utilities.getCompareValue(action, this.globalFormConfig, this.globalForm, this.form, item)
							let ruleResult: boolean = this._utilities.checkRule(compareValue, action.Params["Operator"], action.Params["CompareValue"])
						
							if(ruleResult) {
								if(action.Params && action.Params.CompareValueType == "Fixe") {
									this.openIncrementsModal()				
								}
							}	
						}	

						if(action && action.ResultAction == "SetProperty") {
							let compareValue: any = this._utilities.getCompareValue(action, this.globalFormConfig, this.globalForm, this.form, item)
							let ruleResult: boolean = this._utilities.checkRule(compareValue, action.Params["Operator"], action.Params["CompareValue"])
					
							if(ruleResult) {
								if(action.Params && action.Params.CompareValueType == "Fixe") {
									if(item[action.TargetProperty] !== null && item[action.TargetProperty] !== undefined) {
										item[action.TargetProperty] = action.PropertyValue
									}
								}

								if(action.Params && action.Params.CompareValueType == "fromCurrentStep") {
									if(item[action.TargetProperty] !== null && item[action.TargetProperty] !== undefined) {

										if(action.TargetProperty == "ReadOnly" && action.PropertyValue == "true") {
											this.f[action.Target].disable()
										}
										else if(action.TargetProperty == "ReadOnly" && action.PropertyValue == "false") {
											this.f[action.Target].enable()
										}
										else {
											item[action.TargetProperty] = action.PropertyValue											
										}
									}
								}
							}	
						}		
					}
				}
			)			
		}
	}

	onClick(event) {
		if(event !== undefined) {
			if(event.target !== undefined) {
				this.propagateChange(event.target.value)				
			}
		}

		let currentValue: any
		if(this.item["Type"] == "card_select" || this.item["Type"] == 'radio' || this.item["Type"] == 'btn_radio'){
			let tempValue: any = this.f[this.item["Name"]].value

			if(this.item.ReadOnly !== true) {
				currentValue = event
				this.f[this.item["Name"]].patchValue(event)
				this.changing.emit({ value: currentValue, itemName: this.item.Name })
			}
			else {
				this.f[this.item["Name"]].patchValue(tempValue)
			}

		}
		else {
			if(this.item["Type"] == "btn_check") {
				event.Checked = !event.Checked
			}
			else {
				currentValue = this.f[this.item["Name"]] !== undefined ? this.f[this.item["Name"]].value : null				
			}
		}

		if(this.item["Actions"] && this.item["Actions"].length > 0 ) {			
			this.item["Actions"].forEach(
				action => {
					if(action && action.Type == "ApiCall_whenClick") {
						let currentCtrl: any = this.formConfig.Controls.find( ctlr => { return ctlr.Name == action.Target} )

						this._utilities.formActionApiCall(action, this.form, currentValue, currentCtrl.Pagination)
							.subscribe(
								data => { 
									if(action.ResultAction == "FillSelect"){     					
										if(currentCtrl) {
											currentCtrl.Values = data

											if(data.length == 0){
												currentCtrl.value = ""
												if(this.f[action.Target]) {
													this.f[action.Target].patchValue("")													
												}
											}			
										}
									}
								},
								Error => { console.log(Error) }
							)
					}

					if(action && action.Type == "OnClick") {

						if(action && action.ResultAction == "EnableField_whenClick") {
							let ruleResult: boolean = this._utilities.checkRule(currentValue, action.Params["Operator"], action.Params["Value"])
							let currentCtrl: any = this.formConfig.Controls.find( ctlr => { return ctlr.Name == action.Target} )					

							if(ruleResult === true) {
								this.form.controls[action.Target].enable()
							}	
							else {
								this.form.controls[action.Target].patchValue("")
								this.form.controls[action.Target].disable()
							}
						}

						if(action && action.ResultAction == "DisableField_whenClick") {
							let ruleResult: boolean = this._utilities.checkRule(currentValue, action.Params["Operator"], action.Params["Value"])
							let currentCtrl: any = this.formConfig.Controls.find( ctlr => { return ctlr.Name == action.Target} )					

							if(ruleResult === true) {
								this.form.controls[action.Target].patchValue("")
								this.form.controls[action.Target].disable()
							}	
							else {
								this.form.controls[action.Target].enable()
							}
						}

						if(action && action.ResultAction == "ShowField") {
							let compareValue: any = this._utilities.getCompareValue(action, this.globalFormConfig, this.globalForm, this.form, this.item)
							let ruleResult: boolean = this._utilities.checkRule(compareValue, action.Params["Operator"], action.Params["CompareValue"])
							let currentCtrl: any = this.formConfig.Controls.find( ctlr => { return ctlr.Name == action.Target} )
							
							currentCtrl.ToHide = !ruleResult		
							if(ruleResult === false) {

								this.form.controls[action.Target].patchValue("")
								this.form.controls[action.Target].disable()
							}	
							else {
								if(action.Params.IsRequired) {
									this.form.controls[action.Target].setValidators(Validators.required)
								}

								if(currentCtrl.Default != "" && currentCtrl.Default != null && currentCtrl.Default != undefined) {
									this.form.controls[action.Target].patchValue(currentCtrl.Default)
									this.changing.emit({ value: currentCtrl.Default, itemName: action.Target })

								}

								this.form.controls[action.Target].enable()
							}	
						}

						if(action && action.ResultAction == "ExecuteCSP") {
							let csp: string = this._Forms.getCSP(this.form)
							this.form.controls[action.Target].patchValue(csp)
							
							this.changing.emit({ value: csp, itemName: action.Target })							
						}

						if(action && action.ResultAction == "SetOtherFieldValidators") {
							let formIdx: number = this.globalFormConfig.findIndex( frm => { return frm.Name == action.TargetStep } )
							if(formIdx) {
								let frmCtrl = this.globalForm[formIdx].controls[action.Target]

								if(frmCtrl) {
									if(action.Params && action.Params.length > 0) {
										action.Params.forEach(
											param => {
												if(this._utilities.checkRule(currentValue, param.Operator, param.CompareValue) === true) {												
													if(param.SetValidators && param.SetValidators.length > 0) {
														frmCtrl.clearValidators()

														let valids: Array<any> = []
									                    param.SetValidators
									                        .forEach(
									                            v => {
									                            	if(v.Type && v.Type != "clearValidators"){
									                            		switch (v.Type) {
									                            			case "minValueWithParams":
									                            				let SourceFormIdx1: number = this.globalFormConfig.findIndex( frm => { return frm.Name == v.Params.SourceStep } )
											                            		let SourceForm1: FormGroup = this.globalForm[SourceFormIdx1]
											                            		
											                            		if(SourceForm1) {
											                            			let SourceField = this.form.controls[v.Params.SourceField]
											                            			let Variation: number = v.Params.VariationValue !== null && v.Params.VariationValue !== undefined ? v.Params.VariationValue : 0
											                            			if(SourceField) {
											                            				let ValidatorValue: number = parseFloat(SourceField.value) + Variation
											                            				valids.push(this._Forms.getValidator(v.Type, ValidatorValue))
											                            			}
											                            		}
									                            				break

									                            			case "maxValueWithParams":
									                            				let SourceFormIdx2: number = this.globalFormConfig.findIndex( frm => { return frm.Name == v.Params.SourceStep } )
											                            		let SourceForm2: FormGroup = this.globalForm[SourceFormIdx2]
											                            		if(SourceForm2) {
											                            			let SourceField = this.form.controls[v.Params.SourceField]
											                            			let Variation: number = v.Params.VariationValue !== null && v.Params.VariationValue !== undefined ? v.Params.VariationValue : 0
											                            			if(SourceField) {
											                            				let ValidatorValue: number = parseFloat(SourceField.value) + Variation
											                            				valids.push(this._Forms.getValidator(v.Type, ValidatorValue))
											                            			}
											                            		}
									                            				break
									                            			
									                            			case "clear":
									                            				break

									                            			default:			
									                            				valids.push(this._Forms.getValidator(v.Type))						                            				
									                            				break
									                            		}									                            											                            
									                            	}
									                            }
									                        )                       
									                    this.form.controls[action.Target].setValidators(Validators.compose(valids))
									                    this.form.controls[action.Target].patchValue(this.form.controls[action.Target].value)
													}
												}
											}
										)
									}
								}
							}
						}

						if(action && action.ResultAction == "SetValue_fromPreviousStep") {
							if(action.Params && action.Params.length>0){
								let ApplyAction: boolean = true

								action.Params.forEach(
									param => {
										let frmIdx: number = this._Forms.GetFormStepIndex(param.CompareValueModule, param.CompareValueForm)

										if(param.CompareValueType == "fromCurrentStep") {
											let frmValue: any = this.f[this.item["Name"]].value
											
											if(!this._utilities.checkRule(frmValue, param.Operator, param.CompareValue)) {
												ApplyAction = false												
											}
										}	

										if(param.CompareValueType == "fromPreviousStep") {
											let frmValue: any = this._creation.GetFormFieldValue(param.CompareValueModule, frmIdx, 0, 0,  param.CompareValueField)

											if(!this._utilities.checkRule(frmValue, param.Operator, param.CompareValue)) {
												ApplyAction = false												
											}
										}									
									}
								)

								if(ApplyAction === true) {	
									if(action.Property == "Value") {
										if(action.ReplaceMethod == "Always") {
											let frmIdx: number = this._Forms.GetFormStepIndex(action.Mapping.SourceModule, action.Mapping.SourceStep)	
											let frmValue: any = this._creation.GetFormFieldValue(action.Mapping.SourceModule, frmIdx, 0, 0,  action.Mapping.SourceField)
											let tempV: any = frmValue

											if(typeof(frmValue) === "object" && (frmValue !== null && frmValue !== undefined)) {
												tempV = frmValue[action.Mapping.Source]
											}
											
											
											this.f[action.Target].patchValue(tempV)
											this.changing.emit({ value: tempV, itemName: action.Target })
										}
									}

									if(action.Property == "radioValue") {
										this.f[action.Target].patchValue(action.Value)
										this.changing.emit({ value: action.Value, itemName: action.Target })
									}

									if(action.Property == "CustomClass") {
										let frmCtrl = this.formConfig.Controls.find( ctrl => { return ctrl.Name == action.Target } )									

										if(frmCtrl) {
											frmCtrl[action.Property] = action.Value
										}
									}

									if(action.Property == "Select") {
										let ctrl: formControl = this.formConfig.Controls.find( c => { return c.Name == action.Target } )

										if(ctrl) {
											ctrl.Values = action.Value										
										}
									}
								}
							}															
						}			
					}				
				}
			)			
		}			

		if(this.item["Type"] == "card_select" || this.item["Type"] == 'radio' || this.item["Type"] == 'btn_radio'){
			//this.f[this.item["Name"]].updateValueAndValidity()
			this.wasChanged = true
			this.onBlur(null)
		}
	}

	onBlur(event) {
		this.InCtrl = false

		if(this.item.Type == "text_autocomplete") {
			this.fakeDropdownVisible = false
		}

		if(this.item.Type == "text" && this.item.Mask == "0.00") {
			let val = this.f[this.item["Name"]].value
			
			if(val && val.length == 1) {
				val = val + ".00"

				this.f[this.item["Name"]].patchValue(val)
				this.changing.emit({ value: val, itemName: this.item.Name })
			}

			if(val && val.length == 2) {
				val = val + "00"

				this.f[this.item["Name"]].patchValue(val)
				this.changing.emit({ value: val, itemName: this.item.Name })
			}

			if(val && val.length == 3) {
				val = val + "0"

				this.f[this.item["Name"]].patchValue(val)
				this.changing.emit({ value: val, itemName: this.item.Name })
			}

			this.propagateChange(val)
		}
		else {
			if(this.wasChanged === true) {
				let val = this.f[this.item["Name"]].value

				this.f[this.item["Name"]].patchValue(val)
				this.changing.emit({ value: val, itemName: this.item.Name })
				this.propagateChange(val)
			}
		}

		if(this.wasChanged === true && this.f[this.item["Name"]].invalid) {
			let errors: Array<string> = []

			Object.keys(this.f[this.item["Name"]].errors).forEach(
				err => {
					if(err == "max" && typeof this.f[this.item["Name"]].errors[err] == "object") {
						if(this.item["Errors"][err + this.f[this.item["Name"]].errors[err][err]] === undefined) {
							errors.push(this.item["Errors"][err])
						}
						else {
							errors.push(this.item["Errors"][err + this.f[this.item["Name"]].errors[err][err]])							
						}
					}
					else {
						errors.push(this.item["Errors"][err])						
					}
				}
			)
			// if(this.f[this.item["Name"]].value != ""){
				this.showError(errors.join("<BR /><BR />"))			
			// }
		}
	}

	onFocus($event) {
        this.InCtrl = true
    }

    onKeyup($event) {
        this.wasChanged = true
    }

	GetSelectedIndicator(item): string {
		let indicator: any = item.RightIndicator.find( i => { return i.Selected == true } )
		return indicator !== null && indicator !== null ? indicator.Label : ""
	}
  
  	SelectIndicator(item, indic) {
  		item.RightIndicator.forEach( it => {
  			if(it.Id == indic.Id) {
  				it.Selected = true
  			}
  			else {
  				it.Selected = false
  			}
  		})
  	}

  	showError(message: string) {
   	   this._utilities.showError(message)
    }

    onFileSelected(event) {
    	if(event.target.files.length > 0) {
	    	this.file = event.target.files[0]	  
	    	let reader = new FileReader()
				reader.readAsDataURL(this.file) 

				reader.onload = () => {   
					this.changing.emit({ value: reader.result, itemName: this.item.Name })  
				}
	    	
    	}
    }

    openFileSelection() {
    	document.getElementById(this.item.Name).click()   	
    }

    openSelectedFile(title: string) {
		let step: number = this._Forms.GetFormStepIndex(this.moduleName, this.formConfig.Name)
		let index: number = this._creation.GetFormSubIndex()
		let subDetailsIdx: number = this._creation.GetFormSubDetailsIndex()
    	let tempFile: File = this._creation.GetFormFieldValue(this.moduleName, step, index, subDetailsIdx, this.item.Name)
    	
    	this._utilities.showDocument(title, tempFile)
    }

    removeFile() {
    	this.file = null
    	this.f[this.item["Name"]].patchValue("")
    	this.changing.emit({ value: null, itemName: this.item.Name })  
    }

    openIncrementsModal() {
    	let step: number = this._Forms.GetFormStepIndex("quotation", "Loan")
		let index: number = this._creation.GetFormSubIndex()
		let subDetailsIdx: number = this._creation.GetFormSubDetailsIndex()
		let IncrementsArr: Array<{Id: number, Amount: number, Duration: number}> = this._creation.GetFormFieldValue("quotation", step, index, subDetailsIdx, "Increments")

		let incrementsDefaultCount: any = this._Forms.getFormControlPropertyValue("quotation", "Loan", "Increments", "CustomValue")

		this._utilities.showIncrementsModal("Prêt à Paliers n°" + (subDetailsIdx + 1), IncrementsArr, parseInt(incrementsDefaultCount))			
			.then(
				resp => {
					if(resp) {
						this.f["Increments"].patchValue(resp)

						this.changing.emit({ value: resp, itemName: "Increments" })
					

						

						let maxMontant= this._creation.GetLoansAmount()

						let maxDuration = this._creation.GetLoansMaxDuration()


					if(!maxDuration){
						
							window.location.reload();

							localStorage.setItem('Durée', '555');

							


	
		var Sommeduree:number=0;
		let maxDuration = this._creation.GetLoansMaxDuration()
		IncrementsArr.forEach(
			inc => {
		
	  Sommeduree=Number(inc.Duration)+ Sommeduree
			})
	
	
	
 















						}

						else if (!maxMontant)
						{


						

						
								var SommeMont:number=0;
									let maxMontant= this._creation.GetLoansAmount()
									IncrementsArr.forEach(
									inc => {
										SommeMont=Number(inc.Amount)+ SommeMont
									})
								

								window.location.reload();

								localStorage.setItem('Montant', String(SommeMont));
	
	
							
							

						}

			}
				}
			)
			.catch(
				err => {
					console.log(err)
				}
			)	
    }

    TopRightButtonAction(action) {
    	if(action.Type == "ExecuteFn") {
	    	if(action.Params.FnName == "openIncrementsModal") {
	    		this["openIncrementsModal"]()
	    	}    		
    	}
    }

    fakeDropdownItemSelection(elt) {
    	this.f[this.item["Name"]].patchValue(elt.Label)
    	this.changing.emit({ value: elt.Label, itemName: this.item.Name })
    	this.fakeDropdownVisible = false

    	if(this.item["Actions"] && this.item["Actions"].length > 0 ) {			
			this.item["Actions"].forEach(
				action => {

			    	if(action && action.ResultAction == "SetValue_whenChange_fromSelect") {
						let currentCtrl: any = this.f[action.Target]
						if(currentCtrl) {
							currentCtrl.patchValue(elt[action.Target])
							this.changing.emit({ value: elt[action.Target], itemName: action.Target })
						}						
					}

				}
			)
		}
    }

    ShowFakeDropDown($event) {
    	$event.preventDefault()
    	$event.stopPropagation()
    	this.fakeDropdownVisible = !this.fakeDropdownVisible
    }

    @HostListener('document:click', ['$event']) clickedOutside($event){
    	if(this.fakeDropdownVisible == true) {
			this.fakeDropdownVisible = false
    	}
  	}

  	EndOfSelect() {
  		if(this.item.Pagination && this.item.Pagination.End < this.item.Pagination.Max) {

  			this._utilities.formActionApiCall(this.item.Pagination.PreviousAction.action, this.form, this.item.Pagination.PreviousAction.currentValue, this.item.Pagination)								
				.subscribe(
					data => { 
						if(this.item.Pagination.PreviousAction.action.ResultAction == "FillSelect"){     					

							if(this.item) {
								let frmCtrl = this.form.controls[this.item.Pagination.PreviousAction.action.Target]

								if(this.item.Pagination.PreviousAction.action.ReplaceMethod == "Always"){
									frmCtrl.patchValue("")														
								}

								this.item.Pagination = data.Pagination
								this.item.Values = [...this.item.Values, ...data.Items]

								if(data &&  data.Items && data.Items.length == 0){
									this.item.Values = []
									if(this.f[this.item.Pagination.PreviousAction.action.Target]) {
										if(this.item.Pagination.PreviousAction.action.ReplaceMethod == "Always"){

											this.f[this.item.Pagination.PreviousAction.action.Target].patchValue("")
											this.changing.emit({ value: "", itemName: this.item.Pagination.PreviousAction.action.Target })
										}													
									}
								}
								else {
									if(data &&  data.Items && this.item.Pagination.PreviousAction.action.AutoSelectFirst === true) {
										this.f[this.item.Pagination.PreviousAction.action.Target].patchValue(data[0])
										this.changing.emit({ value: data.Items[0], itemName: this.item.Pagination.PreviousAction.action.Target })
										
										let targetCtrl: any = this._Forms.getFormControl("quotation", this.item.Pagination.PreviousAction.action.TargetStep, this.item.Pagination.PreviousAction.action.Target)

										if(targetCtrl !== null) {
											this.ApplyAction(data.Items[0], targetCtrl)
										}
									}
								}	

							}
							
							this.item.isLoading = false		
						}
					},
					Error => { console.log(Error) }
				)			
  		}
  	}

  	SearchListApi(evt: string): Observable<any> {

  		if(evt) {
  			if(evt.length > 2) {

				if(this.item.SearchAction) {

					this.item.isLoading = true

					this._utilities.formActionApiCall(this.item.SearchAction, this.form, evt.toUpperCase(), null)								
						.subscribe(
							data => { 
								if(this.item.SearchAction.ResultAction == "FillSelect"){     					
									this.item.isLoading = false		

									if(this.item) {
										let frmCtrl = this.form.controls[this.item.SearchAction.Target]

										if(this.item.SearchAction.ReplaceMethod == "Always"){
											frmCtrl.patchValue("")														
										}

										this.item.Pagination = data.Pagination
										this.item.Values = data.Items

										if(data &&  data.Items && data.Items.length == 0){
											this.item.Values = []
											if(this.f[this.item.SearchAction.Target]) {
												if(this.item.SearchAction.ReplaceMethod == "Always"){

													this.f[this.item.SearchAction.Target].patchValue("")
													this.changing.emit({ value: "", itemName: this.item.SearchAction.Target })
												}													
											}
										}
										else {
											if(data &&  data.Items && this.item.SearchAction.AutoSelectFirst === true) {
												this.f[this.item.SearchAction.Target].patchValue(data[0])
												this.changing.emit({ value: data.Items[0], itemName: this.item.SearchAction.Target })
												
												let targetCtrl: any = this._Forms.getFormControl("quotation", this.item.SearchAction.TargetStep, this.item.SearchAction.Target)

												if(targetCtrl !== null) {
													this.ApplyAction(data.Items[0], targetCtrl)
												}
											}
										}	

										return of(this.item.Values)
									}
									
								}
								else {
									return of([])
								}
							},
							Error => { 
								console.log(Error)
								this.item.isLoading = false		
								
								return of([])
							}
						)			
				}
  			}

  		}
  		else {
			return of([])
		}
  	}
}
