import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map, delay } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { of } from 'rxjs'

import { CONFIG } from '../app.constants'

import { ContractCoveredDetails, ContractCoveredSchedules } from '../models/contracts/contract.model'


@Injectable({
	providedIn: 'root'
})
export class ContractService {

    public currentContract: any = null

	constructor(
		private http: HttpClient
    ) { }

    SetCurrentContract(contract: any) {
        this.currentContract = contract
    }
    GetCurrentContract(): any  {
        return this.currentContract
    }
    ClearCurrentContract(): void {
        this.currentContract = null
    }


    ExportContracts(): Observable<any>{
        return this.http.get(`${CONFIG.API[CONFIG.ENV]}/contracts/export/all`,{ responseType: "blob"})
            .pipe(
                map(
                    (resp => {
                        return resp
                    }
                )
            ))
    }

    // HTTP
    GetContracts(): Observable<any> {
        return this.http.get<any>(`${CONFIG.API[CONFIG.ENV]}/contracts`)
            .pipe(
                map(
                    resp => {
                        return resp
                    }
                )
            )
    }

    GetContractDetails(id: string, status: string): Observable<any> {
        return this.http.get<any>(`${CONFIG.API[CONFIG.ENV]}/contracts/details/`+ status + `/` + id)
            .pipe(
                map(
                    resp => {
                        return resp
                    }
                )
            )
    }

    GetContractTracking(id: string, status: string): Observable<any> {
        return this.http.get<any>(`${CONFIG.API[CONFIG.ENV]}/contracts/tracking/`+ status + `/` + id)
            .pipe(
                map(
                    resp => {
                        return resp
                    }
                )
            )
    }

    GetContractDocuments(id: string, status: string): Observable<any> {
        return this.http.get<any>(`${CONFIG.API[CONFIG.ENV]}/contracts/documents/`+ status + `/` + id)
            .pipe(
                map(
                    resp => {
                        return resp
                    }
                )
            )
    }

    GetContractQuotation(id: string): Observable<any> {
        return this.http.get<any>(`${CONFIG.API[CONFIG.ENV]}/contracts/quotation/`+ id)
            .pipe(
                map(
                    resp => {
                        return resp
                    }
                )
            )
    }

    DeleteDocument(itemId: string, documentId: number): Observable<any> {
        return this.http.delete<any>(`${CONFIG.API[CONFIG.ENV]}/contracts/`+ itemId + `/documents/` + documentId)
            .pipe(
                map(
                    resp => {
                        return resp
                    }
                )
            )

        // return of(true)
    }

    UploadDocument(itemId: string, document): Observable<any> {
        return this.http.post<any>(`${CONFIG.API[CONFIG.ENV]}/contracts/documents`,
                {
                    ItemId: itemId, 
                    Document: document
                }
            )
            .pipe(
                map(
                    resp => {
                        return resp
                    }
                )
            )
    }

    GetContractCoveredDetails(id: string, status: string): Observable<ContractCoveredDetails> {
        return this.http.get<any>(`${CONFIG.API[CONFIG.ENV]}/contracts/covered/`+ status + `/` + id)
            .pipe(
                map(
                    resp => {
                        return resp
                    }
                )
            )
    }

    GetContractCoveredSchedule(id: string, status: string): Observable<ContractCoveredSchedules> {
           return this.http.get<any>(`${CONFIG.API[CONFIG.ENV]}/contracts/schedule/`+ status + `/` + id)
            .pipe(
                map(
                    resp => {
                        return resp
                    }
                )
            )
    }
}
