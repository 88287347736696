export class Contract {
	Id: string
	Subscriber: string
	Covered: string
	Product: string
	CreatedDate: string
	EffectiveDate: string
	EndDate: string
	Status: string
	QSS: boolean
	Documents: boolean
	Cotisations: Array<CotisationModel>
	Loans: Array<Loan>

	constructor() {
		this.Id = ""
		this.Subscriber = ""
		this.Covered = ""
		this.Product = ""
		this.CreatedDate = ""
		this.EffectiveDate = ""
		this.EndDate = ""
		this.Status	= ""
		this.Cotisations = null
		this.Loans = new Array<Loan>()
	}
}

export class CotisationModel {
	Headers: Array<CotisationValue>
	Decesptia: Array<CotisationValue>
	Ittiptipp: Array<CotisationValue>
	Total: Array<CotisationValue>	

	constructor() {
		this.Headers = new Array<CotisationValue>()
		this.Decesptia = new Array<CotisationValue>()
		this.Ittiptipp = new Array<CotisationValue>()
		this.Total = new Array<CotisationValue>()
	}
}

export class CotisationValue {
	Type: string
	Value: any

	constructor() {
		this.Type = null
		this.Value = null
	}
}

export class Loan {
	LoanId: number
	Kind: string
	Amount: number
	Duration: number
	Deferral: number
	Rate: number
	Borrowers: Array<Borrower>

	constructor() {
		this.LoanId = null
		this.Kind = null
		this.Amount = null
		this.Duration = null
		this.Deferral = null
		this.Rate = null
		this.Borrowers = new Array<Borrower>()
	}
}

export class Borrower {
	Name: LoanControl
	Quotite: LoanControl

	constructor() {
		this.Name = new LoanControl()
		this.Quotite = new LoanControl()
	}
}

export class LoanControl {
	Type: string
	Value: any
	HasRightIndicateur: boolean
	RightIndicateur: string

	constructor() {
		this.Type = ""
		this.Value = ""
		this.HasRightIndicateur = false
		this.RightIndicateur = ""
	}
}

export class Document {
	CreatedDate: string
	File: string | ArrayBuffer
	GEDRef: string
	Id: number
	Name: string
	Sign: string
	Type: string
	Uploaded: boolean
	UploadedDate: string
	Loading: boolean

	constructor(doc) {
		this.CreatedDate = doc ? doc.CreatedDate : ""
		this.File = doc ? doc.File : null
		this.GEDRef = doc ? doc.GEDRef : ""
		this.Id = doc ? doc.Id : null
		this.Name = doc ? doc.Name : ""
		this.Sign = doc ? doc.Sign : ""
		this.Type = doc ? doc.Type : ""
		this.Uploaded = doc ? doc.Uploaded : false
		this.UploadedDate = doc ? doc.UploadedDate : ""
		this.Loading = false
	}
}



//////////////////////////////
// Contract Covered Details //
//////////////////////////////

export class CoveredDetails {
	Name: string
	ContractId: string

	StatutProfessionnel: string
	JobType: string
	Profession: string
	Deplacements: string
	ActiviteMannuelle: string
	TravailEnHauteur: string
	Csp: string
	Fumeur: string
	SportARisque: string
	SportPratique: string
	ResidentFiscalFrancais: string
	PersonnePolitiquementExposee: string
	ProchePolitiquementExposee: string
}
export class ContractCoveredDetails {
	State: boolean
	Covereds: Array<CoveredDetails>
}


///////////////////////////////
// Contract Covered Schedule //
///////////////////////////////

export class ScheduleRow {
	StartDate: string
	EndDate: string
	Age: number
	Capital: string
	DCPTIA: string
	ITTITP: string
	YearTotalCotisation: string
	MonthlyTotalCotisation: string
}
export class Schedule {
	Rows: Array<ScheduleRow>
	Total: string
	TMIC: string
	TAEA: string
}
export class CoveredSchedule {
	Name: string
	ContractId: string

	Schedules: Array<Schedule>
}
export class ContractCoveredSchedules {
	State: boolean
	Covereds: Array<CoveredSchedule>
}

