import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from "ngx-simple-modal"

export interface qssModel {
	question: string
	answer: string
}


@Component({
  selector: 'app-qss-modal',
  templateUrl: './qss-modal.component.html',
  styleUrls: ['./qss-modal.component.scss']
})


export class QssModalComponent extends SimpleModalComponent<qssModel, string> implements qssModel {

	question: string
	answer: string

	constructor() {
		super()
	}

	ngOnInit() {
	}

	confirm() {
		this.result = this.answer
		this.close()
	}

	cancel() {
		this.close()
	}

}
