import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { Router } from '@angular/router'
import { FormGroup } from '@angular/forms'
import { Observable } from 'rxjs'

import { formModel, } from '../../../models/forms/form.model'
import { Field, FieldOption, } from '../../../models/creation/project/project.model'
import { FormSubStepDetails } from '../../../models/creation/maintabs.model'

import { CreationService } from '../../../services/creation.service'
import { FormsService } from '../../../services/forms.service'
import { UtilitiesService } from '../../../services/utilities.service'


@Component({
  selector: 'app-right-bar',
  templateUrl: './right-bar.component.html',
  styleUrls: ['./right-bar.component.scss']
})
export class RightBarComponent implements OnInit {
	@Input() moduleName: string
	@Input() step: number
	@Input() idx: number
	@Input() formDetailsIndex: number
	@Input() form: FormGroup
	@Input() globalControls: Array<formModel> = new Array<formModel>()
	@Input() globalForm: Array<FormGroup>
	@Input() invalidForms: Observable<any>
	@Output() submitting = new EventEmitter<boolean>()

	public totalCount: number = 1
	public subDetailsValue: Array<string> = new Array<string>()
	public invalids: Array<any> = []

	constructor(
		public router: Router,
		public _creation: CreationService,
		public _Forms: FormsService,
		public _utilities: UtilitiesService
	) { }

	ngOnInit() {
		this.invalidForms.subscribe( data => { this.invalids = data } )
	}

	isFormInvalid(i: number): boolean {
		return (this.invalids.findIndex( it => it.index == this.idx && it.detailsIndex == i ) != -1)
	}

	counter() {
		let count: number = this._creation.GetFormSubStepDetailsLength(this.moduleName, this.step, 0)
		
		if(this.globalControls[this.step].HasRightBar === true && this.globalControls[this.step].RightBar.HasItemSubDetails === true) {
        	let TargetIdx: number = this.globalControls.findIndex( frm => { return frm.Name == this.globalControls[this.step].RightBar.ItemSubDetails.TargetStep } )
			let TargetFields: Array<{Name: string, Type: string, Mapping: Object}> = this.globalControls[this.step].RightBar.ItemSubDetails.TargetFields

			for (var i = 0; i < count; i++) {
				let currentValues = this._creation.GetFormSubStepDetails("quotation", TargetIdx, 0,i)

				if(currentValues) {
					let tempArr: Array<string> = []
					TargetFields.forEach(
						field => {
							let ctrl = currentValues.Fields.find( c => { return c.name == field.Name} )
							if(ctrl){
								if(field.Type == "Full") {
									tempArr.push(ctrl.value)									
								}
								if(field.Type == "Short") {
									let val: string = ""
										val = field.Mapping[ctrl.value]

									tempArr.push(val)									
								}
							}
						}
					)
					this.subDetailsValue[i] = tempArr.join(" ")				
				}
			}			
        } 
		return new Array(count)
	}  

	AddItem() {
		this.submitting.emit(true)

		// if(this.form.valid){
						
			this.SaveDatas()			

			let newSubDetailsIndex: number = this._creation.AddEmptyFormStepDetails("quotation", this.step, this.idx)

			if(this.globalControls[this.step].HasRightBar == true && this.globalControls[this.step].RightBar.ExtraActions.length > 0) {

			      	this.globalControls[this.step].RightBar.ExtraActions.forEach(
			      		action => {

			      			if(action.Type == "SameStepLengthFromDetailsLength") {
			      				let formIndex = this.globalControls.findIndex( gf => { return gf.Name == action.TargetStep })
			      				let TargetLength: number = this._creation.GetFormSubStepLength(action.Target, formIndex)
			      				let SourceLength: number = this._creation.GetFormSubStepDetailsLength(action.Source, this.step, 0)
			      				let TargetDetailsLength: number = this._creation.GetFormSubStepDetailsLength(action.Target, formIndex, 0)

			      				if(TargetLength <= SourceLength) {
			      					for (var i = TargetLength; i <= SourceLength -1; i++) {
			      						this._creation.AddEmptyFormStep(action.Target, formIndex, i)	  

			      						for (var j = 0; j < TargetDetailsLength-1; j++) {
			      							this._creation.AddEmptyFormStepDetails(action.Target, formIndex, i)	
			      						}
			      					}

			      					if(action.SharedId && action.SharedId.length > 0) {
			      						this._creation.updateNextStepSharedIds(action, formIndex)	
			      					}
			      				}
			      			}

			      			if(action.Type == "SameStepDetailsLength") {
			      				this._Forms.getForm(action.Target)
			      					.then(
			      						form => {
											if(form && form != []) {											
							      				let formIndex = form.findIndex( gf => { return gf.Name == action.TargetStep })
							      				let TargetLength: number = this._creation.GetFormSubStepLength(action.Target, formIndex)
							      				let SourceDetailsLength: number = this._creation.GetFormSubStepDetailsLength("quotation", this.step, 0)

							      				for (var i = 0; i <= TargetLength; i++) {
							      					let TargetDetailsLength: number = this._creation.GetFormSubStepDetailsLength(action.Target, formIndex, i)

							      					for (var j = TargetDetailsLength; j <= SourceDetailsLength -1; j++) {
							      						this._creation.AddEmptyFormStepDetails(action.Target, formIndex, i)	
							      					}
							      				}
											}			      							
			      						}
			      					)
			      					.catch(
			      						() => {
								    		this._utilities.showError("Il y a eu un problème lors de l'ajout aux étapes suivantes.")
								    		return false
								    	}
			      					)
			      			}
			      		}
			      	)
			      }

	        this._creation.SetFormSubDetailsIndex(newSubDetailsIndex)

    		this.submitting.emit(false)
		// }
	}

	GoToItem(index: number) {
		this.submitting.emit(true)

		// if(this.form.valid){
			this.SaveDatas()
			this._creation.SetFormSubDetailsIndex(index)

			//this.submitting.emit(false)
		// }
	}

	GetSubDetails(idx: number): string {
		return this.subDetailsValue[idx] || ""		
	}

	SaveDatas() {
		let formFields: Array<Field> = new Array<Field>()
		
		this.formDetailsIndex = this._creation.GetFormSubDetailsIndex()

		Object.keys(this.form.controls).forEach(key => {
			let formField: Field = new Field()
				formField.name = key
				formField.value = this.form.get(key).value

            let frmCtrl = this.globalControls[this.step].Controls.find( item => { return item.Name === key } )

            if(frmCtrl && frmCtrl.HasRightIndicator && frmCtrl.RightIndicatorType == "button"){
            	let opts = frmCtrl.RightIndicator.find( indic => { return indic.Selected === true } )

            	if(opts !== null && opts !== undefined){
	            	let formFieldOption: FieldOption = new FieldOption()
	            		formFieldOption.name = opts.Label
	            		formFieldOption.value = true	  

	            		formField.options.push(formFieldOption)          		
            	}
            }

            formFields.push(formField)
        })   
        this._creation.AddFormStepValues("quotation", this.step, this.idx, this.formDetailsIndex, formFields)        
	}

	removeItem(itemIndex: number) {
		this._creation.RemoveSubStepDetails("quotation", this.step, this.idx, itemIndex)

		this.globalControls[this.step].RightBar.TrashExtraActions.forEach(
      		action => {

      			if(action.Type == "RemoveSubStep") {
      				let formSubStepIndex: number = this._Forms.GetFormStepIndex(action.Target, action.TargetStep)
      				
      				if(formSubStepIndex != -1) {     
      					this._creation.RemoveSubStep(action.Target, formSubStepIndex, itemIndex)
      				}      				
      			}

      			if(action.Type == "RemoveSubStepDetails") {   
      				let formIndex: number = this._Forms.GetFormStepIndex(action.Target, action.TargetStep)
      				let fromSubStepLength: number = this._creation.GetFormSubStepLength(action.Target, formIndex)

      				if(formIndex != -1) {
      					for (var i = 0; i < fromSubStepLength; ++i) {
      						this._creation.RemoveSubStepDetails(action.Target, formIndex, i, itemIndex)
      					}      					
      				}
      			}
      		}
      	)

      	this._creation.SetFormSubDetailsIndex(0)
	}

	removeConfirmation(idx: number) {

		this._utilities.showConfirm("Voulez-vous supprimer cet élément ?")
			.then(
				resp => {
					if(resp === true) {
						this.removeItem(idx)
					}
				}
			)
			.catch(
				err => {
					// console.log(err)
				}
			)
	}
}
