import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { Customer } from '../../../../models/customers/customer.model'


import { CreationService } from '../../../../services/creation.service'
import { CustomersService } from '../../../../services/customers.service'
import { UtilitiesService } from '../../../../services/utilities.service'


@Component({
	selector: 'app-customers-listing',
	templateUrl: './customers-listing.component.html',
	styleUrls: ['./customers-listing.component.scss']
})
export class CustomersListingComponent implements OnInit {

	public filter: string = ""
	public customers: Array<Customer> = new Array<Customer>()
	private gridApi
	private gridColumnApi

	columnDefs = [
        {headerName: 'Identifiant client', field: 'Id'},
        {headerName: 'Identifiant contrat', field: 'ContractId'},
        {headerName: 'Souscripteur', field: 'Subscriber'},
        {headerName: 'Assurés rattachés', field: 'Covered'},
        {headerName: "Date d'effet", field: 'EffectiveDate'}
    ]

    rowData = []

	constructor(
		public router: Router,
		public _creation: CreationService,
		public _customers: CustomersService,
		public _utilities: UtilitiesService
	) { }

	ngOnInit() { }

	onGridReady(params) {
	    this.gridApi = params.api
	    this.gridColumnApi = params.columnApi
	    this.gridApi.sizeColumnsToFit()
	    this.gridApi.hideOverlay()

	    this.initGrid()
	}

	initGrid() {
		this.gridApi.showLoadingOverlay()

		this._customers.GetCustomers()
			.subscribe(
				resp => {
					if(resp && resp.State === true) {
						this.customers = resp.Customers
						this.rowData = this.customers

						if(this.rowData && this.rowData.length<1) {
							this.gridApi.showNoRowsOverlay()
						}
					}
					else {
						this._utilities.showError("Il y a eu une erreur.")
						this.gridApi.showNoRowsOverlay()
					}
				},
				Error => {
					console.log(Error)
					this._utilities.showError("Il y a eu une erreur.")
					this.gridApi.showNoRowsOverlay()
				}
			)
	}

	Filtering() {
		if(this.filter != ""){
			let filter: string = this.filter.toLowerCase()

			this.rowData = this.customers
						.filter( 
							item => { 
								return (
										item.Id.toLowerCase().includes(this.filter)
										|| item.ContractId.toLowerCase().includes(this.filter)
										|| item.Subscriber.toLowerCase().includes(this.filter)
										|| item.Covered.toLowerCase().includes(this.filter)
										|| item.EffectiveDate.toLowerCase().includes(this.filter)
									) 
							}
						)
		}
		else{
			this.rowData = this.customers
		}
	}

	ClearFilter() {
		this.filter = ""
		this.Filtering()
	}

	onCellDoubleClicked(item: any) {
		if(item) {
			if(item.colDef.field == "Id") {
				this.router.navigate(["customers/details/" + item.data.Id])				
			}
			else if(item.colDef.field == "ContractId") {
				let status: string = item.data.ContractStatus
				if(status) { status = status.toLowerCase() }

				let intStatus: number
				switch (status) {
					case "actif":
						intStatus = 1
						break

					case "annulée":
						intStatus = 2
						break

					case "devis":
						intStatus = 3
						break

					case "projet":
						intStatus = 4
						break

					case "envoyée":
						intStatus = 5
						break
					
					default:
						intStatus = 0
						break
				}	

				this._creation.SetCurrentFormModifiedFieldCount(0)
				this.router.navigate(["contracts/details/" + intStatus + "/" + item.data.ContractId])		
			}			
		}
	}

	GoBack() {
		if(this.gridApi && !(this.gridApi.paginationGetCurrentPage() === 0)) {
			this.gridApi.paginationGoToPreviousPage()
		}
	}

	GoNext() {
		if(this.gridApi && !(this.gridApi.paginationGetTotalPages() - 1 === this.gridApi.paginationGetCurrentPage())) {
			this.gridApi.paginationGoToNextPage()
		}
	}

	IsFirstPage(): boolean {
		return this.gridApi ? !(this.gridApi.paginationGetCurrentPage() === 0) : false
	}

	IsLastPage(): boolean {
		return this.gridApi ?  !(this.gridApi.paginationGetTotalPages() - 1 === this.gridApi.paginationGetCurrentPage()): false
	}

	TableAsRow(): boolean {
		return this.gridApi ? (this.gridApi.paginationGetRowCount() > 0) : false			
	}

}
