export class formModel {
	Name: string
	Label: string
	InitActions: Array<Action>
	HasRightBar: boolean
	RightBar: RightBarDetails
	HasNavigationBar: boolean
	NavigationBarActions: Array<any>
	Controls: Array<formControl>
	BtnLabels: any
	OnSubmit: Array<any>
	OnCancel: Array<any>	
	OnBack: Array<any>
	OnGetQuote: Array<any>
	onSendAQuote: Array<any>
	HasSuccessMessage: boolean
	OnSuccessMessage: SuccessMessages

	constructor() {
		this.Name = ""
		this.Label = ""
		this.Controls = new Array<formControl>()
		this.InitActions = new Array<Action>()
		this.HasRightBar = false
		this.RightBar = new RightBarDetails()
		this.HasNavigationBar = false
		this.NavigationBarActions = new Array<any>()
		this.BtnLabels = null
		this.OnSubmit = new Array<any>()
		this.OnCancel = new Array<any>()
		this.OnBack = new Array<any>()
		this.OnGetQuote = new Array<any>()
		this.onSendAQuote = new Array<any>()
		this.HasSuccessMessage = false
		this.OnSuccessMessage = new SuccessMessages()
	}
}

export class formControl {
	Name: string
	Type: string
	CustomClass: string
	Label: string
	UpperCase : boolean
	Capitalize: boolean
	IgnoreCaseFormat: boolean
	PlaceHolder: string
	Default: string
	Mask: string
	ReadOnly: boolean
	ToHide: boolean
	Disabled: boolean
	Multiple: boolean
	Values: Array<any>
	HasRightIndicator: boolean
	RightIndicatorType: string
	RightIndicator: any
	HasTopRightButton: boolean
	TopRightButtonLabel: string
	TopRightButtonCtrlError: string
	TopRightButtonAction: Array<any>
	Validators: Array<CtrlValidator>
	ShowErrorsUnderItem: boolean
	Errors: any
	HasHelper: boolean
	HelperPosition: string
	HelperTitle: string
	HelperBody: string
	Actions: Array<Action>
	Comment: string
	isLoading: boolean
	Pagination: ListPagination
	Searchable: boolean
	SearchAction: any

	constructor() {
		this.Name = ""
		this.Type = ""
		this.CustomClass = ""
		this.Label = ""
		this.UpperCase = false
		this.Capitalize = false
		this.IgnoreCaseFormat = false
		this.PlaceHolder = ""
		this.Default = ""
		this.ToHide = false
		this.Disabled = false
		this.ReadOnly = false
		this.Multiple = false
		this.Values = []
		this.HasRightIndicator = false
		this.RightIndicatorType = ""
		this.RightIndicator = ""
		this.HasTopRightButton = false
		this.TopRightButtonLabel = ""
		this.TopRightButtonCtrlError = ""
		this.TopRightButtonAction = []
		this.Validators = []
		this.ShowErrorsUnderItem = false
		this.Errors = null
		this.HasHelper = false
		this.HelperPosition = ""
		this.HelperTitle = ""
		this.HelperBody = ""
		this.Actions = new Array<Action>()
		this.Comment = ""
		this.isLoading = false
		this.Pagination = new ListPagination()
		this.Searchable = false
		this.SearchAction = null
	}
}

export class CtrlValidator {
	Type: string
	Params: any
}

export class Action {
	Id: number
	Type: string
	ResultAction: string
	Target: string
	TargetStep: string
	TargetModule: string
	MinLength: number
	ValuesPropertyName: string
	AutoSelectFirst: boolean
	Mapping: any
	Params: any
	SetValidators: Array<any>
	Property: any
	Value: any
	ReplaceMethod: string
	TargetType: string
	TargetProperty: string
	PropertyValue: string
	ResponseField: string

	constructor() {
		this.Id = 0
		this.Type = ""
		this.ResultAction = ""
		this.Target = ""
		this.TargetStep = ""
		this.TargetModule = ""
		this.TargetModule = ""
		this.MinLength = 0
		this.AutoSelectFirst = false
		this.Mapping = null
		this.Params = null
		this.SetValidators = null
		this.Property = null
		this.Value = null
		this.ReplaceMethod = ""
		this.TargetType = ""
		this.TargetProperty = ""
		this.PropertyValue = ""
		this.ResponseField = ""
	}
}

export class RightBarDetails {
	GoToItemBtnCaption_First: string
	GoToItemBtnCaption: string
	HasItemSubDetails: boolean
	ItemSubDetails: ItemSubDetail
	HasAddItemBtn: boolean
	HasTrashBtnFirst: boolean
	HasTrashBtnNext: boolean
	AddItemBtnCaption: string
	ExtraActions: Array<any>
	TrashExtraActions: Array<any>

	constructor() {
		this.GoToItemBtnCaption_First = ""
		this.GoToItemBtnCaption = ""
		this.HasItemSubDetails = false
		this.ItemSubDetails = new ItemSubDetail()
		this.HasAddItemBtn = false
		this.AddItemBtnCaption = ""
		this.ExtraActions = []
		this.HasTrashBtnFirst = false
		this.HasTrashBtnNext = false
		this.TrashExtraActions = []
	}
}

export class ItemSubDetail {
	TargetStep: string
	TargetFields: Array<any>

	constructor() {
		this.TargetStep = ""
		this.TargetFields = new Array<any>()
	}
}

export class Opts {
	Id: number
	Value: string
	Label: string

	constructor() {
		this.Id = 0
		this.Value = ""
		this.Label = ""
	}
}

export class SuccessMessages {
	Messages: Array<SuccessMessage>

	constructor() {
		this.Messages = new Array<SuccessMessage>()
	}
}

export class SuccessMessage {
	Text: string
	Visible: boolean
	Actions: Array<any>

	constructor() {
		this.Text = ""
		this.Visible = false
		this.Actions = []
	}
}

export class ListPagination {
	Start: number
	End: number
	Max: number
	PreviousAction: PreviousAction
}

export class PreviousAction {
	action: any
	currentForm: any
	currentValue: any
}