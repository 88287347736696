import { Injectable } from '@angular/core'
import { FormGroup, FormControl, AbstractControl } from '@angular/forms'
import { Validators as V } from '@angular/forms'
import { DatePipe } from '@angular/common'

import { FORMS } from '../app.forms'


export const getDate = (dateToCheck: string): any => {


	if(dateToCheck.length >= 8 && dateToCheck.length <= 12) {

		if(dateToCheck.includes('/')) {
			let dateSplit = dateToCheck.split('/')

			return new Date(Number(dateSplit[2]), (Number(dateSplit[1] )- 1), Number(dateSplit[0]))
		}
		else {
			return new Date(Number(dateToCheck.substring(4)), (Number(dateToCheck.substring(2,4)) - 1), Number(dateToCheck.substring(0,2)))
		}
	}
	else {
		return new Date(dateToCheck)
	}
}

export const DateGreaterThan = (params: any) => {
	
	return (c: AbstractControl) => {

		let compareDateTime = new Date(params.compareValue)
    		compareDateTime.setHours(0,0,0,0)

	    let controlValue: any = getDate(c.value)
	    	controlValue.setHours(0,0,0,0)		

	    if(controlValue <= compareDateTime)
	    {
	        let response: {[k: string]: boolean} = {}
	    		response[params.ValidatorType] = false

		    return response
	    }
		return null
	}	
}

export const HamonEffectiveDateValidity = (params: any) => {
	
	return (c: AbstractControl) => {

		let compareDateTime = new Date(params.compareValue)
    		compareDateTime.setHours(0,0,0,0)

	    let controlValue: any = getDate(c.value)
	    	controlValue.setHours(0,0,0,0)		

	    if(controlValue <= compareDateTime)
	    {
	        let response: {[k: string]: boolean} = {}
	    		response[params.ValidatorType] = false

		    return response
	    }
		return null
	}	
}

export const DateLowerThan = (params: any) => {
	return (c: AbstractControl) => {
		let compareDateTime = new Date(params.compareValue)
    		compareDateTime.setHours(0,0,0,0)

	    let controlValue: any = getDate(c.value)
	    	controlValue.setHours(0,0,0,0)		
    	
	    if(controlValue >= compareDateTime)
	    {
	        let response: {[k: string]: boolean} = {}
	    		response[params.ValidatorType] = false

		    return response
	    }
		return null
	}	
}

export const EqualsTo = (validator: string, params: any) => {
	
	return (c: AbstractControl) => {    	

		let val = typeof c.value == "object" ? c.value.Value : c.value

	    if(params.Val != val)
	    {
	        let response: {[k: string]: boolean} = {}
	    		response[validator] = false

		    return response
	    }
		return null
	}	
}

export const NotEqualsTo = (validator: string, params: any) => {
	
	return (c: AbstractControl) => {    	

		let val = typeof c.value == "object" ? c.value.Value : c.value

	    if(params.Val == val)
	    {
	        let response: {[k: string]: boolean} = {}
	    		response[validator] = false

		    return response
	    }
		return null
	}	
}

export const IncrementsValidation = (validator: string, params: any) => {
	
	return (c: AbstractControl) => { 
		if(params){
		    if(params.increments == "" || params.increments === null || params.increments === undefined || params.increments.length < 1)
		    {
		        let response: {[k: string]: boolean} = {}
		    		response[validator] = false
			    return response
		    }			
		}
		return null
	}	
}

export const CheckAllShare100 = (validator: string, params: any) => {
	return (c: AbstractControl) => { 		

		let totalShare: number = 0
		let hasEmpty: boolean = false

		params.forEach(		
			item => {
				if(item == "") {
					hasEmpty = true
				}
				else {
					totalShare += parseInt(item)
				}
			}
		)

		if(totalShare < 100 && !hasEmpty)
	    {
	        let response: {[k: string]: boolean} = {}
	    		response[validator] = false
		    return response
	    }

		return null
	}	
}
/*88888888888888888*/
/*
 export const verfieTable =()=>
 {
	return (c: AbstractControl) => { 
		_creation.getterDuree();
		_creation.getterMontant();

	}
 }
*/



export const max90Y = (validator: string, params: any, CalculateCompareDate) => {
	return (c: AbstractControl) => { 		

		if(params) {
			let compareDateTime = new Date(params.endOfLoan)
	    		compareDateTime.setHours(0,0,0,0)

		    let controlValue = getDate(c.value)
		    	controlValue.setHours(0,0,0,0)			

		    	controlValue = CalculateCompareDate(controlValue, "year", 90)

			if(compareDateTime > controlValue) {
				let response: {[k: string]: boolean} = {}
	    			response[validator] = false

		    	return response
			}	
		}

		return null
	}	
}

export const NotCSP4 = (validatorType) => {
	
	return (c: AbstractControl) => {

	    if(c.value == "CSP4")
	    {
	        let response: {[k: string]: boolean} = {}
	    		response[validatorType] = false

		    return response
	    }
		return null
	}	
}

export const DurationMinusParam = (validator: string, params: any) => {
	return (c: AbstractControl) => { 		

		let calculatedDuration = params.duration - params.delay
		
		if(c.value > calculatedDuration)
	    {
	        let response: {[k: string]: boolean} = {}
	    		response[validator] = false
		    return response
	    }

		return null
	}	
}

@Injectable({
	providedIn: 'root'
})
export class FormsService {

	public params: any = null
	public getDate = getDate

	constructor() { }

	getForms(formName: string): any {
		return JSON.parse(JSON.stringify(FORMS[formName])) || []
	}

	getForm(formName: string): Promise<any> {
		return new Promise<any>(
			(resolve, reject) => {				
				resolve(JSON.parse(JSON.stringify(FORMS[formName])) || [])
			}
		)
	}

	getFormControl(main: string, step: string, control: string): any {
		if(FORMS[main]) {
			let formStep: any = FORMS[main].find( s => { return s.Name == step } )
			if(formStep) {
				let formControl: any = formStep.Controls.find( c => { return c.Name == control } )

				if(formControl) {
					return formControl
				}
			}	
		}

		return null
	}

	getFormControlPropertyValue(main: string, step: string, control: string, property: string): any {
		if(FORMS[main]) {
			let formStep: any = FORMS[main].find( s => { return s.Name == step } )
			if(formStep) {
				let formControl: any = formStep.Controls.find( c => { return c.Name == control } )

				if(formControl) {
					return formControl[property]
				}
			}	
		}

		return null
	}

	getFormStep(formName: string, step: number): any {
		return JSON.parse(JSON.stringify(FORMS[formName][step])) || []
	}

	getFormsStep(formName: string, step: number): Promise<any> {
		//window.location.reload();

		return new Promise<any>(
			(resolve, reject) => {			
				resolve(JSON.parse(JSON.stringify(FORMS[formName][step])) || [])
			}
		)
	}

	getFormLength(formName: string): number {
		return FORMS[formName].length || 0
	}

	GetFormStepIndex(main: string, stepName: string): number {
		return FORMS[main].findIndex( frm => frm.Name == stepName )
	}

	DateGreaterThanNow(c: FormControl) {
    	let currentDateTime = new Date(Date.now())
	    	currentDateTime.setHours(0,0,0,0)

	    if(!c.value) {
	    	return false
	    }
	    
	    let dateSplit = c.value.split('/')
		let tmpVal = dateSplit[1] + "/" + dateSplit[0] + "/" + dateSplit[2]

	    let controlValue = new Date(tmpVal)
	    	controlValue.setHours(0,0,0,0)			

	    if(controlValue <= currentDateTime)
	    {
	        return { DateGreaterThanNow: false }
	    }

	    return null
	}

	DateLowerThanNow(c: FormControl) {
    	let currentDateTime = new Date(Date.now())
	    	currentDateTime.setHours(0,0,0,0)

	    let dateSplit = c.value.split('/')
		let tmpVal = dateSplit[1]+"/"+dateSplit[0] +"/"+dateSplit[2]
	    let controlValue = new Date(tmpVal)
	    	controlValue.setHours(0,0,0,0)			

	    if(controlValue > currentDateTime)
	    {
	        return { DateLowerThanNow: false }
	    }

	    return null
	}

	getValidator(validator: string, params?: any): V {
		switch (validator) {
			case "required":
				return V.required
				break

			case "email":
				return V.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
				break

			case "iban":
				return V.pattern(/^[a-zA-Z]{2}[0-9]{2}\s[a-zA-Z0-9]{4}\s[0-9]{4}\s[0-9]{3}([a-zA-Z0-9]\s[a-zA-Z0-9]{4}\s[a-zA-Z0-9]{4}\s[a-zA-Z0-9]{2}[a-zA-Z0-9]{1})$/)
				break

			case "bic":
				return V.pattern(/^[a-zA-Z]{6}[0-9a-zA-Z]{2}([0-9a-zA-Z]{3})?$/)
				break

			case "mobile":
				return V.pattern(/^(0)[6-7]([-. ]?[0-9]{2}){4}$/)
				break

			case "forbindenDOMTOMPostCode":
				return V.pattern(/^([0-8][0-9]?[0-9]?[0-9]?[0-9]?|971[0-9]?[0-9]?|972[0-9]?[0-9]?|974[0-9]?[0-9]?|9[0-6][0-9]?[0-9]?[0-9]?)+$/)
				// return V.pattern(/^(?!97|98)\w+$/)
				break
			
			case "onlyNumber":
				return V.pattern(/^-?(0|[1-9]\d*)?$/)
				break

			case "onlyFloat":
				return V.pattern(/^[0-9]+(\\.[0-9]+)?/)
				break

			case "maxValue5000000":
				return V.max(5000000)
				break

			case "minValue0+":
				return V.min(0.00001)
				break

			case "minValue37":
				return V.min(37)
				break

			case "maxValue36":
				return V.max(36)
				break

			case "maxValue300":
				return V.max(300)
				break

			case "maxValue100":
				return V.max(100)
				break
			

			case "maxValue180":
				return V.max(180)
				break

			case "maxValue240":
				return V.max(240)
				break
			case "maxValue300":
					return V.max(300)
					break

			case "maxValue360":
				return V.max(360)
				break

			case "maxValue420":
				return V.max(420)
				break

			case "minValueWithParams":
				return V.min(params)
				break
			
			case "minParams":
				return V.min(params)
				break

			case "maxParams":
				return V.max(params)
				break
				
			case "maxValueWithParams":
				return V.max(params)
				break

			case "DateGreaterThanNow":
				return this.DateGreaterThanNow
				break

			case "DateLowerThanNow":
				return this.DateLowerThanNow
				break

			case "DateBourquin":
			case "DateHamon":
			case "DateGreaterThanDateDeblocage":
			case "GreaterThan18YearsOld":
			case "LowerThan85Y":
				return DateGreaterThan(params)
				break

			case "HamonEffectiveDateValidity":
				return DateGreaterThan(params)
				break

			case "GreaterThan":		
				return DateGreaterThan(params)
				break

			case "GreaterThan18Y":
			case "LowerThan85YearsOld":			
			case "LowerThanHamon":			
				return DateLowerThan(params)
				break

			case "EqualsTo":
				return EqualsTo(validator, params)
				break

			case "NotEqualsTo":
				return EqualsTo(validator, params)
				break

			case "increments":
				return IncrementsValidation(validator, params)
				break

			case "CheckAllShare100":
				return CheckAllShare100(validator, params)
				break
			case "IsDateValid":
				return V.pattern(/(^(((0[1-9]|[12]\d|30)(0[13456789]|1[012])((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|3[01])(0[13578]|1[02])((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])02((19|[2-9]\d)\d{2}))|(2902((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))|^(((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)))))$/)
				break
			case "max90Y":
				return max90Y(validator, params, this.CalculateCompareDate)
				break

			case "NotCSP4":
				return NotCSP4(validator)
				break	

			case "DurationMinusParam":
				return DurationMinusParam(validator, params)
				break

			default:
				return null
				break
		}
	}

	getCSP(frm: FormGroup): string {
		let job: any = frm.controls["StatutProfessionnel"].value
			job = parseInt(job.Value)

		let dangerousJob: any
			dangerousJob = frm.controls["JobType"].value
			dangerousJob = parseInt(dangerousJob.Value)

		let proMoves: string = frm.controls["Deplacements"].value
		let hWork: string = frm.controls["TravailEnHauteur"].value
		let manualActivites: string = frm.controls["ActiviteMannuelle"].value

		if(job !== null && proMoves !== null && hWork !== null && dangerousJob !== null && manualActivites !== null && job !== undefined && proMoves !== undefined && hWork !== undefined && dangerousJob !== undefined && manualActivites !== undefined){		
			if(proMoves != "" && hWork != "" && manualActivites != "") {
				switch (job) {
					case 0: // Salarié cadre, fonctionnaire classe A
						if(proMoves === "0") { // <20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP2"
										
										case "1": // Léger
											return "CSP3"
										
										case "0": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP1"
										
										case "1": // Important
											return "CSP3"
										
										case "0": // Léger
											return "CSP2"
										
										default:
											return ""
											break
									}
								}
							}
						}
						else { // >20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP3"
										
										case "1": // Léger
											return "CSP3"
										
										case "0": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP2"
										
										case "1": // Léger
											return "CSP3"
										
										case "0": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
						}

						break

					case 1: // Salarie non cadre, fonctionnaire hors classe A
						if(proMoves === "0") { // <20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP2"
										
										case "1": // Léger
											return "CSP3"
										
										case "0": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP1"
										
										case "1": // Important
											return "CSP3"
										
										case "0": // Léger
											return "CSP2"
										
										default:
											return ""
											break
									}
								}
							}
						}
						else {// >20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP3"
										
										case "1": // Léger
											return "CSP3"
										
										case "0": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP2"
										
										case "1": // Léger
											return "CSP3"
										
										case "0": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
						}

						break		
					
					case 2: // Profession libérale (hors médical et paramédical)
						if(proMoves === "0") { // <20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP2"
										
										case "1": // Léger
											return "CSP3"
										
										case "0": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP1"
										
										case "0": // Léger
											return "CSP2"
										
										case "1": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
						}
						else {// >20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP3"
										
										case "1": // Léger
											return "CSP3"
										
										case "0": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP2"
										
										case "1": // Léger
											return "CSP3"
										
										case "0": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
						}

						break

					case 3: // Profession médicale
						if(proMoves === "0") { // <20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP1"
										
										case "1": // Léger
											return "CSP1"
										
										case "0": // Important
											return "CSP1"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP1"
										
										case "1": // Léger
											return "CSP1"
										
										case "0": // Important
											return "CSP1"
										
										default:
											return ""
											break
									}
								}
							}
						}
						else {// >20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP1"
										
										case "1": // Léger
											return "CSP1"
										
										case "0": // Important
											return "CSP1"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP1"
										
										case "1": // Léger
											return "CSP1"
										
										case "0": // Important
											return "CSP1"
										
										default:
											return ""
											break
									}
								}
							}
						}

						break

					case 4: // Profession paramédicale
						if(proMoves === "0") { // <20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP2"
										
										case "1": // Léger
											return "CSP2"
										
										case "0": // Important
											return "CSP2"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP2"
										
										case "1": // Léger
											return "CSP2"
										
										case "0": // Important
											return "CSP2"
										
										default:
											return ""
											break
									}
								}
							}
						}
						else {// >20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP2"
										
										case "1": // Léger
											return "CSP2"
										
										case "0": // Important
											return "CSP2"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP2"
										
										case "1": // Léger
											return "CSP2"
										
										case "0": // Important
											return "CSP2"
										
										default:
											return ""
											break
									}
								}
							}
						}

						break
					
					case 5: // Gérant, chef d’entreprise
						if(proMoves === "0") { // <20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP2"
										
										case "1": // Léger
											return "CSP3"
										
										case "0": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP1"
										
										case "0": // Léger
											return "CSP2"
										
										case "1": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
						}
						else {// >20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP3"
										
										case "1": // Léger
											return "CSP3"
										
										case "0": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP2"
										
										case "1": // Léger
											return "CSP3"
										
										case "0": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
						}

						break

					case 6: // Artisan, commerçant
						if(proMoves === "0") { // <20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP2"
										
										case "1": // Léger
											return "CSP3"
										
										case "0": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP1"
										
										case "0": // Léger
											return "CSP2"
										
										case "1": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
						}
						else {// >20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP3"
										
										case "1": // Léger
											return "CSP3"
										
										case "0": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP2"
										
										case "1": // Léger
											return "CSP3"
										
										case "0": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
						}

						break

					case 7: // Agriculteur exploitant / profession agricole
						if(proMoves === "0") { // <20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP3"
										
										case "1": // Léger
											return "CSP3"
										
										case "0": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP3"
										
										case "0": // Léger
											return "CSP3"
										
										case "1": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
						}
						else {// >20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP3"
										
										case "1": // Léger
											return "CSP3"
										
										case "0": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP3"
										
										case "1": // Léger
											return "CSP3"
										
										case "0": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
						}

						break

					case 8: // Profession du transport de passagers ou de marchandises
						if(proMoves === "0") { // <20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP3"
										
										case "1": // Léger
											return "CSP3"
										
										case "0": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP3"
										
										case "0": // Léger
											return "CSP3"
										
										case "1": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
						}
						else {// >20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP3"
										
										case "1": // Léger
											return "CSP3"
										
										case "0": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP3"
										
										case "1": // Léger
											return "CSP3"
										
										case "0": // Important
											return "CSP3"
										
										default:
											return ""
											break
									}
								}
							}
						}

						break

					case 9: // Professionnel du spectacle, forain
						if(proMoves === "0") { // <20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "0": // Léger
											return "CSP4"
										
										case "1": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
							}
						}
						else {// >20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
							}
						}

						break

					case 10: // Retraité, préretraité
						if(proMoves === "0") { // <20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP1"
										
										case "1": // Léger
											return "CSP1"
										
										case "0": // Important
											return "CSP1"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP1"
										
										case "0": // Léger
											return "CSP1"
										
										case "1": // Important
											return "CSP1"
										
										default:
											return ""
											break
									}
								}
							}
						}
						else {// >20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP1"
										
										case "1": // Léger
											return "CSP1"
										
										case "0": // Important
											return "CSP1"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP1"
										
										case "1": // Léger
											return "CSP1"
										
										case "0": // Important
											return "CSP1"
										
										default:
											return ""
											break
									}
								}
							}
						}

						break

					case 11: // Intérimaire, intermittent, saisonnier
						if(proMoves === "0") { // <20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "0": // Léger
											return "CSP4"
										
										case "1": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
							}
						}
						else {// >20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
							}
						}

						break

					case 12: // Sans profession, au foyer
						if(proMoves === "0") { // <20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP1"
										
										case "1": // Léger
											return "CSP1"
										
										case "0": // Important
											return "CSP1"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP1"
										
										case "0": // Léger
											return "CSP1"
										
										case "1": // Important
											return "CSP1"
										
										default:
											return ""
											break
									}
								}
							}
						}
						else {// >20 000km/an
							if(hWork == "0") {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP1"
										
										case "1": // Léger
											return "CSP1"
										
										case "0": // Important
											return "CSP1"
										
										default:
											return ""
											break
									}
								}
							}
							else {
								if(dangerousJob != 0) {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP4"
										
										case "1": // Léger
											return "CSP4"
										
										case "0": // Important
											return "CSP4"
										
										default:
											return ""
											break
									}
								}
								else {
									switch (manualActivites) {
										case "2": // Aucune
											return "CSP1"
										
										case "1": // Léger
											return "CSP1"
										
										case "0": // Important
											return "CSP1"
										
										default:
											return ""
											break
									}
								}
							}
						}

						break

					default:
						return ""
						break
				}
			}
		}
	}

	getAge(birthdate: string): number {
		let SplitDate: Array<string> = birthdate.split("/")

		if(SplitDate && SplitDate.length === 3) {
			let birthDate: any = new Date(parseInt(SplitDate[2]), parseInt(SplitDate[1])-1, parseInt(SplitDate[0]))		
			let age: number = Math.ceil(Date.now() - birthDate.getTime())

			return Math.floor((age / (1000 * 3600 * 24))/365.25)			
		}
		else {
			return 0
		}
	}

	CalculateCompareDate(dateToCalculate: any, unit: string, val: number): Date {
		if(dateToCalculate != "Invalid Date") {

			let tempDate: any

			if(dateToCalculate instanceof Date && !isNaN(dateToCalculate.valueOf())) {
				tempDate = dateToCalculate
			}
			else {
				tempDate = getDate(dateToCalculate)
			}
  
			if(tempDate) {
				switch (unit) {
		    		case "day":
		    			tempDate = new Date(tempDate.setUTCDate(tempDate.getUTCDate() + val))	    	
		    			break

		    		case "month":
		    			tempDate = new Date(tempDate.setUTCMonth(tempDate.getUTCMonth() + val))
		    			break

		    		case "year":	  
		    			tempDate = new Date(tempDate.setUTCFullYear(tempDate.getUTCFullYear() + val))
		    			break
		    		
		    		default:
		    			break
		    	}

		    	return tempDate
			}			
		}
	}

	getMedicalFormatities(age: number, amount: number): Array<string> {

		let respArr: Array<string> = []

		if(age < 46) {
			if(amount <= 100000) {
				respArr = ["Questionnaire de Santé Simplifié"]
			}
			else if(amount >=100001 && amount <= 500000) {
				respArr = ["Questionnaire de Santé Simplifié"]
			}
			else if(amount >= 500001 && amount <= 1000000) {
				respArr = ["Questionnaire de Santé"]
			}
			else if(amount >= 1000001 && amount <= 1700000) {
				respArr = ["Questionnaire de Santé", "Profil Sanguin"]
			}
			else if(amount >= 1700001 && amount <= 2500000) {
				respArr = ["Dossier médical standard", "Copie de l'offre de prêt"]
			}
			else if(amount >= 2500001 && amount <= 5000000) {
				respArr = ["Dossier médical standard", "Copie de l'offre de prêt", "Document Financier"]
			}
		}
		else if(age >= 46 && age < 55) {
			if(amount <= 100000) {
				respArr = ["Questionnaire de Santé Simplifié"]
			}
			else if(amount >=100001 && amount <= 500000) {
				respArr = ["Questionnaire de Santé Simplifié"]
			}
			else if(amount >= 500001 && amount <= 1000000) {
				respArr = ["Questionnaire de Santé"]
			}
			else if(amount >= 1000001 && amount <= 1700000) {
				respArr = ["Dossier médical standard"]
			}
			else if(amount >= 1700001 && amount <= 2500000) {
				respArr = ["Dossier médical standard", "Copie de l'offre de prêt"]
			}
			else if(amount >= 2500001 && amount <= 5000000) {
				respArr = ["Dossier médical standard", "Copie de l'offre de prêt", "ECG d'Effort avec rapport du Cardiologue", "Document Financier"]
			}
		}
		else if(age >= 55 && age < 70) {
			if(amount <= 100000) {
				respArr = ["Questionnaire de Santé"]
			}
			else if(amount >=100001 && amount <= 500000) {
				respArr = ["Questionnaire de Santé", "Rapport de visite médicale", "Profil Sanguin"]
			}
			else if(amount >= 500001 && amount <= 1000000) {
				respArr = ["Dossier médical standard"]
			}
			else if(amount >= 1000001 && amount <= 1700000) {
				respArr = ["Dossier médical standard"]
			}
			else if(amount >= 1700001 && amount <= 2500000) {
				respArr = ["Dossier médical standard", "Copie de l'offre de prêt", "Document Financier"]
			}
			else if(amount >= 2500001 && amount <= 5000000) {
				respArr = ["Consulter le Réassureur avant constitution du dossier"]
			}
		}
		else {
			respArr = ["Consulter le Réassureur avant constitution du dossier"]
		}


		return respArr
	}

	
}
