import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { QssService } from '../../../services/qss.service'


@Component({
	selector: 'app-qss-summary',
	templateUrl: './qss-summary.component.html',
	styleUrls: ['./qss-summary.component.scss']
})
export class QssSummaryComponent implements OnInit {

	public qssForm: Array<any> = []
	public userId: number
	public userToken: string = ""
	public isSubmit: boolean = false
	
	constructor(
		private _QSS: QssService,
		public router: Router
	) {
		this.qssForm = this._QSS.GetAvailableForm()
	}

	ngOnInit() {
		this.userId = parseInt(localStorage.getItem("u_qss_uid"))
		this.userToken = localStorage.getItem("qss_uid")

		if(this.userId === NaN || this.userId === null || this.userId === undefined || this.userId === 0 || this.userToken === null || this.userToken === undefined || this.userToken == "") {
			this.router.navigate(["/questionnaire/warning/forbidden"])
		}
	}

	valid() {
		this.isSubmit = true

		this._QSS.SendDatasForPdf(this.userId, this.userToken, this.qssForm)
			.subscribe(
				resp => {
					this.isSubmit = false
					this.router.navigate(["questionnaire/validation"])
				},
				Error => {
					this.isSubmit = false
					console.log("error send datas for pdf generation")
				}
			)
	}	
}
