import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { from, Observable, of, Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router, ResolveStart } from '@angular/router'
import { SimpleModalService } from "ngx-simple-modal"

import { ModalComponent } from '../components/modal/modal.component'
import { ModalDocumentComponent } from '../components/modal-document/modal-document.component'
import { ModalIncrementsComponent } from '../components/modal-increments/modal-increments.component'

import { formModel, SuccessMessage, ListPagination } from '../models/forms/form.model'
import { FormSubStepDetails } from '../models/creation/maintabs.model'
import { Field } from '../models/creation/project/project.model'

import { FORMS } from '../app.forms'
import { CONFIG } from '../app.constants'
import { CreationService } from '../services/creation.service'
import { FormsService } from '../services/forms.service'

@Injectable({
	providedIn: 'root'
})
export class UtilitiesService {

	disposable: any = null
	eventSubscription: Subscription;

	constructor(
		private formBuilder: FormBuilder,
		public _http: HttpClient,
		public router: Router,
		public _creation: CreationService,
		private simpleModalService: SimpleModalService,
		public _Forms: FormsService
	) { }

	ApiCallV2(action: any, frmModule: string, step: number): Observable<any> {
		let newUrl: string = action.Params.Url
		let body: any = {}

		if (action.Params.HasVariable === true) {
			let tempVar: any = action.Params.VariableName
			if (action.Params.VariableType == "FromService") {
				tempVar = this._creation[tempVar]
			}

			newUrl = this.replaceUrlVariable(newUrl, tempVar)
		}

		switch (action.Params.Method) {
			case "POST":
				if (action.Mapping.HasMapping === true) {
					let StepLength: number = this._creation.GetFormSubStepLength(frmModule, step)

					if (action.Mapping.MappingType == "Object") {
						let temp: any = this.buildAPIObject(action, body, step, 0, 0)

						body = temp
					}

					if (action.Mapping.MappingType == "Array") {
						let DetailsLength: number = this._creation.GetFormSubStepDetailsLength(frmModule, step, 0)
						let subBody: Array<any> = []

						for (var j = 0; j < DetailsLength; j++) {
							let temp: any = this.buildAPIObject(action, body, step, 0, j)

							subBody.push(temp)
						}

						body = subBody
					}

					if (action.Mapping.MappingType == "ArrayOfArray") {
						body = []

						for (var i = 0; i < StepLength; i++) {
							let DetailsLength: number = this._creation.GetFormSubStepDetailsLength(frmModule, step, i)
							let subBody: Array<any> = []

							for (var j = 0; j < DetailsLength; j++) {
								let temp: any = this.buildAPIObject(action, body, step, i, j)

								subBody.push(temp)
							}

							body.push(subBody)
						}
					}
				}

				return this._http.post<any>(CONFIG.API[CONFIG.ENV] + action.Params.Url, {})
					.pipe(
						map(
							data => {
								return data
							}
						)
					)

			case "PUT":
				if (action.Mapping.HasMapping === true) {
					let StepLength: number = this._creation.GetFormSubStepLength(frmModule, step)

					if (action.Mapping.MappingType == "Object") {
						let temp: any = this.buildAPIObject(action, body, step, 0, 0)

						body = temp
					}

					if (action.Mapping.MappingType == "Array") {
						let DetailsLength: number = this._creation.GetFormSubStepDetailsLength(frmModule, step, 0)
						let subBody: Array<any> = []

						for (var j = 0; j < DetailsLength; j++) {
							let temp: any = this.buildAPIObject(action, body, step, 0, j)

							subBody.push(temp)
						}

						body = subBody
					}

					if (action.Mapping.MappingType == "ArrayOfArray") {
						body = []

						for (var i = 0; i < StepLength; i++) {

							let DetailsLength: number = this._creation.GetFormSubStepDetailsLength(frmModule, step, i)
							let subBody: Array<any> = []

							for (var j = 0; j < DetailsLength; j++) {
								let temp: any = this.buildAPIObject(action, body, step, i, j)

								subBody.push(temp)
							}

							body.push(subBody)
						}
					}
				}

				return this._http.put<any>(CONFIG.API[CONFIG.ENV] + newUrl, body)
					.pipe(
						map(
							data => {
								return data
							}
						)
					)

			case "GET":
				return this._http.get<any>(CONFIG.API[CONFIG.ENV] + newUrl)
					.pipe(
						map(
							data => {
								return data
							}
						)
					)

			default:
				return of<any>([])
		}
	}

	formActionApiCall(action: any, currentForm: FormGroup, currentValue: any, pagination: ListPagination): Observable<any> {
		let url: string = action.Params.Url
		let minlengthLocked: boolean = false
		let currentPagination: ListPagination = new ListPagination()
		currentPagination.PreviousAction = { action, currentForm, currentValue }

		if (url && url !== "") {

			if (action && action.Params && action.Params.Variables) {
				action.Params.Variables.forEach(
					v => {
						if (v.Type == "FromForm") {
							if (currentForm.controls[v.Name] && v.MinLength !== 0
								&& (currentForm.controls[v.Name].value
									&& currentForm.controls[v.Name].value.length < v.MinLength)
								|| (currentForm.controls[v.Name] && !currentForm.controls[v.Name].value)) {

								minlengthLocked = true
							}
						}
					}
				)
			}

			if (minlengthLocked === false) {
				url = this.replaceUrlFormVariable(url, currentForm, action, currentValue)

				switch (action.Params.Method) {
					case "GET":
						if (action.ResultAction == "FillSelect" && url !== null && currentValue !== null) {
							let headers: HttpHeaders = new HttpHeaders();
							if (pagination && pagination.End < pagination.Max) {
								headers = headers.append('Range', (pagination.End + 1) + '-' + (pagination.End + 21))
							}

							return this._http.get<any>(
								CONFIG.API[CONFIG.ENV] + url,
								{ headers, observe: 'response' }
							)
								.pipe(
									map(
										resp => {
											const keys = resp.headers.keys()
											let contentRange: string = resp.headers.get("content-range")

											if (contentRange && contentRange.indexOf("/") != -1 && contentRange.indexOf("-") != -1) {
												let scale: Array<string> = contentRange.split("/")
												let startEnd: Array<string> = scale[0].split("-")

												currentPagination.Start = Number(startEnd[0])
												currentPagination.End = Number(startEnd[1])
												currentPagination.Max = Number(scale[1])
											}

											let data: any = resp.body
											let items: Array<any> = []
											if (data && data.length > 0) {
												data.forEach(
													item => {
														let it: object = {}
														Object.keys(action.Mapping).forEach((key) => {
															it[key] = action.Mapping[key] == "[obj]" ? item : item[action.Mapping[key]]
														})
														items.push(it)
													}
												)
												return { Pagination: currentPagination, Items: items }
											}

											return { Pagination: currentPagination, Items: [] }
										}
									)
								)
						}
						else if (action.ResultAction == "FillTable" && url !== null && currentValue !== null) {
							return this._http.get<any>(CONFIG.API[CONFIG.ENV] + url)
								.pipe(
									map(
										data => {

											return data
										}
									)
								)
						}
						else {
							return of<any>([])
						}

					case "EXT_GET":
						if (action.ResultAction == "FillSelect" && url !== null && currentValue !== null) {
							return this._http.get<any>(url)
								.pipe(
									map(
										data => {

											let items: Array<any> = []
											let tempData: Array<any> = []

											if (action.ValuesPropertyName == "") {
												tempData = data
											}
											else {
												if (data[action.ValuesPropertyName]) {
													tempData = data[action.ValuesPropertyName]
												}
											}

											tempData.forEach(
												item => {
													let it: object = {}
													Object.keys(action.Mapping).forEach((key) => {
														it[key] = action.Mapping[key] == "[obj]" ? item : item[action.Mapping[key]]
													})
													items.push(it)
												}
											)
											return items

											// return tempData
										}
									)
								)
						}
						else {
							return of<any>([])
						}
				}
			}
			else {
				return of<any>([])
			}
		}
	}

	replaceUrlVariable(apiUrl: string, variable: any): string {
		let re: any = /[^\{{]+(?=\}})/g
		let emptyVar: boolean = false

		let checkVariable: Array<string> = []
		checkVariable = apiUrl.match(re)

		if (checkVariable !== null && checkVariable.length > 0) {
			checkVariable.forEach(
				cv => {

					if (variable !== null) {
						apiUrl = apiUrl.replace("{{" + cv + "}}", variable)
					}
					else {
						emptyVar = true
					}
				}
			)
		}

		return emptyVar === false ? apiUrl : null
	}

	replaceUrlFormVariable(apiUrl: string, currentForm: FormGroup, action: any, currentValue: any): string {
		let emptyVar: boolean = false

		if (action.Params.HasVariable === true) {
			action.Params.Variables.forEach(
				v => {
					let temp: any = null

					if (v.Type == "FromService") {
						temp = this._creation[v.Name]
					}

					if (v.Type == "searchValue") {
						temp = currentValue
					}

					if (v.Type == "FromForm") {
						if (currentForm.controls[v.Name]) {
							let frmValue: any = currentForm.controls[v.Name].value
							if (typeof (frmValue) === "object") {
								temp = frmValue.Id
							}
							else {
								temp = frmValue
							}
						}
					}

					if (temp !== null) {
						apiUrl = apiUrl.replace("{{" + v.Name + "}}", temp)
					}
					else {
						emptyVar = true
					}
				}
			)
		}

		return emptyVar === false ? apiUrl : null
	}

	checkRule(currentValue: any, operator: string, goalValue: any): boolean {
		if (typeof currentValue == "object") {
			if (currentValue && currentValue.Value !== null && currentValue.Value !== undefined) {
				currentValue = currentValue.Value
			}
		}

		if (typeof currentValue == "string") {
			currentValue = currentValue.trim().toLowerCase()
		}

		if (typeof goalValue == "string") {
			goalValue = goalValue.trim().toLowerCase()
		}


		switch (operator) {
			case ">":
				return currentValue > goalValue

			case ">=":
				return currentValue >= goalValue

			case "<":
				return currentValue < goalValue

			case "<=":
				return currentValue <= goalValue

			case "=":
				return currentValue == goalValue

			case "!=":
				return currentValue != goalValue

			case "IN":
				return goalValue.indexOf(currentValue) != -1

			case "!IN":
				return goalValue.indexOf(currentValue) == -1

			case "NO":
				return true

			default:
				return false

		}
	}

	getCompareValue(action: any, globalFormConfig: Array<formModel>, globalForm: Array<FormGroup>, currentForm: FormGroup, item: any): any {
		let formIndex: number
		let currentCtrlValue: any
		let formSubStepDetails: FormSubStepDetails

		switch (action.Params["CompareValueType"]) {
			case "Fixe":
				currentCtrlValue = currentForm.controls[item.Name].value
				return currentCtrlValue

			case "fromPreviousStep":
				// formIndex = globalFormConfig.findIndex( gf => { return gf.Name == action.Params["CompareValueForm"] })
				formIndex = this._Forms.GetFormStepIndex(action.Params["CompareValueModule"], action.Params["CompareValueForm"])
				formSubStepDetails = this._creation.GetFormSubStepDetails("quotation", formIndex, 0, 0)

				if (formSubStepDetails !== null) {
					let val: any = formSubStepDetails.Fields.find(item => { return item.name == action.Params["CompareValueField"] })

					currentCtrlValue = val ? val.value : null
				}

				return currentCtrlValue

			case "fromCurrentStep":
				currentCtrlValue = currentForm.controls[action.Params.CompareValueField] ? currentForm.controls[action.Params.CompareValueField].value : null

				return currentCtrlValue

			case "steplength":
				let frmIdx: number = this._Forms.GetFormStepIndex(action.Params["CompareValueModule"], action.Params["CompareValueForm"])
				let frmLength: any = this._creation.GetFormSubStepDetailsLength(action.Params["CompareValueModule"], frmIdx, 0)

				return frmLength

			default:
				return null

		}
	}

	buildAPIObject(action: any, body: any, step: number, subStep: number, details: number): any {
		let formIdx: number = FORMS[action.Mapping.SourceModule].findIndex(item => { return item.Name == action.Mapping.SourceStep })
		let resp: any = {}

		if (formIdx !== null && formIdx !== undefined) {
			let currentForm: FormSubStepDetails = this._creation.GetFormSubStepDetails(action.Mapping.SourceModule, formIdx, subStep, details)

			if (currentForm) {
				action.Mapping.SubValues.forEach(
					field => {
						let formVal: any

						if (field.ResponseObjectName !== "") {
							if (resp[field.ResponseObjectName]) {

								if (field.FieldType == "FixeValue") {
									resp[field.ResponseObjectName][field.Name] = this.ParseValue(field, field.FixedValue)
								}
								else {
									formVal = currentForm.Fields.find(item => { return item.name == field.FormName })

									if (formVal) {
										switch (field.FieldType) {
											case "FieldValue":
												resp[field.ResponseObjectName][field.Name] = this.ParseValue(field, formVal.value)
												break

											case "DateValue":
												let tmp: any = formVal.value

												if (formVal.value && formVal.value.length == 8) {
													if (formVal.value.indexOf("/") == -1) {
														tmp = formVal.value.substring(0, 2) + "/" + formVal.value.substring(2, 4) + "/" + formVal.value.substring(4)
													}
												}

												resp[field.ResponseObjectName][field.Name] = tmp
												break

											case "OptionValue":
												resp[field.ResponseObjectName][field.Name] = formVal.options[0] ? this.ParseValue(field, formVal.options[0].name) : null
												break

											case "ObjectValue":
												resp[field.ResponseObjectName][field.Name] = formVal.value ? this.ParseValue(field, formVal.value[field.Mapping]) : null
												break

											case "ArrayValue":
												resp[field.ResponseObjectName][field.Name] = formVal.value ? formVal.value : null
												break

											case "File":
												resp[field.ResponseObjectName][field.Name] = formVal.value ? (typeof (formVal.value) == "string" ? formVal.value : null) : null
												break
										}
									}
								}
							}
							else {
								resp[field.ResponseObjectName] = {}

								if (field.FieldType == "FixeValue") {
									resp[field.ResponseObjectName][field.Name] = this.ParseValue(field, field.FixedValue)
								}
								else {
									formVal = currentForm.Fields.find(item => { return item.name == field.FormName })

									if (formVal) {
										switch (field.FieldType) {
											case "FieldValue":
												resp[field.ResponseObjectName][field.Name] = this.ParseValue(field, formVal.value)
												break

											case "DateValue":
												let tmp: any = formVal.value

												if (formVal.value && formVal.value.length == 8) {
													if (formVal.value.indexOf("/") == -1) {
														tmp = formVal.value.substring(0, 2) + "/" + formVal.value.substring(2, 4) + "/" + formVal.value.substring(4)
													}
												}

												resp[field.ResponseObjectName][field.Name] = tmp
												break

											case "OptionValue":
												resp[field.ResponseObjectName][field.Name] = formVal.options[0] ? this.ParseValue(field, formVal.options[0].name) : null
												break

											case "ObjectValue":
												resp[field.ResponseObjectName][field.Name] = formVal.value ? this.ParseValue(field, formVal.value[field.Mapping]) : null
												break

											case "ArrayValue":
												resp[field.ResponseObjectName][field.Name] = formVal.value ? formVal.value : null
												break

											case "File":
												resp[field.ResponseObjectName][field.Name] = formVal.value ? (typeof (formVal.value) == "string" ? formVal.value : null) : null
												break
										}
									}
								}
							}
						}
						else {
							if (field.FieldType == "FixeValue") {
								resp[field.Name] = this.ParseValue(field, field.FixedValue)
							}
							else {
								formVal = currentForm.Fields.find(item => { return item.name == field.FormName })

								if (formVal) {
									switch (field.FieldType) {
										case "FieldValue":
											resp[field.Name] = this.ParseValue(field, formVal.value)
											break

										case "DateValue":
											let tmp: any = formVal.value

											if (formVal.value && formVal.value.length == 8) {
												if (formVal.value.indexOf("/") == -1) {
													tmp = formVal.value.substring(0, 2) + "/" + formVal.value.substring(2, 4) + "/" + formVal.value.substring(4)
												}
											}

											resp[field.Name] = tmp
											break

										case "OptionValue":
											resp[field.Name] = formVal.options[0] ? this.ParseValue(field, formVal.options[0].name) : null
											break

										case "ObjectValue":
											resp[field.Name] = formVal.value ? this.ParseValue(field, formVal.value[field.Mapping]) : null
											break

										case "ArrayValue":
											resp[field.Name] = formVal.value ? formVal.value : null
											break

										case "File":
											resp[field.Name] = formVal.value ? (typeof (formVal.value) == "string" ? formVal.value : null) : null
											break
									}
								}
							}
						}
					}
				)
			}

			return resp
		}
	}

	ParseValue(field: any, value: any): any {
		switch (field.Type) {
			case "string":
				return value !== null ? value.toString() : ""

			case "integer":
				return parseInt(value)

			case "float":
				return parseFloat(value)

			default:
				return value
		}
	}

	MapData(action: any, form: any, item: any, response: any): void {
		let formCtrl: any
		let responseValue: any = null

		if (item.Type == "Value") {
			formCtrl = form.controls[item.FormField]

			if (formCtrl) {
				responseValue = (response === null || response === undefined) ? null : response[item.ResponseField]
				formCtrl.patchValue(responseValue)
			}
		}
		else {
			if (item.Type == "Object") {
				item.Mapping.forEach(
					im => {
						responseValue = (response === null || response === undefined) ? null : response[item.ResponseField]
						this.MapData(action, form, im, responseValue)
					}
				)
			}
		}
	}

	AddFormControl(form: FormGroup, item: any, step: any) {
		if (item.Type == "cotisations_table") {
			form.addControl(item.Name, this.formBuilder.control([]))

			item.TableLine.forEach(
				TL => {
					TL.Controls.forEach(
						TLc => {
							this.AddFormControl(form, TLc, step)
						}
					)
				}
			)
		}
		else {
			form.addControl(item.Name, this.formBuilder.control([]))

			let valids: Array<any> = []
			item.Validators
				.forEach(
					v => {
						valids.push(this._Forms.getValidator(v.Type, v.Params))
					}
				)
			form.controls[item.Name].setValidators(Validators.compose(valids))

			if (item.Disabled == true) {
				form.controls[item.Name].disable()
			}

			let formField: Field = new Field()

			if (step && step.Fields.length > 0) {
				formField = step.Fields.find(f => { return f.name == item.Name })
			}
			let value: any = (formField && formField.value !== null) ? formField.value : item.Default

			if (item.Type != "file_upload") {
				form.controls[item.Name].patchValue(value)
				form.controls[item.Name].updateValueAndValidity()
			}

			if (item.Type == "datestring") {
				let tmpvalue: string = value
				if (value !== null && value !== undefined) {
					if (value.length == 8) {
						if (value.indexOf("/") == -1) {
							let day: string = tmpvalue.substring(0, 2)
							let month: string = tmpvalue.substring(2, 4)
							let year: string = tmpvalue.substring(4)

							tmpvalue = day + "/" + month + "/" + year
						}
					}
				}

				form.controls[item.Name].patchValue(tmpvalue)
				form.controls[item.Name].updateValueAndValidity()
			}

			if (item.Type == "btn_check") {
				let vals: Array<any> = []
				vals = step.Fields.find(elt => { return elt.name == item.Name }).value || []

				if (vals) {
					vals.forEach(
						v => {
							let selectedItem = item.Values.find(it => { return it.Value == v.Value })

							if (selectedItem) {
								selectedItem.Checked = true
							}
						}
					)
				}
			}
		}
	}

	FormValidation(formModule: string, formModuleControls: Array<formModel>, formModuleForms: Array<FormGroup>, fields: Array<any>, step: number, index: number, detailsIndex: number): Promise<boolean> {
		let formToValid = this.formBuilder.group({})

		return new Promise<boolean>(
			(resolve, reject) => {

				this._Forms.getFormsStep(formModule, step)
					.then(
						form => {
							let formConfig = form
							if (formConfig != []) {
								fields
									.forEach(
										item => {
											formToValid.addControl(item.name, this.formBuilder.control([]))

											let fieldConfig = formConfig.Controls.find(ctrl => { return ctrl.Name == item.name }) || null
											if (fieldConfig) {
												let valids: Array<any> = []
												fieldConfig.Validators
													.forEach(
														v => {
															valids.push(this._Forms.getValidator(v.Type, v.Params))
														}
													)
												formToValid.controls[item.name].setValidators(Validators.compose(valids))
											}

											if (item.Disabled == true) {
												formToValid.controls[item.Name].disable()
											}

											formToValid.controls[item.name].patchValue(item.value)
										}
									)

								formConfig.InitActions.forEach(
									action => {
										if (action && action.Type == "HideField_onInit") {
											if (action.ResultAction == "HideField") {
												let ApplyAction: boolean = true
												let param: any = action.Params
												let frmIdx: number = this._Forms.GetFormStepIndex(param.CompareValueModule, param.CompareValueForm)

												if (frmIdx <= step) {
													if (param.CompareValueType == "fromPreviousStep") {
														let frmValue: any = this._creation.GetFormFieldValue(param.CompareValueModule, frmIdx, 0, 0, param.CompareValueField)
														if (!this.checkRule(frmValue, param.Operator, param.CompareValue)) {
															ApplyAction = false
														}
													}
												}

												if (param.CompareValueType == "fromCurrentStep") {
													frmIdx = formModuleControls.findIndex(frm => { return frm.Name == param.CompareValueForm })
													let frmValue: any = this._creation.GetFormFieldValue(formModule, step, index, detailsIndex, param.CompareValueField)

													if (!this.checkRule(frmValue, param.Operator, param.CompareValue)) {
														ApplyAction = false
													}
												}

												if (param.CompareValueType == "getAge") {
													let frmValue: any = this._creation.GetFormFieldValue(formModule, frmIdx, 0, detailsIndex, param.CompareValueField)

													if (frmValue) {
														let age: number = this._Forms.getAge(frmValue)

														if (!this.checkRule(age, param.Operator, param.CompareValue)) {
															ApplyAction = false
														}
													}
													else {
														ApplyAction = false
													}
												}

												if (ApplyAction === true) {
													if (formToValid.controls[action.Target] !== null && formToValid.controls[action.Target] !== undefined) {
														formToValid.controls[action.Target].clearValidators()
														formToValid.controls[action.Target].updateValueAndValidity()
													}
												}
											}
										}

										if (action && action.Type == "ShowField_onInit") {

											if (action.ResultAction == "ShowField") {
												let param: any = action.Params
												let ApplyAction: boolean = true
												let frmIdx: number = this._Forms.GetFormStepIndex(param.CompareValueModule, param.CompareValueForm)

												if (param.CompareValueType == "fromCurrentStep") {
													frmIdx = formModuleControls.findIndex(frm => { return frm.Name == param.CompareValueForm })
													let frmValue: any = this._creation.GetFormFieldValue(formModule, step, index, detailsIndex, param.CompareValueField)

													if (!this.checkRule(frmValue, param.Operator, param.CompareValue)) {
														ApplyAction = false
													}
												}

												if (ApplyAction === true) {
													if (param.Validators && param.Validators.length > 0) {
														let valids: Array<any> = []

														param.Validators
															.forEach(
																v => {
																	valids.push(this._Forms.getValidator(v.Type, v.Params))
																}
															)

														formToValid.controls[action.Target].setValidators(Validators.compose(valids))
														formToValid.controls[action.Target].updateValueAndValidity()
													}
												}
											}
										}

										if (action && action.Type == "AfterInit") {

											if (action.ResultAction == "SetOtherFieldValidators") {
												if (action.Params && action.Params.length > 0) {
													let ApplyAction: boolean = true

													action.Params.forEach(
														param => {
															if (param.Operator !== "NO") {
																let frmIdx: number = this._Forms.GetFormStepIndex(param.CompareValueModule, param.CompareValueForm)

																if (frmIdx <= step) {
																	if (param.CompareValueType == "fromPreviousStep") {
																		let frmValue: any = this._creation.GetFormFieldValue(formModule, frmIdx, 0, 0, param.CompareValueField)
																		if (!this.checkRule(frmValue, param.Operator, param.CompareValue)) {
																			ApplyAction = false
																		}
																	}
																}

																if (param.CompareValueType == "fromCurrentStep") {
																	frmIdx = formModuleControls.findIndex(frm => { return frm.Name == param.CompareValueForm })
																	let frmValue: any = this._creation.GetFormFieldValue(formModule, step, index, detailsIndex, param.CompareValueField)

																	if (typeof frmValue == "object") {
																		if (frmValue) {
																			frmValue = frmValue.Value
																		}
																	}

																	if (!this.checkRule(frmValue, param.Operator, param.CompareValue)) {
																		ApplyAction = false
																	}
																}

																if (param.CompareValueType == "getAge") {
																	let frmValue: any = this._creation.GetFormFieldValue(formModule, frmIdx, 0, detailsIndex, param.CompareValueField)

																	if (frmValue) {
																		let age: number = this._Forms.getAge(frmValue)

																		if (!this.checkRule(age, param.Operator, param.CompareValue)) {
																			ApplyAction = false
																		}
																	}
																	else {
																		ApplyAction = false
																	}
																}
															}
														}
													)

													if (ApplyAction === true) {
														if (action.SetValidators && action.SetValidators.length > 0) {
															formToValid.controls[action.Target].clearValidators()
															let valids: Array<any> = []
															action.SetValidators
																.forEach(
																	v => {
																		if (v.Type) {
																			let compareFrmIdx: number

																			if (v.Type != "clearValidators") {
																				switch (v.Type) {
																					case "DateBourquin":
																					case "DateHamon":
																					case "LowerThan85YearsOld":
																					case "LowerThan85Y":
																					case "GreaterThan18Y":
																					case "GreaterThan18YearsOld":
																					case "DateGreaterThanDateDeblocage":
																					case "DateLowerThanNow":
																					case "LowerThanHamon":
																					case "IsDateValid":

																						compareFrmIdx = formModuleControls.findIndex(f => f.Name == v.Params.CompareValueForm)
																						if (compareFrmIdx) {

																							let compareValue: any = this._creation.GetFormFieldValue(formModule, compareFrmIdx, 0, detailsIndex, v.Params.CompareValueField)

																							if (v.Type == "GreaterThan18Y" || v.Type == "LowerThan85Y") {
																								compareValue = new Date(Date.now())
																							}
																							else if (v.Type == "IsDateValid") {
																								valids.push(this._Forms.getValidator(v.Type, v.Params))
																							}

																							if (compareValue && v.Type != "IsDateValid") {
																								if (compareValue) {
																									v.Params.compareValue = this._Forms.CalculateCompareDate(compareValue, v.Params.Unit, v.Params.Val)
																									v.Params.Control = formModuleForms[step].controls[action.Target]
																									v.Params.ValidatorType = v.Type

																									let validation = this._Forms.getValidator(v.Type, v.Params)
																									valids.push(validation)
																								}
																							}
																						}

																						valids.push(this._Forms.getValidator(v.Type, v.Params))
																						break

																					case "GreaterThan":
																						valids.push(this._Forms.getValidator(v.Type, { compareValue: this._Forms.getDate(v.Params), Control: formModuleForms[step].controls[action.Target], ValidatorType: v.Type }))
																						break

																					case "EqualsTo":
																						valids.push(this._Forms.getValidator(v.Type, v.Params))
																						break

																					case "minParams":
																						valids.push(this._Forms.getValidator(v.Type, v.Params))
																						break

																					case "maxParams":
																						valids.push(this._Forms.getValidator(v.Type, v.Params))
																						break

																					case "minValueWithParams":
																						let frmValue: any = this._creation.GetFormFieldValue(action.TargetModule, step, index, detailsIndex, v.Params.SourceField)

																						if (frmValue) {
																							let newVal: number = parseFloat(frmValue) + parseFloat(v.Params.VariationValue)
																							valids.push(this._Forms.getValidator(v.Type, newVal))
																						}

																						break

																					case "maxValueWithParams":
																						let frmValue2: any = this._creation.GetFormFieldValue(action.TargetModule, step, index, detailsIndex, v.Params.SourceField)

																						if (frmValue2) {
																							let newVal: number = parseFloat(frmValue2) + parseFloat(v.Params.VariationValue)
																							valids.push(this._Forms.getValidator(v.Type, newVal))
																						}

																						break

																					case "increments":
																						let frmIdx: number = this._Forms.GetFormStepIndex(formModule, "loan")
																						let incs: any = this._creation.GetFormFieldValue(formModule, frmIdx, index, detailsIndex, "increments")
																						v.Params = {}
																						v.Params.increments = Array.isArray(incs) ? incs : []
																						valids.push(this._Forms.getValidator(v.Type, v.Params))
																						break

																					case "CheckAllShare100":
																						let frmIndex: number = this._Forms.GetFormStepIndex(action.TargetModule, action.TargetStep)
																						let formSubIndex: number = this._creation.GetFormSubIndex()
																						let frmLength: number = this._creation.GetFormSubStepDetailsLength(action.TargetModule, frmIndex, formSubIndex)

																						v.Params = []

																						for (var i = 0; i < frmLength; i++) {
																							v.Params.push(this._creation.GetFormFieldValue(action.TargetModule, frmIndex, formSubIndex, i, action.Target))
																						}

																						valids.push(this._Forms.getValidator(v.Type, v.Params))
																						break

																					case "max90Y":
																						let maxDuration = this._creation.GetLoansMaxDuration()
																						let targetStepIdx: number = this._Forms.GetFormStepIndex(action.TargetModule, action.TargetStep)
																						let targetSubIdx: number = this._creation.GetFormSubIndex()

																						let dateNow = new Date(Date.now())
																						dateNow.setHours(0, 0, 0, 0)

																						let endOfLoan: any = this._Forms.CalculateCompareDate(dateNow.toString(), "month", maxDuration)

																						v.Params = {
																							endOfLoan: endOfLoan
																						}

																						valids.push(this._Forms.getValidator(v.Type, v.Params))
																						break

																					case "HamonEffectiveDateValidity":
																						let today = new Date(Date.now())
																						today.setHours(0, 0, 0, 0)

																						v.Params.compareValue = this._Forms.CalculateCompareDate(today, v.Params.Unit, v.Params.Val)
																						v.Params.Control = formModuleForms[step].controls[action.Target]
																						v.Params.ValidatorType = v.Type

																						let validation = this._Forms.getValidator(v.Type, v.Params)
																						valids.push(validation)
																						break

																					case "DurationMinusParam":
																						let formIndex: number = this._Forms.GetFormStepIndex(action.TargetModule, action.TargetStep)

																						let duration = this._creation.GetFormFieldValue(action.TargetModule, formIndex, index, detailsIndex, "Duration")
																						if (duration) {
																							v.Params.duration = Number(duration)

																							valids.push(this._Forms.getValidator(v.Type, v.Params))
																						}
																						break

																					default:
																						valids.push(this._Forms.getValidator(v.Type))
																						break
																				}
																			}
																		}
																	}
																)

															formToValid.controls[action.Target].setValidators(Validators.compose(valids))
															formToValid.controls[action.Target].patchValue(formToValid.controls[action.Target].value)
														}
													}
												}
											}

											if (action.ResultAction == "SetValueFromCurrentStepFirstSubDetails") {
												if (detailsIndex !== 0) {
													let sourceValue: any = this._creation.GetFormFieldValue(formModule, step, 0, 0, action.Params.SourceValueField)
													let tergetValue: any = this._creation.GetFormFieldValue(formModule, step, 0, detailsIndex, action.Params.SourceValueField)

													let frmCtrl = formModuleForms[step].controls[action.Target]

													if (frmCtrl && (tergetValue == "" || tergetValue === null || tergetValue === undefined)) {
														frmCtrl.patchValue(sourceValue)
													}
												}
											}
										}
									}
								)

								fields
									.forEach(
										item => {
											formToValid.controls[item.name].clearValidators()

											console.log('****************************')
											console.log('****************************')
											console.log(formToValid)
											console.log('****************************')
											console.log('****************************')
										

											if (((this.router.url) == "/creation/quotation/1")) {



												var TypePret = formToValid.value.Kind.Value

												if (TypePret == "graduated") {
												





													if ((sessionStorage.getItem('Sommeduree') != null) || (sessionStorage.getItem('SommeMont') != null)) {
														if (
															(this._creation.GetLoansMaxDuration() != Number(sessionStorage.getItem('Sommeduree'))) || (this._creation.GetLoansAmount() < Number(sessionStorage.getItem('SommeMont')))
														) {




															this.showError("Le tableau des échéances est vide ou incorrect")

															resolve(false)

														}

													}

												}



												if (TypePret == "intermediate") {

													if (formToValid.value.Duration > 36) {
														this.showError("La durée du prêt doit être inférieur à 37 mois.")
														resolve(false)

													}
													if (formToValid.value.Rate == 0) {
														this.showError("La valeur du taux n'est pas valide.")
														resolve(false)

													}
													else
													resolve(true)


												}
												if (TypePret == "balloon") {
													if (formToValid.value.Duration < 37) {
														this.showError("La durée du prêt doit être supérieure à 37 mois.")
														resolve(false)
													}
													if (formToValid.value.Duration > 420) {
														this.showError("La durée du prêt ne peut excéder 420 mois soit l’équivalent de 35 ans.")
														resolve(false)
													}
													if (formToValid.value.Rate == 0) {
														this.showError("La valeur du taux n'est pas valide.")
														resolve(false)

													}
													else{
														resolve(true)
												}
												}


												if (TypePret == "regroupementdecredits") {
													if (formToValid.value.Duration < 37) {
														this.showError("La durée du prêt doit être supérieure à 37 mois.")
														resolve(false)
													}
													if (formToValid.value.Rate == 0) {
														this.showError("La valeur du taux n'est pas valide.")
														resolve(false)

													}
													else{
														resolve(true)
												}
												}




												if (TypePret == "interest_free") {
													if (Number(formToValid.value.Rate) > 0) {
														this.showError("La valeur du taux n'est pas valide.")
														resolve(false)
													}
											
													if (formToValid.value.Duration < 37) {
														this.showError("La durée du prêt doit être supérieure à 37 mois.")
														resolve(false)
													}

													if(Number(formToValid.value.Deferral)>Number(formToValid.value.Duration))
													{

														console.log(formToValid.value.Deferral,formToValid.value.Duration)
														this.showError("La durée du prêt doit être supérieure au duree de Différé.")
														resolve(false)
													}

													if(formToValid.value.Deferral>=180)
													{
														this.showError("La durée du différé ne peut excéder 180 mois.")
														resolve(false)
													}

													else{
														resolve(true)
												}

												}


												if (TypePret == "fixed_rate") {
													if (formToValid.value.Rate == 0) {
														this.showError("La valeur du taux n'est pas valide.")
														resolve(false)

													}
													
													if (formToValid.value.Duration < 37) {
														this.showError("La durée du prêt doit être supérieure à 37 mois.")
														resolve(false)
													}
													if (formToValid.value.Duration > 420) {
														this.showError("La durée du prêt ne peut excéder 420 mois soit l’équivalent de 35 ans.")
														resolve(false)
													}

													
													else
													{
														resolve(true)
													}

												}
												//  setTimeout(()=>{







												if (formToValid.controls[item.name].valid === false) {
						
												



													/*if ((formToValid.controls[item.name].value > 240) && (item.name == "Duration")) {
														this.showError("La durée du prêt ne peut excéder 240 mois soit l’équivalent de 20 ans.")

													}*/


													if ((formToValid.controls[item.name].value > 180) && (item.name == "Deferral")) {
														this.showError("La durée du différé ne peut excéder 180 mois.")
														resolve(false)

													}


													if ((item.name == "Deferral") && ((formToValid.controls[item.name].errors.max) == undefined)) {
														this.showError("Le différé doit être inférieur à la durée du prêt - 1 an.")

													}

													if ((item.name == "Amount")) {
														this.showError("Le montant est requis")

													}



												}
											}


										})

								resolve(formToValid.valid)
							}
							else {
								resolve(false)
							}
						}
					)
					.catch(
						(err) => {
							console.log(err);
							this.showError("Il y a eu un problème lors de l'initialisation de la vérification.")
							reject(false)
						}
					)
			}
		)
	}


	///////////////////////
	//       Alert       //
	///////////////////////
	showError(message: string) {
		if (this.disposable === null) {
			this.disposable = this.simpleModalService.addModal(ModalComponent, {
				title: "",
				message: message,
				hasCancel: false,
				cancelCaption: "",
				validCaption: "OK"
			})
				.subscribe(
					() => {
						this.disposable = null
					}
				)
		}
	}

	showErrorWithPromise(message: string): Promise<boolean> {

		return new Promise(
			(resolve, reject) => {

				let disposable = this.simpleModalService.addModal(ModalComponent, {
					title: "",
					message: message,
					hasCancel: false,
					cancelCaption: "",
					validCaption: "OK"
				})
					.subscribe(
						(isConfirmed) => {
							resolve(true)
						}
					)
			}
		)
	}

	showConfirm(message: string): Promise<boolean> {

		return new Promise(
			(resolve, reject) => {

				let disposable = this.simpleModalService.addModal(ModalComponent, {
					title: "",
					message: message,
					hasCancel: true,
					cancelCaption: "Non",
					validCaption: "Oui"
				})
					.subscribe(
						(isConfirmed) => {
							if (isConfirmed) {
								resolve(true)
							}
							else {
								reject(false)
							}
						}
					)
			}
		)
	}

	showDocument(title: string, doc: any) {
		if (this.disposable === null) {
			this.disposable = this.simpleModalService.addModal(ModalDocumentComponent, {
				title: title,
				message: doc,
				hasCancel: false,
				cancelCaption: "",
				validCaption: "OK"
			})
				.subscribe(
					() => {
						this.disposable = null
					}
				)
		}
	}

	showSuccessMessage(messages: Array<SuccessMessage>): Promise<boolean> {

		let messagesArr: Array<string> = []

		messages.forEach(
			m => {
				let messageText = m.Text

				if (m.Actions && m.Actions.length > 0) {
					m.Actions.forEach(
						action => {
							if (action.Type == "PopUp") {
								let ApplyAction: boolean = true

								if (action.ResultAction == "ReplaceVariable" || action.ResultAction == "HideText" || action.ResultAction == "AddText") {

									action.Params.forEach(
										param => {
											let frmIdx: number = this._Forms.GetFormStepIndex(param.CompareValueModule, param.CompareValueForm)

											if (param.CompareValueType == "fromPreviousStep") {
												let frmValue: any
												if (param.CompareValueModule == "quotation" && param.CompareValueForm == "loan" && param.CompareValueField == "amount") {
													frmValue = this._creation.GetLoansAmount()
												}
												else {
													frmValue = this._creation.GetFormFieldValue(param.CompareValueModule, frmIdx, 0, 0, param.CompareValueField)
												}

												if (!this.checkRule(frmValue, param.Operator, param.CompareValue)) {
													ApplyAction = false
												}
											}

											if (param.CompareValueType == "getAge") {
												let frmValue: any = this._creation.GetFormFieldValue(param.CompareValueModule, frmIdx, 0, 0, param.CompareValueField)
												if (frmValue) {
													let age: number = this._Forms.getAge(frmValue)

													if (!this.checkRule(age, param.Operator, param.CompareValue)) {
														ApplyAction = false
													}
												}
												else {
													ApplyAction = false
												}
											}
										}
									)

								}

								if (ApplyAction === true) {
									if (action.ResultAction == "ReplaceVariable") {
										messageText = messageText.replace(action.Variable, action.ReplaceValue)
									}

									if (action.ResultAction == "HideText") {
										m.Visible = false
									}

									if (action.ResultAction == "AddText") {
										messageText = messageText + action.TextToAdd
									}
								}
							}
						}
					)
				}

				if (m.Visible === true) {
					messagesArr.push(messageText)
				}
			}
		)


		return new Promise(
			(resolve, reject) => {

				let disposable = this.simpleModalService.addModal(ModalComponent, {
					title: "",
					message: messagesArr.join(""),
					hasCancel: false,
					cancelCaption: "Non",
					validCaption: "OK"
				})
					.subscribe(
						(isConfirmed) => {
							if (isConfirmed) {
								resolve(true)
							}
							else {
								reject(false)
							}
						}
					)
			}
		)
	}

	showIncrementsModal(title: string, storedIncrements: Array<any>, count: number): Promise<boolean> {
		let Increments: Array<{ Id: number, Amount: number, AmountError: boolean, Duration: number, DurationError: boolean }> = []
		if (storedIncrements !== null && storedIncrements !== undefined && storedIncrements.length > 0) {
			Increments = storedIncrements
		}
		else {
			for (var i = 1; i <= count; ++i) {
				Increments.push({ Id: i, Amount: null, AmountError: false, Duration: null, DurationError: false })
			}
		}
		return new Promise(
			(resolve, reject) => {

				let disposable = this.simpleModalService.addModal(ModalIncrementsComponent, {
					title: title,
					increments: Increments,
					leftBtnCaption: "Ajouter un palier",
					validCaption: "Enregistrer"
				})
					.subscribe(
						(isConfirmed) => {
							if (isConfirmed) {
								resolve(isConfirmed)
							}
							else {
								reject(false)
							}
						}
					)
			}
		)
	}

	showWaitingMessage(message: string): Promise<boolean> {
		return new Promise(
			(resolve, reject) => {
				if (this.disposable === null) {
					this.disposable = this.simpleModalService.addModal(ModalComponent, {
						title: "",
						message: message,
						hasCancel: false,
						cancelCaption: "Non",
						validCaption: "_LOADER_"
					})
				}
				else {
					reject(false)
				}
			}
		)
	}

	closeWaitingMessage() {
		if (this.disposable !== null) {
			this.simpleModalService.removeAll()

			this.disposable = null
		}
	}
}
