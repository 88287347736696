import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ResolveStart } from '@angular/router'
import { combineLatest, Subject } from 'rxjs'

import { CoveredSchedule } from '../../../../models/contracts/contract.model'

import { ContractService } from '../../../../services/contract.service'
import { UtilitiesService } from '../../../../services/utilities.service'


@Component({
	selector: 'app-contract-schedule',
	templateUrl: './contract-schedule.component.html',
	styleUrls: ['./contract-schedule.component.scss']
})
export class ContractScheduleComponent implements OnInit {
	
  	public contractSchedule: Array<CoveredSchedule> = null	
	public contractId: string = ""
	public contractStatus: string = ""
	public currentCovered: CoveredSchedule = null
	public currentLoanIdx: number = 0

	constructor(
		public router: Router,
		private activatedRoute: ActivatedRoute,
		public _contract: ContractService,
		public _utilities: UtilitiesService
	) { }

	ngOnInit() {
		const urlParams = combineLatest(
			this.activatedRoute.params,
			this.activatedRoute.data,
			this.activatedRoute.queryParams,
			(params, queryParams, data) => ({ ...params, ...queryParams, ...data})
	    )

		urlParams.subscribe(
		 	routeParams => {
		 		this.contractId = routeParams.id
		 		this.contractStatus = routeParams.status

		 		if(this.contractId != "") {
					this._contract.GetContractCoveredSchedule(this.contractId, this.contractStatus)
							.subscribe(
								resp => {						
									if(resp && resp.State === true){							
										this.contractSchedule = resp.Covereds
										this.currentLoanIdx = 0
										
										if(this.contractSchedule !== null && this.contractSchedule !== undefined && this.contractSchedule.length > 0) {
											this.currentCovered = this.contractSchedule[0]
										}

										console.log(this.contractSchedule);
									}
									else{
										this._utilities.showErrorWithPromise("Il y a eu une erreur.")
											.then(
												() => {
													this.router.navigate(["contracts"])
												}
											)
											.catch(
												() => {
													this.router.navigate(["contracts"])
												}
											)
										
									}
								},
								Error => {
									console.log(Error)
									this._utilities.showErrorWithPromise("Il y a eu une erreur.")
										.then(
											() => {
												this.router.navigate(["contracts"])
											}
										)
										.catch(
											() => {
												this.router.navigate(["contracts"])
											}
										)
								}
							)		 			
		 		}
		 		else {
		 			this._utilities.showErrorWithPromise("Il y a eu une erreur.")
		 				.then(
		 					() => {
		 						this.router.navigate(["contracts"])
		 					}
		 				)
		 				.catch(
		 					() => {
		 						this.router.navigate(["contracts"])
		 					}
		 				)
		 		}
			}
		)
	}

	SwitchCoveredNavigation(covered: CoveredSchedule) {
		if(this.currentCovered != covered) {
			this.currentCovered = covered
			this.currentLoanIdx = 0
		}
	}

	SwitchLoanNavigation(loanIdx: number) {
		this.currentLoanIdx = loanIdx
	}

	counter(item: number) {
		return new Array(item)
	} 

	NavigateTo(route: string) {
		this.router.navigate(["contracts/" + route + "/" + this.contractStatus + "/" + this.contractId])
	}
}
