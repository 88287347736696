import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router, ActivatedRoute, ResolveStart } from '@angular/router'
import { combineLatest, Subject } from 'rxjs'
import { Location } from '@angular/common'
import { Subscription } from 'rxjs'

import { FORMS } from '../../../../app.forms'

import { formModel, formControl, Opts, Action } from '../../../../models/forms/form.model'
import { Field, FieldOption, Step } from '../../../../models/creation/project/project.model'
import { FormField } from '../../../../models/creation/maintabs.model'

import { CreationService } from '../../../../services/creation.service'
import { UtilitiesService } from '../../../../services/utilities.service'
import { FormsService } from '../../../../services/forms.service'

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

	public formModule: string = "quotation"

	public formModuleControls: Array<formModel> = new Array<formModel>()
	public formModuleForms: Array<FormGroup> = []
	public formStep: number
	public formIndex: number
	public formDetailsIndex: number
	public loading: boolean
	public count: number = 0
	public event: Subscription = null
	public isSubmitting: boolean = false
	public isSubmit: boolean
	public modiifiedFieldCount: number
	public InvalidForms: Array<{index: number, detailsIndex: number}> = []

	private invalidFormsEvent: Subject<any> = new Subject<any>()

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		public _creation: CreationService,
		public _utilities: UtilitiesService,
		private location: Location,
		public _Forms: FormsService
	) { }

	ngOnDestroy() {
		if(this.event !== null) {
			this.event.unsubscribe();
		}
	}

	ngOnInit() {

	
		this.isSubmitting = false
		this.isSubmit = false

        const urlParams = combineLatest(
			this.activatedRoute.params,
			this.activatedRoute.data,
			this.activatedRoute.queryParams,
			(params, queryParams, data) => ({ ...params, ...queryParams, ...data})
	    )
	    urlParams.subscribe(routeParams => {
	    	this._Forms.getForm(this.formModule)
	    		.then(
	    			form => {
	    				this.formModuleControls = form
	    				this.formModule = routeParams.frmModule
	    				this.formStep = routeParams.step

						this.formStep = this.formStep !== null && this.formStep !== undefined ? this.formStep : 0
						if(typeof this.formStep == 'string') { this.formStep = parseInt(this.formStep) }
						if(this.formStep < 0) { this.onCancel() }
						if(this.formStep > this.formModuleControls.length - 1) {
							this.onCancel()
						}
						else {
					    	this.formIndex = this._creation.GetFormSubIndex()
					    	this.formDetailsIndex = this._creation.GetFormSubDetailsIndex()   	

					    	if(this.event === null) {

						    	this.event = this._creation.formSubDetailsIndexEvent.subscribe( data => { 
						    		if(this.formDetailsIndex != data.index || data.refresh === true) {
							    		this.formDetailsIndex = data.index 
							    		this.initForm(this.formStep, this.formIndex, this.formDetailsIndex)

						    		}
						    	})	    		
					    	}

							if(this._creation.GetQId() == "") {
								this._creation.SetFormSubIndex(0)
								this._creation.SetFormSubDetailsIndex(0)

								this.router.navigate(["creation/quotation/0"])
									.then( data => { $(".creation").scrollTop(0) } )	
							}

							this.loading = false

							if(!this._creation.IsStepEnable(this.formModule, this.formStep)) {
								this._creation.SetFormSubIndex(0)
								this._creation.SetFormSubDetailsIndex(0)
								
								this.router.navigate(["creation/quotation/0"])
									.then( data => { $(".creation").scrollTop(0) } )	
							}
							else {
						        this.initForm(this.formStep, this.formIndex, this.formDetailsIndex)
						    }
						}
			    	}
			    )
			    .catch(
			    	() => {
			    		this._utilities.showError("Il y a eu un problème lors de l'initialisation.")
			    	}
			    )    	
	    })
	}


	// ***************************************
	// ********* utilities functions *********
	// ***************************************
	counter(item: number) {
		return new Array(item)
	}  

	CheckquotationId(): Promise<boolean> {
		return new Promise<boolean>(
			(resolve, reject) => {
				let tempQId: any = this._creation.GetQId()
				if(tempQId === 0 || tempQId === null || tempQId === undefined || tempQId == "" || tempQId == "0"){
					this._creation.GetQuotationId()
						.subscribe(
							resp => {
								if(resp && resp === true) {

									this._creation.PutSellingProduct()
										.subscribe(
											data => {
												if(data && data == true) {
													resolve(true)
												}
											},	
											Error => {
												console.log("PutSellingProduct KO")	
												reject(false)					
											}
										)
								}
								else{
									console.log("quotation KO")	
									reject(false)
								}
							},
							Error => {
								console.log("quotation_id KO")	
								reject(false)				
							}
						)
				}
				else {
					resolve(true)
				}
			}
		)	
	}

	filtering(items: Array<formControl>): Array<formControl> {
		return items.filter( 
			item => { 
				return item.ToHide == false 
			}
		)
	}

	isFormInvalid(idx: number): boolean {
		return (this.InvalidForms.findIndex( it => it.index == idx ) != -1)
	}

	FormValidation(StepLength: number, DetailsLength: number): Promise<boolean> {
		return new Promise<boolean>(
			(resolve, reject) => {				
				let allFormsValid: boolean = true
				let PromiseCount: number = 0

				for(let stp = 0; stp < StepLength; stp++) {
					for(let dtls = 0; dtls < DetailsLength; dtls++) {

						let FormSubStepDetails: any = this._creation.GetFormSubStepDetails(this.formModule, this.formStep, stp, dtls)
						if(FormSubStepDetails) {
							let fieldsList: Array<any> = FormSubStepDetails.Fields

							this._utilities.FormValidation(this.formModule, this.formModuleControls, this.formModuleForms, fieldsList, this.formStep, stp, dtls)
								.then(
									data => {
										PromiseCount++
										if(data === false) {
											this.InvalidForms.push({index: stp, detailsIndex: dtls})
											allFormsValid = false
										}	

										if(PromiseCount == StepLength * DetailsLength) {
											resolve(allFormsValid)
										}
									}
								)
								.catch(
									err => {
										PromiseCount++
										if(err === false) {
											this.InvalidForms.push({index: stp, detailsIndex: dtls})
											allFormsValid = false
										}	

										if(PromiseCount == StepLength * DetailsLength) {
											resolve(allFormsValid)
										}
									}
								)											
						}
					}
				}
			}
		)
	}


	// ***************************************
	// ********* Form initialisation *********
	// ***************************************
	initForm(step: number, index: number, detailsIndex: number) {
		if(this.formStep != step) { this.isSubmit = false }		
		this.modiifiedFieldCount = 0
		this.formModuleForms[step] = this.formBuilder.group({})

		this._Forms.getForm(this.formModule)
			.then(
				form => {
					this.formModuleControls = form

					let frmStep = this._creation.GetFormSubStepDetails(this.formModule, step, index, detailsIndex)

			        this.formModuleControls[step].Controls
			            .forEach( 
			                item => {
			                	this._utilities.AddFormControl(this.formModuleForms[step], item, frmStep)	
			                	this.FormInputChange({value: this.formModuleForms[step].controls[item.Name].value, itemName: item.Name})
			                }
			            )

			        this.formModuleControls[step].InitActions.forEach(
			        	action => {
			        		if(action.Type == "ApiCall_init") {

			        			let frmCtrl: formControl = this.formModuleControls[step].Controls.find( ctlr => { return ctlr.Name == action.Target} )
			        			if(frmCtrl) {
			        				frmCtrl.isLoading = true
			        			}

			        			this._utilities.formActionApiCall(action, this.formModuleForms[step], "", frmCtrl.Pagination)
			        				.subscribe(
			        					data => { 
			        						if(action.ResultAction == "FillSelect"){
			        							frmCtrl = this.formModuleControls[step].Controls.find( ctlr => { return ctlr.Name == action.Target} )
					
							        			if(frmCtrl) {
							        				frmCtrl.Values = data.Items	
													frmCtrl.Pagination = data.Pagination
							        				frmCtrl.isLoading = false

							        				let frmField: FormField = frmStep.Fields.find( item => { return item.name == action.Target} )
							        				if(frmField && frmField.value) {				        					

							        					if(frmCtrl.Actions.length > 0) {
							        						let actions: Array<Action> = frmCtrl.Actions.filter( act => { return act.ResultAction == "FillSelect" && act.TargetStep == this.formModuleControls[this.formStep].Name } )

							        						if(actions.length > 0) {
							        							actions.forEach(
							        								frmAction => {
									        							if(frmAction && frmAction.Type == "ApiCall_whenChange") {
																			this._utilities.formActionApiCall(frmAction,  this.formModuleForms[this.formStep], frmField.value, null)
																				.subscribe(
																					data => { 
																						if(frmAction.ResultAction == "FillSelect"){  

																							let currentCtrl: any = this.formModuleControls[this.formStep].Controls.find( ctlr => { return ctlr.Name == frmAction.Target} )
																							if(currentCtrl) {
																								
																								currentCtrl.Pagination = data.Pagination
																								currentCtrl.Values = data.Items

																								if(data.length == 0){
																									currentCtrl.value = ""
																									if(this.formModuleForms[this.formStep].controls[frmAction.Target]) {
																										this.formModuleForms[this.formStep].controls[frmAction.Target].patchValue("")													
																									}
																								}			
																							}
																						}
																					},
																					Error => { console.log(Error) }
																				)
																		}				        									
							        								}
							        							)
							        						}
							        					}
							        				}
							        			}
			        						}

			        						if(action.ResultAction == "FillTable"){
			        							action.Mapping.forEach(
			        								mapp => {
			        									this._utilities.MapData(action, this.formModuleForms[step], mapp, data[action.ResponseField])

			        									Object.keys(this.formModuleForms[step].controls).forEach(
			        										ctrl => {
			        											this.FormInputChange({value: this.formModuleForms[step].controls[ctrl].value, itemName: ctrl})
			        										}
			        									)
			        								}
			        							)
			        							frmCtrl.isLoading = false
			        						}
			        					},
			        					Error => { console.log(Error) }
			        				)
			        		}

			    			if(action && action.Type == "HideField_onInit") {
								let compareValue: any = this._utilities.getCompareValue(action, this.formModuleControls, this.formModuleForms, this.formModuleForms[step], this.formModuleControls[step])
								let ruleResult: boolean = this._utilities.checkRule(compareValue, action.Params["Operator"], action.Params["CompareValue"])
								let currentCtrl: any = this.formModuleControls[step].Controls.find( ctlr => { return ctlr.Name == action.Target} )

								currentCtrl.ToHide = ruleResult	

								let formCtrl: any = this.formModuleForms[step].controls[action.Target]
								if(formCtrl && ruleResult === true) {
									formCtrl.clearValidators()
									formCtrl.updateValueAndValidity()
								}														
							}

							if(action && action.Type == "ShowField_onInit") {
								let compareValue: any = this._utilities.getCompareValue(action, this.formModuleControls, this.formModuleForms, this.formModuleForms[step], this.formModuleControls[step])
								let ruleResult: boolean = this._utilities.checkRule(compareValue, action.Params["Operator"], action.Params["CompareValue"])
								let currentCtrl: any = this.formModuleControls[step].Controls.find( ctlr => { return ctlr.Name == action.Target} )
								if(ruleResult === true) {
									currentCtrl.ToHide = !ruleResult	

									if(action.Params.Validators && action.Params.Validators.length > 0) {
										let valids: Array<any> = []
								        action.Params.Validators
								            .forEach(
								                v => {
								                    valids.push(this._Forms.getValidator(v.Type, v.Params))
								                }
								            )                       
								        this.formModuleForms[step].controls[action.Target].setValidators(Validators.compose(valids))
								        this.formModuleForms[step].controls[action.Target].updateValueAndValidity()

									}					
								}
							}		

							if(action && action.Type == "AfterInit") {
								if(action.ResultAction == "ReplaceVariable") {
									if(action.TargetType == "comment"){		

										this._Forms.getForm(this.formModule)
											.then(
												form => {
													if(form && form != []) {
														let initialForm = form.find( frm => { return frm.Name == action.TargetStep } )
														let initialCtrl = initialForm.Controls.find( ctrl => { return ctrl.Name == action.Target } )
														let initialValue = initialCtrl.Comment
																					
														action.Params.forEach(
															param => {
																let frmIdx: number = 0
																let newValue: string

																if(param.SourceStep == "Current") {
																	if(param.SourceField == "GetBorrowerType") {
																		newValue = detailsIndex === 0 ? (initialForm.RightBar.GoToItemBtnCaption_First || "") : (initialForm.RightBar.GoToItemBtnCaption + " " + (detailsIndex < 10 ? '0' + detailsIndex : detailsIndex) || "")
																		newValue = newValue.toUpperCase()
																	}
																}
																else {
																	frmIdx = this.formModuleControls.findIndex( i => { return i.Name == param.SourceStep } )

																	if(param.From == "SubStep") {
																		newValue = this._creation.GetFormFieldValue(this.formModule, frmIdx, 0, index, param.SourceField)
																	}
																	else {
																		newValue = this._creation.GetFormFieldValue(this.formModule, frmIdx, 0, detailsIndex, param.SourceField)																		
																	}
																}
															
																initialValue = initialValue.replace(param.Variable, newValue ? newValue : '')																		
															}
														)
														
														let frmCtrls = this.formModuleControls.find( i => { return i.Name == action.TargetStep } )
														let frmCtrl = frmCtrls.Controls.find( i => { return i.Name == action.Target} )
														frmCtrl.Comment = initialValue																							
													}
												}
											)
											.catch(
										    	() => {
										    		this._utilities.showError("Il y a eu un problème lors de l'execution d'une action.")
										    		return false
										    	}
										    )

									}
								}	

								if(action.ResultAction == "SetPropertyFromPreviousStep") {
									if(action.Params && action.Params.length>0){
										let ApplyAction: boolean = true

										action.Params.forEach(
											param => {
												let frmIdx: number = this.formModuleControls.findIndex( frm => { return frm.Name == param.CompareValueForm } )

												if(frmIdx <= step){
													if(param.CompareValueType == "fromPreviousStep") {
														let frmValue: any = this._creation.GetFormFieldValue(this.formModule, frmIdx, 0, detailsIndex,  param.CompareValueField)
		
														if(!this._utilities.checkRule(frmValue, param.Operator, param.CompareValue)) {
															ApplyAction = false												
														}
													}

													if(param.CompareValueType == "fromPreviousLoanStep") {
														let frmValue: any = this._creation.GetFormFieldValue(this.formModule, frmIdx, 0, index,  param.CompareValueField)

														if(!this._utilities.checkRule(frmValue, param.Operator, param.CompareValue)) {
															ApplyAction = false												
														}
													}

													if(param.CompareValueType == "getAge") {
														let frmValue: any = this._creation.GetFormFieldValue(this.formModule, frmIdx, 0, detailsIndex,  param.CompareValueField)

														if(frmValue) {
															let age: number = this._Forms.getAge(frmValue)

															if(!this._utilities.checkRule(age, param.Operator, param.CompareValue)) {
																ApplyAction = false
															}
														}
														else {
															ApplyAction = false
														}
													}

													if(param.CompareValueType == "steplength") {
														let frmIdx : number = this._Forms.GetFormStepIndex(param.CompareValueModule, param.CompareValueForm)

														if(frmIdx>0) {
															let frmLength: any = this._creation.GetFormSubStepDetailsLength(param.CompareValueModule, frmIdx, 0)			
														
															if(action.Value == "[BorrowersList]") {
																let arrValues: Array<any> = []

																for (var i = 0; i < frmLength; i++) {
																	let lastName: string = this._creation.GetFormFieldValue(param.CompareValueModule, frmIdx, 0, i, "LastName")
																	let firstName: string = this._creation.GetFormFieldValue(param.CompareValueModule, frmIdx, 0, i, "FirstName")
																	let borrowerIdx: number = this._creation.GetFormFieldValue(param.CompareValueModule, frmIdx, 0, i, "BorrowerIndex")

																	arrValues.push(
																		{ 
																			Id: i,
																			Value: borrowerIdx,
																			Label:  i == 0 ? "Emprunteur principal"  :("Co-emprunteur " + ((i<10) ? "0"+i : i)),
																			SubLabel: ((firstName !== null && firstName !== undefined) ? firstName : "") + " " + ((lastName !== null && lastName !== undefined) ? lastName : "")
																		}
																	)
																}
																let frmConfigCtrl: any = this.formModuleControls[step].Controls.find( ctrl => { return ctrl.Name == action.Target } )

																if(frmConfigCtrl) {
																	frmConfigCtrl.Values = arrValues
																}

																let frmCtrl: any = this.formModuleForms[step].controls[action.Target]
																let frmCtrlValue: any = this._creation.GetFormFieldValue(this.formModule, step, 0, 0, action.Target)

																if(frmCtrl) {
																	if(frmCtrl.value && frmCtrl.value != "[BorrowersList]") {
																		this.formModuleForms[step].controls[action.Target].patchValue(frmCtrl.value) 
																	}
																	else {
																		this.formModuleForms[step].controls[action.Target].patchValue("principal") 
																	}
																}
															}
														}															
													}
												}
											}
										)

										if(ApplyAction === true) {
											if(action.Property == "Label") {
												let frmCtrl = this.formModuleControls[step].Controls.find( ctrl => { return ctrl.Name == action.Target } )									

												if(frmCtrl) {
													frmCtrl[action.Property] = action.Value
												}
											}

											if(action.Property == "CustomClass") {
												let frmCtrl = this.formModuleControls[step].Controls.find( ctrl => { return ctrl.Name == action.Target } )									

												if(frmCtrl) {
													frmCtrl[action.Property] = action.Value
												}
											}

											if(action.Property == "Value") {
												if(action.ReplaceMethod == "Always") {
													this.formModuleForms[step].controls[action.Target].patchValue(action.Value) 
													this.FormInputChange({value: action.Value, itemName: action.Target})
												}

												if(action.ReplaceMethod == "WhenEmpty") {
													if(
														this.formModuleForms[step].controls[action.Target].value == "" || 
														this.formModuleForms[step].controls[action.Target].value == null || 
														this.formModuleForms[step].controls[action.Target].value == undefined
													){
														this.formModuleForms[step].controls[action.Target].patchValue(action.Value)
														this.FormInputChange({value: action.Value, itemName: action.Target}) 											
													}
												}
											}

											if(action.Property == "Select") {
												let ctrl: formControl = this.formModuleControls[step].Controls.find( c => { return c.Name == action.Target } )

												if(ctrl) {
													ctrl.Values = action.Value										
												}
											}
										}
									}						
								}	

								if(action.ResultAction == "SetValueFromCurrentStepFirstSubDetails") {
									if(this.formDetailsIndex !== 0){
										let sourceValue: any = this._creation.GetFormFieldValue(this.formModule, step, 0, 0,  action.Params.SourceValueField)
										let tergetValue: any = this._creation.GetFormFieldValue(this.formModule, step, 0, this.formDetailsIndex,  action.Params.SourceValueField)

										let frmCtrl = this.formModuleForms[step].controls[action.Target]								

										if(frmCtrl && (tergetValue == "" || tergetValue === null || tergetValue === undefined) ) {
											frmCtrl.patchValue(sourceValue)
											this.FormInputChange({value: sourceValue, itemName: action.Target})
										}																								
									}
								}	

								if(action.ResultAction == "SetValueFromPreviousStepFirstSubDetails") {
									let SourceStepIdx: number = this._Forms.GetFormStepIndex(action.Mapping.SourceModule ,action.Mapping.SourceStep)
									let sourceValue: any = this._creation.GetFormFieldValue(action.Mapping.SourceModule, SourceStepIdx, 0, 0,  action.Mapping.SourceField)

									if(sourceValue !== null && sourceValue !== undefined) {
										if(action.Mapping.Source == "") {
											this.formModuleForms[step].controls[action.Target].patchValue(sourceValue)											
										}
										else {
											this.formModuleForms[step].controls[action.Target].patchValue(sourceValue[action.Mapping.Source])
										}
										this.FormInputChange({value: sourceValue[action.Mapping.Source], itemName: action.Target}) 																																					
									}
								}	

								if(action.ResultAction == "SetOtherFieldValidators") {
									
									if(action.Params && action.Params.length>0){
										let ApplyAction: boolean = true

										action.Params.forEach(
											param => {	
												if(param.Operator != "NO") {
													let frmIdx: number = this._Forms.GetFormStepIndex(param.CompareValueModule, param.CompareValueForm)

													if(frmIdx < step){
														if(param.CompareValueType == "fromPreviousStep") {
															let frmValue: any = this._creation.GetFormFieldValue(this.formModule, frmIdx, 0, 0,  param.CompareValueField)

															if(!this._utilities.checkRule(frmValue, param.Operator, param.CompareValue)) {
																ApplyAction = false												
															}
														}
													}

													if(param.CompareValueType == "fromCurrentStep") {
														frmIdx = this.formModuleControls.findIndex( frm => { return frm.Name == param.CompareValueForm } )
														
														let frmValue: any = this._creation.GetFormFieldValue(this.formModule, step, index, detailsIndex,  param.CompareValueField)
														
														if(typeof frmValue == "object") {
															if(frmValue) {
																frmValue = frmValue.Value
															}
														}

														if(!this._utilities.checkRule(frmValue, param.Operator, param.CompareValue)) {
															ApplyAction = false												
														}
													}

													if(param.CompareValueType == "getAge") {
														let frmValue: any = this._creation.GetFormFieldValue(this.formModule, frmIdx, 0, detailsIndex,  param.CompareValueField)

														if(frmValue) {
															let age: number = this._Forms.getAge(frmValue)

															if(!this._utilities.checkRule(age, param.Operator, param.CompareValue)) {
																ApplyAction = false
															}
														}
														else {
															ApplyAction = false
														}
													}													
												}
											}
										)

										if(ApplyAction === true) {
											if(action.SetValidators && action.SetValidators.length > 0) {
												this.formModuleForms[step].controls[action.Target].clearValidators()
												let valids: Array<any> = []
							                    action.SetValidators
							                        .forEach(
							                            v => {				                            	
							                            	if(v.Type){
							                            		let compareFrmIdx: number

							                            		if(v.Type != "clearValidators") {
								                            		switch (v.Type) {			                            			
								                            			case "DateBourquin":
								                            			case "DateHamon":
								                            			case "LowerThan85YearsOld":
								                            			case "GreaterThan18Y":
								                            			case "LowerThan85Y":
								                            			case "GreaterThan18YearsOld":
								                            			case "DateGreaterThanDateDeblocage":
								                            			case "LowerThanHamon":
								                            			case "DateLowerThanNow":
								                            			case "IsDateValid":
																			compareFrmIdx = this.formModuleControls.findIndex( f => f.Name ==  v.Params.CompareValueForm)
																			if(compareFrmIdx) {

																				let compareValue: any = this._creation.GetFormFieldValue(this.formModule, compareFrmIdx, 0, detailsIndex, v.Params.CompareValueField)

																				if(v.Type == "GreaterThan18Y" || v.Type == "LowerThan85Y") {
																					compareValue = new Date(Date.now())
																				} 
																				else if(v.Type == "IsDateValid"){
																					valids.push(this._Forms.getValidator(v.Type, v.Params))
																				}

																				if(compareValue && v.Type != "IsDateValid") {
																					v.Params.compareValue = this._Forms.CalculateCompareDate(compareValue, v.Params.Unit, v.Params.Val)
																					v.Params.Control = this.formModuleForms[step].controls[action.Target]
																					v.Params.ValidatorType = v.Type

																					let validation = this._Forms.getValidator(v.Type, v.Params)
																					valids.push(validation)
																				}
																			}

																			valids.push(this._Forms.getValidator(v.Type, v.Params))
								                            				break

																		case "minParams":
																			valids.push(this._Forms.getValidator(v.Type, v.Params))
																			break

							                            				case "maxParams":
										                            		valids.push(this._Forms.getValidator(v.Type, v.Params))
								                            				break

								                            			case "GreaterThan":			
																			valids.push(this._Forms.getValidator(v.Type, { compareValue: this._Forms.getDate(v.Params), Control: this.formModuleForms[step].controls[action.Target], ValidatorType: v.Type }))
								                            				break

								                            			case "EqualsTo":
								                            				valids.push(this._Forms.getValidator(v.Type, v.Params))
								                            				break

								                            			case "minValueWithParams":
																			let frmValue: any = this._creation.GetFormFieldValue(this.formModule, step, index, detailsIndex,  v.Params.SourceField)

																			if(frmValue) {
																				let newVal: number = parseFloat(frmValue) + parseFloat(v.Params.VariationValue)
								                            					valids.push(this._Forms.getValidator(v.Type, newVal))
																			}

								                            				break

								                            			case "maxValueWithParams":
								                            				let frmValue2: any = this._creation.GetFormFieldValue(this.formModule, step, index, detailsIndex,  v.Params.SourceField)

																			if(frmValue2) {
																				let newVal: number = parseFloat(frmValue2) + parseFloat(v.Params.VariationValue)
								                            					valids.push(this._Forms.getValidator(v.Type, newVal))
																			}

								                            				break

								                            			case "increments":
								                            				let frmIdx: number = this._Forms.GetFormStepIndex(this.formModule, "loan")
								                            				let incs: any = this._creation.GetFormFieldValue(this.formModule, frmIdx, index, detailsIndex, "increments")
								                            				v.Params = {} 
								                            				v.Params.increments = Array.isArray(incs) ? incs : []
								                            				valids.push(this._Forms.getValidator(v.Type, v.Params))
								                            				break

								                            			case "CheckAllShare100":
								                            				let frm_Idx: number = this._Forms.GetFormStepIndex(this.formModule, "Garantees")
								                            				let formSubIndex: number = this._creation.GetFormSubIndex()
																			let frmLength: number = this._creation.GetFormSubStepDetailsLength(action.TargetModule, frm_Idx, formSubIndex)

								                            				v.Params = [] 

								                            				for (var i = 0; i < frmLength; i++) {
								                            					v.Params.push(this._creation.GetFormFieldValue(action.TargetModule, frm_Idx, formSubIndex, i, action.Target))
								                            				}

								                            				valids.push(this._Forms.getValidator(v.Type, v.Params))
								                            				break

								                            			case "max90Y":
								                            				let maxDuration = this._creation.GetLoansMaxDuration()
								                            				let targetStepIdx: number = this._Forms.GetFormStepIndex(action.TargetModule, action.TargetStep)
																			let targetSubIdx: number = this._creation.GetFormSubIndex()

																			let dateNow = new Date(Date.now())
																			dateNow.setHours(0,0,0,0)
																			
																			let endOfLoan: any = this._Forms.CalculateCompareDate(dateNow.toString(), "month", maxDuration)

																			v.Params = {
																				endOfLoan: endOfLoan
																			}

						                            						valids.push(this._Forms.getValidator(v.Type, v.Params))								                            				
								                            				break

							                            				case "HamonEffectiveDateValidity":
								                            				let today = new Date(Date.now())
																			today.setHours(0,0,0,0)

																			v.Params.compareValue = this._Forms.CalculateCompareDate(today, v.Params.Unit, v.Params.Val)
																			v.Params.Control = this.formModuleForms[step].controls[action.Target]
																			v.Params.ValidatorType = v.Type

																			let validation = this._Forms.getValidator(v.Type, v.Params)
																			valids.push(validation)
								                            				break

								                            			case "DurationMinusParam":
																			let formIndex: number = this._Forms.GetFormStepIndex(action.TargetModule, action.TargetStep)

								                            				let duration = this._creation.GetFormFieldValue(action.TargetModule, formIndex, this._creation.GetFormSubIndex(), this._creation.GetFormSubDetailsIndex(), "Duration")
							                            					if(duration) {
									                            				v.Params.duration = Number(duration)

																				valids.push(this._Forms.getValidator(v.Type, v.Params))						                            						
							                            					}
																			break

								                            			default:
								                            				valids.push(this._Forms.getValidator(v.Type))						                            				
								                            				break
								                            		}	
							                            		}
							                            	}
							                            }
							                        )    

							                    this.formModuleForms[step].controls[action.Target].setValidators(Validators.compose(valids))
							                    this.formModuleForms[step].controls[action.Target].patchValue(this.formModuleForms[step].controls[action.Target].value)
											}															
										}
									}		
								}

								if(action.ResultAction == "SetFieldWithIndex") {
									let frmCtrl = this.formModuleForms[step].controls[action.Target]

										if(frmCtrl) {
											frmCtrl.patchValue(index)
										}	
								}

								if(action.ResultAction == "CheckForNextStep") {
									let ApplyAction: boolean = true

										action.Params.forEach(
											param => {	
												let frmIdx: number = this._Forms.GetFormStepIndex(param.CompareValueModule, param.CompareValueForm)

												if(param.CompareValueType == "fromPreviousStep") {
													let frmValue: any = this._creation.GetFormFieldValue(param.CompareValueModule, frmIdx, 0, 0,  param.CompareValueField)
																										
													if(!this._utilities.checkRule(frmValue, param.Operator, param.CompareValue)) {
														ApplyAction = false												
													}
												}	

												if(param.CompareValueType == "steplength") {
													let frmIdx: number = this._Forms.GetFormStepIndex(param["CompareValueModule"], param["CompareValueForm"])
													let frmLength: any = this._creation.GetFormSubStepDetailsLength(param["CompareValueModule"], frmIdx, 0)
													
													if(!this._utilities.checkRule(frmLength, param["Operator"], param["CompareValue"])){
														ApplyAction = false
													}
												}																																				
											}
										)

										if(ApplyAction === true) {
											this.onSubmit(true, false)
										}
								}

								if(action.ResultAction == "SetProperty") {
									if(action.Params && action.Params.length>0){
										let ApplyAction: boolean = true

										action.Params.forEach(
											param => {
												let frmIdx: number = this.formModuleControls.findIndex( frm => { return frm.Name == param.CompareValueForm } )

												if(frmIdx <= step){
													if(param.CompareValueType == "Fixe") {
														let frmValue: any = this._creation.GetFormFieldValue(param.CompareValueModule, frmIdx, 0, detailsIndex,  param.CompareValueField)

														if(!this._utilities.checkRule(frmValue, param.Operator, param.CompareValue)) {
															ApplyAction = false												
														}
													}
												}

												if(param.CompareValueType == "fromCurrentStep") {
													frmIdx = this.formModuleControls.findIndex( frm => { return frm.Name == param.CompareValueForm } )
													let frmValue: any = this._creation.GetFormFieldValue(this.formModule, step, index, detailsIndex,  param.CompareValueField)
													
													if(!this._utilities.checkRule(frmValue, param.Operator, param.CompareValue)) {
														ApplyAction = false												
													}
												}
											}
										)

										if(ApplyAction === true) {
											let frmCtrl = this.formModuleControls[step].Controls.find( ctrl => { return ctrl.Name == action.Target } )									
											if(frmCtrl) {
												frmCtrl[action.Property] = action.Value

												if(action.Property == "Value") {
													let formCtrl = this.formModuleForms[step].controls[action.Target]

													if(formCtrl) {
														formCtrl.patchValue(action.Value)
														this.FormInputChange({value: action.Value, itemName: action.Target})
													}
												}

												if(action.Property == "Disabled") {
													let formCtrl = this.formModuleForms[step].controls[action.Target]

													if(formCtrl && action.Value === true) {
														formCtrl.disable()
													}
												}

												if(action.Property == "FirstSelectValue") {
													let formCtrl = this.formModuleForms[step].controls[action.Target]

													if(formCtrl) {
														let frmCtrl = this.formModuleControls[step].Controls.find( ctrl => { return ctrl.Name == action.Target } )

														if(frmCtrl && frmCtrl.Values && frmCtrl.Values.length>0) {
															if(frmCtrl.Values[0]) {
																formCtrl.patchValue(frmCtrl.Values[0].Value)
																this.FormInputChange({value: frmCtrl.Values[0].Value, itemName: action.Target})																															
															}
														}
													}
												}

												if(action.Property == "ReadOnly") {
													let formCtrl = this.formModuleForms[step].controls[action.Target]

													if(formCtrl && action.Value === true) {
														formCtrl.disable()
													}

													if(formCtrl && action.Value === false) {
														formCtrl.enable()
													}
												}
											}											
										}
									}	
								}
						
								if(action.ResultAction == "setIndex") {
									
									let currentFormValues: any = this._creation.GetFormSubStepDetails(this.formModule, step, index, detailsIndex)
									let indexCtrl: any = currentFormValues.Fields.find( c => { return c.name == action.Target } )

									if(indexCtrl && (indexCtrl.value == "" || indexCtrl.value == null || indexCtrl.value == undefined) ) {
										let maxId: any = this._creation.GetStepMaxId(this.formModule, step, index, action.Target)

										if(maxId >= 0) {
											maxId = parseInt(maxId) + 1

											this._creation.SetFormFieldValue(this.formModule, step, index, detailsIndex, action.Target, maxId)

											this.formModuleForms[step].controls[action.Target].patchValue(maxId)

											if(action.Params && action.Params.length > 0){
												action.Params.forEach(
													param => {
														let stepIdx: number = this._Forms.GetFormStepIndex(param.Target, param.TargetStep)
													
														if(stepIdx >= 0) {
															let stepCount: number = this._creation.GetFormSubStepLength(param.Target, stepIdx)

															for (var i = 0; i < stepCount; i++) {																
																let detailsLength: number = this._creation.GetFormSubStepDetailsLength(param.Target, stepIdx, i)

																for (var j = 0; j < detailsLength; j++) {
																	
																	let nextFormValues: any = this._creation.GetFormSubStepDetails(param.Target, stepIdx, i, j)

																	if(nextFormValues) {
																		let nextCtrl: any = nextFormValues.Fields.find( c => { return c.name == action.Target } )																

																		if(nextCtrl && (nextCtrl.value == "" || nextCtrl.value == null || nextCtrl.value == undefined) ) {
																			this._creation.SetFormFieldValue(param.Target, stepIdx, i, j, action.Target, maxId)
																		}	
																	}
																	
																	if(param.ExtraFields && param.ExtraFields.length > 0) {			
																		param.ExtraFields.forEach(
																			f => {
																				let StepIndex: number = this._Forms.GetFormStepIndex(f.SourceModule, f.SourceStep)
																				let fValue: any = this._creation.GetFormFieldValue(f.SourceModule, StepIndex, 0, i, f.SourceField)
																				
																				this._creation.SetFormFieldValue(param.Target, stepIdx, i, j, f.SourceField, fValue)
																			}
																		)
																	}
																}

															}
														}													
													}
												)
											}
										}
									}
								}	

								if(action.ResultAction == "getMedicalFormalities") {
									let loanFrmIndex = this._Forms.GetFormStepIndex("quotation", "Loan")
									let amount: number = this._creation.GetLoansAmount()
									let BorrowersFrmIndex = this._Forms.GetFormStepIndex("quotation", "Covered")

									let frmLength: number = this._creation.GetFormSubStepDetailsLength(this.formModule, this.formStep, this.formIndex)

									for (var i = 0; i < frmLength; i++) {
										let frmBirthday: string = this._creation.GetFormFieldValue("quotation", BorrowersFrmIndex, 0, i, "BirthDate")
										let age: number = 0

										let medicalsArr: Array<string> = []

										if(frmBirthday && frmBirthday != "") {
											age = this._Forms.getAge(frmBirthday)
											medicalsArr = this._Forms.getMedicalFormatities(age, amount)
											
											let configCtrl = this.formModuleControls[step].Controls.find( c => { return c.Name == action.Target } )
											if(configCtrl) {
												
												if(i === this.formDetailsIndex){
													configCtrl.Values = medicalsArr													
												}

												this._creation.SetFormFieldValue(this.formModule, this.formStep, this.formIndex, i, action.Target, medicalsArr)
											}	

											let formCtrl = this.formModuleForms[step].controls[action.Target]
											if(formCtrl) {
												formCtrl.patchValue(medicalsArr)
											}
										}		
									}
								}

								if(action.ResultAction == "ExecuteCSP") {
									let csp: string = this._Forms.getCSP(this.formModuleForms[step])

									this.formModuleForms[step].controls[action.Target].patchValue(csp)
									this.FormInputChange({value: csp, itemName: action.Target})
								}

								if(action.ResultAction == "Disable") {
									if(action.Params && action.Params.length>0){
										let ApplyAction: boolean = true

										action.Params.forEach(
											param => {
												let frmIdx: number = this.formModuleControls.findIndex( frm => { return frm.Name == param.CompareValueForm } )

												if(frmIdx <= step){
													if(param.CompareValueType == "Fixe") {
														let frmValue: any = this._creation.GetFormFieldValue(param.CompareValueModule, frmIdx, 0, detailsIndex,  param.CompareValueField)

														if(!this._utilities.checkRule(frmValue, param.Operator, param.CompareValue)) {
															ApplyAction = false												
														}
													}
												}
											}
										)

										if(ApplyAction === true) {
											
											let formCtrl = this.formModuleForms[step].controls[action.Target]

											if(formCtrl) {
												formCtrl.disable()
											}
								
										}
									}	
								}
							}				
			        	}
			        )

			        this.formStep = step

			        this.count =  this._creation.GetFormSubStepLength(this.formModule, this.formStep)

			        this.formModuleForms[this.formStep]
			        	.valueChanges
			        	.subscribe(
			        		control => {
			        			let initCount: number = 0
			        			Object.keys(control).forEach(
			        				ctrl => {
			        					if(control[ctrl] !== null && control[ctrl] !== undefined && control[ctrl] != "") {
			        						initCount++
			        					}
			        				}
			        			)

			        			this._creation.SetCurrentFormModifiedFieldCount(initCount)
			        		}
			        	)										
				}
			)
			.catch(
		    	(err) => {
		    		console.log(err);
		    		this._utilities.showError("Il y a eu un problème lors de l'initialisation.")
		    		return false
		    	}
		    )		
	}


	// ***************************************
	// * Inside Form vertical bar naviqation *
	// ***************************************
	SwitchNavigation(idx: number) {
		this.formIndex = idx
		this._creation.SetFormSubIndex(this.formIndex)	

		this.initForm(this.formStep, idx, this.formDetailsIndex)
	}


	// ***************************************
	// *********** RightBar Event ************
	// ***************************************
	RightBarSubmit(event: boolean) {
		this.isSubmit = event
	}


	// ***************************************
	// ********** Form Input Event ***********
	// ***************************************
	FormInputChange(v: {value: any, itemName: string}) {
		this._creation.StoreChanges(this.formModule, this.formStep, this.formIndex, this.formDetailsIndex, v.itemName, v.value)	    	
	}


	// ***************************************
	// ******** Form buttons Actions *********
	// ***************************************
	onSubmit(goNext: boolean, saveAndQuit?: boolean) {
		this.isSubmit = true
		this.InvalidForms = []
		let StepLength: number = this._creation.GetFormSubStepLength(this.formModule, this.formStep)
		let DetailsLength: number = this._creation.GetFormSubStepDetailsLength(this.formModule, this.formStep, this.formIndex)

		this.FormValidation(StepLength, DetailsLength)
			.then(
				validation => {
					this.invalidFormsEvent.next(this.InvalidForms)

					if(this.formModuleForms[this.formStep].valid && validation === true) {
						if(this.isSubmitting === false) {
							this.isSubmitting = true
							this.loading = true

							this.CheckquotationId()
								.then(
									data => {
										if(data === true) {

											let formFields: Array<Field> = new Array<Field>()

											Object.keys(this.formModuleForms[this.formStep].controls).forEach(key => {
									            let frmCtrl = this.formModuleControls[this.formStep].Controls.find( item => { return item.Name === key } )

												let formField: Field = new Field()
													formField.name = key
													if(frmCtrl) {
														switch (frmCtrl.Type) {
															case "btn_check":
																formField.value = frmCtrl.Values
																break

															case "file_upload":
																formField.value = this._creation.GetFormFieldValue(this.formModule, this.formStep, this.formIndex, this.formDetailsIndex, frmCtrl.Name)
																break
															
															default:
																formField.value = this.formModuleForms[this.formStep].get(key).value
																break
														}
													}

									            if(frmCtrl && frmCtrl.HasRightIndicator && frmCtrl.RightIndicatorType == "button"){
									            	let opts = frmCtrl.RightIndicator.find( indic => { return indic.Selected === true } )

									            	if(opts !== null && opts !== undefined){
										            	let formFieldOption: FieldOption = new FieldOption()
										            		formFieldOption.name = opts.Name
										            		formFieldOption.value = true	  

										            		formField.options.push(formFieldOption)          		
									            	}
									            }

									            formFields.push(formField)
									        })

									        this._creation.AddFormStepValues(this.formModule, this.formStep, this.formIndex, this.formDetailsIndex, formFields)        

									        this.OnSubmitAction()
									        	.then(
									        		result => {
									        			if(result && result.State === true){
									        				if(saveAndQuit === true) {
							        							this.Cancelling()
							        						}	
						        							else {
									        					this.OnSuccessMessage()
											        				.then(
											        					resp => {
											        						if(resp === true){											        							
											        							this.isSubmit = false
														        				this._creation.SetFormSubIndex(0)
																				this._creation.SetFormSubDetailsIndex(0)
																				//this._creation.SetCurrentFormModifiedFieldCount(0)
																				this.InvalidForms = []
																				this.invalidFormsEvent.next(this.InvalidForms)

																				this._creation.GoNext(this.formModule, this.formStep, goNext)

																				if(result.ResultAction == "Next" || result.ResultAction == "") {
																					let step: number = this.formStep
																						step++

																					if(step < 0) { step = 0 }
																					if(step > this.formModuleControls.length - 1) { 
																						step = this.formModuleControls.length - 1

																						this.event.unsubscribe()

																						switch (this.formModule) {
																							case "quotation":
																								this._creation.EnabledMainStep("subscription", goNext)
																								break
																							
																							case "subscription":
																								this._creation.EnabledMainStep("upload", goNext)
																								break

																							case "subscription":
																								this._creation.ClearFormObj()

																								this.router.navigate(["creation/quotation/0" ])
																									.then( data => {
																										$(".creation").scrollTop(0)
																									})
																								break
																						}																				
																					}
																					else{
																						this._creation.EnableNextStep(this.formModule, step)																																							

																						if(goNext === true) {
																							this.router.navigate(["creation/" + this.formModule + "/" + step ], { replaceUrl:true })
																							.then( data => {
																								$(".creation").scrollTop(0)
																							})	
																						}
																						else {
																							this._creation.GoNext(this.formModule, step, goNext)
																							this.router.navigate(["creation/" + this.formModule + "/" + step ])
																								.then( 
																									data => {
																										$(".creation").scrollTop(0)
																									}
																								)														
																						}
																					}							        																													
																				}
											        							else {
											        								if(result.ResultAction == "redirectToContract") {
											        									this.redirectToContract(result.QuotationStatus,result.ContractId)
											        								}
											        							}			        							

																				this.isSubmitting = false
																				this._creation.Log()
											        						}
											        					}
											        				)
											        				.catch(
											        					Error => {
											        						this.isSubmitting = false
														        			this.loading = false
														        			this._utilities.showError("Il y eu une erreur. Contactez votre responsable si le problème persiste.")
											        					}
											        				)									        				
									        				}
									        			}
									        			this.loading = false
									        			this.isSubmitting = false
									        		}
									        	)
									        	.catch(
									        		Error => {
									        			console.log(Error)
									        			this.isSubmitting = false
									        			this.loading = false
									        			this._utilities.showError("Il y eu une erreur. Contactez votre responsable si le problème persiste.")
									        		}
									        	)
											}
										else {
											this.isSubmitting = false
											this.loading = false
											console.log("this.CheckquotationId if data KO")
										}
									}
								)
								.catch(
									Error => {
										this.isSubmitting = false
										this.loading = false
										console.log("this.CheckquotationId then KO", Error)						
									}
								)					
						}

					}
					else {
						if(StepLength > 1 || DetailsLength > 1) {
					        this._utilities.showError("Un formulaire n'est pas correctement rempli.")
					    }
					    else {
					    	Object.keys(this.formModuleForms[this.formStep].controls)
								.forEach( 
								    item => { 
								    	if(this.formModuleForms[this.formStep].controls[item].valid === false) {

									    	if(item == "Csp" && this.formModuleForms[this.formStep].controls[item].value == "CSP4") {
									    		this._utilities.showError("<B>Attention</B><BR /><BR />Les informations saisies ne permettent pas la souscription en ligne,<BR />merci de vous rapprocher du responsable Souscription.")
									    	}
								    	}
								    })
					    }

				        this.invalidFormsEvent.next(this.InvalidForms)
					}
				}
			)	
			.catch(
				err => console.log(err)
			)		
	}	
	OnSubmitAction(): Promise<{State: boolean, ResultAction: string, ContractId: string, QuotationStatus: number}> {
		return new Promise<{State: boolean, ResultAction: string, ContractId: string, QuotationStatus: number}>(
			(resolve, reject) => { 
				if(this.formModuleControls[this.formStep].OnSubmit) {
					if(this.formModuleControls[this.formStep].OnSubmit.length > 0) {
						this.formModuleControls[this.formStep].OnSubmit.forEach(
							action => {

								if(action.Type == "ApiCall") {
									
									if(action.HasWaitingPopup === true) {
										this._utilities.showWaitingMessage(action.WaitingPopup.Text)
									}

									this._utilities.ApiCallV2(action, this.formModule, this.formStep)
										.subscribe(
											resp => {
												if(action.HasWaitingPopup === true) {
													this._utilities.closeWaitingMessage()
												}

												if(resp && resp.QuotationResult && resp.QuotationResult.Errors === null) {
													if(action.ResultAction == "redirectToContract") {

														let contractId: string = (resp && resp.QuotationResult && resp.QuotationResult.Quotation && resp.QuotationResult.Quotation.ContratCoogId) ? resp.QuotationResult.Quotation.ContratCoogId : ""
														let contractStatus: number = (resp && resp.QuotationResult && resp.QuotationResult.Quotation && resp.QuotationResult.Quotation.Status) ? resp.QuotationResult.Quotation.Status : 0
														
														resolve({State: true, ResultAction: action.ResultAction, ContractId: contractId, QuotationStatus: contractStatus})
													}
													else {
														resolve({State: true, ResultAction: action.ResultAction, ContractId: "0", QuotationStatus: 0})														
													}
												}			
												else {
													reject({State: false, ResultAction: "", ContractId: "0", QuotationStatus: 0})
												}	
											},
											Error => {
												console.log(Error)
												if(action.HasWaitingPopup === true) {
													this._utilities.closeWaitingMessage()
												}
												
												reject({State: false, ResultAction: Error, ContractId: "0", QuotationStatus: 0})														
											}
										)
								}
								else if(action.Type == "SetEarlierEffectDate") {
									let StepLength: number = this._creation.GetFormSubStepLength(this.formModule, this.formStep)
									let DetailsLength: number = this._creation.GetFormSubStepDetailsLength(this.formModule, this.formStep, this.formIndex)

									let FieldValue: any = null

									let sourceField: string
									if(this._creation.GetFormFieldValue("quotation", 0, 0, 0, "NatureProjet") == "adhesion") {
										sourceField = "ReleaseDate"
									}
									else {
										sourceField = "EffectiveDate"										
									}

									for (var i = 0; i < StepLength; i++) {
										for (var j = 0; j < DetailsLength; j++) {
											let tempFieldValue: any = this._creation.GetFormFieldValue(this.formModule, this.formStep, i, j, sourceField)

											if((FieldValue > tempFieldValue) || (FieldValue === null)) {
												FieldValue = tempFieldValue
											}
										}
									}									

									let stepIdx: number = this._Forms.GetFormStepIndex(action.Target, action.TargetStep)
									this._creation.SetFormFieldValue(action.Target, stepIdx, 0, 0, action.TargetField, FieldValue)
								}
								else {
									reject({State: false, ResultAction: "", ContractId: "0", QuotationStatus: 0})					
								}						
							}
						)						
					}
					else {
						resolve({State: true, ResultAction: "", ContractId: "0", QuotationStatus: 0})
					}
				}
				else {
					reject({State: false, ResultAction: "", ContractId: "0", QuotationStatus: 0})					
				}
			}
		)	
	}
	OnSuccessMessage(): Promise<boolean> {
		return new Promise<boolean>(
			(resolve, reject) => { 
				if(this.formModuleControls[this.formStep].HasSuccessMessage === true) {
					this._utilities.showSuccessMessage(this.formModuleControls[this.formStep].OnSuccessMessage.Messages)
						.then( () => resolve(true))
						.catch( () => reject(false))									
				}
				else{
					resolve(true)					
				}
			}
		)
	}

	Cancelling() {
		this.isSubmit = false
		this._creation.ClearFormObj()
		this.InvalidForms = []
		this.invalidFormsEvent.next(this.InvalidForms)
		this._creation.SetCurrentFormModifiedFieldCount(0)

		this.router.navigate(["creation/quotation/0"])
			.then(
				nav => {
					if(nav !== true) {
						this.initForm(0, 0, 0)							
					}
				}
			)
	}
	onCancelAction(): Promise<boolean> {
		return new Promise<boolean>(
			(resolve, reject) => { 
				if(this.formModuleControls[this.formStep].OnCancel) {
					if(this.formModuleControls[this.formStep].OnCancel.length > 0) {
						this.formModuleControls[this.formStep].OnCancel.forEach(
							action => {

								if(action.Type == "ApiCall") {
										
									this._utilities.ApiCallV2(action, this.formModule, this.formStep)
										.subscribe(
											resp => {
												resolve(true)												
											},
											Error => {
												console.log(Error)
												reject(Error)														
											}
										)
								}
								else {
									reject(false)					
								}						
							}
						)						
					}
					else {
						resolve(true)
					}
				}
				else {
					reject(false)					
				}
			}
		)	
	}
	onCancel() {
		if(this._creation.GetCurrentFormModifiedFieldCount() < 3) {
			this.loading = true
			this.onCancelAction()
				.then(
					resp => {
						this.isSubmitting = false
						this.loading = false
						this.Cancelling()									
					}
				)
				.catch(
					err => {
						this.isSubmitting = false
						this.loading = false
						console.log(err)
					}
				)
		}
		else {
			this._utilities.showConfirm("Etes-vous sûr de vouloir continuer ? Les informations saisies seront perdues. ?")
				.then(
					() => {
						this.loading = true

						this.onCancelAction()
							.then(
								resp => {
									this.isSubmitting = false
									this.loading = false
									this.Cancelling()									
								}
							)
							.catch(
								err => {
									this.isSubmitting = false
									this.loading = false
									console.log(err)
								}
							)
					}
				)
				.catch( () => {} )
		}
	}

	onBack() {
		this.onBackAction()
			.then(
				resp => {
					if(resp === true) {
						//this._creation.SetCurrentFormModifiedFieldCount(0)	
						this._creation.SetFormSubIndex(0)
						this._creation.SetFormSubDetailsIndex(0)
						this.InvalidForms = []
						this.invalidFormsEvent.next(this.InvalidForms)

						this._creation.GoBack(this.formModule, this.formStep)	
					}
					else {
						console.log("onBackError");
					}
				}
			)
			.catch(
				err => { console.log(err) }
			)
	}
	onBackAction(): Promise<boolean> {
		return new Promise<boolean>(
			(resolve, reject) => { 
				if(this.formModuleControls[this.formStep].OnBack) {
					if(this.formModuleControls[this.formStep].OnBack.length > 0) {
						this.formModuleControls[this.formStep].OnBack.forEach(
							action => {
								if(action.Type == "ShowPopUpBeforeExecute") {
									if(action.Params) {
										if(action.Params.CheckModifiedInput === true) {
											if(this._creation.GetCurrentFormModifiedFieldCount() > 0){
												if(action.Params.PopUpType == "Confirm") {
													this._utilities.showConfirm(action.Params.PopUpMessage)
														.then(
															resp => {
																if(resp === true) {
																	if(action.Params.onConfirm != "") {
																		this._creation.EraseStep(this.formModule, this.formStep, this.formIndex, this.formDetailsIndex)															
																	}

																	resolve(true)
																}
																else {
																	reject(false)
																}
															}
														)
														.catch(
															err => {
																console.log(err)
																reject(false)
															}
														)
												}
											}
											else {
												resolve(true)
											}
											
										}
										else {
											if(action.Params.PopUpType == "Confirm") {
												this._utilities.showConfirm(action.Params.PopUpMessage)
													.then(
														resp => {
															if(resp === true) {
																if(action.Params.onConfirm != "") {
																	this._creation.EraseStep(this.formModule, this.formStep, this.formIndex, this.formDetailsIndex)															
																}

																resolve(true)
															}
															else {
																console.log("confirm_reject")
																reject(false)
															}
														}
													)
													.catch(
														err => {
															console.log(err)
															reject(false)
														}
													)
											}

											reject(false)
										}										
									}											
								}
								else {
									reject(false)					
								}						
							}
						)						
					}
					else {
						resolve(true)
					}
				}
				else {
					resolve(true)					
				}
			}
		)	
	}

	onSaveAndQuit() {
		this._utilities.showConfirm("Etes-vous sûr de vouloir sauvegarder et quitter ?<BR /> Le formulaire en cours doit être rempli complètement.")
			.then(
				() => {
					this.onSubmit(false, true)
				}
			)
			.catch( () => {} )			
	}
	
	onGetQuote() {
		this.loading = true

		let Taea: number = this._creation.GetFormFieldValue("quotation", this.formStep, this.formIndex, this.formDetailsIndex, "taea")		

		if(Taea > 0) {
			this.onGetQuoteAction()
				.then(
					resp => {
						this.loading = false
					}
				)
				.catch(
					err => {
						this._utilities.showError("Il y a eu une erreur.<BR />Si le problème persiste, merci de contacter le support.")
						this.loading = false
					}
				)
		}
		else {
			this._utilities.showError("La souscription est invalide, nous ne pouvons établir de devis.")
			this.loading = false
		}		
	}	
	onGetQuoteAction(): Promise<boolean> {
		return new Promise<boolean>(
			(resolve, reject) => { 
				
				if(this.formModuleControls[this.formStep].OnGetQuote) {
					if(this.formModuleControls[this.formStep].OnGetQuote.length > 0) {
						this.formModuleControls[this.formStep].OnGetQuote.forEach(
							action => {

								if(action.Type == "ApiCall") {
										
									this._utilities.ApiCallV2(action, this.formModule, this.formStep)
										.subscribe(
											resp => {
												if(resp && resp.ok === true) {

													if(action.ResultAction == "OpenFileInNewTab") {
														let url: string = resp.url

														window.open(url, "_blank")


														resolve(true)
													}


													reject(false)
												}
												else{
													reject(false)													
												}
											},
											Error => {
												console.log(Error)
												reject(Error)														
											}
										)
								}
								else {
									reject(false)					
								}						
							}
						)						
					}
					else {
						resolve(true)
					}
				}
				else {
					reject(false)					
				}
			}
		)	
	}

	onSendAQuote() {
		this.loading = true

		this.onSendAQuoteAction()
			.then(
				resp => {
					this.loading = false
				}
			)
			.catch(
				err => {
					this._utilities.showError("Il y a eu une erreur.<BR />Si le problème persiste, merci de contacter le support.")
					this.loading = false
				}
			)
	
	}	
	onSendAQuoteAction(): Promise<boolean> {
		return new Promise<boolean>(
			(resolve, reject) => { 
				
				if(this.formModuleControls[this.formStep].onSendAQuote) {
					if(this.formModuleControls[this.formStep].onSendAQuote.length > 0) {
						this.formModuleControls[this.formStep].onSendAQuote.forEach(
							action => {

								if(action.Type == "ApiCall") {
										
									this._utilities.ApiCallV2(action, this.formModule, this.formStep)
										.subscribe(
											resp => {
												if(resp && resp.ok === true) {
													this._utilities.showSuccessMessage(
														[
															{
																Text: "Le devis a été envoyé par mail à l'emprunteur principal et au(x) co-emprunteur(s).",
																Visible: true,
																Actions : []
															}
														]																											
														)
														.then( () => resolve(true))
														.catch( () => reject(false))	
													resolve(true)
												}
												else{
													reject(false)													
												}
											},
											Error => {
												console.log(Error)
												reject(Error)														
											}
										)
								}
								else {
									reject(false)					
								}						
							}
						)						
					}
					else {
						resolve(true)
					}
				}
				else {
					reject(false)					
				}
			}
		)	
	}

	redirectToContract(status: number, contractId: string) {
		this.router.navigate(["contracts/details/"+status+"/"+contractId])
			.then(
				nav => {
					if(nav !== true) {
						this.initForm(0, 0, 0)							
					}
				}
			)
	}
}
