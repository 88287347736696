import { Component, OnInit, ElementRef } from '@angular/core'
import { Router } from '@angular/router'

import { CONFIG } from '../../../app.constants'

import { QssService } from '../../../services/qss.service'

declare var universignSigInit: any


@Component({
	selector: 'app-qss-validation',
	templateUrl: './qss-validation.component.html',
	styleUrls: ['./qss-validation.component.scss']
})
export class QssValidationComponent implements OnInit {

	public isValidate: boolean = false
	public loading: boolean = false
	public validationCheck: boolean
	public identity: string = ""
	public userId: number
	public userToken: string = ""

	constructor(
		public router: Router,
		private _QSS: QssService,
		private elementRef: ElementRef
	) { }

	ngOnInit() {
		this.userId = parseInt(localStorage.getItem("u_qss_uid"))
		this.userToken = localStorage.getItem("qss_uid")

		if(this.userId === NaN || this.userId === null || this.userId === undefined || this.userId === 0 || this.userToken === null || this.userToken === undefined || this.userToken == "") {
			this.router.navigate(["/questionnaire/warning/forbidden"])
		}

		this._QSS.CheckSignValidity(this.userId.toString(), this.userToken)
		 	.subscribe(
		 		resp => {
		 			this.loading = false

		 			if(resp && resp.State === true) {
						this.identity = this._QSS.GetFullIdentity()

						let UniverSignId = resp.UniversignId
						this.generateIframe(UniverSignId)				    		 				
		 			}
		 			else if(resp && resp.State === false) {
		 				if(resp.Error && resp.Error != "")
		 				this.router.navigate(["/questionnaire/warning/" + resp.Error])
		 			}
		 			else {
		 				this.router.navigate(["/404"])
		 			}
		 		},
		 		Error => {
		 			this.loading = false
		 			this.router.navigate(["/404"])
		 		}
		 	)			
	}

	generateIframe(id: string) {
		const configuration: any = {};
		configuration.redirectionMode = 'IN'

		universignSigInit('iframeContainerId', id, configuration,`${CONFIG.UNIVERSIGN[CONFIG.ENV]}`)

		window.addEventListener('pdsEvent', (e: CustomEvent) => {

            if(e && e.detail) {
            	if(e.detail.eventType == "begin") {
            		console.log("Signature started")
            	}

            	if(e.detail.eventType == "end") {
            		console.log("Signature ended")

            		//this.router.navigate(["/questionnaire/warning/success"])
            	}
            }
    	})
	}
}
