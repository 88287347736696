import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router, ActivatedRoute, ResolveStart } from '@angular/router'
import { combineLatest, Subject } from 'rxjs'
import { FilterPipe } from 'ngx-filter-pipe'

import { QssService } from '../../../services/qss.service'

export class btnAction {
	HasNextStep: boolean
	HasPreviousStep: boolean
	needFormValid: boolean
	Type: string
	Caption: string
	Action: string
}

@Component({
	selector: 'app-qss',
	templateUrl: './qss.component.html',
	styleUrls: ['./qss.component.scss']
})
export class QSSComponent implements OnInit {

	public userId: string
	public userToken: string
	public isAuth: boolean = false
	public qssStep: number
	public form: any = {}
	public fromLength: number = 0
	public progressWidth: string = ""
	public loading: boolean = true
	public qssForm: FormGroup

	constructor(
		private activatedRoute: ActivatedRoute,
		private formBuilder: FormBuilder,
		private router: Router,
		private _QSS: QssService,
		private filter: FilterPipe
	) {

		localStorage.clear()

		let params: any = this.activatedRoute.params
		this.userId = params._value.id
		this.userToken = params._value.token	

		localStorage.setItem("qss_uid", this.userToken)
		localStorage.setItem("u_qss_uid", this.userId)		
	}

	ngOnInit() {
		const urlParams = combineLatest(
			this.activatedRoute.params,
			this.activatedRoute.queryParams,
			(params, queryParams) => ( { ...params, ...queryParams} )
	    )	

	    urlParams.subscribe(
	    	routeParams => {
	    		this.qssStep = parseInt(routeParams.step)

	    		if(this.isAuth === false && this.qssStep > 0) {
	    			this.router.navigate(["questionnaire/qss/" + this.userId + "/" + this.userToken + "/" + 0], { replaceUrl: true })
	    		}

	    		if(this.isAuth === false) {
		    		this._QSS.CheckUserAuth(this.userId, this.userToken)
						.subscribe(
							resp => {
								this.loading = false

								if(resp && resp.State === true) {
									this.isAuth = true

									localStorage.setItem("qss_form", JSON.stringify(resp.Form))

									if(resp.AddOptional === true) {
										this._QSS.InitOptionalQuestions()
										this.fromLength = this._QSS.AddOptionalSteps()
									}
									else {
										this.fromLength = this._QSS.GetAvailableStepLength()										
									}

		    		 				this.InitStep(this.qssStep)					    		 				
								}
								else if(resp && resp.State === false) {
									if(resp.Error && resp.Error != "" && resp.Error !== "done")
										this.router.navigate(["/questionnaire/warning/" + resp.Error])
									else if(resp.Error && resp.Error != "" && resp.Error === "done")
										this.router.navigate(["/questionnaire/validation"])
								}
								else {
									this.router.navigate(["/404"])
								}
							},
							Error => {
								this.loading = false
								this.router.navigate(["/404"])
							}
						)	    			
	    		}
	    		else {		    		 
		    		 this.InitStep(this.qssStep)			
	    		}
	    	}
	    )
	}

	InitStep(step: number) {
		let userId = parseInt(localStorage.getItem("u_qss_uid"))
		let userToken = localStorage.getItem("qss_uid")

		if(userId === NaN || userId === null || userId === undefined || userId === 0 || userToken === null || userToken === undefined || userToken == "") {
			if(userId.toString() != this.userId || userToken != this.userToken) {
				this.router.navigate(["/questionnaire/warning/forbidden"])				
			}
		}

		if(step === 0) {
			this._QSS.initQSS()
		}
		
		this.fromLength = this._QSS.GetAvailableStepLength()

		if(this.userId != ""){

			this.progressWidth = ((step / (this.fromLength-1)) * 100).toString() + "%"

			this.form = this._QSS.GetStep(step)

			if(this.form === null){
				this.router.navigate(["/404"])
			}	
			else {
				this.qssForm = this.formBuilder.group({})
				this.form.Body.Questions
		            .forEach( 
		                item => {
		                    this.qssForm.addControl(item.Name, this.formBuilder.control([]))
		                    this.qssForm.controls[item.Name].setValidators(Validators.required)

		                    if(item.FieldValue != "" && item.FieldValue != undefined && item.FieldValue != null) {
		                    	this.qssForm.controls[item.Name].patchValue(item.FieldValue)
		                    }
		                }
		            )				
			}
		}	   
	}

	onRadioClick(item: any, value: any) {
		this.qssForm.controls[item.Name].patchValue(value)
		this._QSS.UpdateValue(this.qssStep, item.Name, "FieldValue", value)

		if(value == "Non") {
			this._QSS.UpdateValue(this.qssStep, item.Name, "AffirmativeCommentValue", "")
		}

		if(item.HasAffirmativeModal === true) {
			if(item.AffirmativeModalOpenOnValue == value) {

				this._QSS.showQssCommentModal(item.AffirmativeModalOpenOnQuestion, item.AffirmativeCommentValue)
					.then(
						resp => {
							item.AffirmativeCommentValue = resp
							this._QSS.UpdateValue(this.qssStep, item.Name, "AffirmativeCommentValue", resp)
						}
					)
					.catch(
						err => {}
					)
			}
		}
	}

	onChange(item: any, event: any) {
		if(event && event.target) {
			this.qssForm.controls[item.Name].patchValue(event.target.value)
			this._QSS.UpdateValue(this.qssStep, item.Name, "FieldValue", event.target.value)
		}
	}

	FormBtn(item: btnAction) {
		let IMC: number

		if(this.form.Type == "Identity") {
			let hasHeightCtrl = this.qssForm.controls['Height']
			let hasWeightCtrl = this.qssForm.controls['Weight']

			if(hasHeightCtrl !== undefined && hasHeightCtrl !== null && hasWeightCtrl !== undefined && hasWeightCtrl !== null) {				
				let height: number = hasHeightCtrl.value
				let weight: number = hasWeightCtrl.value
				IMC = weight / (height*height)

				if(IMC<20 || IMC>29.9) {
					this._QSS.correctIMC = false
				}
				else {
					this._QSS.correctIMC = true
				}
			}			
		}

		if(!this._QSS.CheckOptionalIsRequired() || !this._QSS.correctIMC || this._QSS.addOptionalInInit) {
			this.fromLength = this._QSS.AddOptionalSteps()
		}
		else {	
			this.fromLength = this._QSS.RemoveOptionalSteps()
		}


		if(item && item.Type == "Next") {
			if(item.Action == "GoToNextStep") {
				if(item.HasNextStep === true) {
					this.GoToNextStep()
				}
			}		

			if(item.Action == "CheckIMCAndGoToNextStep") {
				if(item.HasNextStep === true) {
					if(IMC<18.5 || IMC>30) {
						let IMCType: { level: string, text: string } = this._QSS.GetIMCType(IMC)

						this._QSS.showMessage("Situation IMC", "Voici votre situation après calcul de votre indice de masse corporelle: <BR /><BR />" + IMCType.level + ": <B>" + IMCType.text + "</B>")
							.then(
								() => this.GoToNextStep()
							)
					}
					else {
						this.GoToNextStep()
					}
				}
			}	

			if(item.Action == "GoToSummary") {
				this.router.navigate(["questionnaire/summary"])					
			}
		}

		if(item && item.Type == "Previous") {

			if(item.Action == "GoToPreviousStep") {
				if(item.HasPreviousStep === true) {
					let previousStepIdx: number = this.qssStep-1

					if(previousStepIdx < 0) {
						this.router.navigate(["/404"])
					}
					else {
						this.router.navigate(["questionnaire/qss/" + this.userId + "/" + this.userToken + "/" + previousStepIdx])					
					}

				}
			}
		}
	}

	GoToNextStep() {
		let nextStepIdx: number = this.qssStep+1

		if(nextStepIdx > (this.fromLength -1)) {
			this.router.navigate(["/404"])
		}
		else {
			this.router.navigate(["questionnaire/qss/" + this.userId + "/" + this.userToken + "/" + nextStepIdx])
		}
	}

	Back() {
		this.router.navigate(["questionnaire/qss/" + this.userId + "/" + this.userToken + "/" + (this.qssStep-1).toString()])					
	}

}
