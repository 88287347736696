import { Component, OnInit } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'

import { LibraryService } from '../../../services/library.service'
import { UtilitiesService } from '../../../services/utilities.service'

@Component({
	selector: 'app-library',
	templateUrl: './library.component.html',
	styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit {

	public loading: boolean = true
	public documents: Array<any> = []

	constructor(
		public _library: LibraryService,
		public _utilities: UtilitiesService,
		public sanitizer: DomSanitizer
	) { }

	ngOnInit() {
		this._library.GetDocuments()
			.subscribe(
				data => {
					this.loading = false

					if(data && data.State === true) {
						this.documents = data.Documents						
					}
					else {
						this._utilities.showError("Il y a eu une erreur.")
					}
				},
				Error => {
					console.log(Error)
					this.loading = false
					this._utilities.showError("Il y a eu une erreur.")
				}
			)
	}

	GetFile(base64Str: string): any {
		return this.sanitizer.bypassSecurityTrustResourceUrl(base64Str)
	}

	openSelectedFile(doc: any) {
		this._utilities.showDocument(doc.Name, doc.File)
	}
}
