import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'

import { CONFIG } from '../app.constants'

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(
		private http: HttpClient
	) { }

  SignDocument(documentToken : string){
  	return this.http.post(`${CONFIG.API[CONFIG.ENV]}/documents/validation`, 
            { documentToken })
            .pipe(
                map(
                    resp => { 
                    	return resp 
                    }
                )
            )
  }

  ResendDocument(quotationId : string){
  	return this.http.get(`${CONFIG.API[CONFIG.ENV]}/documents/resend/`+ quotationId)
            .pipe(
                map(
                    resp => { 
                    	return resp 
                    }
                )
            )
  }

}
