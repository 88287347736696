import { Routes, RouterModule } from '@angular/router'

import { AuthGuard } from './guards/auth.guard'
import { LoginComponent } from './components/login/login.component'
import { PasswordResetComponent } from './components/password-reset/password-reset.component'
import { HomeComponent } from './components/home/home.component'
import { CreationComponent } from './components/home/creation/creation.component'
import { ContractsComponent } from './components/home/contracts/contracts.component'
import { ContractDetailsComponent } from './components/home/contracts/contract-details/contract-details.component'
import { ContractScheduleComponent } from './components/home/contracts/contract-schedule/contract-schedule.component'
import { ContractCoveredComponent } from './components/home/contracts/contract-covered/contract-covered.component'
import { ContractTrackingComponent } from './components/home/contracts/contract-tracking/contract-tracking.component'
import { ContractsListingComponent } from './components/home/contracts/contracts-listing/contracts-listing.component'
import { ContractDocumentsComponent } from './components/home/contracts/contract-documents/contract-documents.component'
import { CustomersComponent } from './components/home/customers/customers.component'
import { CustomersListingComponent } from './components/home/customers/customers-listing/customers-listing.component'
import { CustomerDetailsComponent } from './components/home/customers/customer-details/customer-details.component'
import { CustomerContractsComponent } from './components/home/customers/customer-contracts/customer-contracts.component'
import { CustomerEventsComponent } from './components/home/customers/customer-events/customer-events.component'
import { ActivitiesComponent } from './components/home/activities/activities.component'
import { ProjectComponent } from './components/home/creation/project/project.component'
import { ContactsComponent } from './components/home/contacts/contacts.component'
import { QSSComponent } from './components/qss/qss-form/qss.component'
import { QssErrorComponent } from './components/qss/qss-error/qss-error.component'
import { QssSummaryComponent } from './components/qss/qss-summary/qss-summary.component'
import { QssValidationComponent } from './components/qss/qss-validation/qss-validation.component'
import { NotFoundComponent } from './components/not-found/not-found.component'
import { LibraryComponent } from './components/home/library/library.component'
import { DocumentsErrorComponent } from './components/documents/documents-error/documents-error.component'
import { DocumentsValidationComponent } from './components/documents/documents-validation/documents-validation.component'

const appRoutes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: '404', component: NotFoundComponent },
    { path: 'passwordreset/:id/:token', component: PasswordResetComponent },
    
    { path: 'questionnaire/qss/:id/:token/:step', component: QSSComponent },
    { path: 'questionnaire/qss/:id/:token', redirectTo: 'questionnaire/qss/:id/:token/0', pathMatch: 'full' },
    { path: 'questionnaire/warning/:type', component: QssErrorComponent },
    { path: 'questionnaire/summary', component: QssSummaryComponent },
    { path: 'questionnaire/validation', component: QssValidationComponent },
    { path: 'documents/warning/:type', component: DocumentsErrorComponent },
    { path: 'documents/validation/:docToken', component: DocumentsValidationComponent },

    { 
    	path: '', 
    	component: HomeComponent, 
    	canActivate: [AuthGuard],
    	
    	children: [
    		{
    			path: '', redirectTo: 'creation' , pathMatch: 'full'
    		}, 
		    { 
		    	path: 'creation', component: CreationComponent,
		    	children: [		    		
				    { path: 'quotation', redirectTo: 'quotation/0', pathMatch: 'full', data : { frmModule : 'quotation' } }, 
				    { 
				    	path: 'quotation/:step', 
				    	component: ProjectComponent, 
				    	data : { frmModule : 'quotation' }
 
				    }, 
				    { path: 'subscription', redirectTo: 'subscription/0', pathMatch: 'full' }, 
				    { 
				    	path: 'subscription/:step', 
				    	component: ProjectComponent, 
				    	data : { frmModule : 'subscription' }
 
				    }, 
				    { path: 'upload', redirectTo: 'upload/0', pathMatch: 'full' }, 
				    { 
				    	path: 'upload/:step', 
				    	component: ProjectComponent, 
				    	data : { frmModule : 'upload' }
 
				    }, 
				    { path: '', redirectTo: 'quotation/0', pathMatch: 'full', data : { frmModule : 'quotation' } }, 
		    	] 
		    }, 

		    { 
		    	path: 'contracts', component: ContractsComponent,
		    	children: [		    		
				    { 
				    	path: 'details/:status/:id', 
				    	component: ContractDetailsComponent 
				    }, 
				    { 
				    	path: 'schedule/:status/:id', 
				    	component: ContractScheduleComponent 
				    }, 
				    { 
				    	path: 'covered/:status/:id', 
				    	component: ContractCoveredComponent 
				    }, 
				    { 
				    	path: 'tracking/:status/:id', 
				    	component: ContractTrackingComponent 
				    },
				    { 
				    	path: 'documents/:status/:id', 
				    	component: ContractDocumentsComponent 
				    },
				    { 
				    	path: 'listing', 
				    	component: ContractsListingComponent 
				    }, 
				    { path: '', redirectTo: 'listing', pathMatch: 'full' }
		    	] 
		    }, 

		    { 
		    	path: 'customers', component: CustomersComponent,
		    	children: [	
		    		{ 
				    	path: 'details/:id', 
				    	component: CustomerDetailsComponent 
				    },
				    { 
				    	path: 'contracts/:id', 
				    	component: CustomerContractsComponent 
				    },
				    { 
				    	path: 'events/:id', 
				    	component: CustomerEventsComponent 
				    },	    		
				    { 
				    	path: 'listing', 
				    	component: CustomersListingComponent 
				    }, 
				    { path: '', redirectTo: 'listing', pathMatch: 'full' }
		    	] 
		    }, 
		    { 
		    	path: 'activities', component: ActivitiesComponent
		    },
		    { 
		    	path: 'library', component: LibraryComponent
		    },
		    { 
		    	path: 'contacts', component: ContactsComponent
		    }
    	]
    },


    { path: '**', redirectTo: '404' }
];

export const routing = RouterModule.forRoot(appRoutes)