import { Component } from '@angular/core'
import { SimpleModalComponent } from "ngx-simple-modal"
import { SimpleModalService } from "ngx-simple-modal"
import { ModalComponent } from '../modal/modal.component'
import { CreationService } from '../../services/creation.service'


export interface incrementsModel {
	title:string
	increments: Array<{Id: number, Amount: number, Duration: number}>
	leftBtnCaption: string
	validCaption: string
}

export class increment {
	Id: number
	Amount: any
	AmountError: boolean
	Duration: number
	DurationError: boolean
}

@Component({
  selector: 'app-modal-increments',
  templateUrl: './modal-increments.component.html',
  styleUrls: ['./modal-increments.component.scss']
})
export class ModalIncrementsComponent extends SimpleModalComponent<incrementsModel, any> implements incrementsModel {

	title: string
	increments: Array<increment>
	leftBtnCaption: string
	validCaption: string
	disposable: any = null
    SommeMont:any ;
	Sommeduree:any


	constructor(public _creation: CreationService,
		private simpleModalService: SimpleModalService,

		) {
		super()
	}

	confirm() {
		let incrementToStore: Array<increment> = []
		let valid: boolean = true

		this.increments.forEach(
			inc => {
			
					if((inc.Amount !== null && inc.Amount !== undefined && inc.Amount > 0)&&(inc.Duration !== null && inc.Duration !== undefined && inc.Duration > 0)) {
						
						
 					    if (this.TotalMontant()) {
							valid = false
							inc.AmountError = true

							this.showError("Le totale du Montant des paliers doit etre egale ou inférieur à Montant")
						}
			
				
					
			
			            else if (this.TotalDuration())
				  {
					valid = false
					inc.DurationError = true
					  this.showError("Le totale du duree des paliers doit etre égal à duree")
				            }
				
				
				
					
								
					else {
						incrementToStore.push(inc)}
			}
		}
		)

		if(valid === true && incrementToStore.length > 0) {
			this.result = incrementToStore
			this.close()			
		}
	}


	TotalDuration()
	{
		var Sommeduree:number=0;
		let maxDuration = this._creation.GetLoansMaxDuration()
		this.increments.forEach(
			inc => {
		
	  Sommeduree=Number(inc.Duration)+ Sommeduree
			})
		sessionStorage.setItem('Sommeduree', Sommeduree.toString());


			this._creation.setterDuree(Sommeduree)

		if (maxDuration){
	
		 if((maxDuration!= Sommeduree))
		 {
		 return true;
		}}
	}
	
	TotalMontant()
	{
		var SommeMont:number=0;
			let maxMontant= this._creation.GetLoansAmount()
		this.increments.forEach(
			inc => {
				SommeMont=Number(inc.Amount)+ SommeMont
			})
		sessionStorage.setItem('SommeMont', SommeMont.toString());
		sessionStorage.setItem('maxMontant', maxMontant.toString());

		this._creation.setterMontant(SommeMont)
if(maxMontant){
		 if(maxMontant< SommeMont)
		 {

		 return true;
		}}
	
	}
	
	addStep() {
		this.increments.push({ Id: (this.increments.length + 1), Amount: null, AmountError: false, Duration: null, DurationError: false })
	}

	cancel() {
		this.close()
	}

	RemoveIncrement(id: number) {
		this.increments = this.increments.filter( item => { return item.Id != id } )

		let incremntId = 1
		this.increments.forEach(
			item => {
				item.Id = incremntId

				incremntId++
			}
		)
	}

	amountChange(increment: increment) {
		if(increment && increment.Amount) {
			increment.Amount = Number(increment.Amount.replace(',', '.')).toFixed(2)		
		}

		increment.AmountError = false
	}

	durationChange(increment: increment) {
		increment.DurationError = false
	}

	public dotCount:number=0
	public checkNumberOnly: any
	public checkString: any

	numberOnly(event): boolean {   
	    let charCode = (event.which) ? event.which : event.keyCode;      

	    if (charCode == 46 || charCode == 44) {
	        this.dotCount += 1
	        this.checkNumberOnly = (event.target.value)

	        var numericCheck = (event.target.value).toString()
	        if (numericCheck.includes('.') || numericCheck.includes(',')) {
	            this.dotCount += 1
	        }
	        if (this.dotCount > 1) {   
	            this.dotCount = 0
	            return false
	        }
	    }

	    if (charCode !== 44 && charCode > 31 && (charCode <= 45 || charCode > 57 || charCode==47)) {
	        return false
	    }

	    this.checkNumberOnly = (event.target.value);

	    if (this.checkNumberOnly != null) {
	        var numeric = (event.target.value).toString();
	        if (numeric.includes('.')) {
	            var checkNumeric = numeric.split('.')
	            if (checkNumeric.length > 2) {
	                return false
	            }
	            this.checkString = checkNumeric[1].split('')
	            if (this.checkString.length > 1) {
	                return false
	            }
	        }

	        if (numeric.includes(',')) {
	            var checkNumeric = numeric.split(',')
	            if (checkNumeric.length > 2) {
	                return false
	            }
	            this.checkString = checkNumeric[1].split('')
	            if (this.checkString.length > 1) {
	                return false
	            }
	        }

	    }

		this.dotCount = 0  	    
	    return true
	}

	showError(message: string) {
		if(this.disposable === null) {
			this.disposable = this.simpleModalService.addModal(ModalComponent, {
				title: "",
				message: message,				
				hasCancel: false,
				cancelCaption: "",
				validCaption: "OK"
			})
			.subscribe(
				() => {
					this.disposable = null
				}
			)			
		}
	}
}
