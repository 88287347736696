import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'

import { User } from '../models/user/user.model'

import { CONFIG } from '../app.constants'


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    
    constructor(
        private http: HttpClient
    ) { }
    
    logout(): void {
        localStorage.clear()
    }

    login(username: string, password: string): Observable<any> {
        return this.http.post(`${CONFIG.API[CONFIG.ENV]}/auth/login`, 
            { username,password })
            .pipe(
                map(
                    resp => { return resp }
                )
            )                       
    }

    getUserDetails(): Observable<any> {
        return this.http.get<any>(`${CONFIG.API[CONFIG.ENV]}/auth/me`)
            .pipe(
                map(
                    resp => {
                        return resp
                    }
                )
            )
    }

    isLogged(): any {
        return localStorage.getItem('currentUser')
    }

    resetPassword(email: string): Observable<any> {
         return this.http.post<any>(`${CONFIG.API[CONFIG.ENV]}/users/resetpassword`, { email })
            .pipe(
                map(
                    resp => {
                        return resp
                    }
                )
            )
    }

    resetValidity(id: number, token: string): Observable<any> {
         return this.http.get<any>(`${CONFIG.API[CONFIG.ENV]}/users/resetvalidation/${id}/${token}`)
            .pipe(
                map(
                    resp => {
                        return resp
                    }
                )
            )
    }

    changePassword(id: number, token: string, password: string): Observable<any> {
        console.log(id, token, password);
         return this.http.post<any>(`${CONFIG.API[CONFIG.ENV]}/users/changepassword`, { id, token, password })
            .pipe(
                map(
                    resp => {
                        return resp
                    }
                )
            )
    }
}