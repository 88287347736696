///////////////////////
// Customer listings //
///////////////////////

export class Customer {
	Id: string
	ContractId: string
	ContractStatus: string
	Subscriber: string
	Covered: string
	EffectiveDate: string
	
	constructor() {
		this.Id = ""
		this.ContractId = ""
		this.ContractStatus = ""
		this.Subscriber = ""		
		this.Covered = ""		
		this.EffectiveDate = ""
	}
}
export class RemoteCustomers {
	State: boolean
	Customers: Array<Customer>

	constructor() {
		this.State = false,
		this.Customers = new Array<Customer>()
	}
}


//////////////////////
// Customer details //
//////////////////////

export class CustomerDetails {
	Id: string
	Gender: string
	LastName: string
	FirstName: string
	BirthDate: string
	Phone: string
	Mail: string
	Address1: string
	Address2: string
	PostCode: string
	City: string
	Country: string
	IBAN: string
	BIC: string

	constructor() {
		this.Id = ""
		this.Gender = ""
		this.LastName = ""
		this.FirstName = ""
		this.BirthDate = ""
		this.Phone = ""
		this.Mail = ""
		this.Address1 = ""
		this.Address2 = ""
		this.PostCode = ""
		this.City = ""
		this.Country = ""
		this.IBAN = ""
		this.BIC = ""
	}
}
export class CustomerEditableDatas {
	Phone: string
	Mail: string
	Address1: string
	Address2: string
	PostCode: string
	City: string
	Country: string

	constructor(customer?: CustomerDetails) {		
		this.Phone = customer ? customer.Phone : ""
		this.Mail = customer ? customer.Mail : ""
		this.Address1 = customer ? customer.Address1 : ""
		this.Address2 = customer ? customer.Address2 : ""
		this.PostCode = customer ? customer.PostCode : ""
		this.City = customer ? customer.City : ""
		this.Country = customer ? customer.Country : ""
	}
}
export class City {
	code: string
	city: string
	country: string
}


////////////////////////
// Customer contracts //
////////////////////////

export class CustomerContracts {
	ContractId: string
	ContractStatus: string
	Subscriber: string
	Product: string
	EffectiveDate: string
}
export class RemoteCustomerContracts {
	State: boolean
	CustomerContracts: Array<CustomerContracts>

	constructor() {
		this.State = false,
		this.CustomerContracts = new Array<CustomerContracts>()
	}
}


/////////////////////
// Customer events //
/////////////////////

export class CustomerEvents {
	ContractId: string
	Product: string
	Events: string
	Description: string
	EventDate: string
}
export class RemoteCustomerEvents {
	State: boolean
	CustomerEvents: Array<CustomerEvents>

	constructor() {
		this.State = false,
		this.CustomerEvents = new Array<CustomerEvents>()
	}
}