import { Component, OnInit, HostListener } from '@angular/core'
import { Router, ActivatedRoute, ResolveStart } from '@angular/router'
import { combineLatest, Subject } from 'rxjs'

import { CustomerEvents } from '../../../../models/customers/customer.model'

import { CustomersService } from '../../../../services/customers.service'
import { UtilitiesService } from '../../../../services/utilities.service'


@Component({
	selector: 'app-customer-events',
	templateUrl: './customer-events.component.html',
	styleUrls: ['./customer-events.component.scss']
})
export class CustomerEventsComponent implements OnInit {

	public customerId: string = ""
	public customerEvents: Array<CustomerEvents> = null
	public filteredcustomerEvents: Array<CustomerEvents> = null
	public loading: boolean = false
	public filter: string = ""
	public pageIdx: number = 0
	public maxPageIdx: number = 0

	constructor(
		public router: Router,
		private activatedRoute: ActivatedRoute,
		public _customer: CustomersService,
		public _utilities: UtilitiesService
	) { }

	ngOnInit() {
		const urlParams = combineLatest(
			this.activatedRoute.params,
			this.activatedRoute.data,
			this.activatedRoute.queryParams,
			(params, queryParams, data) => ({ ...params, ...queryParams, ...data})
	    )

	    urlParams.subscribe(
		 	routeParams => {
		 		this.customerId = routeParams.id

		 		if(this.customerId != "") {
		 			this.loading = true

					this._customer.GetCustomerEvents(this.customerId)
							.subscribe(
								resp => {		
									this.loading = false				

									if(resp && resp.State === true){							
										this.customerEvents = resp.CustomerEvents
										
										this.pageIdx = 0
										this.maxPageIdx = 0

										this.Filtering()
									}
									else{
										this._utilities.showErrorWithPromise("Il y a eu une erreur.")
											.then(
												() => {
													this.router.navigate(["customers"])
												}
											)
											.catch(
												() => {
													this.router.navigate(["customers"])
												}
											)										
									}
								},
								Error => {
									this.loading = false

									console.log(Error)
									this._utilities.showErrorWithPromise("Il y a eu une erreur.")
										.then(
											() => {
												this.router.navigate(["customers"])
											}
										)
										.catch(
											() => {
												this.router.navigate(["customers"])
											}
										)									
								}
							)		 			
		 		}
		 		else {
		 			this.loading = false

		 			this._utilities.showErrorWithPromise("Il y a eu une erreur.")
		 				.then(
		 					() => {
		 						this.router.navigate(["customers"])
		 					}
		 				)
		 				.catch(
		 					() => {
		 						this.router.navigate(["customers"])
		 					}
		 				)					
		 		}
			}
		)
	}

	Filtering() {
		if(this.filter != ""){
			let filter: string = this.filter.toLowerCase()

			this.filteredcustomerEvents = this.customerEvents
						.filter( 
							item => { 
								return (
										item.ContractId.toLowerCase().includes(this.filter)
										|| item.Product.toLowerCase().includes(this.filter)
										|| item.Events.toLowerCase().includes(this.filter)
										|| item.Description.toLowerCase().includes(this.filter)
										|| item.EventDate.toLowerCase().includes(this.filter)
									) 
							}
						)

			this.maxPageIdx = Math.ceil(this.filteredcustomerEvents.length / 10)
			this.filteredcustomerEvents = this.filteredcustomerEvents.slice(this.pageIdx * 10, (this.pageIdx + 1) * 10)
		}
		else{
			this.filteredcustomerEvents = this.customerEvents.slice(this.pageIdx * 10, (this.pageIdx + 1) * 10)		
			this.maxPageIdx = Math.ceil(this.customerEvents.length / 10)	
		}
	}

	ClearFilter() {
		this.pageIdx = 0
		this.filter = ""
		this.Filtering()
	}

	IsFirstPage(): boolean {
		return this.pageIdx === 0
	}

	IsLastPage(): boolean {
		return (this.maxPageIdx - 1) === this.pageIdx
	}

	TableAsRow(): boolean {
		return this.filteredcustomerEvents ? (this.filteredcustomerEvents.length > 0) : false			
	}

	GoNext() {
		if(this.pageIdx < (this.maxPageIdx - 1)) {
			this.pageIdx++
			this.Filtering()
		}
	}

	GoBack() {
		if(this.pageIdx > 0) {
			this.pageIdx--
			this.Filtering()	
		}		
	}

	NavigateTo(route: string) {
		this.router.navigate(["customers/" + route + "/" + this.customerId])
	}

}
