import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators'
import { CONFIG } from '../app.constants'

@Injectable({
  providedIn: 'root'
})
export class QuotationService {

  constructor(
		private http: HttpClient
  ) { }
  
  SendQuotation(quotationId : string){
  	return this.http.get(`${CONFIG.API[CONFIG.ENV]}//quotations/`+ quotationId +`/quote/send`)
            .pipe(
                map(
                    resp => { 
                    	return resp 
                    }
                )
            )
  }
}
