import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ResolveStart } from '@angular/router'
import { combineLatest, Subject } from 'rxjs'
import { DocumentsService } from 'src/app/services/documents.service';
import { QssService } from 'src/app/services/qss.service';
import { QuotationService } from 'src/app/services/quotation.service';

import { Contract } from '../../../../models/contracts/contract.model'

import { ContractService } from '../../../../services/contract.service'
import { CreationService } from '../../../../services/creation.service'
import { UtilitiesService } from '../../../../services/utilities.service'

@Component({
	selector: 'app-contract-details',
	templateUrl: './contract-details.component.html',
	styleUrls: ['./contract-details.component.scss']
})
export class ContractDetailsComponent implements OnInit {

	public contract: Contract = null
	public contractId: string = ""
	public contractStatus: string = ""
	public loanIdx: number = 0
	public loanTable: any = {}

	constructor(
		public router: Router,
		private activatedRoute: ActivatedRoute,
		public _contract: ContractService,
		public _document: DocumentsService,
		public _qss: QssService,
		public _quotation: QuotationService,
		public _creation: CreationService,
		public _utilities: UtilitiesService
	) { }

	ngOnInit() {
		const urlParams = combineLatest(
			this.activatedRoute.params,
			this.activatedRoute.data,
			this.activatedRoute.queryParams,
			(params, queryParams, data) => ({ ...params, ...queryParams, ...data})
	    )

		urlParams.subscribe(
		 	routeParams => {
		 		this.contractId = routeParams.id
		 		this.contractStatus = routeParams.status

		 		if(this.contractId != "") {
					this._contract.GetContractDetails(this.contractId, this.contractStatus)
							.subscribe(
								resp => {						
									if(resp && resp.State === true){							
										this.contract = resp.Contract
										console.log(this.contract);
										this.loanIdx = 0
									}
									else{
										this._utilities.showErrorWithPromise("Il y a eu une erreur.")
											.then(
												() => {
													this.router.navigate(["contracts"])
												}
											)
											.catch(
												() => {
													this.router.navigate(["contracts"])
												}
											)
										
									}
								},
								Error => {
									console.log(Error)
									this._utilities.showErrorWithPromise("Il y a eu une erreur.")
										.then(
											() => {
												this.router.navigate(["contracts"])
											}
										)
										.catch(
											() => {
												this.router.navigate(["contracts"])
											}
										)
									
								}
							)		 			
		 		}
		 		else {
		 			this._utilities.showErrorWithPromise("Il y a eu une erreur.")
		 				.then(
		 					() => {
		 						this.router.navigate(["contracts"])
		 					}
		 				)
		 				.catch(
		 					() => {
		 						this.router.navigate(["contracts"])
		 					}
		 				)
					
		 		}


			}
		)
	}

	counter(item: number) {
		return new Array(item)
	}  

	SwitchNavigation(index: number) {
		this.loanIdx = index
	}

	NavigateTo(route: string) {
		this.router.navigate(["contracts/" + route + "/" + this.contractStatus + "/" + this.contractId])
	}

	GoToQuotation(quotation_id: string) {
		this._creation.Clear()
		
		if(quotation_id !== null && quotation_id !== undefined && quotation_id != "") {
			this._contract.GetContractQuotation(quotation_id)
				.subscribe(
					resp => {
						if(resp 
								&& resp.quotation && resp.quotation !== null && resp.quotation !== undefined
								&& resp.subscription && resp.subscription !== null && resp.subscription !== undefined
								&& resp.upload && resp.upload !== null && resp.upload !== undefined
						) {
							this._creation.SetQId(quotation_id)
							this._creation.SetFormSubIndex(0)
							this._creation.SetFormSubDetailsIndex(0)

							localStorage.setItem("main", JSON.stringify(resp))

							let step: number = resp.step ? resp.step : 1

							this.router.navigate(["creation/quotation/" + step])
						}
					},
					Error => {
						console.log(Error)
						this._utilities.showError("Il y a eu un problème lors de la récupération de la quotation.<BR />Merci de contacter votre responsable si cela persiste.")
					}
				)
		}
	}

	GetWithoutTitle(row: Array<{ Type: string, Value: string }>) {
		return row.filter( item => { return item.Type != "title" } )
	}

	GetTitle(row: Array<{ Type: string, Value: string }>) {
		return row.filter( item => { return item.Type == "title" } )
	}

	GetTitleNotEmpty(row: Array<{ Type: string, Value: string }>) {
		return row.filter( item => { return (item.Type == "title" && item.Value != "") } )
	}

	GetSubTitleNotEmpty(row: Array<{ Type: string, Value: string }>) {
		return row.filter( item => { return (item.Type == "subtitle" && item.Value != "") } )
	}

	SendDocuments(quotation_id: string){
		this._document.ResendDocument(quotation_id)
		.subscribe()		
	}

	SendQSS(quotation_id: string){
		this._qss.ResendQSS(quotation_id)
		.subscribe()	
	}

	SendQuotation(quotation_id: string){
		this._quotation.SendQuotation(quotation_id)
		.subscribe()	
	}
}
