import { Component, OnInit } from '@angular/core'
import { SimpleModalComponent } from "ngx-simple-modal"

export interface ConfirmModel {
	title:string
	message:string
	hasCancel: boolean
	cancelCaption: string
	validCaption: string
}

@Component({
	selector: 'app-modal-document',
	templateUrl: './modal-document.component.html',
	styleUrls: ['./modal-document.component.scss']
})
export class ModalDocumentComponent extends SimpleModalComponent<ConfirmModel, boolean> implements ConfirmModel {

	title: string
	message: string
	hasCancel: boolean
	cancelCaption: string
	validCaption: string

	public doc: any
	public docType: string

	constructor() {
		super()

		
	}

	ngOnInit() {
		let type: string = this.message.split(";")[0] || null
		
		if(type) {
			type = type.split(":")[1] || null

			if(type) {
				if(type.startsWith("image")) {
					this.docType = "image"						
				}
				else if(type == "application/pdf") {
					this.docType = "pdf"						
				}
				else {
					this.docType = "unknown"
				}
			}
		}

		this.doc = this.message
	}

	cancel() {
		this.close()
	}

}
