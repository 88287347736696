import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Subject } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { Observable, of, Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import { formatDate } from '@angular/common'

import { CONFIG } from '../app.constants'

import { MainTabs, FormObj, FormStep, FormSubStep, FormSubStepDetails } from '../models/creation/maintabs.model'
import { Field, Step } from '../models/creation/project/project.model'

import { FormsService } from './forms.service'

@Injectable({
  providedIn: 'root'
})
export class CreationService {

	public variables: any = {}
	public quotation_id: string = ""
	public main: MainTabs = null
	private formObj: FormObj = null
	private formSubIndex: number = null
	private formSubDetailsIndex: number = null
	private currentFormModifiedFieldCount: number = 0
	private dataT :any;
	private dataM :any;

	private _formSubDetailsIndexEvent = new Subject<{index: number, refresh: boolean}>()
	public formSubDetailsIndexEvent = this._formSubDetailsIndexEvent.asObservable()

 	constructor(
 		public _http: HttpClient,
 		public router: Router,
 		private _Forms: FormsService
 	) { }



	 setterMontant(dataM:any){
		this.dataM=dataM;


	
	
	  }
   
	 getterMontant(){
	

	   return this.dataM;
	 }


	 setterDuree(dataT:any){
		this.dataT=dataT;
	  }
   
	 getterDuree(){
	   return this.dataT;
	 }


	 
	 setterSommeMontant(dataM:any){
		this.dataM=dataM;


	
	
	  }
   
	 getterSommeMontant(){
	

	   return this.dataM;
	 }


	 setterSommeDuree(dataT:any){
		this.dataT=dataT;
	  }
   
	 getterSommeDuree(){
	   return this.dataT;
	 }


	 
	 

 	GoNext(main: string, step: number, autoSubmit: boolean): void {
 		let historyStep:any = JSON.parse(localStorage.getItem("HistoryStep"))

 		if(historyStep === null || historyStep === undefined || historyStep == ""){
 			historyStep = {}
 		}

 		if(!historyStep[main]) {
 			historyStep[main] = {}
 		}

 		historyStep[main][step] = autoSubmit

 		localStorage.setItem("HistoryStep", JSON.stringify(historyStep))
 	}
 	GoBack(main: string, step: number) {
 		let historyStep:any = JSON.parse(localStorage.getItem("HistoryStep"))

 		if(historyStep === null || historyStep === undefined || historyStep == ""){
 			historyStep = {}
 		}

 		if(step >= 0) {
 			do {
 				step--

 				if(step < 0) {
 					switch (main) {
 						case "subscription":
 							main = "quotation"
 							step = this._Forms.getFormLength(main) -1
 							break
 						
 						case "upload":
 							main = "subscription"
 							step = this._Forms.getFormLength(main) -1
 							break

 					}
 				}
 				if(!historyStep[main]) { historyStep[main] = {} }
 			} while (historyStep[main][step] === true)


 			this.router.navigate(["creation/" + main + "/" + step ])
				.then( 
					data => {
						$(".creation").scrollTop(0)
					}
				)		
 		}
 		else {
 			if(step === 0) {

 			}
 		}
 	}

	NewFormObj(): void {
		this.main = new MainTabs()
		this.main.quotation = new FormObj()
		this.main.quotation.Steps = new Array<FormStep>()
		this.main.subscription = new FormObj()
		this.main.subscription.Steps = new Array<FormStep>()
		// this.main.upload = new FormObj()
		// this.main.upload.Steps = new Array<FormStep>()
		this.formSubIndex = 0
		this.formSubDetailsIndex = 0
		this.quotation_id = ""

		for (var i = 0; i < this._Forms.getForms("quotation").length; i++) {
			let step: FormStep = new FormStep()
			this.main.quotation.Steps.push(step)

			let subStep: FormSubStep = new FormSubStep()
			this.main.quotation.Steps[i].Step = new Array<FormSubStep>()
			this.main.quotation.Steps[i].Step.push(subStep)

			let details: FormSubStepDetails = new FormSubStepDetails()
			let formFields: Array<Field> = this.GetEmptyFormFields("quotation", i)	
			this.main.quotation.Steps[i].Step[0].Details = new Array<FormSubStepDetails>()
			details.Fields = formFields			

			this.main.quotation.Steps[i].Step[0].Details.push(details)		
		}
		
		this.main.quotation.Steps[0].IsEnabled = true

		for (var i = 0; i < this._Forms.getForms("subscription").length; i++) {
			let step: FormStep = new FormStep()
			this.main.subscription.Steps.push(step)

			let subStep: FormSubStep = new FormSubStep()
			this.main.subscription.Steps[i].Step = new Array<FormSubStep>()
			this.main.subscription.Steps[i].Step.push(subStep)

			let details: FormSubStepDetails = new FormSubStepDetails()
			let formFields: Array<Field> = this.GetEmptyFormFields("subscription", i)	
			this.main.subscription.Steps[i].Step[0].Details = new Array<FormSubStepDetails>()
			details.Fields = formFields			

			this.main.subscription.Steps[i].Step[0].Details.push(details)		
		}			

		localStorage.setItem("main", JSON.stringify(this.main))
	}
	Clear(): void {
		this.main = null
		this.quotation_id = ""
		this.formSubIndex = 0
		this.formSubDetailsIndex = 0
		this.currentFormModifiedFieldCount = 0

		localStorage.removeItem("quotation_id")
		localStorage.removeItem("quotation_formSubIndex")
		localStorage.removeItem("quotation_formSubDetailsIndex")
		localStorage.removeItem("main")
		localStorage.removeItem("HistoryStep")
		
	}
	ClearFormObj(): void {
		this.main = null
		this.quotation_id = ""
		this.formSubIndex = 0
		this.formSubDetailsIndex = 0
		this.currentFormModifiedFieldCount = 0
		this.NewFormObj()

		localStorage.removeItem("quotation_id")
		localStorage.removeItem("quotation_formSubIndex")
		localStorage.removeItem("quotation_formSubDetailsIndex")
		localStorage.removeItem("main")
		localStorage.removeItem("HistoryStep")
	}
	Get(): MainTabs {
		if(this.main){			
			return this.main
		}
		else{
			let storedMain = localStorage.getItem("main")

			if(storedMain) {

				this.main = JSON.parse(storedMain)
				return this.main
			}
			else{
				this.NewFormObj()
				return this.main
			}
		}
	}

	GetQId(): string {
		if(!this.quotation_id) {
			this.quotation_id = localStorage.getItem("quotation_id")

			if(!this.quotation_id) {
				this.SetQId("")
				
				return this.quotation_id
			}
			else {
				return this.quotation_id				
			}
		} 
		else{
			return this.quotation_id			
		}
	}
	SetQId(id: string): void {
		localStorage.setItem("quotation_id", id)

		this.quotation_id = id
	}	

	GetFormSubIndex(): number {
		if(!this.formSubIndex) {
			this.formSubIndex = parseInt(localStorage.getItem("quotation_formSubIndex"))

			if(!this.formSubIndex) {
				this.SetFormSubIndex(0)
				
				return this.formSubIndex
			}
			else {
				return this.formSubIndex				
			}
		}
		else {
			return this.formSubIndex			
		}
	}
	SetFormSubIndex(idx: number): void {
		localStorage.setItem("quotation_formSubIndex", idx.toString())

		this.formSubIndex = idx
	}

	GetFormSubDetailsIndex(): number {
		if(!this.formSubDetailsIndex) {
			this.formSubDetailsIndex =  parseInt(localStorage.getItem("quotation_formSubDetailsIndex"))

			if(!this.formSubDetailsIndex) {
				this.SetFormSubDetailsIndex(0)
				
				return this.formSubDetailsIndex
			}
			else {
				return this.formSubDetailsIndex				
			}
		}
		else {
			return this.formSubDetailsIndex			
		}
	}
	SetFormSubDetailsIndex(idx: number): void {
		localStorage.setItem("quotation_formSubDetailsIndex", idx.toString())
		
		if(this.formSubDetailsIndex == idx) {
			//this._formSubDetailsIndexEvent.next({index: this.formSubDetailsIndex, refresh: true})		
		}
		else {
			this.formSubDetailsIndex = idx
			this._formSubDetailsIndexEvent.next({index: this.formSubDetailsIndex, refresh: false})
		}		
	}

	CheckFormStepState(main: string, step: number, index: number, detailsIndex: number): boolean {
		if(this.main[main]){
			if(this.main[main].Steps[step]){
				if(this.main[main].Steps[step].Step[index].Details[detailsIndex]) {
					return this.main[main].Steps[step].Step[index].Details[detailsIndex].IsEnabled
				}	
			}
			
		}

		return false
	}

	GetFormSubStep(main: string, step: number, index: number): FormSubStepDetails {
		if(this.main[main]){
			if(this.main[main].Steps[step]){
				if(this.main[main].Steps[step].Step[index]) {
					return this.main[main].Steps[step].Step[index]
				}	
			}			
		}

		return null
	}
	GetFormSubStepDetails(main: string, step: number, index: number, detailsIndex: number): FormSubStepDetails {
		if(this.main[main]){
			if(this.main[main].Steps[step]){
				if(this.main[main].Steps[step].Step[index].Details[detailsIndex]) {
					return this.main[main].Steps[step].Step[index].Details[detailsIndex]
				}	
			}			
		}

		return null
	}	

	AddFormStepValues(main: string, step: number, index: number, detailsIndex: number, vals: Array<Field>): void {
		if(this.main[main]){
			if(this.main[main].Steps[step]){
				if(this.main[main].Steps[step].Step[index].Details[detailsIndex]) {
					this.main[main].Steps[step].Step[index].Details[detailsIndex].Fields = vals

					localStorage.setItem("main", JSON.stringify(this.main))
				}	
			}			
		}
	}
	AddEmptyFormStep(main: string, step: number, index?: number): void {
		if(this.main[main]){
			if(this.main[main].Steps[step]){
				if(this.main[main].Steps[step].Step) {	
					let formStep: FormSubStep = new FormSubStep()				
					this.main[main].Steps[step].Step.push(formStep)

					let stepLength: number = 0

					this.AddEmptyFormStepDetails(main, step, index ? index : 0)
				}	
			}			
		}
	}
	AddEmptyFormStepDetails(main: string, step: number, index: number): number {
		if(this.main[main]){
			if(this.main[main].Steps[step]){
				if(this.main[main].Steps[step].Step[index]) {					
					let details: FormSubStepDetails = new FormSubStepDetails()
					let formFields: Array<Field> = this.GetEmptyFormFields(main, step)

					if(!this.main.quotation.Steps[step].Step[index].Details || this.main.quotation.Steps[step].Step[index].Details.length < 1) {
						this.main.quotation.Steps[step].Step[index].Details = new Array<FormSubStepDetails>()						
					}

					details.Fields = formFields			
					this.main[main].Steps[step].Step[index].Details.push(details)

					localStorage.setItem("main", JSON.stringify(this.main))

					return this.main[main].Steps[step].Step[index].Details.length -1
				}	
			}			
		}

		return 0
	}

	RemoveSubStep(main: string, step: number, index: number): void {
		if(this.main[main]){
			if(this.main[main].Steps[step]){
				if(this.main[main].Steps[step].Step[index]) {
					this.main[main].Steps[step].Step = this.main[main].Steps[step].Step.filter( (v, k) => { return k !== index } )
				}	
			}			
		}
	}

	RemoveSubStepDetails(main: string, step: number, index: number, detailsIndex: number): void {
		if(this.main[main]){
			if(this.main[main].Steps[step]){
				if(this.main[main].Steps[step].Step[index].Details[detailsIndex]) {
					this.main[main].Steps[step].Step[index].Details = this.main[main].Steps[step].Step[index].Details.filter( (v, k) => { return k !== detailsIndex } )
				}	
			}			
		}
	}

	GetFormSubStepLength(main: string, step: number): number {
		if(this.main[main]){
			if(this.main[main].Steps[step]){			
				return this.main[main].Steps[step].Step.length
			}			
		}

		return 0
	}
	GetFormSubStepDetailsLength(main: string, step: number, index: number): number {
		if(this.main[main]){
			if(this.main[main].Steps[step]){
				if(this.main[main].Steps[step].Step[index]) {					
					return this.main[main].Steps[step].Step[index].Details.length
				}	
			}			
		}

		return 0
	}

	GetFormFieldValue(main: string, step: number, index: number, detailsIndex: number, field: string): any {
		if(this.main[main]){
			if(this.main[main].Steps[step]){
				if(this.main[main].Steps[step].Step[index]) {	
					let selectedForm = this.main[main].Steps[step].Step[index].Details[detailsIndex]

					if(selectedForm) {
						let selectedField = selectedForm.Fields.find( itm => { return itm.name == field } )
						if(selectedField) {
							return selectedField.value							
						}						
					}
				}	
			}			
		}

		return null
	}
	SetFormFieldValue(main: string, step: number, index: number, detailsIndex: number, field: string, val: any): void {	
		if(this.main[main]){
			if(this.main[main].Steps[step]){
				if(this.main[main].Steps[step].Step[index]) {	
					let selectedForm = this.main[main].Steps[step].Step[index].Details[detailsIndex]

					if(selectedForm) {
						let selectedFieldIdx = selectedForm.Fields.findIndex( itm => { return itm.name == field } )
						if(selectedFieldIdx >= 0 && this.main[main].Steps[step].Step[index].Details[detailsIndex].Fields[selectedFieldIdx]) {
							this.main[main].Steps[step].Step[index].Details[detailsIndex].Fields[selectedFieldIdx].value = val

							localStorage.setItem("main", JSON.stringify(this.main))
						}						
					}
				}	
			}			
		}
	}

	EnabledMainStep(Main: string, goNext: boolean): void {
		this.main[Main].isEnabled = true
		if(this.main[Main]){
			if(this.main[Main].Steps[0]){
					this.main[Main].Steps[0].IsEnabled = true			

					if(this.main[Main].Steps[0].Step[0].Details[0]){
						this.main[Main].Steps[0].Step[0].Details[0].IsEnabled = true			
					}		
						
			}		
		}
		localStorage.setItem("main", JSON.stringify(this.main))

		if(goNext === true) {
			this.router.navigate(["creation/" + Main], { replaceUrl:true })
		}
		else {
			this.router.navigate(["creation/" + Main])			
		}
	}

	IsStepEnable(main: string, step: number): boolean {
		if(this.main[main]){
			if(this.main[main].Steps[step]){
				return this.main[main].Steps[step].IsEnabled				
			}			
		}

		return false
	}

	EnableNextStep(main: string, step: number): void {
		if(this.main[main]){
			if(this.main[main].Steps[step]){
				this.main[main].Steps[step].IsEnabled = true

				localStorage.setItem("main", JSON.stringify(this.main))
			}			
		}
	}

	GetMaxEnableStep(main: string): number {
		if(this.main[main]){
			let maxIdx: number = 0

			for (var i = 0; i < this.main[main].Steps.length; i++) {
				if(this.IsStepEnable(main, i)) {
					maxIdx = i
				}
			}

			return maxIdx
		}

		return 0	
	}

	FormLog(main: string): void {
	}

	Log(): void {
	
	}

	SetCurrentFormModifiedFieldCount(nb: number): void {
		this.currentFormModifiedFieldCount = nb
	}

	GetCurrentFormModifiedFieldCount(): number {
		return this.currentFormModifiedFieldCount
	}

	StoreChanges(main: string, step: number, index: number, detailsIndex: number, field: string, value: any): void {
		if(this.main[main]){

			if(this.main[main].Steps[step]){
				if(this.main[main].Steps[step].Step[index]) {	
					let selectedForm = this.main[main].Steps[step].Step[index].Details[detailsIndex]
					
					if(selectedForm) {					
						let selectedField = selectedForm.Fields.find( itm => { return itm.name == field } )
						if(selectedField) {
							selectedField.value = value

							localStorage.setItem("main", JSON.stringify(this.main))							
						}	
						else {
							
							
						}					
					}
				}	
			}			
		}
	}

	GetEmptyFormFields(main: string, step: number): Array<Field> {
		let form: any = this._Forms.getFormStep(main, step)
		let formFields: Array<Field> = new Array<Field>()
		
		if(form) {
			let fields = form.Controls

			fields.forEach(
				field => {
					let formField: Field = new Field()
						formField.name = field.Name

					formFields.push(formField)
				}
			)

			return formFields
		}
	}

	GetLoansAmount(): number {
		let amount: number = 0
		let LoansIndex: number = this._Forms.GetFormStepIndex("quotation", "Loan")
		let LoansCount: number = this.GetFormSubStepDetailsLength("quotation", LoansIndex, 0)

		if(LoansCount > 0){
			for (var i = 0; i < LoansCount; ++i) {
				let field = this.GetFormFieldValue("quotation", LoansIndex, 0, i, "Amount")

				amount += parseFloat(field)
			}
		}
		sessionStorage.setItem('maxMontant', amount.toString());
		this.setterSommeMontant(amount)

		return amount
	}

	GetLoansMaxDuration(): number {
		let duration: number = 0
		let LoansIndex: number = this._Forms.GetFormStepIndex("quotation", "Loan")
		let LoansCount: number = this.GetFormSubStepDetailsLength("quotation", LoansIndex, 0)

		if(LoansCount > 0){
			for (var i = 0; i < LoansCount; ++i) {
				let field = this.GetFormFieldValue("quotation", LoansIndex, 0, i, "Duration")

				duration = duration > parseInt(field) ? duration : parseInt(field)
			}
		}
		console.log(duration)
		this.setterSommeDuree(duration)

		sessionStorage.setItem('maxDuration', duration.toString());

		return duration

	}

	GetStepMaxId(main: string, step: number, index: number, indexName: string): number {
		let maxId: number = 0
		
		if(this.main[main]){
			if(this.main[main].Steps[step]){
				if(this.main[main].Steps[step].Step[index]) {

					for (var i = 0; i <= this.main[main].Steps[step].Step[index].Details.length; ++i) {
						let stepId: number
						stepId = this.GetFormFieldValue(main, step, index, i, indexName)
						if(stepId > maxId) { 
							maxId = stepId 
						}
					}
				}
			}
		}

		return maxId
	}

	updateNextStepSharedIds(action: any, formIndex: number): void {
		let TargetLength: number = this.GetFormSubStepLength(action.Target, formIndex)

		for (var i = 0; i < TargetLength; ++i) {
			let TargetDetailsLength: number = this.GetFormSubStepDetailsLength(action.Target, formIndex, i)

			action.SharedId.forEach(
				sid => {
					for (var j = 0; j < TargetDetailsLength; j++) {
						let StepIdx: number = this._Forms.GetFormStepIndex(sid.Source, sid.SourceStep)
						let FieldValue: any = this.GetFormFieldValue(sid.Source, StepIdx, 0, j, sid.Field)
						
						if(sid.Type == "Step") {
							FieldValue = this.GetFormFieldValue(sid.Source, StepIdx, 0, i, sid.Field)	
						}
						else {
							FieldValue = this.GetFormFieldValue(sid.Source, StepIdx, 0, j, sid.Field)
						}

						this.SetFormFieldValue(action.Target, formIndex, i, j, sid.Field, FieldValue)								
					}
					
				}
			)
		}
	}

	EraseStep(main: string, step: number, index: number, detailsIndex: number): void {
		if(this.main[main]){
			if(this.main[main].Steps[step]){
				if(this.main[main].Steps[step].Step[index]) {	
					let selectedForm = this.main[main].Steps[step].Step[index].Details[detailsIndex]
					if(selectedForm) {		
						if(selectedForm.Fields && selectedForm.Fields.length > 0){
							selectedForm.Fields.forEach(
								field => {
									field.value = null
								}
							)
						}
					}
				}	
			}			
		}
	}

	//HTTP

	GetQuotationId(): Observable<boolean> {
		return this._http.post<any>(CONFIG.API[CONFIG.ENV] + "/quotations", {})
            .pipe(
                map(
                    data => {
                    	if(data && data.QuotationResult.Quotation) {
                    		this.SetQId(data.QuotationResult.Quotation.QuotationCoogId)
                    		
                    		return true
                    	}

                    	return false
                    }
                )
            )
	}

	PutSellingProduct(): Observable<boolean> {
		return this._http.put<any>(
				CONFIG.API[CONFIG.ENV] + "/quotations/" + this.quotation_id, 
				{
					date : formatDate(new Date(), 'yyyy-MM-dd', 'en'),
					duration : null,
					commercial_product :
						{ 
							id : 1,
							rec_name : "PRODUIT EMPRUNTEUR"
						},
					plan : null,
					coverage : {}
				}
			)
            .pipe(
                map(
                    data => {
                    	if(data) {
                    		
                    		return true
                    	}

                    	return false
                    }
                )
            )
	}
}
