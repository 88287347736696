import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute, ResolveStart } from '@angular/router'
import { combineLatest, Subject } from 'rxjs'

import { Document } from '../../../../models/contracts/contract.model'

import { ContractService } from '../../../../services/contract.service'
import { UtilitiesService } from '../../../../services/utilities.service'

@Component({
	selector: 'app-contract-documents',
	templateUrl: './contract-documents.component.html',
	styleUrls: ['./contract-documents.component.scss']
})
export class ContractDocumentsComponent implements OnInit {

	public contract: any = null
	public contractId: string = ""
	public contractStatus: string = ""
	public currentBorrower: any = null

	constructor(
		public router: Router,
		private activatedRoute: ActivatedRoute,
		public _contract: ContractService,
		public _utilities: UtilitiesService
	) { }

	ngOnInit() {
		const urlParams = combineLatest(
			this.activatedRoute.params,
			this.activatedRoute.data,
			this.activatedRoute.queryParams,
			(params, queryParams, data) => ({ ...params, ...queryParams, ...data})
	    )

		urlParams.subscribe(
		 	routeParams => {
		 		this.contractId = routeParams.id
		 		this.contractStatus = routeParams.status
		 		
		 		if(this.contractId != "") {
					this._contract.GetContractDocuments(this.contractId, this.contractStatus)
							.subscribe(
								resp => {						
									if(resp && resp.State === true){							
										this.contract = resp.Contract

										if(this.contract && this.contract.Borrowers.length > 0) {
											this.currentBorrower = this.contract.Borrowers[0]
										}
									}
									else{
										this._utilities.showErrorWithPromise("Il y a eu une erreur.")
											.then(
												() => {
													this.router.navigate(["contracts"])
												}
											)
											.catch(
												() => {
													this.router.navigate(["contracts"])
												}
											)
									}
								},
								Error => {
									console.log(Error)
									this._utilities.showErrorWithPromise("Il y a eu une erreur.")
										.then(
											() => {
												this.router.navigate(["contracts"])
											}
										)
										.catch(
											() => {
												this.router.navigate(["contracts"])
											}
										)
								}
							)		 			
		 		}
		 		else {
		 			this._utilities.showErrorWithPromise("Il y a eu une erreur.")
						.then(
							() => {
								this.router.navigate(["contracts"])
							}
						)
						.catch(
							() => {
								this.router.navigate(["contracts"])
							}
						)
		 		}


			}
		)
	}

	SwitchNavigation(item: any) {
		this.currentBorrower = item
	}

	openSelectedFile(doc : Document) {
		if(doc !== null && doc !== undefined && doc.File !== null && doc.File !== undefined && doc.File !== "") {
			this._utilities.showDocument(doc.Name, doc.File)
		}
	}

	NavigateTo(route: string) {
		this.router.navigate(["contracts/" + route + "/" + this.contractStatus + "/" + this.contractId])
	}

}
