import { Component } from '@angular/core'
import { SimpleModalComponent } from "ngx-simple-modal"

export interface ConfirmModel {
	title:string
	message:string | Array<string>
	hasCancel: boolean
	cancelCaption: string
	validCaption: string
	fromQSS? :boolean
}

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss']
})


export class ModalComponent extends SimpleModalComponent<ConfirmModel, boolean> implements ConfirmModel {
	title: string
	message: string | Array<string>
	hasCancel: boolean
	cancelCaption: string
	validCaption: string
	fromQSS: boolean

	constructor() {
		super()
	}

	confirm() {
		this.result = true
		this.close()
	}
}
