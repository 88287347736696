import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { DatePipe } from '@angular/common'

import { Contract } from '../../../../models/contracts/contract.model'

import { CreationService } from '../../../../services/creation.service'
import { ContractService } from '../../../../services/contract.service'
import { UtilitiesService } from '../../../../services/utilities.service'


@Component({
  selector: 'app-contracts-listing',
  templateUrl: './contracts-listing.component.html',
  styleUrls: ['./contracts-listing.component.scss']
})

export class ContractsListingComponent implements OnInit {

	public filter: string = ""
	public contracts: Array<Contract> = new Array<Contract>()
	private gridApi
	private gridColumnApi
	public isMultiSearch: boolean = false

	public contractId: string = ""
	public products: Array<string> = []
	public effectiveStartDate: string = ""
	public subscriber: string = ""
	public status: Array<string> = []
	public effectiveEndDate: string = ""

	public ContractsListProducts: Array<string> = []
	public ContractsListStatus: Array<string> = []

	columnDefs = [
        {headerName: 'Identifiant', field: 'Id'},
        {headerName: 'Souscripteur', field: 'Subscriber'},
        {headerName: 'Produit', field: 'Product'},
        {headerName: 'Date de création', field: 'CreatedDate'},
        {headerName: "Date d'effet", field: 'EffectiveDate'},
        {headerName: 'Statut', field: 'Status'}
    ]

    rowData = []

	constructor(
		public router: Router,
		private _creation: CreationService,
		private _contract: ContractService,
		private _utilities: UtilitiesService,
		private _datepipe: DatePipe
	) { }

	ngOnInit() { }	

	onGridReady(params) {
	    this.gridApi = params.api
	    this.gridColumnApi = params.columnApi
	    this.gridApi.sizeColumnsToFit()
	    this.gridApi.hideOverlay()

	    this.initGrid()
	}

	initGrid() {
		this.gridApi.showLoadingOverlay()

		this._contract.GetContracts()
			.subscribe(
				resp => {
					if(resp && resp.State === true) {
						this.contracts = resp.Contracts
						this.rowData = this.contracts

						this.contracts.forEach(
							item => {
								if(this.ContractsListProducts.findIndex(elt => elt == item.Product) == -1) {
									this.ContractsListProducts.push(item.Product)
								}
								this.ContractsListProducts.sort()

								if(this.ContractsListStatus.findIndex(elt => elt == item.Status) == -1) {
									this.ContractsListStatus.push(item.Status)
								}
								this.ContractsListStatus.sort()
							}
						)
						

						if(this.rowData && this.rowData.length<1) {
							this.gridApi.showNoRowsOverlay()
						}
					}
					else {
						this._utilities.showError("Il y a eu une erreur.")
					}
				},
				Error => {
					console.log(Error)
					this._utilities.showError("Il y a eu une erreur.")
				}
			)
	}

	Filtering() {
		if(this.filter != ""){
			let filter: string = this.filter.toLowerCase()

			this.rowData = this.contracts
						.filter( 
							item => { 
								return (
										item.Id.toLowerCase().includes(this.filter)
										|| item.Subscriber.toLowerCase().includes(this.filter)
										|| item.Product.toLowerCase().includes(this.filter)
										|| item.CreatedDate.toLowerCase().includes(this.filter)
										|| item.EffectiveDate.toLowerCase().includes(this.filter)
										|| item.Status.toLowerCase().includes(this.filter)
									) 
							}
						)
		}
		else{
			this.rowData = this.contracts
		}
	}

	MultiFiltering() {

		let contractId: string = this.contractId.toLowerCase()		
		let products: Array<string> = this.products.map(item => item.toLowerCase())
		let subscriber: string = this.subscriber.toLowerCase()
		let status: Array<string> = this.status.map(item => item.toLowerCase())
		let effectiveStartDate: string = this.effectiveStartDate.toLowerCase()
		let effectiveEndDate: string = this.effectiveEndDate.toLowerCase()

		this.rowData = this.contracts
			.filter( 
				item => { 
					let tmpEffectiveDate: any = item.EffectiveDate.substring(3,5) + "-" + item.EffectiveDate.substring(0,2) + "-" + item.EffectiveDate.substring(6)

					return (
							item.Id.toLowerCase().includes(this.contractId)
							&& item.Subscriber.toLowerCase().includes(this.subscriber)
							&& (
								(effectiveStartDate ? this._datepipe.transform(new Date(tmpEffectiveDate), 'yyyy-MM-dd') >= this._datepipe.transform(new Date(effectiveStartDate), 'yyyy-MM-dd') : true)
								&& (effectiveEndDate ? this._datepipe.transform(new Date(tmpEffectiveDate), 'yyyy-MM-dd') <= this._datepipe.transform(new Date(effectiveEndDate), 'yyyy-MM-dd') : true)
								)
						) 
				}
			)

		if(products.length > 0) {
			this.rowData = this.rowData
			.filter( 
				item => { 
					return (
							products.findIndex(elt => elt == item.Product.toLowerCase()) != -1
						) 
				}
			)
		}

		if(status.length > 0) {
			this.rowData = this.rowData
			.filter( 
				item => { 
					return (
							status.findIndex(elt => elt == item.Status.toLowerCase()) != -1
						) 
				}
			)
		}
	}

	MultiSearch() {
		this.isMultiSearch =! this.isMultiSearch
	}

	ClearFilter() {
		this.filter = ""

		this.contractId = ""
		this.products = []
		this.effectiveStartDate = ""
		this.subscriber = ""
		this.status = []
		this.effectiveEndDate = ""

		if(this.isMultiSearch) {
			this.MultiFiltering()
		}
		else {
			this.Filtering()
		}
	}

	Export(): Promise<boolean> {
		return new Promise<boolean>(
			(resolve, reject) => { 
				this._contract.ExportContracts()
					.subscribe(resp => {
						if(resp) {
							console.log(resp);
							const downloadedFile = new Blob([resp], { type: "application/vnd.ms-excel" });
							const a = document.createElement('a');
							a.setAttribute('style', 'display:none;');
							document.body.appendChild(a);
							a.download = "export_devis_contrat";
							a.href = URL.createObjectURL(downloadedFile);
							a.target = '_blank';
							a.click();
							document.body.removeChild(a);

							resolve(true)

							reject(false)
						}
						else{
							reject(false)													
						}
					},
					Error => {
						console.log(Error)
						this._utilities.showError("Il y a eu une erreur.")
					});
		})
	}

	onCellDoubleClicked(item: any) {
		if(item && item.data && item.data.Id) {		
			let status: string = item.data.Status
			if(status) { status = status.toLowerCase() }

			let intStatus: number
			switch (status) {
					case "actif":
						intStatus = 1
						break

					case "annulée":
						intStatus = 2
						break

					case "devis":
						intStatus = 3
						break

					case "projet":
						intStatus = 4
						break

					case "envoyée":
						intStatus = 5
						break
					
					default:
						intStatus = 0
						break;
				}	

			this._creation.SetCurrentFormModifiedFieldCount(0)
			this.router.navigate(["contracts/details/" + intStatus + "/" + item.data.Id])
		}
	}

	GoBack() {
		if(this.gridApi && !(this.gridApi.paginationGetCurrentPage() === 0)) {
			this.gridApi.paginationGoToPreviousPage()
		}
	}

	GoNext() {
		if(this.gridApi && !(this.gridApi.paginationGetTotalPages() - 1 === this.gridApi.paginationGetCurrentPage())) {
			this.gridApi.paginationGoToNextPage()
		}
	}

	IsFirstPage(): boolean {
		return this.gridApi ? !(this.gridApi.paginationGetCurrentPage() === 0) : false
	}

	IsLastPage(): boolean {
		return this.gridApi ?  !(this.gridApi.paginationGetTotalPages() - 1 === this.gridApi.paginationGetCurrentPage()): false
	}

	TableAsRow(): boolean {
		return this.gridApi ? (this.gridApi.paginationGetRowCount() > 0) : false			
	}
}
