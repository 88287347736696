import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { AuthenticationService } from '../services/authentication.service'


@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            console.log("ErrorInterceptorService",err);

            if (request.url != "api/auth/login" && err.status === 401) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout()
                location.reload(true)
            }
            
            const error = err.error ? err.error.message : err.status
            return throwError(error)
        }))
    }
}

