import { Project, Step } from './project/project.model'


export class MainTabs {
	quotation: FormObj
	// project: Project
	subscription: FormObj
	upload: FormObj


	constructor() {
		this.quotation = new FormObj()
		// this.project = new Project()
		this.subscription = new FormObj()
		this.upload = new FormObj()
	}
}

class Tab {
	isDone: boolean
	isEnabled: boolean

	constructor() {
		this.isDone = false
		this.isEnabled = false
	}
}





export class FormObj {
	Steps: Array<FormStep>
	isEnabled: boolean

	constructor() {
		this.Steps = new Array<FormStep>()
		this.isEnabled = true
	}
}

export class FormStep {
	Step: Array<FormSubStep>
	IsEnabled: boolean

	constructor() {
		this.Step = new Array<FormSubStep>()
		this.IsEnabled = false
	}
}

export class FormSubStep {
	Details: Array<FormSubStepDetails>

	constructor() {
		this.Details = new Array<FormSubStepDetails>()
	}
}

export class FormSubStepDetails {
	Id: number
	Fields: Array<FormField>
	IsEnabled: boolean

	constructor() {
		this.Id = 0
		this.Fields = new Array<FormField>()
		this.IsEnabled = false
	}
}

export class FormField {
	name: string
	value: any
	options: Array<FormFieldOption>	

	constructor() {
		this.name = ""
		this.value = null
		this.options = new Array<FormFieldOption>()		
	}
}

export class FormFieldOption {
	name: string
	value: any

	constructor() {
		this.name = ""
		this.value = null
	}
}