import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ResolveStart } from '@angular/router'
import { combineLatest, Subject } from 'rxjs'

import { CoveredDetails } from '../../../../models/contracts/contract.model'

import { ContractService } from '../../../../services/contract.service'
import { UtilitiesService } from '../../../../services/utilities.service'


@Component({
  selector: 'app-contract-covered',
  templateUrl: './contract-covered.component.html',
  styleUrls: ['./contract-covered.component.scss']
})
export class ContractCoveredComponent implements OnInit {

  	public contractCovereds: Array<CoveredDetails> = null
	public contractId: string = ""
	public contractStatus: string = ""
	public currentCovered: CoveredDetails = null

	constructor(
		public router: Router,
		private activatedRoute: ActivatedRoute,
		public _contract: ContractService,
		public _utilities: UtilitiesService
	) { }

	ngOnInit() {
		const urlParams = combineLatest(
			this.activatedRoute.params,
			this.activatedRoute.data,
			this.activatedRoute.queryParams,
			(params, queryParams, data) => ({ ...params, ...queryParams, ...data})
	    )

		urlParams.subscribe(
		 	routeParams => {
		 		this.contractId = routeParams.id
		 		this.contractStatus = routeParams.status

		 		if(this.contractId != "") {
					this._contract.GetContractCoveredDetails(this.contractId, this.contractStatus)
							.subscribe(
								resp => {						
									if(resp && resp.State === true){							
										this.contractCovereds = resp.Covereds

										if(this.contractCovereds !== null && this.contractCovereds !== undefined && this.contractCovereds.length > 0) {
											this.currentCovered = this.contractCovereds[0]
										}
									}
									else{
										this._utilities.showErrorWithPromise("Il y a eu une erreur.")
											.then(
												() => {
													this.router.navigate(["contracts"])
												}
											)
											.catch(
												() => {
													this.router.navigate(["contracts"])
												}
											)
										
									}
								},
								Error => {
									console.log(Error)
									this._utilities.showErrorWithPromise("Il y a eu une erreur.")
										.then(
											() => {
												this.router.navigate(["contracts"])
											}
										)
										.catch(
											() => {
												this.router.navigate(["contracts"])
											}
										)
								}
							)		 			
		 		}
		 		else {
		 			this._utilities.showErrorWithPromise("Il y a eu une erreur.")
		 				.then(
		 					() => {
		 						this.router.navigate(["contracts"])
		 					}
		 				)
		 				.catch(
		 					() => {
		 						this.router.navigate(["contracts"])
		 					}
		 				)					
		 		}
			}
		)
	}

	SwitchNavigation(covered: CoveredDetails) {
		if(this.currentCovered != covered) {
			this.currentCovered = covered
		}
	}

	NavigateTo(route: string) {
		this.router.navigate(["contracts/" + route + "/" + this.contractStatus + "/" + this.contractId])
	}

}
