import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { SimpleModalService } from "ngx-simple-modal"

import { FORMS } from '../app.forms'

import { CONFIG } from '../app.constants'

import { QssModalComponent } from '../components/qss-modal/qss-modal.component'
import { ModalComponent } from '../components/modal/modal.component'

export class responeMdel {
	Name: string
	Label: string
	ShowLabelOnSummary: boolean
	Values: Array<any>
	Value: any
	HasAffirmativeModal: boolean
	AffirmativeCommentValue: string
}

@Injectable({
	providedIn: 'root'
})
export class QssService {

	public qssForm: Array<any> = []
	public qssAvailableForm: Array<any> = []
	public qssQuestionsToEnable: Array<number> = [0]
	public ConstantForm: Array<string> = ["Home", "Identity", "Mandotory"]
	public disposable: any = null
	public correctIMC: boolean = true
	public addOptionalInInit: boolean = false

	constructor(
		private http: HttpClient,
		private simpleModalService: SimpleModalService
	) { }


	initQSS() {
		this.qssForm = JSON.parse(localStorage.getItem("qss_form"))
		this.qssAvailableForm = []

		this.qssForm.forEach(
			(step, idx) => {
				if(this.ConstantForm.indexOf(step.Type) !== -1) {
					this.qssAvailableForm.push(step)
				}					
			}
		)

		if(this.qssAvailableForm && this.qssAvailableForm.length>0) {
			if(this.qssAvailableForm[this.qssAvailableForm.length-1]) {
				if(this.qssAvailableForm[this.qssAvailableForm.length-1].Footer && this.qssAvailableForm[this.qssAvailableForm.length-1].Footer.Buttons && this.qssAvailableForm[this.qssAvailableForm.length-1].Footer.Buttons.length > 0) {

					let nextBtn: any = this.qssAvailableForm[this.qssAvailableForm.length-1].Footer.Buttons.find( btn => { return btn.Type == "Next" })

					if(nextBtn) {
						nextBtn.HasNextStep = false
						nextBtn.Caption = "Terminer"
						nextBtn.Action = "GoToSummary"							
					}
				}
			}	
		}
	}

	InitOptionalQuestions() {
		this.addOptionalInInit = true
	}

	GetAvailableStepLength(): number {
		if(this.qssAvailableForm === null || this.qssAvailableForm === undefined  || this.qssAvailableForm.length < 1) {
			this.initQSS()
		}

		return this.qssAvailableForm.length
	}

	UpdateValue(step: number, field: string, propertyName: string, value: any) {
		if(this.qssAvailableForm[step]) {
			if(this.qssAvailableForm[step].Body.Questions && this.qssAvailableForm[step].Body.Questions.length > 0) {
				let question: any = this.qssAvailableForm[step].Body.Questions.find( q => { return q.Name == field } )

				if(question) {
					question[propertyName] = value
				}
			}
		}
	}

	GetStep(index: number): any {
		return this.qssAvailableForm[index] || null
	}

	AddOptionalSteps(): number {
		if(this.qssAvailableForm === null || this.qssAvailableForm === undefined  || this.qssAvailableForm.length < 1) {
			this.initQSS()
		}

		if(this.ConstantForm.indexOf("Optional") == -1) {
			this.ConstantForm.push("Optional")

			if(this.qssAvailableForm && this.qssAvailableForm.length>0) {
				if(this.qssAvailableForm[this.qssAvailableForm.length-1]) {
					if(this.qssAvailableForm[this.qssAvailableForm.length-1].Footer && this.qssAvailableForm[this.qssAvailableForm.length-1].Footer.Buttons && this.qssAvailableForm[this.qssAvailableForm.length-1].Footer.Buttons.length > 0) {
						
						let nextBtn: any = this.qssAvailableForm[this.qssAvailableForm.length-1].Footer.Buttons.find( btn => { return btn.Type == "Next" })

						if(nextBtn) {
							nextBtn.HasNextStep = true
							nextBtn.Caption = "Suivant"
							nextBtn.Action = "GoToNextStep"							
						}
					}
				}	
			}

			this.qssForm.forEach(
				(step, idx) => {
					if(step.Type == "Optional") {
						this.qssAvailableForm.push(step)
					}					
				}
			)

			if(this.qssAvailableForm && this.qssAvailableForm.length > 0) {
				if(this.qssAvailableForm[this.qssAvailableForm.length-1]) {
					if(this.qssAvailableForm[this.qssAvailableForm.length-1].Footer && this.qssAvailableForm[this.qssAvailableForm.length-1].Footer.Buttons && this.qssAvailableForm[this.qssAvailableForm.length-1].Footer.Buttons.length > 0) {

						let nextBtn: any = this.qssAvailableForm[this.qssAvailableForm.length-1].Footer.Buttons.find( btn => { return btn.Type == "Next" })

						if(nextBtn) {
							nextBtn.HasNextStep = false
							nextBtn.Caption = "Terminer"
							nextBtn.Action = "GoToSummary"							
						}
					}
				}	
			}
		}

		return this.GetAvailableStepLength()
	}

	CheckOptionalIsRequired(): boolean {
		let AllAnswersAreNo: boolean = true

		this.qssAvailableForm
			.forEach(
				form => {	
					if(form && form.Type == "Mandotory") {
						if(form.Body && form.Body.Questions) {
							form.Body.Questions
								.forEach(
									question => {
										if(question.FieldValue == "Oui") {
											AllAnswersAreNo = false
										}
									}
								)
						}
					}
				}
			)

		return AllAnswersAreNo
	}

	RemoveOptionalSteps(): number {	
		if(this.ConstantForm.indexOf("Optional") !== -1) {
			this.ConstantForm = this.ConstantForm.filter( item => { return item != "Optional" })
			this.qssAvailableForm = this.qssAvailableForm.filter( item => { return item.Type != "Optional" })

			if(this.qssAvailableForm && this.qssAvailableForm.length>0) {
				if(this.qssAvailableForm[this.qssAvailableForm.length-1]) {
					if(this.qssAvailableForm[this.qssAvailableForm.length-1].Footer && this.qssAvailableForm[this.qssAvailableForm.length-1].Footer.Buttons && this.qssAvailableForm[this.qssAvailableForm.length-1].Footer.Buttons.length > 0) {

						let nextBtn: any = this.qssAvailableForm[this.qssAvailableForm.length-1].Footer.Buttons.find( btn => { return btn.Type == "Next" })

						if(nextBtn) {
							nextBtn.HasNextStep = false
							nextBtn.Caption = "Terminer"
							nextBtn.Action = "GoToSummary"							
						}
					}
				}	
			}
		}

		return this.GetAvailableStepLength()
	}

	GetAvailableForm(): Array<responeMdel> {
		let summary: Array<responeMdel> = []

		this.qssAvailableForm.forEach(
			item => {
				if(item.Body && item.Body.Questions) {
					item.Body.Questions.forEach(
						question => {
							let resp: responeMdel = new responeMdel()
								resp.Name = question.Name
								resp.Label = question.Label
								resp.ShowLabelOnSummary = question.ShowLabelOnSummary
								resp.Values = question.Values
								resp.Value = question.FieldValue
								resp.HasAffirmativeModal = question.HasAffirmativeModal
								resp.AffirmativeCommentValue = question.AffirmativeCommentValue

							summary.push(resp)
						}
					)
				}
			}
		)




		return summary
	}

	GetFullIdentity(): string {

		let tempArr: Array<any> = this.qssAvailableForm.filter( item => { return item.Type == "Identity" } )
		let Gender: string = ""
		let FirstName: string = ""
		let LastName: string = ""

		if(tempArr.length>0) {
			tempArr.forEach(
				item => {
					if(item.Body && item.Body.Questions){
						item.Body.Questions.forEach(
							it => {
								if(it.Name == "Gender") { Gender = it.FieldValue}
								if(it.Name == "FirstName") { FirstName = it.FieldValue}
								if(it.Name == "LastName") { LastName = it.FieldValue}
							}
						)
					}					
				}
			)
		}
		return Gender + " " + FirstName + " " + LastName
	}

	GetIMCType(IMC: number): any {
		let IMCType: { level: string, text: string } = { level: "", text: "" }

		if(IMC > 40) {
			IMCType.level = "Plus de 40"
			IMCType.text = "Obésité morbide ou massive"
		}

		if(IMC >= 35 && IMC <= 40) {
			IMCType.level = "De 35 à 40"
			IMCType.text = "Obésité sévère"
		}

		if(IMC >= 30 && IMC < 35) {
			IMCType.level = "De 30 à 35"
			IMCType.text = "Obésité modérée"
		}

		if(IMC >= 25 && IMC < 30) {
			IMCType.level = "De 25 à 30"
			IMCType.text = "Surpoids"
		}

		if(IMC >= 18.5 && IMC < 25) {
			IMCType.level = "De 18.5 à 25"
			IMCType.text = "Corpulence normale"
		}

		if(IMC >= 16.5 && IMC < 18.5) {
			IMCType.level = "De 16.5 à 18.5"
			IMCType.text = "Maigreur"
		}

		if(IMC < 16.5) {
			IMCType.level = "Moins de 16.5"
			IMCType.text = "Famine"
		}

		return IMCType
	}


	// ***************************************
	// *********        HTTP         *********
	// ***************************************
	CheckUserAuth(userId: string, qssToken: string): Observable<any> {
		return this.http.post(`${CONFIG.API[CONFIG.ENV]}/questionnaire/auth`, 
            { userId, qssToken })
            .pipe(
                map(
                    resp => { 
                    	return resp 
                    }
                )
            )
	}

	ResendQSS(quotationId : string){
		return this.http.get(`${CONFIG.API[CONFIG.ENV]}/questionnaire/resend/`+ quotationId)
			  .pipe(
				  map(
					  resp => { 
						  return resp 
					  }
				  )
			  )
	}

	SendSecurityCode(userId: number, qssToken: string, code: string): Observable<any> {
		return this.http.post(`${CONFIG.API[CONFIG.ENV]}/questionnaire/validation`, 
            { userId, qssToken, code })
            .pipe(
                map(
                    resp => { 
                    	return resp 
                    }
                )
            )
	}

	ReSendSecurityCode(userId: number, qssToken: string): Observable<any> {
		return this.http.post(`${CONFIG.API[CONFIG.ENV]}/questionnaire/resend`, 
            { userId, qssToken })
            .pipe(
                map(
                    resp => { 
                    	return resp 
                    }
                )
            )
	}

	SendDatasForPdf(userId: number, qssToken: string, qssForm: any): Observable<any> {
		return this.http.post(`${CONFIG.API[CONFIG.ENV]}/questionnaire/send`, 
            { userId, qssToken, qssForm })
            .pipe(
                map(
                    resp => { 
                    	return resp 
                    }
                )
            )
	}

	CheckSignValidity(userId: string, qssToken: string): Observable<any> {
		return this.http.get(`${CONFIG.API[CONFIG.ENV]}/questionnaire/sign/validity/` + userId + `/` + qssToken)
            .pipe(
                map(
                    resp => { 
							console.log(resp)

                    	return resp 
                    }
                )
            )
	}

	StoreQss(userId: number, qssToken: string) {

		 
		

		
	


		return this.http.post(`${CONFIG.API[CONFIG.ENV]}/questionnaire/validation`, 
		{ userId, qssToken });
	}


	// ***************************************
	// *********        MDOAL        *********
	// ***************************************
	showQssCommentModal(question: string, answer: string): Promise<string | boolean> {

    	return new Promise(
    		(resolve, reject) => {

		        let disposable = this.simpleModalService.addModal(QssModalComponent, {
		        	question: question,
		        	answer: answer
		        })
	            .subscribe(
	            	(data)=>{
		                if(data) {
		                    resolve(data)
		                }
		                else {
		                    reject(false)
		                }
		            }
		        )
    		}
    	)
    }

    showMessage(title: string, message: string): Promise<boolean> {		
		return new Promise(
    		(resolve, reject) => {

		        if(this.disposable === null) {
			        this.disposable = this.simpleModalService.addModal(ModalComponent, {
						title: title,
						message: message,				
						hasCancel: false,
						cancelCaption: "",
						validCaption: "OK",
						fromQSS: true
			        })
			        .subscribe(
			        	() => {
			        		this.disposable = null
			        		resolve(true)
			        	}
			        )			
				}
    		}
    	)
    }
}
