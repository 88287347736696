import { Component, OnInit, HostListener } from '@angular/core'
import { Router, ActivatedRoute, ResolveStart } from '@angular/router'
import { combineLatest, Subject } from 'rxjs'
import { FormGroup, FormControl, Validators } from '@angular/forms'

import { CustomerDetails, CustomerEditableDatas, City } from '../../../../models/customers/customer.model'

import { CustomersService } from '../../../../services/customers.service'
import { CreationService } from '../../../../services/creation.service'
import { UtilitiesService } from '../../../../services/utilities.service'

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {

	public customer: CustomerDetails = null
	public customerEditableDatas: CustomerEditableDatas = new CustomerEditableDatas()
	public customerId: string = ""
	public editionMode: boolean = false
	public updatingCustomerLoading: boolean = false
	public isSubmit: boolean = false
	public fakeDropdownVisible: boolean = false
	public cities: Array<City> = []
	public countries: Array<any> = []
	public isCountriesLoading: boolean = false
	public tempValue: string = ""

	public CustomerForm: FormGroup = new FormGroup({
		Phone: new FormControl('', [Validators.required, Validators.pattern(/^(0)[6-7]([-. ]?[0-9]{2}){4}$/)]),
		Mail: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
		Address1: new FormControl('', [Validators.required]),
		Address2: new FormControl('', []),
		PostCode: new FormControl('', [Validators.required, Validators.pattern(/^([0-8][0-9]?[0-9]?[0-9]?[0-9]?|971[0-9]?[0-9]?|972[0-9]?[0-9]?|974[0-9]?[0-9]?|9[0-6][0-9]?[0-9]?[0-9]?)+$/)]),
		City: new FormControl('', [Validators.required]),
		Country: new FormControl('', [Validators.required])
	})

	constructor(
		public router: Router,
		private activatedRoute: ActivatedRoute,
		public _customer: CustomersService,
		public _creation: CreationService,
		public _utilities: UtilitiesService
	) { }

	ngOnInit() {
		const urlParams = combineLatest(
			this.activatedRoute.params,
			this.activatedRoute.data,
			this.activatedRoute.queryParams,
			(params, queryParams, data) => ({ ...params, ...queryParams, ...data})
	    )

	    urlParams.subscribe(
		 	routeParams => {
		 		this.customerId = routeParams.id

		 		if(this.customerId != "") {
					this._customer.GetCustomerDetails(this.customerId)
							.subscribe(
								resp => {						
									if(resp && resp.State === true){							
										this.customer = resp.Customer
										this.customerEditableDatas = new CustomerEditableDatas(JSON.parse(JSON.stringify(resp.Customer)))

										Object.keys(this.customerEditableDatas)
											.forEach(
												item => {
													this.CustomerForm.controls[item].patchValue(this.customerEditableDatas[item])
													this.CustomerForm.controls[item].disable()
												}
											)
									}
									else{
										this._utilities.showErrorWithPromise("Il y a eu une erreur.")
											.then(
												() => {
													this.router.navigate(["customers"])
												}
											)
											.catch(
												() => {
													this.router.navigate(["customers"])
												}
											)										
									}
								},
								Error => {
									console.log(Error)
									this._utilities.showErrorWithPromise("Il y a eu une erreur.")
										.then(
											() => {
												this.router.navigate(["customers"])
											}
										)
										.catch(
											() => {
												this.router.navigate(["customers"])
											}
										)									
								}
							)		 			
		 		}
		 		else {
		 			this._utilities.showErrorWithPromise("Il y a eu une erreur.")
		 				.then(
		 					() => {
		 						this.router.navigate(["customers"])
		 					}
		 				)
		 				.catch(
		 					() => {
		 						this.router.navigate(["customers"])
		 					}
		 				)					
		 		}
			}
		)
	}

	NavigateTo(route: string) {
		this.router.navigate(["customers/" + route + "/" + this.customerId])
	}

	EditionMode() {
		this.editionMode = true
		this.isSubmit = false

		this.isCountriesLoading = true
		this._customer.GetCountries()
			.subscribe(
				countries => {
					this.isCountriesLoading = false
					this.countries = countries
				},
				Error => {
					this.isCountriesLoading = false
					console.log(Error)
				}
			)

		Object.keys(this.CustomerForm.controls)
			.forEach(
				item => {
					this.CustomerForm.controls[item].enable()
				}
			)

		this.tempValue = this.CustomerForm.controls["PostCode"].value

		this.PostCodeChanging()
	}

	CancelCustomerEdition() {
		this.editionMode = false
		this.isSubmit = false
		this.fakeDropdownVisible = false
		this.customerEditableDatas = new CustomerEditableDatas(JSON.parse(JSON.stringify(this.customer)))
		this.cities = []

		Object.keys(this.customerEditableDatas)
			.forEach(
				item => {
					this.CustomerForm.controls[item].patchValue(this.customerEditableDatas[item])
					this.CustomerForm.controls[item].disable()
				})
	}

	SaveCustomerEdition() {
		this.isSubmit = true

		if(this.CustomerForm.valid) {
			this.updatingCustomerLoading = true

			let tempCustomerDatas: CustomerEditableDatas = new CustomerEditableDatas()
			Object.keys(this.CustomerForm.controls)
				.forEach(
					item => {
						tempCustomerDatas[item] = this.CustomerForm.controls[item].value
					}

			)

			this._customer.UpdateCustomerDetails(this.customer.Id, tempCustomerDatas)
				.subscribe(
					resp => {
						this.updatingCustomerLoading = false

						if(resp && resp.State === true){	
							this.editionMode = false	
							this.isSubmit = false
							this.fakeDropdownVisible = false
							this.cities = []

							Object.keys(resp.CustomerEditableDatas)
								.forEach(
									item => {
										this.customer[item] = resp.CustomerEditableDatas[item]
										this.CustomerForm.controls[item].patchValue(resp.CustomerEditableDatas[item])
										this.CustomerForm.controls[item].disable()
									}
								)
						}
						else{
							this._utilities.showError("Il y a eu une erreur.")						
						}
					},
					Error => {
						console.log(Error)

						this.updatingCustomerLoading = false
						this._utilities.showError("Il y a eu une erreur.")
					}
				)			
		}
		else {
			Object.keys(this.CustomerForm.controls)
				.forEach(
					item => {
						console.log(item, this.CustomerForm.controls[item].errors);
					}
				)
		}		
	}

	PostCodeChanging() {
		if(this.tempValue !== this.CustomerForm.controls["PostCode"].value) {
			this.CustomerForm.controls["City"].patchValue(null)
		}

		this.tempValue = this.CustomerForm.controls["PostCode"].value || null

		if(this.tempValue !== null && this.tempValue !== "") {
			if(this.tempValue.length > 2) {
				this._customer.GetCities(this.tempValue)
					.subscribe(
						resp => {
							this.cities = resp
						},
						Error => {
							console.log(Error);
						}
					)
			}
		}
	}

	onCityChange(city: any) {
		this.CustomerForm.controls["PostCode"].patchValue(city.code)
		this.CustomerForm.controls["Country"].patchValue(city.country)
	}

	ShowFakeDropDown($event) {
    	$event.preventDefault()
    	$event.stopPropagation()
    	this.fakeDropdownVisible = !this.fakeDropdownVisible
    }

    fakeDropdownItemSelection(elt) {
    	if(elt) {
    		this.CustomerForm.controls["PostCode"].patchValue(elt.code)
    		this.CustomerForm.controls["City"].patchValue(elt.city)
    		this.CustomerForm.controls["Country"].patchValue(elt.country)
    	}
    }

    @HostListener('document:click', ['$event']) clickedOutside($event){
    	if(this.fakeDropdownVisible == true) {
			this.fakeDropdownVisible = false
    	}
  	}

}
