import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'

import { CONFIG } from '../app.constants'

@Injectable({
	providedIn: 'root'
})
export class LibraryService {

	constructor(
		private http: HttpClient
    ) { }


	// HTTP
    GetDocuments(): Observable<any> {
        return this.http.get<any>(`${CONFIG.API[CONFIG.ENV]}/library`)
            .pipe(
                map(
                    resp => {
                        return resp
                    }
                )
            )
    }
}
