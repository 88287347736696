import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { combineLatest } from 'rxjs'

import { QssService } from '../../../services/qss.service'



@Component({
  selector: 'app-qss-error',
  templateUrl: './qss-error.component.html',
  styleUrls: ['./qss-error.component.scss']
})
export class QssErrorComponent implements OnInit {

	public type: string = ""
	public message: string = ""
	public userId: number
	public userToken: string = ""

	constructor(
		private activatedRoute: ActivatedRoute,
		private _QSS: QssService
	) { }

  ngOnInit() {
  	const urlParams = combineLatest(
			this.activatedRoute.params,
			this.activatedRoute.data,
			this.activatedRoute.queryParams,
			(params, queryParams, data) => ({ ...params, ...queryParams, ...data})
	    )

  	urlParams.subscribe(
		routeParams => {
			console.log(routeParams)
			this.type = routeParams.type

			console.log(this.type);

			switch (this.type) {
				case "done":
					this.message = "Vous avez déjà répondu au questionnaire.<BR /><BR />Merci de contacter votre courtier pour plus d'informations."
					break

				case "forbidden":
					this.message = "Vous n'avez pas accès à ce questionnaire.<BR /><BR />Merci de contacter votre courtier pour plus d'informations."
					break

				case "cancel":
					this.message = "Vous avez annuler la procédure de signature du questionnaire.<BR /><BR />Merci de contacter votre courtier pour plus d'informations."
					break

				case "succes":
					this.userId = parseInt(localStorage.getItem("u_qss_uid"))
					this.userToken = localStorage.getItem("qss_uid")
					this.userToken=this.userToken.toString()
					
					if(this.userId === NaN || this.userId === null || this.userId === undefined || this.userId === 0 || this.userToken === null || this.userToken === undefined || this.userToken == "") {
						this.message = "Vous n'avez pas accès à ce questionnaire.<BR /><BR />Merci de contacter votre courtier pour plus d'informations."
					}
					else {
						this.message = "Chargement..."
						
						this._QSS.StoreQss(this.userId, this.userToken)
							
							
									this.message = "Nous vous confirmons que vous avez bien signé le questionnaire médical."
								
								Error => {

									
									this.message = "Il y a eu une erreur.<BR /><BR />Merci de contacter votre courtier pour plus d'informations."
								}
							
					}

					break
				
				default:
				

					this.message = "Il y a eu une erreur.<BR /><BR />Merci de contacter votre courtier pour plus d'informations."
					break;
			}
		}
  	)		
  }

}


