export class Project {
	steps: Array<Array<Step>>

	isDone: boolean
	isEnabled: boolean

	constructor() {
		this.steps = new Array<Array<Step>>()
		this.isDone = false
		this.isEnabled = false
	}
}

export class Step {
	id: number
	fields: Array<Field>

	isDone: boolean
	isEnabled: boolean

	constructor() {
		this.id = 0
		this.fields = new Array<Field>()
		this.isDone = false
		this.isEnabled = false
	}
}

export class Field {
	name: string
	value: any
	options: Array<FieldOption>	

	constructor() {
		this.name = ""
		this.value = null
		this.options = new Array<FieldOption>()		
	}
}

export class FieldOption {
	name: string
	value: any

	constructor() {
		this.name = ""
		this.value = null
	}
}