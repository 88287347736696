import { NgModule }      from '@angular/core'
import { FormsModule } from '@angular/forms'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { BrowserModule } from '@angular/platform-browser'
import { ReactiveFormsModule }    from '@angular/forms'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import "jquery"
import { Select2Module } from 'ng2-select2'
import { NgSelectModule } from '@ng-select/ng-select'
import { SimpleModalModule } from 'ngx-simple-modal'
import { NgxMaskModule } from 'ngx-mask'
import { PdfViewerModule } from 'ng2-pdf-viewer'
import { FilterPipeModule } from 'ngx-filter-pipe'
import { TextareaAutosizeModule } from 'ngx-textarea-autosize'
import { AgGridModule } from 'ag-grid-angular'
import { NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer'
import { DatePipe } from '@angular/common'
// used to create fake backend
import { fakeBackendProvider } from './helpers/fake-backend.service'

import { AppComponent }  from './app.component'
import { routing } from './app.routing' 

import { JwtInterceptorService }  from './helpers/jwt.interceptor.service'
import { ErrorInterceptorService } from './helpers/error.interceptor.service'
import { LoginComponent } from './components/login/login.component'
import { PasswordResetComponent } from './components/password-reset/password-reset.component'
import { HomeComponent } from './components/home/home.component'
    import { MainMenuComponent } from './components/home/main-menu/main-menu.component'
    import { CreationComponent } from './components/home/creation/creation.component'
        import { MainTimelineComponent } from './components/home/creation/main-timeline/main-timeline.component'
        import { ProjectComponent } from './components/home/creation/project/project.component'   
    import { ContractsComponent } from './components/home/contracts/contracts.component'
        import { ContractsListingComponent } from './components/home/contracts/contracts-listing/contracts-listing.component';
        import { ContractDetailsComponent } from './components/home/contracts/contract-details/contract-details.component'
        import { ContractTrackingComponent } from './components/home/contracts/contract-tracking/contract-tracking.component';
        import { ContractDocumentsComponent } from './components/home/contracts/contract-documents/contract-documents.component';
    import { CustomersComponent } from './components/home/customers/customers.component'
    import { ActivitiesComponent } from './components/home/activities/activities.component'
    import { LibraryComponent } from './components/home/library/library.component'
    import { ContactsComponent } from './components/home/contacts/contacts.component'
import { UserProfilComponent } from './components/home/user-profil/user-profil.component'
import { FormInputComponent } from './components/form-input/form-input.component'
import { RightBarComponent } from './components/home/right-bar/right-bar.component'
import { ModalComponent } from './components/modal/modal.component'
import { ModalDocumentComponent } from './components/modal-document/modal-document.component'
import { ModalIncrementsComponent } from './components/modal-increments/modal-increments.component'
import { QSSComponent } from './components/qss/qss-form/qss.component'
import { NotFoundComponent } from './components/not-found/not-found.component'
import { QssModalComponent } from './components/qss-modal/qss-modal.component'
import { QssErrorComponent } from './components/qss/qss-error/qss-error.component'
import { QssSummaryComponent } from './components/qss/qss-summary/qss-summary.component'
import { QssValidationComponent } from './components/qss/qss-validation/qss-validation.component'
import { DocumentsErrorComponent } from './components/documents/documents-error/documents-error.component';
import { DocumentsValidationComponent } from './components/documents/documents-validation/documents-validation.component';
import { CustomersListingComponent } from './components/home/customers/customers-listing/customers-listing.component';
import { CustomerDetailsComponent } from './components/home/customers/customer-details/customer-details.component';
import { CustomerContractsComponent } from './components/home/customers/customer-contracts/customer-contracts.component';
import { CustomerEventsComponent } from './components/home/customers/customer-events/customer-events.component';
import { ContractScheduleComponent } from './components/home/contracts/contract-schedule/contract-schedule.component';
import { ContractCoveredComponent } from './components/home/contracts/contract-covered/contract-covered.component'

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        routing,
        HttpClientModule,
        Select2Module,NgSelectModule,
        SimpleModalModule,
        PdfViewerModule,
        FilterPipeModule,
        TextareaAutosizeModule,
        NgxExtendedPdfViewerModule,
        AgGridModule.withComponents(null),
        NgxMaskModule.forRoot(),
        NgbModule.forRoot()
    ],
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        MainTimelineComponent,
        MainMenuComponent,
        ProjectComponent,
        CreationComponent,
        ContractsComponent,
        CustomersComponent,
        ActivitiesComponent,
        PasswordResetComponent,
        UserProfilComponent,
        FormInputComponent,
        RightBarComponent,
        ModalComponent,
        ModalDocumentComponent,
        ModalIncrementsComponent,
        QSSComponent,
        NotFoundComponent,
        QssModalComponent,
        QssErrorComponent,
        QssSummaryComponent,
        QssValidationComponent,
        ContractDetailsComponent,
        ContractTrackingComponent,
        ContractDocumentsComponent,
        ContractsListingComponent,
        ContactsComponent,
        LibraryComponent,
        DocumentsErrorComponent,
        DocumentsValidationComponent,
        CustomersListingComponent,
        CustomerDetailsComponent,
        CustomerContractsComponent,
        CustomerEventsComponent,
        ContractScheduleComponent,
        ContractCoveredComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
        DatePipe,
        // provider used to create fake backend
        //fakeBackendProvider
    ],
    entryComponents: [
        ModalComponent,
        ModalDocumentComponent,
        ModalIncrementsComponent,
        QssModalComponent
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }