import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { combineLatest } from 'rxjs'
import { first } from 'rxjs/operators'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

import { AuthenticationService } from '../../services/authentication.service'

import { FORMS } from '../../app.forms'


@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

	public isTokenValid: boolean
	public loading: boolean
	public success: boolean
	public userId: number
	public token: string
	error: any = {type: '', message: ''}
	forgetForm: FormGroup  = new FormGroup({})
	submitted: boolean
    forgetControls: any

	constructor(
		private formBuilder: FormBuilder,
		private activatedRoute: ActivatedRoute,
		public router: Router,
		public _auth: AuthenticationService
	) { }

	ngOnInit() {
		this.loading = true
		this.isTokenValid = false
		this.success = false

        this.forgetControls = FORMS.Auth.Password.Changing 
        this.forgetControls.Controls
            .forEach( 
                item => {

                    this.forgetForm.addControl(item.Name, this.formBuilder.control([]))

                    let valids: Array<any> = []
                    item.Validators
                        .forEach(
                            v => {
                                valids.push(v)
                            }
                        )
                    this.forgetForm.controls[item.Name].setValidators(Validators.compose(valids))

                    this.forgetForm.controls[item.Name].setValue(item.Default)

                }
            )
		
		const urlParams = combineLatest(
	      this.activatedRoute.params,
	      this.activatedRoute.queryParams,
	      (params, queryParams) => ({ ...params, ...queryParams})
	    )

	    // Subscribe to the single observable, giving us both
	    urlParams.subscribe(routeParams => {

	    	this.userId = routeParams.id
	    	this.token = routeParams.token
        	this._auth.resetValidity(this.userId, this.token)
        		.pipe(first())
	            .subscribe(
	                data => {

	                    if(data && data.state === true){
	                    	this.isTokenValid = true
	                    	this.loading = false
	                    }
	                },
	                error => {
	                	this.loading = false
	                    console.log(error)        
	                    this.error = error            
	                })
	    })
	}

	get f() { return this.forgetForm.controls; }

    onSubmit() {
        this.submitted = true
        this.error = {type: '', message: ''}

        if (this.forgetForm.invalid) {
            return
        }

        if (this.loading === true) {
            return
        }

        if(this.f.password1.value !== this.f.password2.value){
        	this.error = { type: '', message: 'Les mots de passe ne sont pas identiques' }
        	return
        }

        this.loading = true
        this._auth.changePassword(this.userId, this.token, this.f.password1.value)
            .pipe(first())
            .subscribe(
                data => {
                    if(data && data.state === true){
                    	this.success = true
                    	
                    }
                    else{
                    	this.error = { type: '', message: 'Problème durant le changement de mot de passe. Veuillez réessayer' }
                    }
                },
                error => {
                    this.error = error
                    this.loading = false
                    this.submitted = false
                }
            )
    }

    backToLogin() {
    	this.router.navigate(["/login"])
    }
}
