import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable()
export class JwtInterceptorService implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let user_token: string = localStorage.getItem("t_uid") || ""
        let user_id: string = localStorage.getItem("u_uid") || ""       

        request = request.clone({
            setHeaders: { 
                "Content-Type": "application/json; charset=utf-8",
                "Token": user_token,
                "U_uid": user_id
            }
        })


        return next.handle(request)
    }
}