import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute, ResolveStart } from '@angular/router'

import { CONFIG } from '../../../app.constants'

import { CreationService } from '../../../services/creation.service'
import { AuthenticationService } from '../../../services/authentication.service'
import { UtilitiesService } from '../../../services/utilities.service'


@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

	public currentView: string = "creation"
	public currentVersion: string

	constructor(
		private router: Router,
        private route: ActivatedRoute,
        public _creation: CreationService,
        public _auth: AuthenticationService,
        public _utilities: UtilitiesService
	) { }

	ngOnInit() {
		this.currentVersion = CONFIG.VERSION
		
		this.router.events.subscribe(e => { 
		  if (e instanceof ResolveStart) {	
		  	let currentPath: string = e.urlAfterRedirects

		  	if(currentPath !== "") {
		  		this.currentView = currentPath.slice(1, currentPath.length).split("/")[0] 
		  	}
		  }
		})
	}

	GoToView(view) {
		if(this.currentView === "creation") {
			if(this.currentView != view) {
				if(this._creation.GetCurrentFormModifiedFieldCount() >= 3 || this._creation.GetQId() != "") {
					this._utilities.showConfirm("Etes-vous sûr de vouloir continuer ? Les informations saisies seront perdues.")
						.then(
							() => {
	        					this._creation.Clear()
	        					this.currentView = view
								this.router.navigate([view])
							}
						)
						.catch( () => {} )
				}
				else {
					this._creation.Clear()
					this.currentView = view
					this.router.navigate([view])					
				}
			}	
		}
		else {
			this._creation.Clear()
			this.currentView = view
			this.router.navigate([view])					
		}			
	}

	logout(): void {
        this._auth.logout()
        this._creation.Clear()

        this.router.navigate(["login"])
    }

    logQ() {
    	this._creation.Log()
    }
}
