import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ResolveStart } from '@angular/router'
import { combineLatest, Subject } from 'rxjs'

import { Document } from '../../../../models/contracts/contract.model'

import { ContractService } from '../../../../services/contract.service'
import { UtilitiesService } from '../../../../services/utilities.service'

@Component({
	selector: 'app-contract-tracking',
	templateUrl: './contract-tracking.component.html',
	styleUrls: ['./contract-tracking.component.scss']
})
export class ContractTrackingComponent implements OnInit {

	public contract: any = null
	public contractId: string = ""
	public contractStatus: string = ""
	public currentBorrower: any = null

	constructor(
		public router: Router,
		private activatedRoute: ActivatedRoute,
		public _contract: ContractService,
		public _utilities: UtilitiesService
	) { }

	ngOnInit() {
		const urlParams = combineLatest(
			this.activatedRoute.params,
			this.activatedRoute.data,
			this.activatedRoute.queryParams,
			(params, queryParams, data) => ({ ...params, ...queryParams, ...data})
	    )

		urlParams.subscribe(
		 	routeParams => {
		 		this.contractId = routeParams.id
		 		this.contractStatus = routeParams.status
	 		
		 		if(this.contractId != "") {
					this._contract.GetContractTracking(this.contractId, this.contractStatus)
							.subscribe(
								resp => {						
									if(resp && resp.State === true){							
										this.contract = resp.Contract

										if(this.contract && this.contract.Borrowers.length > 0) {
											this.currentBorrower = this.contract.Borrowers[0]
										}

										// console.log(this.contract)
									}
									else{
										this._utilities.showErrorWithPromise("Il y a eu une erreur.")
											.then(
												() => {
													this.router.navigate(["contracts"])
												}
											)
											.catch(
												() => {
													this.router.navigate(["contracts"])
												}
											)
									}
								},
								Error => {
									console.log(Error)
									this._utilities.showErrorWithPromise("Il y a eu une erreur.")
										.then(
											() => {
												this.router.navigate(["contracts"])
											}
										)
										.catch(
											() => {
												this.router.navigate(["contracts"])
											}
										)
								}
							)		 			
		 		}
		 		else {
		 			this._utilities.showErrorWithPromise("Il y a eu une erreur.")
						.then(
							() => {
								this.router.navigate(["contracts"])
							}
						)
						.catch(
							() => {
								this.router.navigate(["contracts"])
							}
						)
		 		}


			}
		)
	}

	counter(item: number) {
		return new Array(item)
	} 

	SwitchNavigation(item: any) {
		this.currentBorrower = item
	}

	removeFile(docToUpload: Document) {
		this._utilities.showConfirm("Etes-vous sur de vouloir supprimer ce fichier ?")
			.then(
				resp => {
					if(resp === true) {
						this.removeFileConfirmed(docToUpload)
					}
				}
			)
			.catch( () => {} )
	}

	removeFileConfirmed(docToUpload: Document) {
		this._contract.DeleteDocument(this.contractId, docToUpload.Id)
			.subscribe(
				resp => {
					docToUpload.Uploaded = false
					docToUpload.GEDRef = ""
					docToUpload.UploadedDate = ""
					docToUpload.File = null
				},
				Error => {
					this._utilities.showError("Il y a eu une erreur.")
				}
			)
	}

	openFileSelection(docToUpload: Document) {
		if(docToUpload === null || (docToUpload && docToUpload.File === null)) {
			document.getElementById("iptFile" + docToUpload.Type).click() 			
		}
		else {
			this._utilities.showConfirm("Etes-vous sur de vouloir modifier ce fichier ?")
			.then(
				resp => {
					if(resp === true) {
						this._contract.DeleteDocument(this.contractId, docToUpload.Id)
							.subscribe(
								resp => {
									docToUpload.Uploaded = false
									docToUpload.GEDRef = ""
									docToUpload.UploadedDate = ""
									docToUpload.File = null
									
									document.getElementById("iptFile" + docToUpload.Type).click() 	
								},
								Error => {
									this._utilities.showError("Il y a eu une erreur.")
								}
							)
					}
				}
			)
			.catch( () => {} )
		}
	}

	openSelectedFile(docToUpload: Document) {
		this._utilities.showDocument(docToUpload.Name, docToUpload.File)
	}

	onFileSelected(event, docToUpload: Document) {

		console.log(docToUpload)
    	if(event.target.files.length > 0) {

    		docToUpload.Loading = true
	    		  
	    	let reader = new FileReader()
				reader.readAsDataURL(event.target.files[0]) 

				reader.onload = () => {   
					let tempDocument: Document = new Document(docToUpload)
						tempDocument.File = reader.result

					let contractId: string = this.contractId
					if(this.currentBorrower.ContractId !== null && this.currentBorrower.ContractId !== undefined && this.currentBorrower.ContractId !== "") {
						contractId = this.currentBorrower.ContractId
					}

					this._contract.UploadDocument(contractId, tempDocument)
						.subscribe(
							resp => {
								docToUpload.Loading = false

								this.currentBorrower.DocumentsToUpload.forEach(
									(doc, idx) => {
										if(doc.Type == tempDocument.Type) {
											tempDocument.GEDRef = resp.GEDRef
											tempDocument.CreatedDate = resp.CreatedDate
											tempDocument.Id = resp.Id
											tempDocument.Name = resp.Name
											tempDocument.Sign = resp.Sign
											tempDocument.Uploaded = resp.Uploaded
											tempDocument.UploadedDate = resp.UploadedDate											

											this.currentBorrower.DocumentsToUpload[idx] = tempDocument
										}
									}
								)
							},
							Error => {
								docToUpload.Loading = false

								if(Error == 404) {
									this._utilities.showError("Ce document n'est pas attendu pour ce type de contrat.")
								}
								else {
									this._utilities.showError("Il y a eu une erreur.")
								}
							}
						)
				}	    	
    	}
    }

	NavigateTo(route: string) {
		this.router.navigate(["contracts/" + route + "/" + this.contractStatus + "/" + this.contractId])
	}

}
