import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { combineLatest } from 'rxjs'

import {DocumentsService} from '../../../services/documents.service'

@Component({
  selector: 'app-documents-error',
  templateUrl: './documents-error.component.html',
  styleUrls: ['./documents-error.component.scss']
})
export class DocumentsErrorComponent implements OnInit {

	public type: string = ""
	public message: string = ""
	public userId: number
	public documentToken: string = ""
    
    constructor(
		private activatedRoute: ActivatedRoute,
		private _DocumentsService : DocumentsService
	) { }

	ngOnInit() {
	  	const urlParams = combineLatest(
				this.activatedRoute.params,
				this.activatedRoute.data,
				this.activatedRoute.queryParams,
				(params, queryParams, data) => ({ ...params, ...queryParams, ...data})
		    )

	  	urlParams.subscribe(
			routeParams => {
				this.type = routeParams.type

				console.log(this.type);

				switch (this.type) {
					case "cancel":
						this.message = "Vous avez annuler la procédure de signature du document.<BR /><BR />Merci de contacter votre courtier pour plus d'informations."
						break

					case "succes":
						//Appel Api pour validation de la signature du document
						this.documentToken = localStorage.getItem("document_token")
						this.message = "Chargement..."
						this._DocumentsService.SignDocument(this.documentToken)
							.subscribe(
									resp => {
										this.message = "Nous vous confirmons que vous avez bien signé les documents."
									},
									Error => {
										this.message = "Il y a eu une erreur.<BR /><BR />Merci de contacter votre courtier pour plus d'informations."
									}
								)
						break
					
					default:
						this.message = "La signature a échouée.<BR /><BR />Merci de contacter votre courtier pour plus d'informations."
						break;
				}
			}
	  	)		
	}
}
