import { Component, OnInit, ElementRef } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { combineLatest } from 'rxjs'

import { DocumentsService } from '../../../services/documents.service'
import { CONFIG } from '../../../app.constants'


declare var universignSigInit: any

@Component({
  selector: 'app-documents-validation',
  templateUrl: './documents-validation.component.html',
  styleUrls: ['./documents-validation.component.scss']
})
export class DocumentsValidationComponent implements OnInit {

	public documentToken : string = ""

	public loading: boolean = false
  	constructor(
		public router: Router,
		private activatedRoute: ActivatedRoute,
		private _DocumentsService: DocumentsService,
		private elementRef: ElementRef
	) { }

	ngOnInit() {
		localStorage.clear()
		const urlParams = combineLatest(
			this.activatedRoute.params,
			this.activatedRoute.data,
			this.activatedRoute.queryParams,
			(params, queryParams, data) => ({ ...params, ...queryParams, ...data})
	    )
		
	    urlParams.subscribe(
			routeParams => {
				this.documentToken = routeParams.docToken

				console.log(this.documentToken)

				if(this.documentToken == "" || this.documentToken == null){
					this.router.navigate(["/documents/warning/forbidden"])
				}

				this.generateIframe(this.documentToken)	

			}
		)
	}

  	generateIframe(id: string) {
		const configuration: any = {};
		configuration.redirectionMode = 'IN'

		universignSigInit('iframeContainerId', id, configuration,`${CONFIG.UNIVERSIGN[CONFIG.ENV]}`)

		localStorage.setItem("document_token",this.documentToken)
		
		window.addEventListener('pdsEvent', (e: CustomEvent) => {

            if(e && e.detail) {
            	if(e.detail.eventType == "begin") {
            		console.log("Signature started")
            	}

            	if(e.detail.eventType == "end") {
            		console.log("Signature ended")

            		//this.router.navigate(["/questionnaire/warning/success"])
            	}
            }
    	})
	}

}
