
export const FORMS = {

	Auth : {
		Login : {
			Name : "Login",
			Label : "Authentifiation",
			InitActions : [],
			HasRightBar : false,
			RightBar : {},
			Controls : [
				{
					Name : "username",
					Type : "text",
					Label : "Mon adresse e-mail professionnelle",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: false,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: true,
					Errors : {
						"required" : "L'email est requis",
						"pattern" : "L'email est invalide"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []				
				},
				{
					Name : "password",
					Type : "password",
					Label : "Mot de passe",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: true,
					Errors : {
						"required" : "Le mot de passe est requis",
						"pattern" : "Le mot de passe est invalide"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []			
				}
			]
		},
		Password : {
			Reset : {
				Name : "Reset",
				Label : "Réinitialisation",
				InitActions : [],
				HasRightBar : false,
				RightBar : {},
				Controls : [
					{
						Name : "emailForForgottenPassword",
						Type : "text",
						Label : "Mon adresse e-mail professionnelle",
						UpperCase : false,
						Capitalize: false,
						IgnoreCaseFormat: false,
						PlaceHolder : "",
						Default : "",
						ToHide: false,
						Values : [],
						HasRightIndicator: false,
						RightIndicatorType : "",
						RightIndicator : "",
						Validators : [
							{ Type : "required", Params : "" },
							{ Type : "email", Params : "" }
						],
						ShowErrorsUnderItem: true,
						Errors : {
							"required" : "L'email est requis",
							"pattern" : "L'email est invalide"
						},
						HasHelper : false,
						HelperPosition: "",
						HelperTitle: "",
						HelperBody: "",
						Actions : []				
					}
				]
			},
			Changing : {
				Name : "Changing",
				Label : "Modification du mot de passe",
				InitActions : [],
				HasRightBar : false,
				RightBar : {},
				Controls : [
					{
						Name : "password1",
						Type : "password",
						Label : "Nouveau mot de passe",
						UpperCase : false,
						Capitalize: false,
						IgnoreCaseFormat: true,
						PlaceHolder : "",
						Default : "",
						ToHide: false,
						Values : [],
						HasRightIndicator: false,
						RightIndicatorType : "",
						RightIndicator : "",
						Validators : [
							{ Type : "required", Params : "" }
						],
						ShowErrorsUnderItem: true,
						Errors : {
							"required" : "Le nouveau mot de passe est requis",
							"pattern" : "Le mot de passe est invalide"
						},
						HasHelper : false,
						HelperPosition: "",
						HelperTitle: "",
						HelperBody: "",
						Actions : []				
					},
					{
						Name : "password2",
						Type : "password",
						Label : "Confirmation du mot de passe",
						UpperCase : false,
						Capitalize: false,
						IgnoreCaseFormat: true,
						PlaceHolder : "",
						Default : "",
						ToHide: false,
						Values : [],
						HasRightIndicator: false,
						RightIndicatorType : "",
						RightIndicator : "",
						Validators : [
							{ Type : "required", Params : "" }
						],
						ShowErrorsUnderItem: true,
						Errors : {
							"required" : "La confirmation de mot de passe est requis",
							"pattern" : "Le mot de passe est invalide"
						},
						HasHelper : false,
						HelperPosition: "",
						HelperTitle: "",
						HelperBody: "",
						Actions : []				
					}
				]
			}
		}
	},

	quotation : [
		{
			Name : "ContractExtra",
			Label : "IKI emprunteur : nouveau projet",
			InitActions : [],
			HasRightBar : false,
			RightBar : {},
			HasNavigationBar: false,	
			NavigationBarActions: [],
			Controls : [
				{
					Name : "TypeProjet",
					Type : "card_select",
					CustomClass : "size-1 no-rightbar",
					Label : "Investissement",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					ReadOnly: false,
					Values : [
						{ Id: 0, Value: "principale", Label: "Résidence principale", Icon: "icon-house" },
						{ Id: 1, Value: "secondaire", Label: "Résidence secondaire", Icon: "icon-sea" },
						{ Id: 2, Value: "locatif", Label: "Investissement locatif", Icon: "icon-handshake" }
					],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le type de projet est requis"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []				
				},				
				{
					Name : "NatureProjet",
					Type : "card_select",
					CustomClass : "size-1 no-rightbar",
					Label : "Assurance",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Values : [
						{ Id: 0, Value: "adhesion", Label: "Nouvelle assurance", Icon: "" },
						{ Id: 1, Value: "substitution_hamon", Label: "Reprise assurance < 1 an<BR />(Loi Hamon)", Icon: "" },
						{ Id: 2, Value: "substitution_bourquin", Label: "Reprise assurance > 1 an<BR />(Loi Bourquin)", Icon: "" }
					],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "La nature du projet est requis"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []
				}
			],
			BtnLabels: {
				HasCancel: false,
				Cancel: "Abandonner",
				HasSaveAndQuit: false,
				SaveAndQuit: "Sauvegarder et quitter",
				HasGetAQuote: false,
				GetAQuote: "Obtenir un devis",
				HasPrevious: false,
				Previous: "Précédent",
				HasSubmit: true,
				Submit: "Suivant"
			},
			OnSubmit: [
				{
					Id : 0,
					Type : "ApiCall",
					ResultAction: "Next",
					Target: "", 
					TargetStep: "",
					Mapping : {
						HasMapping: true,
						MappingType: "Object",
						SourceStep : "ContractExtra",
						SourceModule: "quotation",
						SubValues : [
							{ 
								ResponseObjectName: "ContractExtra", 
								Name : "NatureProjet",
								FormName: "NatureProjet" ,
								Mapping: "NatureProjet", 
								FieldType: "FieldValue",
								Type: "string"
							},
							{ 
								ResponseObjectName: "ContractExtra", 
								Name : "TypeProjet", 
								FormName: "TypeProjet" ,
								Mapping: "TypeProjet", 
								FieldType: "FieldValue",
								Type: "string"
							}
						]
					}, 
					Params: { 
						Method: "PUT", 
						Url: "/quotations/{{quotation_id}}/contract_extra",
						VariableType: "FromService",
						VariableName: "quotation_id",
						HasVariable: true
					},
					HasWaitingPopup: false,
					WaitingPopup: {
						Text: ""
					}
				}
			],
			OnCancel: [
				{
					Id : 0,
					Type : "ApiCall",
					ResultAction: "Cancel",
					Target: "", 
					TargetStep: "",
					Mapping : { HasMapping: false }, 
					Params: { 
						Method: "PUT", 
						Url: "/quotations/{{quotation_id}}/cancel",
						VariableType: "FromService",
						VariableName: "quotation_id",
						HasVariable: true
					},
					HasWaitingPopup: false,
					WaitingPopup: {
						Text: ""
					}
				}
			],
			OnGetQuote: [],	
			OnBack: [],	
			HasSuccessMessage: false,
			OnSuccessMessage: {}			
		},
		{
			Name : "Loan",
			Label : "Caractéristiques du prêt",			
			InitActions : [
				{
					Id: 16, 
					Type: "AfterInit", 
					ResultAction : "setIndex", 
					Target: "LoanId", 
					TargetStep: "Loan",
					Value : "",	
					Property : "",
					Mapping : {},  
					ReplaceMethod: "",
					Params: [
						{ Type: "SameStepDetailsLength", Target: "quotation", TargetStep: "Garantees"}
					]
				},
				{
					Id: 0, 
					Type: "AfterInit", 
					ResultAction : "SetPropertyFromPreviousStep", 
					Target: "Amount", 
					TargetStep: "Loan",
					Value : "Montant",	
					Property : "Label",
					Mapping : {},  
					ReplaceMethod: "Always",
					Params: [
						{ 
							Operator : "=",
							CompareValueType: "fromPreviousStep",	
							CompareValueForm: "ContractExtra",
							CompareValueModule: "quotation",
							CompareValueField: "NatureProjet",				
							CompareValue : "adhesion"
						} 
					]
				},
				{
					Id: 1, 
					Type: "AfterInit", 
					ResultAction : "SetPropertyFromPreviousStep", 
					Target: "Amount", 
					TargetStep: "Loan",
					Value : "Capital restant dû",	
					Property : "Label",
					Mapping : {},  
					ReplaceMethod: "Always",
					Params: [
						{ 
							Operator : "=",
							CompareValueType: "fromPreviousStep",	
							CompareValueForm: "ContractExtra",
							CompareValueModule: "quotation",
							CompareValueField: "NatureProjet",				
							CompareValue : "substitution_hamon" 
						} 
					]
				},
				{
					Id: 2, 
					Type: "AfterInit", 
					ResultAction : "SetPropertyFromPreviousStep", 
					Target: "Amount", 
					TargetStep: "Loan",
					Value : "Capital restant dû",	
					Property : "Label",
					Mapping : {},  
					ReplaceMethod: "Always",
					Params:  [
						{ 
							Operator : "=",
							CompareValueType: "fromPreviousStep",	
							CompareValueForm: "ContractExtra",
							CompareValueModule: "quotation",
							CompareValueField: "NatureProjet",				
							CompareValue : "substitution_bourquin" 
						}
					] 
				},
				{
					Id : 3, 
					Type : "ApiCall_init", 
					ResultAction : "FillSelect", 
					Target: "Lender", 
					TargetStep: "Loan",
					Mapping : { Id: "LenderCode", Value: "LenderCode", Label: "RecName" }, 
					Params: { Method: "GET", Url: "/lenders" } 
				},		
				{
					Id: 21, 
					Type: "AfterInit", 
					ResultAction : "SetPropertyFromPreviousStep", 
					Target: "RateType", 
					TargetStep: "Loan",
					Value : "fixe",	
					Property : "Value",
					Mapping : {},  
					ReplaceMethod: "Always",
					Params:  [
						{ 
							Operator : "=",
							CompareValueType: "fromPreviousStep",	
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
							CompareValueField: "Kind",				
							CompareValue : "interest_free" 
						}
					] 
				},					
				{
					Id: 4, 
					Type: "ShowField_onInit", 
					ResultAction : "ShowField", 
					Target: "VariableMin",
					TargetStep: "Loan",
					Mapping : {}, 
					Params: { 
						Operator : "=",
						Value : "",	
						CompareValueType: "fromCurrentStep",	
						CompareValueForm: "Loan",
						CompareValueModule: "quotation",
						CompareValueField: "RateType",				
						CompareValue : "variable",

					} 
				},
				{
					Id: 5, 
					Type: "ShowField_onInit", 
					ResultAction : "ShowField", 
					Target: "VariableMax",
					TargetStep: "Loan",
					Mapping : {}, 
					Params: { 
						Operator : "=",
						Value : "",	
						CompareValueType: "fromCurrentStep",	
						CompareValueForm: "Loan",
						CompareValueModule: "quotation",
						CompareValueField: "RateType",				
						CompareValue : "variable",

					} 
				},
				{
					Id: 6, 
					Type: "AfterInit", 
					ResultAction : "SetProperty", 
					Target: "Kind",
					TargetStep: "Loan",
					Property: "HasTopRightButton",
					Value: true,
					Mapping : {}, 
					Params: [
						{ 
							Operator : "=",
							Value : 1,
							CompareValueType: "Fixe",	
							CompareValue : "graduated",
							CompareValueField: "Kind",				
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
						}
					] 
				},
				{
					Id: 14, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "Increments",
					TargetStep: "Loan",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" },
						{ Type : "Increments", Params : "" },
					],
					Params: [
						{ 
							Operator : "=",
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "Loan",							
							CompareValueModule: "quotation",
							CompareValueField: "Kind",				
							CompareValue : "graduated"
						}
					]
				},				
				{
					Id: 15, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "Increments",
					TargetStep: "Loan",
					Mapping : {}, 
					SetValidators: [
						{ Type : "clearValidators", Params : "" }
					],
					Params: [
						{ 
							Operator : "!=",
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "Loan",							
							CompareValueModule: "quotation",
							CompareValueField: "Kind",				
							CompareValue : "graduated"
						}
					]
				},
				// {
				// 	Id: 16, 
				// 	Type: "AfterInit", 
				// 	ResultAction : "SetOtherFieldValidators", 
				// 	Target: "VariableMin",
				// 	TargetStep: "Loan",
				// 	TargetModule: "quotation",
				// 	Mapping : {}, 
				// 	SetValidators: [
				// 		{ Type : "required", Params : "" }
				// 	],
				// 	Params: [
				// 		{ 
				// 			Operator : "=",
				// 			Value : "",	
				// 			CompareValueType: "fromCurrentStep",	
				// 			CompareValueForm: "Loan",
				// 			CompareValueModule: "quotation",
				// 			CompareValueField: "RateType",				
				// 			CompareValue : "variable",
				// 		}
				// 	]
				// },
				// {
				// 	Id: 17, 
				// 	Type: "AfterInit", 
				// 	ResultAction : "SetOtherFieldValidators", 
				// 	Target: "VariableMax",
				// 	TargetStep: "Loan",
				// 	TargetModule: "quotation",
				// 	Mapping : {}, 
				// 	SetValidators: [
				// 		{ Type : "required", Params : "" }
				// 	],
				// 	Params: [
				// 		{ 
				// 			Operator : "=",
				// 			Value : "",	
				// 			CompareValueType: "fromCurrentStep",	
				// 			CompareValueForm: "Loan",
				// 			CompareValueModule: "quotation",
				// 			CompareValueField: "RateType",				
				// 			CompareValue : "variable",
				// 		}
				// 	]
				// },	
				{
					Id: 18, 
					Type: "ShowField_onInit", 
					ResultAction : "ShowField", 
					Target: "Deferral",
					TargetStep: "Loan",
					Mapping : {}, 
					Params: { 
						Operator : "IN",
						Value : "",	
						CompareValueType: "fromCurrentStep",	
						CompareValueForm: "Loan",
						CompareValueModule: "quotation",
						CompareValueField: "DeferralType",				
						CompareValue : ["partially", "fully"],
						Validators : [
							{ Type : "required", Params : "" },
							//{ Type : "maxValue300", Params : "" },
							{ Type : "onlyNumber", Params : "" }
						]
					} 
				},
				{
					Id: 19, 
					Type: "AfterInit", 
					ResultAction : "SetPropertyFromPreviousStep", 
					Target: "Rate", 
					TargetStep: "Loan",
					Value : 0,	
					Property : "Value",
					Mapping : {},  
					ReplaceMethod: "Always",
					Params:  [
						{ 
							Operator : "=",
							CompareValueType: "fromPreviousStep",	
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
							CompareValueField: "Kind",				
							CompareValue : "interest_free" 
						}
					] 
				},
				{
					Id: 20, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "Rate",
					TargetStep: "Loan",
					TargetModule: "quotation",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" },
						{ Type : "maxValueWithParams", Params : 0 }
					],
					Params: [
						{ 
							Operator : "=",
							Value : "",	
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
							CompareValueField: "Kind",				
							CompareValue : "interest_free",
						}
					]
				},
				{
					Id: 20, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "Rate",
					TargetStep: "Loan",
					TargetModule: "quotation",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" },
						{ Type : "minValue0+", Params : "" }
					],
					Params: [
						{ 
							Operator : "=",
							Value : "",	
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
							CompareValueField: "Kind",				
							CompareValue : "fixed_rate",
						}
					]
				},
				{
					Id: 21, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "VariableMin",
					TargetStep: "Loan",
					TargetModule: "quotation",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" },
						{ 
							Type : "minValueWithParams", Params : {
								SourceStep: "Loan",
								SourceField: "Rate",
								VariationValue: -2
							} 
						},										
						{ 
							Type : "maxValueWithParams", Params : {
								SourceStep: "Loan",
								SourceField: "Rate",
								VariationValue: 0
							} 
						}
					],
					Params: [
						{ 
							Operator : "=",
							Value : "variable",	
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
							CompareValueField: "RateType",				
							CompareValue : "variable",
						}
					]
				},
				{
					Id: 22, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "VariableMax",
					TargetStep: "Loan",
					TargetModule: "quotation",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" },
						{ 
							Type : "minValueWithParams", Params : {
								SourceStep: "Loan",
								SourceField: "Rate",
								VariationValue: 0
							} 
						},										
						{ 
							Type : "maxValueWithParams", Params : {
								SourceStep: "Loan",
								SourceField: "Rate",
								VariationValue: 2
							} 
						}
					],
					Params: [
						{ 
							Operator : "=",
							Value : "variable",	
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
							CompareValueField: "RateType",				
							CompareValue : "variable",
						}
					]
				},
					
				{
					Id: 6, 
					Type: "AfterInit", 
					ResultAction : "SetProperty", 
					Target: "DeferralType",
					TargetStep: "Loan",
					Property: "Values",
					Value:[
						{Id: 0, Value: "no", Label: "Aucun"},
						{Id: 2, Value: "fully", Label: "Total"}
					],
					Mapping : {}, 
					Params: [
						{ 
							Operator : "=",
							Value : 1,
							CompareValueType: "Fixe",	
							CompareValue : "intermediate",
							CompareValueField: "Kind",				
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
						}
					] 
				},
				{
					Id: 6, 
					Type: "AfterInit", 
					ResultAction : "SetProperty", 
					Target: "DeferralType",
					TargetStep: "Loan",
					Property: "Values",
					Value:[
						{Id: 0, Value: "no", Label: "Aucun"},
						{Id: 1, Value: "partially", Label: "Partiel"},
					],
					Mapping : {}, 
					Params: [
						{ 
							Operator : "=",
							Value : 1,
							CompareValueType: "Fixe",	
							CompareValue : "fixed_rate",
							CompareValueField: "Kind",				
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
						}
					] 
				},
				{
					Id: 6, 
					Type: "AfterInit", 
					ResultAction : "SetProperty", 
					Target: "DeferralType",
					TargetStep: "Loan",
					Property: "Values",
					Value:[
						{Id: 0, Value: "no", Label: "Aucun"},
						{Id: 1, Value: "partially", Label: "Partiel"},
						{Id: 2, Value: "fully", Label: "Total"}
					],
					Mapping : {}, 
					Params: [
						{ 
							Operator : "=",
							Value : 1,
							CompareValueType: "Fixe",	
							CompareValue : "interest_free",
							CompareValueField: "Kind",				
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
						}
					] 
				},

				{
					Id: 6, 
					Type: "AfterInit", 
					ResultAction : "Disable", 
					Target: "DeferralType",
					TargetStep: "Loan",
					Value:[],
					Mapping : {}, 
					Params: [
						{ 
							Operator : "IN",
							Value : 1,
							CompareValueType: "Fixe",	
							CompareValue : ["balloon", "graduated", "regroupementdecredits"],
							CompareValueField: "Kind",				
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
						}
					] 
				},

				{
					Id: 22, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "Deferral",
					TargetStep: "Loan",
					TargetModule: "quotation",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" },
						{ Type : "DurationMinusParam", Params : {duration: 0, delay: 12} },

						{ Type : "maxParams", Params : 180 },
					
					],
					Params: [
						{ 
							Operator : "IN",
							Value : "variable",	
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
							CompareValueField: "DeferralType",				
							CompareValue : ["partially", "fully"],
						},
						{ 
							Operator : "=",
							Value : "variable",	
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
							CompareValueField: "Kind",				
							CompareValue : "interest_free",
						}
					]
				},
				{
					Id: 22, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "Deferral",
					TargetStep: "Loan",
					TargetModule: "quotation",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" },
						{ Type : "maxParams", Params : 36 }
					],
					Params: [
						{ 
							Operator : "=",
							Value : "variable",	
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
							CompareValueField: "DeferralType",				
							CompareValue : "fully",
						},
						{ 
							Operator : "=",
							Value : "variable",	
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
							CompareValueField: "Kind",				
							CompareValue : "intermediate",
						}
					]
				},

				{
					Id: 22, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "Deferral",
					TargetStep: "Loan",
					TargetModule: "quotation",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" },
						{ Type : "DurationMinusParam", Params : {duration: 0, delay: 12} }
					],
					Params: [
						{ 
							Operator : "=",
							Value : "variable",	
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
							CompareValueField: "DeferralType",				
							CompareValue : "partially",
						},
						{ 
							Operator : "=",
							Value : "variable",	
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
							CompareValueField: "Kind",				
							CompareValue : "fixed_rate",
						}
					]
				},

				{
					Id: 22, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "Duration",
					TargetStep: "Loan",
					TargetModule: "quotation",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" },
						{ Type : "maxValue420", Params : "" },
						{ Type : "minValue37", Params : "" }
					],
					Params: [
						{ 
							Operator : "=",
							Value : "variable",	
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
							CompareValueField: "Kind",				
							CompareValue : "fixed_rate",
						}
					]
				},
				{
					Id: 22, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "Duration",
					TargetStep: "Loan",
					TargetModule: "quotation",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" },
						{ Type : "maxValue420", Params : "" },
						{ Type : "minValue37", Params : "" }
					],
					Params: [
						{ 
							Operator : "=",
							Value : "variable",	
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
							CompareValueField: "Kind",				
							CompareValue : "balloon",
						}
					]
				},
				{
					Id: 22, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "Duration",
					TargetStep: "Loan",
					TargetModule: "quotation",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" },
						{ Type : "maxValue420", Params : "" },
						{ Type : "minValue37", Params : "" }
					],
					Params: [
						{ 
							Operator : "=",
							Value : "variable",	
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
							CompareValueField: "Kind",				
							CompareValue : "graduated",
						}
					]
				},
				{
					Id: 22, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "Duration",
					TargetStep: "Loan",
					TargetModule: "quotation",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" },
						//{ Type : "maxValue36", Params : "" }
					],
					Params: [
						{ 
							Operator : "=",
							Value : "variable",	
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
							CompareValueField: "Kind",				
							CompareValue : "intermediate",
						}
					]
				},
				{
					Id: 22, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "Duration",
					TargetStep: "Loan",
					TargetModule: "quotation",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" },
						{ Type : "maxValue300", Params : "" },
						{ Type : "minValue37", Params : "" }
					],
					Params: [
						{ 
							Operator : "=",
							Value : "variable",	
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
							CompareValueField: "Kind",				
							CompareValue : "interest_free",
						}
					]
				},
				{
					Id: 22, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "Duration",
					TargetStep: "Loan",
					TargetModule: "quotation",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" },
						//{ Type : "maxValue300", Params : "" },
						{ Type : "minValue37", Params : "" },

					],
					Params: [
						{ 
							Operator : "=",
							Value : "variable",	
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
							CompareValueField: "Kind",				
							CompareValue : "regroupementdecredits",
						}
					]
				},
				// {
				// 	Id: 22, 
				// 	Type: "AfterInit", 
				// 	ResultAction : "SetOtherFieldValidators", 
				// 	Target: "Deferral",
				// 	TargetStep: "Loan",
				// 	TargetModule: "quotation",
				// 	Mapping : {}, 
				// 	SetValidators: [
				// 		{ Type : "required", Params : "" },
				// 		{ Type : "DurationMinusParam", Params : {duration: 0, delay: 12} }
				// 	],
				// 	Params: [
				// 		{ 
				// 			Operator : "=",
				// 			Value : "variable",	
				// 			CompareValueType: "fromCurrentStep",	
				// 			CompareValueForm: "Loan",
				// 			CompareValueModule: "quotation",
				// 			CompareValueField: "DeferralType",				
				// 			CompareValue : "fully",
				// 		},
				// 		{ 
				// 			Operator : "=",
				// 			Value : "variable",	
				// 			CompareValueType: "fromCurrentStep",	
				// 			CompareValueForm: "Loan",
				// 			CompareValueModule: "quotation",
				// 			CompareValueField: "Kind",				
				// 			CompareValue : "fixed_rate",
				// 		}
				// 	]
				// },
					
			],
			HasRightBar : true,
			RightBar : {
				GoToItemBtnCaption_First: "",
				GoToItemBtnCaption: "Prêt",
				HasItemSubDetails: false,
				HasAddItemBtn: true,
				AddItemBtnCaption: "Ajouter un prêt",
				ExtraActions : [
					{ 
						Type: "SameStepLengthFromDetailsLength", 
						Source: "quotation", 
						Target: "quotation", 
						TargetStep: "Garantees",
						SharedId : [
							{ Source: "quotation", SourceStep: "Loan", Field: "LoanId", Type: "Step" },
							{ Source: "quotation", SourceStep: "Covered", Field: "BorrowerIndex", Type: "Details" }
						] 
					}
				],
				HasTrashBtnFirst: false,
				HasTrashBtnNext: true,
				TrashExtraActions : [
					{ Type: "RemoveSubStep", Source: "quotation", Target: "quotation", TargetStep: "Garantees"},
				]
			},
			HasNavigationBar: false,	
			NavigationBarActions: [],
			Controls : [	
				{
					Name : "LoanId",
					Type : "hidden",
					CustomClass : "",
					Label : "",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ReadOnly: false,
					ToHide: false,
					Multiple: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					HasTopRightButton: false,
					TopRightButtonLabel: "",
					TopRightButtonCtrlError: "",
					TopRightButtonAction: {},					
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []
				},		
				{
					Name : "Kind",
					Type : "select2",
					CustomClass : "size-2",
					Label : "Type de prêt",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : {Id: 0, Value: "fixed_rate", Label: "Amortissables"},
					ReadOnly: false,
					ToHide: false,
					Multiple: false,
					Values : [
						{Id: 0, Value: "fixed_rate", Label: "Amortissables"},
						{Id: 1, Value: "balloon", Label: "In fine"},
						{Id: 2, Value: "graduated", Label: "Palier"},
						{Id: 3, Value: "intermediate", Label: "Relais"},
						{Id: 4, Value: "interest_free", Label: "Taux zéro"},
						{Id: 5, Value: "regroupementdecredits", Label: "Regroupement de crédits"}
					],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					HasTopRightButton: false,
					TopRightButtonLabel: "Voir les paliers",
					TopRightButtonCtrlError: "Increments",
					TopRightButtonAction: {
						Type: "ExecuteFn",
						Params: {
							FnName: "openIncrementsModal"
						}
					},					
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le type de prêt est requis",
						"Increments" : ""
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : [
						{
							Id: 7, 
							Type: "OnChange", 
							ResultAction : "SetValue_whenChange", 
							Target: "RateType",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: { 
								Operator : "=",
								Value : "interest_free",
								CompareValueType: "Fixe",	
								CompareValue : "fixe"
							} 
						},
						{
							Id: 8, 
							Type: "OnChange", 
							ResultAction : "HideField_whenChange", 
							Target: "VariableMin",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: { 
								Operator : "=",
								Value : "interest_free",
								CompareValueType: "Fixe",	
								CompareValue : "fixe"
							} 
						},
						{
							Id: 9, 
							Type: "OnChange", 
							ResultAction : "HideField_whenChange", 
							Target: "VariableMax",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: { 
								Operator : "=",
								Value : "interest_free",
								CompareValueType: "Fixe",	
								CompareValue : "fixe"
							} 
						},
						{
							Id: 0, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidators", 
							Target: "Duration",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 
									Operator : "=",
									Value : 0,
								},
							],
							SetValidators: [
								{ Type : "required", Params : "" },
								{ Type : "maxValue420", Params : "" },
								{ Type : "minValue37", Params : "" }
							]
						},
						{
							Id: 0, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidators", 
							Target: "Duration",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 
									Operator : "=",
									Value : 1,
								},
							],
							SetValidators: [
								{ Type : "required", Params : "" },
								{ Type : "maxValue420", Params : "" },
								{ Type : "minValue37", Params : "" }
							]
						},
						{
							Id: 0, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidators", 
							Target: "Duration",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 
									Operator : "=",
									Value : 2,
								},
							],
							SetValidators: [
								{ Type : "required", Params : "" },
								{ Type : "maxValue420", Params : "" },
								{ Type : "minValue37", Params : "" }
							]
						},
						{
							Id: 0, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidators", 
							Target: "Duration",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 
									Operator : "=",
									Value : 3,
								},
							],
							SetValidators: [
								{ Type : "required", Params : "" },
								{ Type : "maxValue36", Params : "" }
							]
						},
						{
							Id: 0, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidators", 
							Target: "Duration",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 
									Operator : "=",
									Value : 4,
								},
							],
							SetValidators: [
								{ Type : "required", Params : "" },
								//{ Type : "maxValue240", Params : "" },
								{ Type : "minValue37", Params : "" },
								{ Type : "maxValue300", Params : "" },

							]
						},
						{
							Id: 0, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidators", 
							Target: "Duration",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 
									Operator : "=",
									Value : 5,
								}    
							],
							SetValidators: [
								{ Type : "required", Params : "" },
								{ Type : "maxValue360", Params : "" },
								{ Type : "minValue37", Params : "" }
							]
						},

						{
							Id: 1, 
							Type: "OnChange", 
							ResultAction : "OpenIncrementsModal", 
							Target: "EffectiveDate",
							TargetStep: "CoveredExtraOthers",
							Mapping : {}, 
							Params: { 
								Operator : "=",
								Value : 1,
								CompareValueType: "Fixe",	
								CompareValue : "graduated"
							} 
						},
						{
							Id: 2, 
							Type: "OnChange", 
							ResultAction : "SetValue_whenChange", 
							Target: "Increments",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: { 
								Operator : "!=",
								Value : "graduated",
								CompareValueType: "Fixe",	
								CompareValue : []
							} 
						},
						{
							Id: 3, 
							Type: "OnChange", 
							ResultAction : "SetProperty", 
							Target: "Kind",
							TargetStep: "Loan",
							TargetProperty: "HasTopRightButton",
							PropertyValue: true,
							Mapping : {}, 
							Params: { 
								Operator : "=",
								Value : 1,
								CompareValueType: "Fixe",	
								CompareValue : "graduated"
							} 
						},
						{
							Id: 4, 
							Type: "OnChange", 
							ResultAction : "SetProperty", 
							Target: "Kind",
							TargetStep: "Loan",
							TargetProperty: "HasTopRightButton",
							PropertyValue: false,
							Mapping : {}, 
							Params: { 
								Operator : "!=",
								Value : 1,
								CompareValueType: "Fixe",	
								CompareValue : "graduated"
							} 
						},
						{
							Id: 5, 
							Type: "OnChange", 
							ResultAction : "SetValue_whenChange", 
							Target: "Rate",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: { 
								Operator : "=",
								Value : "interest_free",
								CompareValueType: "fromCurrentStep",	
								CompareValueField: "Kind",
								CompareValue : 0
							} 
						},
						{
							Id: 6, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidators",
							Target: "Rate",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 
									Operator : "=",
									Value : 4,
								},
							],
							SetValidators: [
								{ Type : "required", Params : "" },
								{ Type : "maxParams", Params : 0 }
							]
						},
						{
							Id: 6, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidators", 
							Target: "Rate",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 
									Operator : "!=",
									Value : 4,
								}   
							],
							SetValidators: [
								{ Type : "required", Params : "" }
							]
						},		
						{
							Id: 6, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidators",
							Target: "Rate",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 
									Operator : "=",
									Value : 0,
								},
							],
							SetValidators: [
								{ Type : "required", Params : "" },
								{ Type : "minValue0+", Params : "" }
							]
						},									
						// {
						// 	Id: 6, 
						// 	Type: "OnChange", 
						// 	ResultAction : "SetProperty", 
						// 	Target: "Rate",
						// 	TargetStep: "Loan",
						// 	TargetProperty: "ReadOnly",
						// 	PropertyValue: "true",
						// 	Mapping : {}, 
						// 	Params: { 
						// 		Operator : "=",
						// 		Value : "",
						// 		CompareValueType: "fromCurrentStep",	
						// 		CompareValueField: "Kind",
						// 		CompareValue : "interest_free"
						// 	} 
						// },						
						// {
						// 	Id: 6, 
						// 	Type: "OnChange", 
						// 	ResultAction : "SetProperty", 
						// 	Target: "Rate",
						// 	TargetStep: "Loan",
						// 	TargetProperty: "ReadOnly",
						// 	PropertyValue: "false",
						// 	Mapping : {}, 
						// 	Params: { 
						// 		Operator : "!=",
						// 		Value : "",
						// 		CompareValueType: "fromCurrentStep",	
						// 		CompareValueField: "Kind",
						// 		CompareValue : "interest_free"
						// 	} 
						// },
						// {
						// 	Id: 5, 
						// 	Type: "OnChange", 
						// 	ResultAction : "SetOtherFieldValidators", 
						// 	Target: "Kind",
						// 	TargetStep: "Loan",
						// 	Mapping : {}, 
						// 	Params: [
						// 		{ 
						// 			Operator : "=",
						// 			Value : "graduated",
						// 			SetValidators: [
						// 				{ Type : "required", Params : "" },
						// 				{ Type : "Increments", Params : "" }
						// 			]
						// 		} 
						// 	]
						// }

						{
							Id: 3, 
							Type: "OnChange", 
							ResultAction : "SetProperty_whenChangeWithCombinedParams", 
							Target: "DeferralType",
							TargetStep: "Loan",
							TargetProperty: "Values",
							PropertyValue: [
								{Id: 0, Value: "no", Label: "Aucun"},
								{Id: 2, Value: "fully", Label: "Total"}
							],
							Mapping : {}, 
							Params: [
								{ 
									SourceModule: "quotation",
									SourceStep: "Loan",
									SourceField: "Kind",
									Operator : "=",
									Value : "intermediate",	
								} 
							]
						},
						{
							Id: 3, 
							Type: "OnChange", 
							ResultAction : "SetProperty_whenChangeWithCombinedParams", 
							Target: "DeferralType",
							TargetStep: "Loan",
							TargetProperty: "Values",
							PropertyValue: [
								{Id: 0, Value: "no", Label: "Aucun"},
								{Id: 1, Value: "partially", Label: "Partiel"},
							],
							Mapping : {}, 
							Params: [
								{ 
									SourceModule: "quotation",
									SourceStep: "Loan",
									SourceField: "Kind",
									Operator : "=",
									Value : "fixed_rate",	
								} 
							]
						},
						{
							Id: 3, 
							Type: "OnChange", 
							ResultAction : "SetProperty_whenChangeWithCombinedParams", 
							Target: "DeferralType",
							TargetStep: "Loan",
							TargetProperty: "Values",
							PropertyValue: [
								{Id: 0, Value: "no", Label: "Aucun"},
								{Id: 1, Value: "partially", Label: "Partiel"},
								{Id: 2, Value: "fully", Label: "Total"}
							],
							Mapping : {}, 
							Params: [
								{ 
									SourceModule: "quotation",
									SourceStep: "Loan",
									SourceField: "Kind",
									Operator : "=",
									Value : "interest_free",	
								} 
							]
						},
						{
							Id: 50, 
							Type: "OnChange", 
							ResultAction : "DisableField_whenChangeWithCombinedParams", 
							Target: "DeferralType",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 									
									SourceModule: "quotation",
									SourceStep: "Loan",
									SourceField: "Kind",
									Operator : "IN",
									Value : ["balloon", "graduated", "regroupementdecredits"],	
								}
							]
						},
						{
							Id: 50, 
							Type: "OnChange", 
							ResultAction : "EnableField_whenChangeWithCombinedParams", 
							Target: "DeferralType",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 									
									SourceModule: "quotation",
									SourceStep: "Loan",
									SourceField: "Kind",
									Operator : "IN",
									Value : ["fixed_rate", "intermediate", "interest_free"],	
								}
							]
						},

						// FIXED_RATE
						{
							Id: 50, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidatorsWithCombinedParams", 
							Target: "DeferralType",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "Kind",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "fixed_rate",
								}
							],
							SetValidators: [
								{ Type : "clearValidators", Params : {} }
							]
						},
						{
							Id: 50, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidatorsWithCombinedParams", 
							Target: "Deferral",
							TargetStep: "Loan",
							TargetModule: "quotation",
							Mapping : {}, 
							Params: [
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "Kind",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "fixed_rate",
								},
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "DeferralType",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "partially",
								} 
							],
							SetValidators: [
								{ Type : "DurationMinusParam", Params : {duration: 0, delay: 12} }
							]
						},
						{
							Id: 7, 
							Type: "OnChange", 
							ResultAction : "SetValue_whenChangeWithCombinedParams", 
							Target: "DeferralType",
							TargetStep: "Loan",
							TargetModule: "quotation",
							ReplaceValue : {Id: 0, Value: "no", Label: "Aucun"},
							ReplaceValueMode: "fixe",
							Mapping : {}, 
							Params: [
								{ 
									SourceModule: "quotation",
									SourceStep: "Loan",
									SourceField: "Kind",
									Operator : "=",
									Value : "fixed_rate",	
								},
								{ 
									SourceModule: "quotation",
									SourceStep: "Loan",
									SourceField: "DeferralType",
									Operator : "=",
									Value : "fully",	
								}
							]
						},	
						

						// BALLOON
						{
							Id: 7, 
							Type: "OnChange", 
							ResultAction : "SetValue_whenChangeWithCombinedParams", 
							Target: "DeferralType",
							TargetStep: "Loan",
							TargetModule: "quotation",
							ReplaceValue : {Id: 0, Value: "no", Label: "Aucun"},
							ReplaceValueMode: "fixe",
							Mapping : {}, 
							Params: [
								{ 
									SourceModule: "quotation",
									SourceStep: "Loan",
									SourceField: "Kind",
									Operator : "=",
									Value : "balloon",	
								}
							]
						},
						{
							Id: 8, 
							Type: "OnChange", 
							ResultAction : "HideField_whenChangeWithCombinedParams", 
							Target: "Deferral",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 
									SourceModule: "quotation",
									SourceStep: "Loan",
									SourceField: "Kind",
									Operator : "=",
									Value : "balloon",	
								}
							]
						},
						{
							Id: 7, 
							Type: "OnChange", 
							ResultAction : "SetValue_whenChangeWithCombinedParams", 
							Target: "Deferral",
							TargetStep: "Loan",
							TargetModule: "quotation",
							ReplaceValue : null,
							ReplaceValueMode: "fixe",
							Mapping : {}, 
							Params: [
								{ 
									SourceModule: "quotation",
									SourceStep: "Loan",
									SourceField: "Kind",
									Operator : "=",
									Value : "balloon",	
								}
							]
						},
						

						// GRADUATED
						{
							Id: 7, 
							Type: "OnChange", 
							ResultAction : "SetValue_whenChangeWithCombinedParams", 
							Target: "DeferralType",
							TargetStep: "Loan",
							TargetModule: "quotation",
							ReplaceValue : {Id: 0, Value: "no", Label: "Aucun"},
							ReplaceValueMode: "fixe",
							Mapping : {}, 
							Params: [
								{ 
									SourceModule: "quotation",
									SourceStep: "Loan",
									SourceField: "Kind",
									Operator : "=",
									Value : "graduated",	
								}
							]
						},
						{
							Id: 8, 
							Type: "OnChange", 
							ResultAction : "HideField_whenChangeWithCombinedParams", 
							Target: "Deferral",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 
									SourceModule: "quotation",
									SourceStep: "Loan",
									SourceField: "Kind",
									Operator : "=",
									Value : "graduated",	
								}
							]
						},
						{
							Id: 7, 
							Type: "OnChange", 
							ResultAction : "SetValue_whenChangeWithCombinedParams", 
							Target: "Deferral",
							TargetStep: "Loan",
							TargetModule: "quotation",
							ReplaceValue : null,
							ReplaceValueMode: "fixe",
							Mapping : {}, 
							Params: [
								{ 
									SourceModule: "quotation",
									SourceStep: "Loan",
									SourceField: "Kind",
									Operator : "=",
									Value : "graduated",	
								}
							]
						},

						// INTERMEDIATE
						{
							Id: 7, 
							Type: "OnChange", 
							ResultAction : "SetValue_whenChangeWithCombinedParams", 
							Target: "DeferralType",
							TargetStep: "Loan",
							TargetModule: "quotation",
							ReplaceValue : {Id: 0, Value: "no", Label: "Aucun"},
							ReplaceValueMode: "fixe",
							Mapping : {}, 
							Params: [
								{ 
									SourceModule: "quotation",
									SourceStep: "Loan",
									SourceField: "Kind",
									Operator : "=",
									Value : "intermediate",	
								},
								{ 
									SourceModule: "quotation",
									SourceStep: "Loan",
									SourceField: "DeferralType",
									Operator : "=",
									Value : "partially",	
								}
							]
						},						
						{
							Id: 8, 
							Type: "OnChange", 
							ResultAction : "HideField_whenChangeWithCombinedParams", 
							Target: "Deferral",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 
									SourceModule: "quotation",
									SourceStep: "Loan",
									SourceField: "Kind",
									Operator : "=",
									Value : "intermediate",	
								},
								{ 
									SourceModule: "quotation",
									SourceStep: "Loan",
									SourceField: "DeferralType",
									Operator : "!=",
									Value : "fully",	
								},
							]
						},
						{
							Id: 7, 
							Type: "OnChange", 
							ResultAction : "SetValue_whenChangeWithCombinedParams", 
							Target: "Deferral",
							TargetStep: "Loan",
							TargetModule: "quotation",
							ReplaceValue : null,
							ReplaceValueMode: "fixe",
							Mapping : {}, 
							Params: [
								{ 
									SourceModule: "quotation",
									SourceStep: "Loan",
									SourceField: "Kind",
									Operator : "=",
									Value : "intermediate",	
								},
								{ 
									SourceModule: "quotation",
									SourceStep: "Loan",
									SourceField: "DeferralType",
									Operator : "!=",
									Value : "fully",	
								},
							]
						},
						{
							Id: 51, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidatorsWithCombinedParams", 
							Target: "Deferral",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "Kind",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "intermediate",
								},
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "DeferralType",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "fully",
								} 
							],
							SetValidators: [
								{ Type : "maxParams", Params : 36 }
							]
						},


						// INTEREST_FREE
						{
							Id: 50, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidatorsWithCombinedParams", 
							Target: "Deferral",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "Kind",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "interest_free",
								},
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "DeferralType",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "partially",
								} 
							],
							SetValidators: [
								{ Type : "maxParams", Params : 180 },
								{ Type : "DurationMinusParam", Params : {duration: 0, delay: 12} }

							]
						},


/*

						{
							Id: 50, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidatorsWithCombinedParams", 
							Target: "Deferral",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "Kind",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "interest_free",
								},
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "DeferralType",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "partially",
								} 
							],
							SetValidators: [
								{ Type : "DurationMinusParam", Params : {duration: 0, delay: 12} }
							]
						},




*/

























						

						{
							Id: 51, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidatorsWithCombinedParams", 
							Target: "Deferral",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "Kind",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "interest_free",
								},
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "DeferralType",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "fully",
								} 
							],
							SetValidators: [
								{ Type : "maxParams", Params : 180 },

								{ Type : "DurationMinusParam", Params : {duration: 0, delay: 12} }
							]
						},
						// REGROUPEMENTDECREDITS				
						{
							Id: 7, 
							Type: "OnChange", 
							ResultAction : "SetValue_whenChangeWithCombinedParams", 
							Target: "DeferralType",
							TargetStep: "Loan",
							TargetModule: "quotation",
							ReplaceValue : {Id: 0, Value: "no", Label: "Aucun"},
							ReplaceValueMode: "fixe",
							Mapping : {}, 
							Params: [
								{ 
									SourceModule: "quotation",
									SourceStep: "Loan",
									SourceField: "Kind",
									Operator : "=",
									Value : "regroupementdecredits",	
								}
							]
						},
						{
							Id: 8, 
							Type: "OnChange", 
							ResultAction : "HideField_whenChangeWithCombinedParams", 
							Target: "Deferral",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 
									SourceModule: "quotation",
									SourceStep: "Loan",
									SourceField: "Kind",
									Operator : "=",
									Value : "regroupementdecredits",	
								}
							]
						},
						{
							Id: 7, 
							Type: "OnChange", 
							ResultAction : "SetValue_whenChangeWithCombinedParams", 
							Target: "Deferral",
							TargetStep: "Loan",
							TargetModule: "quotation",
							ReplaceValue : null,
							ReplaceValueMode: "fixe",
							Mapping : {}, 
							Params: [
								{ 
									SourceModule: "quotation",
									SourceStep: "Loan",
									SourceField: "Kind",
									Operator : "=",
									Value : "regroupementdecredits",	
								}
							]
						},
					]
				},
				{
					Name : "Amount",
					Type : "text",
					CustomClass : "size-2",
					Label : "Montant",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					ReadOnly: false,
					maxlength: 9,
					max: 9999999.99,
					Mask: "0*.00",
					// pattern: "^[0-9]+(\\.[0-9][0-9])?",
					Values : [],
					HasRightIndicator: true,
					RightIndicatorType : "text",
					RightIndicator : "€",
					Validators : [
						{ Type : "required", Params : "" },
						{ Type : "maxValue5000000", Params : "" },
						{ Type : "onlyFloat", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le montant est requis",
						"max5000000" : "Le montant du prêt ne peut excéder 5 millions d’euros.",
						"onlyFloat" : "Le montant doit numérique"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []				
				},
				{
					Name : "Rate",
					Type : "text",
					CustomClass : "size-2",
					Label : "Taux",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,				
					Mask : "0.00",
					Values : [],
					HasRightIndicator: true,
					RightIndicatorType : "text",
					RightIndicator : "%",
					Validators : [
						{ Type : "required", Params : "" },
						{ Type : "minValue0+", Params : ""}
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le taux est requis",
						"max" : "Le valeur du taux n'est pas valide",
						"min" : "La valeur du taux n'est pas valide"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : [
						// {
						// 	Id: 0, 
						// 	Type: "OnChange", 
						// 	ResultAction : "SetOtherFieldValidators", 
						// 	Target: "VariableMin",
						// 	TargetStep: "Loan",
						// 	Mapping : {}, 
						// 	Params: [
						// 		{ 
						// 			Operator : "NO",
						// 			Value : 0,
						// 		},   
						// 	],
						// 	SetValidators: [
						// 		{ Type : "required", Params : "" },
						// 		{ 
						// 			Type : "minValueWithParams", Params : {
						// 				SourceStep: "Loan",
						// 				SourceField: "Rate",
						// 				VariationValue: -2
						// 			} 
						// 		},										
						// 		{ 
						// 			Type : "maxValueWithParams", Params : {
						// 				SourceStep: "Loan",
						// 				SourceField: "Rate",
						// 				VariationValue: 0
						// 			} 
						// 		}
						// 	]
						// },
						{
							Id: 50, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidatorsWithCombinedParams", 
							Target: "VariableMin",
							TargetStep: "Loan",
							TargetModule: "quotation",
							Mapping : {}, 
							Params: [
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "Rate",
									CompareValueForm: "Loan",
									Operator : "NO",
									Value : 0,
								},
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "RateType",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "variable",
								} 
							],
							SetValidators: [
								{ Type : "required", Params : "" },
								{ 
									Type : "minValueWithParams", Params : {
										SourceStep: "Loan",
										SourceField: "Rate",
										VariationValue: -2
									} 
								},										
								{ 
									Type : "maxValueWithParams", Params : {
										SourceStep: "Loan",
										SourceField: "Rate",
										VariationValue: 0
									} 
								}
							]
						},
						// {
						// 	Id: 1, 
						// 	Type: "OnChange", 
						// 	ResultAction : "SetOtherFieldValidators", 
						// 	Target: "VariableMax",
						// 	TargetStep: "Loan",
						// 	Mapping : {}, 
						// 	Params: [
						// 		{ 
						// 			Operator : "NO",
						// 			Value : 0,
						// 		},   
						// 	],
						// 	SetValidators: [
						// 		{ Type : "required", Params : "" },										
						// 		{ 
						// 			Type : "minValueWithParams", Params : {
						// 				SourceStep: "Loan",
						// 				SourceField: "Rate",
						// 				VariationValue: 0
						// 			} 
						// 		},
						// 		{ 
						// 			Type : "maxValueWithParams", Params : {
						// 				SourceStep: "Loan",
						// 				SourceField: "Rate",
						// 				VariationValue: 2
						// 			} 
						// 		}
						// 	]
						// },
						{
							Id: 50, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidatorsWithCombinedParams", 
							Target: "VariableMin",
							TargetStep: "Loan",
							TargetModule: "quotation",
							Mapping : {}, 
							Params: [
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "Rate",
									CompareValueForm: "Loan",
									Operator : "NO",
									Value : 0,
								},
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "RateType",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "variable",
								} 
							],
							SetValidators: [
								{ Type : "required", Params : "" },										
								{ 
									Type : "minValueWithParams", Params : {
										SourceStep: "Loan",
										SourceField: "Rate",
										VariationValue: 0
									} 
								},
								{ 
									Type : "maxValueWithParams", Params : {
										SourceStep: "Loan",
										SourceField: "Rate",
										VariationValue: 2
									} 
								}
							]
						},
					]				
				},
				{
					Name : "RateType",
					Type : "btn_radio",
					CustomClass : "size-2 no-label",
					Label : "",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,					
					Disabled: false,
					Values : [
						{ Id: 0, Value: "fixe", Label: "Fixe" },
						{ Id: 1, Value: "variable", Label: "Variable" }

					],
					HasRightIndicator: false,
					RightIndicator : "",
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le taux est requis"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : [
						{
							Id: 0, 
							Type: "OnClick", 
							ResultAction : "ShowField", 
							Target: "VariableMin",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: { 
								Operator : "=",
								Value : "",
								CompareValueType: "Fixe",	
								CompareValue : "variable",
								IsRequired : true
							} 
						},
						{
							Id: 1, 
							Type: "OnClick", 
							ResultAction : "ShowField", 
							Target: "VariableMax",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: { 
								Operator : "=",
								Value : "",
								CompareValueType: "Fixe",	
								CompareValue : "variable",
								IsRequired : true
							} 
						},
						{
							Id: 2, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidators", 
							Target: "VariableMin",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 
									Operator : "NO",
									Value : 0,
								},   
							],
							SetValidators: [
								{ Type : "required", Params : "" },
								{ 
									Type : "minValueWithParams", Params : {
										SourceStep: "Loan",
										SourceField: "Rate",
										VariationValue: -2
									} 
								},										
								{ 
									Type : "maxValueWithParams", Params : {
										SourceStep: "Loan",
										SourceField: "Rate",
										VariationValue: 0
									} 
								}
							]
						},
						{
							Id: 3, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidators", 
							Target: "VariableMax",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 
									Operator : "NO",
									Value : 0,
								},   
							],
							SetValidators: [
								{ Type : "required", Params : "" },										
								{ 
									Type : "minValueWithParams", Params : {
										SourceStep: "Loan",
										SourceField: "Rate",
										VariationValue: 0
									} 
								},
								{ 
									Type : "maxValueWithParams", Params : {
										SourceStep: "Loan",
										SourceField: "Rate",
										VariationValue: 2
									} 
								}
							]
						}
					]				
				},
				{
					Name : "VariableMin",
					Type : "text",
					CustomClass : "size-4 clearer-before-size-2",
					Label : "Taux mini",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: true,					
					Disabled: false,
					Mask : "0.00",
					Values : [],
					HasRightIndicator: false,
					RightIndicator : "",
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le taux mini est requis",
						"min" : "Le taux mini doit être supérieur à -2 pts du taux variable",
						"max" : "Le taux mini doit être inférieur au taux variable"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []				
				},
				{
					Name : "VariableMax",
					Type : "text",
					CustomClass : "size-4",
					Label : "Taux maxi",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: true,					
					Disabled: false,
					Mask : "0.00",
					Values : [],
					HasRightIndicator: false,
					RightIndicator : "",
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le taux maxi est requis",
						"min" : "Le taux maxi doit être supérieur au taux variable",
						"max" : "Le taux maxi doit être inférieur à +2 pts du taux variable"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []				
				},
				{		
					Name : "Duration",
					Type : "text",
					CustomClass : "size-2",
					Label : "Durée",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,					
					max: 999,
					Mask : "999",
					Values : [],
					HasRightIndicator: true,
					RightIndicatorType : "text",
					RightIndicator : "Mois",
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "La durée est requise",
						"min" : "La durée du prêt doit être supérieure à 37 mois.",
						"min37" : "La durée du prêt doit être supérieure à 37 mois.",
						"max36" : "La durée du prêt ne peut excéder 36 mois soit l’équivalent de 3 ans.",
					     "max240" : "La durée du prêt ne peut excéder 240 mois soit l’équivalent de 20 ans.",
						 "max300" : "La durée du prêt ne peut excéder 300 mois soit l’équivalent de 25 ans.",

						"max360" : "La durée du prêt ne peut excéder 360 mois soit l’équivalent de 30 ans.",
						"max420" : "La durée du prêt ne peut excéder 420 mois soit l’équivalent de 35 ans.",
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : [
						{
							Id: 50, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidatorsWithCombinedParams", 
							Target: "Deferral",
							TargetStep: "Loan",
							TargetModule: "quotation",
							Mapping : {}, 
							Params: [
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "Kind",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "fixed_rate",
								},
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "DeferralType",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "partially",
								} 
							],
							SetValidators: [
								{ Type : "DurationMinusParam", Params : {duration: 0, delay: 12} }
							]
						},
						{
							Id: 50, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidatorsWithCombinedParams", 
							Target: "Deferral",
							TargetStep: "Loan",
							TargetModule: "quotation",
							Mapping : {}, 
							Params: [
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "Kind",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "fixed_rate",
								},
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "DeferralType",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "fully",
								} 
							],
							SetValidators: [
								{ Type : "DurationMinusParam", Params : {duration: 0, delay: 12} }
							]
						},
					]				
				},
				{
					Name : "DeferralType",
					Type : "select2",
					CustomClass : "size-2",
					Label : "Différé",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : {Id: 0, Value: "no", Label: "Aucun"},
					ReadOnly: false,
					ToHide: false,
					Multiple: false,
					Values : [
						{Id: 0, Value: "no", Label: "Aucun"},
						{Id: 1, Value: "partially", Label: "Partiel"},
						{Id: 2, Value: "fully", Label: "Total"}
					],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					HasTopRightButton: false,
					TopRightButtonLabel: "",
					TopRightButtonCtrlError: "",
					TopRightButtonAction: {},					
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le type de différé est requis",
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : [
						{
							Id: 7, 
							Type: "OnChange", 
							ResultAction : "SetValue_whenChangeWithCombinedParams", 
							Target: "Deferral",
							TargetStep: "Loan",
							TargetModule: "quotation",
							ReplaceValue : null,
							ReplaceValueMode: "fixe",
							Mapping : {}, 
							Params: [
								{ 
									SourceModule: "quotation",
									SourceStep: "Loan",
									SourceField: "DeferralType",
									Operator : "=",
									Value : "no",	
								}
							]
						},		
						{
							Id: 0, 
							Type: "OnChange", 
							ResultAction : "ShowField_whenChange", 
							Target: "Deferral",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: { 
								Operator : "IN",
								Value : "",
								CompareValueType: "fromCurrentStep",
								CompareValueField: "DeferralType",	
								CompareValue : ["partially", "fully"],
								Validators : [
									{ Type : "required", Params : "" },
								//	{ Type : "maxValue300", Params : "" },
									{ Type : "onlyNumber", Params : "" }
								]
							} 
						},
						{
							Id: 50, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidatorsWithCombinedParams", 
							Target: "Deferral",
							TargetStep: "Loan",
							TargetModule: "quotation",
							Mapping : {}, 
							Params: [
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "Kind",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "fixed_rate",
								},
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "DeferralType",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "partially",
								} 
							],
							SetValidators: [
								{ Type : "DurationMinusParam", Params : {duration: 0, delay: 12} }
							]
						},	
						{
							Id: 50, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidatorsWithCombinedParams", 
							Target: "Deferral",
							TargetStep: "Loan",
							TargetModule: "quotation",
							Mapping : {}, 
							Params: [
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "Kind",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "fixed_rate",
								},
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "DeferralType",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "fully",
								} 
							],
							SetValidators: [
								{ Type : "DurationMinusParam", Params : {duration: 0, delay: 12} }
							]
						},																				
						{
							Id: 51, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidatorsWithCombinedParams", 
							Target: "Deferral",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "Kind",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "intermediate",
								},
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "DeferralType",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "fully",
								} 
							],
							SetValidators: [
								{ Type : "required", Params : "" },
								{ Type : "maxParams", Params : 36 }
							]
						},
						{
							Id: 50, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidatorsWithCombinedParams", 
							Target: "Deferral",
							TargetStep: "Loan",
							Mapping : {}, 
							Params: [
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "Kind",
									CompareValueForm: "Loan",
									Operator : "=",
									Value : "interest_free",
								},
								{ 
									CompareValueModule: "quotation",
									CompareValueField: "DeferralType",
									CompareValueForm: "Loan",
									Operator : "IN",
									Value : ["partially", 'fully'],
								} 
							],
							SetValidators: [
								{ Type : "required", Params : "" },
								{ Type : "maxParams", Params : 180 },
								{ Type : "DurationMinusParam", Params : {duration: 0, delay: 12} }

							]
						},
					]
				},
				{
					Name : "Deferral",
					Type : "text",
					CustomClass : "size-2 clearer-before",
					Label : "Durée du différé",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: true,
					Disabled: false,
					max: 99,
					Mask : "0*",
					Values : [],
					HasRightIndicator: true,
					RightIndicatorType : "text",
					RightIndicator : "Mois",
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Differé est requis",
						"max0" : "Le différé n'est pas autorisé.",
						"max36" : "La durée du différé ne peut excéder 36 mois.",
						"max300" : "La durée du différé ne peut excéder 300 mois.",
						"max180" : "La durée du différé ne peut excéder 180 mois.",

						"onlyNumber" : "Le montant doit numérique",
						"DurationMinusParam": "Le différé doit être inférieur à la durée du prêt - 1 an."
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []				
				},
				{
					Name : "PaymentFrequency",
					Type : "btn_radio",
					CustomClass : "size-1",
					Label : "Périodicité de remboursement",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Values : [
						{ Id: 0, Value: "month", Label: "Mensuelle" },
						{ Id: 1, Value: "quarter", Label: "Trimestrielle" },
						{ Id: 1, Value: "half_year", Label: "Semestrielle" },
						{ Id: 1, Value: "year", Label: "Annuelle" }

					],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "La fréquence est requise"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []				
				},
				{
					Name : "Lender",
					Type : "select2",
					CustomClass : "size-2",
					Label : "Organisme prêteur",
					UpperCase : true,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Multiple: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Searchable: false,
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "L'organisme est requis"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",		
					Actions : [
						{
							Id: 0, 
							Type: "ApiCall_whenChange", 
							ResultAction : "FillSelect", 
							Target: "LenderAddress", 
							TargetStep: "Loan",
							MinLength: 0,	
							ReplaceMethod: "Always",							
							ValuesPropertyName: "",						
							Mapping : { Id: "LenderAddressId", Value: "LenderAddressId", Label: "RecName", StreetAddress: "street", Postcode: "zip", City: "city", Country: "country" }, 
							Params: { 
								Method: "GET", 
								Url: "/lenders/{{Lender}}/addresses",
								HasVariable: true,
								Variables: [
									{
										Type: "FromForm", 
										Name: "Lender" 
									}
								]
							} 
						},
						{
							Id: 1, 
							Type: "OnChange", 
							ResultAction : "ResetValue_ToNextStep", 
							Target: "sameaddress", 
							TargetStep: "bank",
							TargetModule: "subscription",
							MinLength: 0,								
							ValuesPropertyName: "",						
							Mapping : {}, 
							Params: { 
								Operator : "NO",
								Value : "",
								CompareValueType: "Fixe",	
								CompareValue : ""
							} 
						},
						{
							Id: 2, 
							Type: "OnChange", 
							ResultAction : "ResetValue_ToNextStep", 
							Target: "raisonsociale", 
							TargetStep: "bank",
							TargetModule: "subscription",
							MinLength: 0,								
							ValuesPropertyName: "",						
							Mapping : {}, 
							Params: { 
								Operator : "NO",
								Value : "",
								CompareValueType: "Fixe",	
								CompareValue : ""
							} 
						},
						{
							Id: 3, 
							Type: "OnChange", 
							ResultAction : "ResetValue_ToNextStep", 
							Target: "Address1", 
							TargetStep: "bank",
							TargetModule: "subscription",
							MinLength: 0,								
							ValuesPropertyName: "",						
							Mapping : {}, 
							Params: { 
								Operator : "NO",
								Value : "",
								CompareValueType: "Fixe",	
								CompareValue : ""
							} 
						},
						{
							Id: 4, 
							Type: "OnChange", 
							ResultAction : "ResetValue_ToNextStep", 
							Target: "Postcode", 
							TargetStep: "bank",
							TargetModule: "subscription",
							MinLength: 0,								
							ValuesPropertyName: "",						
							Mapping : {}, 
							Params: { 
								Operator : "NO",
								Value : "",
								CompareValueType: "Fixe",	
								CompareValue : ""
							} 
						},
						{
							Id: 5, 
							Type: "OnChange", 
							ResultAction : "ResetValue_ToNextStep", 
							Target: "City", 
							TargetStep: "bank",
							TargetModule: "subscription",
							MinLength: 0,								
							ValuesPropertyName: "",						
							Mapping : {}, 
							Params: { 
								Operator : "NO",
								Value : "",
								CompareValueType: "Fixe",	
								CompareValue : ""
							} 
						},
						{
							Id: 6, 
							Type: "OnChange", 
							ResultAction : "ResetValue_ToNextStep", 
							Target: "Country", 
							TargetStep: "bank",
							TargetModule: "subscription",
							MinLength: 0,								
							ValuesPropertyName: "",						
							Mapping : {}, 
							Params: { 
								Operator : "NO",
								Value : "",
								CompareValueType: "Fixe",	
								CompareValue : ""
							} 
						},
						{
							Id: 6, 
							Type: "OnChange", 
							ResultAction : "ResetValue_FromCurrentStep", 
							Target: "LenderAddressStreet", 
							TargetStep: "Loan",
							TargetModule: "quotation",
							MinLength: 0,								
							ValuesPropertyName: "",						
							Mapping : {}, 
							Params: { 
								Operator : "NO",
								Value : "",
								CompareValueType: "Fixe",	
								CompareValue : ""
							} 
						},
						{
							Id: 7, 
							Type: "OnChange", 
							ResultAction : "ResetValue_FromCurrentStep", 
							Target: "LenderAddressZip", 
							TargetStep: "Loan",
							TargetModule: "quotation",
							MinLength: 0,								
							ValuesPropertyName: "",						
							Mapping : {}, 
							Params: { 
								Operator : "NO",
								Value : "",
								CompareValueType: "Fixe",	
								CompareValue : ""
							} 
						},
						{
							Id: 8, 
							Type: "OnChange", 
							ResultAction : "ResetValue_FromCurrentStep", 
							Target: "LenderAddressCity", 
							TargetStep: "Loan",
							TargetModule: "quotation",
							MinLength: 0,								
							ValuesPropertyName: "",						
							Mapping : {}, 
							Params: { 
								Operator : "NO",
								Value : "",
								CompareValueType: "Fixe",	
								CompareValue : ""
							} 
						},
						// {
						// 	Id: 9, 
						// 	Type: "OnChange", 
						// 	ResultAction : "ResetValue_FromCurrentStep", 
						// 	Target: "LenderAddressCountry", 
						// 	TargetStep: "Loan",
						// 	TargetModule: "quotation",
						// 	MinLength: 0,								
						// 	ValuesPropertyName: "",						
						// 	Mapping : {}, 
						// 	Params: { 
						// 		Operator : "NO",
						// 		Value : "",
						// 		CompareValueType: "Fixe",	
						// 		CompareValue : ""
						// 	} 
						// }
					]			
				},
				{
					Name : "LenderAddress",
					Type : "select2",
					CustomClass : "size-2",
					Label : "Adresse du centre de gestion",
					UpperCase : true,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Multiple: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Searchable: false,
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "L'adresse du centre de gestion est requise"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : [
						{
							Id: 0, 
							Type: "OnChange", 
							ResultAction : "SetValue_whenChange_fromSelect", 
							Target: "LenderAddressStreet",
							TargetStep: "Loan",
							Mapping : { Source: "StreetAddress" }, 
							Params: {} 
						},
						{
							Id: 1, 
							Type: "OnChange", 
							ResultAction : "SetValue_whenChange_fromSelect", 
							Target: "LenderAddressZip",
							TargetStep: "Loan",
							Mapping : { Source: "Postcode" }, 
							Params: {} 
						},
						{
							Id: 2, 
							Type: "OnChange", 
							ResultAction : "SetValue_whenChange_fromSelect", 
							Target: "LenderAddressCity",
							TargetStep: "Loan",
							Mapping : { Source: "City" }, 
							Params: {} 
						},
						// {
						// 	Id: 3, 
						// 	Type: "OnChange", 
						// 	ResultAction : "SetValue_whenChange_fromSelect", 
						// 	Target: "LenderAddressCountry",
						// 	TargetStep: "Loan",
						// 	Mapping : { Source: "Country" }, 
						// 	Params: {} 
						// }
					],
					SearchAction: {
						Id: 0, 
						Type: "ApiCall_whenChange", 
						ResultAction : "FillSelect", 
						Target: "LenderAddress", 
						TargetStep: "Loan",
						MinLength: 0,	
						ReplaceMethod: "Always",							
						ValuesPropertyName: "",						
						Mapping : { Id: "LenderAddressId", Value: "LenderAddressId", Label: "RecName", StreetAddress: "street", Postcode: "zip", City: "city", Country: "country" }, 
						Params: { 
							Method: "GET", 
							Url: "/lenders/{{Lender}}/addresses?rec_name={{LenderAddress}}",
							HasVariable: true,
							Variables: [
								{
									Type: "FromForm", 
									Name: "Lender" 
								},
								{
									Type: "searchValue", 
									Name: "LenderAddress" 
								}
							]
						} 
					},						
				},
				{
					Name : "LenderAddressStreet",
					Type : "hidden",
					CustomClass : "",
					Label : "",
					UpperCase : true,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ReadOnly: false,
					ToHide: false,
					Multiple: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					HasTopRightButton: false,
					TopRightButtonLabel: "",
					TopRightButtonCtrlError: "",
					TopRightButtonAction: {},					
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []
				},
				{
					Name : "LenderAddressZip",
					Type : "hidden",
					CustomClass : "",
					Label : "",
					UpperCase : true,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ReadOnly: false,
					ToHide: false,
					Multiple: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					HasTopRightButton: false,
					TopRightButtonLabel: "",
					TopRightButtonCtrlError: "",
					TopRightButtonAction: {},					
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []
				},
				{
					Name : "LenderAddressCity",
					Type : "hidden",
					CustomClass : "",
					Label : "",
					UpperCase : true,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ReadOnly: false,
					ToHide: false,
					Multiple: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					HasTopRightButton: false,
					TopRightButtonLabel: "",
					TopRightButtonCtrlError: "",
					TopRightButtonAction: {},					
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []
				},
				{
					Name : "LenderAddressCountry",
					Type : "hidden",
					CustomClass : "",
					Label : "",
					UpperCase : true,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "France",
					ReadOnly: false,
					ToHide: false,
					Multiple: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					HasTopRightButton: false,
					TopRightButtonLabel: "",
					TopRightButtonCtrlError: "",
					TopRightButtonAction: {},					
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []
				},
				{
					Name : "Increments",
					Type : "hidden",
					CustomClass : "",
					CustomValue : 5,
					Label : "",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					ReadOnly: false,
					maxlength: 0,
					max: 0,
					Mask : "",
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []				
				}
			],
			BtnLabels: {
				HasCancel: true,
				Cancel: "Abandonner",
				HasSaveAndQuit: false,
				SaveAndQuit: "Sauvegarder et quitter",
				HasGetAQuote: false,
				GetAQuote: "Obtenir un devis",
				HasPrevious: true,
				Previous: "Précédent",
				HasSubmit: true,
				Submit: "Suivant"
			},
			OnSubmit: [
				{
					Id : 0,
					Type : "ApiCall",
					ResultAction: "Next",
					Target: "", 
					TargetStep: "",
					Mapping : {
						HasMapping: true,
						MappingType: "Array",
						SourceStep : "Loan",
						SourceModule: "quotation",
						SubValues : [
							{ ResponseObjectName: "", Name : "LoanId", FormName: "LoanId", Mapping: "LoanId", FieldType: "FieldValue", Type: "integer", FixedValue: "" },
							{ ResponseObjectName: "", Name : "Kind", FormName: "Kind", Mapping: "Value", FieldType: "ObjectValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "Amount", FormName: "Amount", Mapping: "Amount", FieldType: "FieldValue", Type: "float", FixedValue: "" },
							// { ResponseObjectName: "", Name : "ReleaseDate", FormName: "ReleaseDate", Mapping: "ReleaseDate", FieldType: "FixeValue", Type: "string", FixedValue: "2018-11-15" },
							{ ResponseObjectName: "", Name : "Rate", FormName: "Rate", Mapping: "Rate", FieldType: "FieldValue", Type: "float", FixedValue: "" },
							{ ResponseObjectName: "", Name : "RateType", FormName: "RateType", Mapping: "RateType", FieldType: "FieldValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "VariableMin", FormName: "VariableMin", Mapping: "VariableMin", FieldType: "FieldValue", Type: "float", FixedValue: "" },
							{ ResponseObjectName: "", Name : "VariableMax", FormName: "VariableMax", Mapping: "VariableMax", FieldType: "FieldValue", Type: "float", FixedValue: "" },
							{ ResponseObjectName: "", Name : "PaymentFrequency", FormName: "PaymentFrequency", Mapping: "PaymentFrequency", FieldType: "FieldValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "Deferral", FormName: "Deferral", Mapping: "Deferral", FieldType: "FieldValue", Type: "integer", FixedValue: "" },
							{ ResponseObjectName: "", Name : "DeferralType", FormName: "DeferralType", Mapping: "Value", FieldType: "ObjectValue", Type: "string", FixedValue: "" },
							// { ResponseObjectName: "", Name : "DurationUnit", FormName: "Duration", Mapping: "Duration", FieldType: "FixeValue", Type: "string", FixedValue: "month" },
							{ ResponseObjectName: "", Name : "Duration", FormName: "Duration", Mapping: "Duration", FieldType: "FieldValue", Type: "integer", FixedValue: "" },
							// { ResponseObjectName: "", Name : "FirstPaymentDate", FormName: "", Mapping: "", FieldType: "FieldValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "Lender", Name : "LenderId", FormName: "Lender", Mapping: "Id", FieldType: "ObjectValue", Type: "integer", FixedValue: "" },
							{ ResponseObjectName: "Lender", Name : "RecName", FormName: "Lender", Mapping: "Label", FieldType: "ObjectValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "LenderAddress", Name : "LenderAddressId", FormName: "LenderAddress", Mapping: "Id", FieldType: "ObjectValue", Type: "integer", FixedValue: "" },
							{ ResponseObjectName: "LenderAddress", Name : "RecName", FormName: "LenderAddress", Mapping: "Label", FieldType: "ObjectValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "Increments", FormName: "Increments", Mapping: "Increments", FieldType: "ArrayValue", Type: "array", FixedValue: "" }
						]
					}, 
					Params: { 
						Method: "PUT", 
						Url: "/quotations/{{quotation_id}}/loan",
						VariableType: "FromService",
						VariableName: "quotation_id",
						HasVariable: true
					},
					HasWaitingPopup: false,
					WaitingPopup: {
						Text: ""
					}
				}
			],
			OnCancel: [
				{
					Id : 0,
					Type : "ApiCall",
					ResultAction: "Cancel",
					Target: "", 
					TargetStep: "",
					Mapping : { HasMapping: false }, 
					Params: { 
						Method: "PUT", 
						Url: "/quotations/{{quotation_id}}/cancel",
						VariableType: "FromService",
						VariableName: "quotation_id",
						HasVariable: true
					},
					HasWaitingPopup: false,
					WaitingPopup: {
						Text: ""
					}
				}
			],
			OnGetQuote: [],
			OnBack: [],
			HasSuccessMessage: false,
			OnSuccessMessage: {}						
		},
		{
			Name : "Covered",
			Label : "Emprunteur(s)",
			InitActions : [
				{
					Id : 3, 
					Type : "ApiCall_init", 
					ResultAction : "FillSelect", 
					Target: "BirthCountry", 
					TargetStep: "Covered",
					Mapping : { Id: "code", Value: "Country", Label: "Country" },
					Params: { Method: "GET", Url: "/search/countries", } 
				},	
				{
					Id : 31, 
					Type : "ApiCall_init", 
					ResultAction : "FillSelect", 
					Target: "Country", 
					TargetStep: "Covered",
					Mapping : { Id: "code", Value: "Country", Label: "Country" },
					Params: { Method: "GET", Url: "/search/countries", } 
				},	
				{
					Id: 16, 
					Type: "AfterInit", 
					ResultAction : "setIndex", 
					Target: "BorrowerIndex", 
					TargetStep: "Covered",
					Value : "",	
					Property : "",
					Mapping : {},  
					ReplaceMethod: "",
					Params: [
						{ Type: "SameStepDetailsLength", Target: "quotation", TargetStep: "CoveredExtra", ExtraFields: []},
						{ Type: "SameStepDetailsLength", Target: "quotation", TargetStep: "CoveredExtraOthers", ExtraFields: []},
						{ Type: "SameStepDetailsLength", Target: "quotation", TargetStep: "cotisations", ExtraFields: []},
						{ Type: "SameStepDetailsLength", Target: "quotation", TargetStep: "Garantees", ExtraFields: [ {SourceModule: "quotation", SourceStep: "Loan", SourceField: "LoanId"} ] },
						{ Type: "SameStepDetailsLength", Target: "subscription", TargetStep: "medicaland_financial_infos", ExtraFields: []},
						// { Type: "SameStepDetailsLength", Target: "upload", TargetStep: "upload", ExtraFields: []}
					]
				},
				{
					Id: 0, 
					Type: "AfterInit", 
					ResultAction : "SetValueFromCurrentStepFirstSubDetails", 
					Target: "Address1", 
					TargetStep: "Covered",
					Mapping : {}, 
					Params: { 								
						SourceValueForm: "Covered",
						SourceValueField: "Address1",	
					} 
				},
				{
					Id: 1, 
					Type: "AfterInit", 
					ResultAction : "SetValueFromCurrentStepFirstSubDetails", 
					Target: "Address2", 
					TargetStep: "Covered",
					Mapping : {}, 
					Params: { 								
						SourceValueForm: "Covered",
						SourceValueField: "Address2",	
					} 
				},
				{
					Id: 2, 
					Type: "AfterInit", 
					ResultAction : "SetValueFromCurrentStepFirstSubDetails", 
					Target: "Postcode", 
					TargetStep: "Covered",
					Mapping : {}, 
					Params: { 								
						SourceValueForm: "Covered",
						SourceValueField: "Postcode",	
					} 
				},
				{
					Id: 0, 
					Type: "AfterInit", 
					ResultAction : "SetValueFromCurrentStepFirstSubDetails", 
					Target: "City", 
					TargetStep: "Covered",
					Mapping : {}, 
					Params: { 								
						SourceValueForm: "Covered",
						SourceValueField: "City",	
					} 
				},
				{
					Id: 0, 
					Type: "AfterInit", 
					ResultAction : "SetValueFromCurrentStepFirstSubDetails", 
					Target: "Country", 
					TargetStep: "Covered",
					Mapping : {}, 
					Params: { 								
						SourceValueForm: "Covered",
						SourceValueField: "Country",	
					} 
				},
				{
					Id: 14, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "BirthDate",
					TargetStep: "Covered",
					TargetModule: "quotation",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" },
						{ 
							Type : "GreaterThan18Y", 
							Params : {
								Unit: "year", 
								Val: -18,
								CompareValueForm: "Covered",
								CompareValueModule: "quotation",
								CompareValueField: "BirthDate",	
							} 
						},
						{ 
							Type : "LowerThan85Y", 
							Params : {
								Unit: "year", 
								Val: -85,
								CompareValueForm: "Covered",
								CompareValueModule: "quotation",
								CompareValueField: "BirthDate",	
							} 
						},
						{
							Type : "IsDateValid",
							Params :{
								CompareValueForm: "Covered",
								CompareValueModule: "quotation",
								CompareValueField: "BirthDate",	
							}
						},
						{
							Type : "max90Y",
							Params :{
								CompareValueForm: "Loan",
								CompareValueModule: "quotation",
								CompareValueField: "Duration",	
							}
						}
					],
					Params: [
						{ 
							Operator : "NO",
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "Covered",							
							CompareValueModule: "quotation",
							CompareValueField: "BirthDate",				
							CompareValue : ""
						}
					]
				},
				{
					Id: 0, 
					Type: "ApiCall_init", 
					ResultAction : "FillSelect", 
					Target: "City", 
					TargetStep: "Covered",
					ValuesPropertyName: "cities",
					Mapping : { Id: "city", Value: "city", Label: "city", Postcode: "code", City: "city", Country: "country" }, 
					Params: { 
						Method: "GET", 
						Url: "/search/cities/{{Postcode}}",
						HasVariable: true,
						Variables: [
							{
								MinLength: 3,
								Type: "FromForm", 
								Name: "Postcode" 
							}
						]
					} 
				}
			],	
			HasRightBar : true,	
			RightBar : {
				GoToItemBtnCaption_First: "Emprunteur principal",				
				GoToItemBtnCaption: "Co-emprunteur",
				HasItemSubDetails: true,
				ItemSubDetails : {
					TargetStep: "Covered",
					TargetFields: [
						{ Name: "Gender", Type: "Short", Mapping: { "Monsieur" : "M.", "Madame" : "Mme"} },
						{ Name: "FirstName", Type: "Full", Mapping: {} },
						{ Name: "LastName", Type: "Full", Mapping: {} }						
					]
				},
				HasAddItemBtn: true,
				AddItemBtnCaption: "Ajouter un <br />co-emprunteur",
				ExtraActions : [
					{ Type: "SameStepDetailsLength", Target: "quotation", TargetStep: "CoveredExtra"},
					{ Type: "SameStepDetailsLength", Target: "quotation", TargetStep: "CoveredExtraOthers"},
					{ Type: "SameStepDetailsLength", Target: "quotation", TargetStep: "cotisations"},
					{ Type: "SameStepDetailsLength", Target: "quotation", TargetStep: "Garantees"},
					{ Type: "SameStepDetailsLength", Target: "subscription", TargetStep: "medicaland_financial_infos"},
					// { Type: "SameStepDetailsLength", Target: "upload", TargetStep: "upload"}
				],
				HasTrashBtnFirst: false,
				HasTrashBtnNext: true,
				TrashExtraActions : [
					{ Type: "RemoveSubStepDetails", Source: "quotation", Target: "quotation", TargetStep: "CoveredExtra"},
					{ Type: "RemoveSubStepDetails", Source: "quotation", Target: "quotation", TargetStep: "CoveredExtraOthers"},
					{ Type: "RemoveSubStepDetails", Source: "quotation", Target: "quotation", TargetStep: "cotisations"},
					{ Type: "RemoveSubStepDetails", Source: "quotation", Target: "quotation", TargetStep: "Garantees"},
					{ Type: "RemoveSubStepDetails", Source: "quotation", Target: "subscription", TargetStep: "medicaland_financial_infos"},
					// { Type: "RemoveSubStepDetails", Source: "quotation", Target: "upload", TargetStep: "upload"}
				]
			},
			HasNavigationBar: false,	
			NavigationBarActions: [],
			Controls : [
				{
					Name : "BorrowerIndex",
					Type : "hidden",
					CustomClass : "",
					Label : "",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ReadOnly: false,
					ToHide: false,
					Multiple: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					HasTopRightButton: false,
					TopRightButtonLabel: "",
					TopRightButtonCtrlError: "",
					TopRightButtonAction: {},					
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []
				},	
				{
					Name : "Gender",
					Type : "btn_radio",
					CustomClass : "size-2 size-1-md clearer",
					Label : "",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [
						{ Id: 0, Value: "Monsieur", Label: "Monsieur" },
						{ Id: 1, Value: "Madame", Label: "Madame" }
					],
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "La civilité est requise"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: ""				
				},
				{
					Name : "LastName",
					Type : "text",
					CustomClass : "size-2",
					Label : "Nom",
					UpperCase : true,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [],
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le nom est requis"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: ""				
				},
				{
					Name : "FirstName",
					Type : "text",
					CustomClass : "size-2",
					Label : "Prénom",
					UpperCase : true,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [],
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le prénom est requis"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: ""			
				},
				{
					Name : "BirthDate",
					Type : "datestring",
					CustomClass : "size-2",
					Label : "Date de naissance",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					ReadOnly: false,
					Values : [],
					Mask : "00/00/0000",
					Validators : [
						{ Type : "required", Params : "" },
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "La date de naissance est requise",
						"pattern" : "La date n'est pas valide.",
						"max90Y" : "L'assuré(e) doit être âgé(e) de moins de 90 ans jusqu'à la fin du prêt le plus long. La souscription est impossible.",
						"GreaterThan18Y" : "L'assuré(e) doit être âgé(e) de 18 ans minimum. La souscription est impossible.",
						"LowerThan85Y" : "L'assuré(e) doit être âgé(e) de moins de 85 ans. La souscription est impossible."
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: ""			
				},
				{
					Name : "BirthCountry",
					Type : "select2",
					CustomClass : "size-2",
					Label : "Pays de naissance",
					UpperCase : true,
					Capitalize: false,
					IgnoreCaseFormat: false,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [],
					Searchable: true,
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le pays de naissance est requis"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : [
						// {
						// 	Id: 0, 
						// 	Type: "ApiCall_whenChange", 
						// 	ResultAction : "FillSelect", 
						// 	Target: "BirthCountry", 
						// 	TargetStep: "Covered",
						// 	MinLength: 2,
						// 	ReplaceMethod: "Keep",
						// 	ValuesPropertyName: "countries",
						// 	Mapping : { Id: "code", Value: "Country", Label: "Country" }, 
						// 	Params: { 
						// 		Method: "GET", 
						// 		Url: "/search/countries/{{BirthCountry}}",
						// 		HasVariable: true,
						// 		Variables: [
						// 			{
						// 				Type: "FromForm", 
						// 				Name: "BirthCountry" 
						// 			}
						// 		]
						// 	} 
						// }
					]				
				},
				{
					Name : "Phone",
					Type : "tel",
					CustomClass : "size-2",
					Label : "Téléphone portable",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					maxlength: 10,
					max: "",
					Mask : "0*",
					Values : [],
					Validators : [
						{ Type : "required", Params : "" },
						{ Type : "mobile", Params : "" }						
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le téléphone est requis",
						"pattern" : "Le numéro de téléphone renseigné doit être un numéro de téléphone portable et commencer par « 06 » ou « 07 »"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: ""				
				},
				{
					Name : "Email",
					Type : "text",
					CustomClass : "size-2",
					Label : "E-mail",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [],
					Validators : [
						{ Type : "required", Params : "" },
						{ Type : "email", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le mail est requis",
						"pattern" : "L’adresse e-mail saisie est incorrecte."
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: ""		
				},
				{
					Name : "Address1",
					Type : "text",
					CustomClass : "size-2",
					Label : "Adresse",
					UpperCase : true,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [],
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "L'adresse est requise"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: ""			
				},
				{
					Name : "Address2",
					Type : "text",
					CustomClass : "size-2",
					Label : "Complément d'adresse",
					UpperCase : true,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [],
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le complément d'adresse est requis"
					},
					HHasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: ""				
				},
				{
					Name : "Postcode",
					Type : "text",
					CustomClass : "size-3",
					Label : "Code postal",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					Mask: "9A999",
					ToHide: false,
					Disabled: false,
					Values : [],
					Validators : [
						{ Type : "required", Params : "" },
						{ Type : "forbindenDOMTOMPostCode", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le code postal est requis",
						"pattern" : "Conformément à notre politique de risques, la souscription de l’offre est impossible pour les territoires suivants : Guyane, Mayotte et Polynésie Française."
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : [
						{
							Id: 0, 
							Type: "ApiCall_whenChange", 
							ResultAction : "FillSelect", 
							Target: "City", 
							TargetStep: "Covered",
							MinLength: 3,
							AutoSelectFirst: false,
							ReplaceMethod: "Always",
							ValuesPropertyName: "cities",
							Mapping : { Id: "city", Value: "city", Label: "city", Postcode: "code", City: "city", Country: "country" }, 
							Params: { 
								Method: "GET", 
								Url: "/search/cities/{{Postcode}}",
								HasVariable: true,
								Variables: [
									{
										Type: "FromForm", 
										Name: "Postcode" 
									}
								]
							} 
						}
					]		
				},
				{
					Name : "City",
					Type : "select2",
					CustomClass : "size-3",
					Label : "Ville",
					UpperCase : true,
					Capitalize: false,
					IgnoreCaseFormat: false,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [],
					Searchable: true,
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "La ville est requise"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : [
						{
							Id: 0, 
							Type: "OnChange", 
							ResultAction : "SetValue_whenChange_fromSelect", 
							Target: "Postcode",
							TargetStep: "Covered",
							Mapping : { Source: "Postcode" }, 
							Params: {} 
						},
						{
							Id: 1, 
							Type: "OnChange", 
							ResultAction : "SetValue_whenChange_fromSelect", 
							Target: "Country",
							TargetStep: "Covered",
							Mapping : { Source: "Country" }, 
							Params: {} 
						}
					]				
				},
				{
					Name : "Country",
					Type : "select2",
					CustomClass : "size-3",
					Label : "Pays",
					UpperCase : true,
					Capitalize: false,
					IgnoreCaseFormat: false,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [],
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le pays est requis"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []
				}
			],
			BtnLabels: {
				HasCancel: true,
				Cancel: "Abandonner",
				HasSaveAndQuit: false,
				SaveAndQuit: "Sauvegarder et quitter",
				HasGetAQuote: false,
				GetAQuote: "Obtenir un devis",
				HasPrevious: true,
				Previous: "Précédent",
				HasSubmit: true,
				Submit: "Suivant"
			},
			OnSubmit: [
				{
					Id : 0,
					Type : "ApiCall",
					ResultAction: "Next",
					Target: "", 
					TargetStep: "",
					Mapping : {
						HasMapping: true,
						MappingType: "Array",
						SourceStep : "Covered",
						SourceModule: "quotation",
						SubValues : [
							{ ResponseObjectName: "", Name : "BorrowerIndex", FormName: "BorrowerIndex", Mapping: "BorrowerIndex", FieldType: "FieldValue", Type: "integer", FixedValue: "" },
							{ ResponseObjectName: "", Name : "Gender", FormName: "Gender", Mapping: "Gender", FieldType: "FieldValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "LastName", FormName: "LastName", Mapping: "LastName", FieldType: "FieldValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "FirstName", FormName: "FirstName", Mapping: "FirstName", FieldType: "FieldValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "BirthDate", FormName: "BirthDate", Mapping: "BirthDate", FieldType: "FieldValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "BirthCountry", FormName: "BirthCountry", Mapping: "Id", FieldType: "ObjectValue", Type: "", FixedValue: "" },
							{ ResponseObjectName: "", Name : "Phone", FormName: "Phone", Mapping: "Phone", FieldType: "FieldValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "Email", FormName: "Email", Mapping: "Email", FieldType: "FieldValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "Address1", FormName: "Address1", Mapping: "Address1", FieldType: "FieldValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "Address2", FormName: "Address2", Mapping: "Address2", FieldType: "FieldValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "Postcode", FormName: "Postcode", Mapping: "Postcode", FieldType: "FieldValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "City", FormName: "City", Mapping: "Value", FieldType: "ObjectValue", Type: "", FixedValue: "" },
							{ ResponseObjectName: "", Name : "Country", FormName: "Country", Mapping: "Country", FieldType: "FieldValue", Type: "string", FixedValue: "" }							
						]
					}, 
					Params: { 
						Method: "PUT", 
						Url: "/quotations/{{quotation_id}}/covered",
						VariableType: "FromService",
						VariableName: "quotation_id",
						HasVariable: true
					},
					HasWaitingPopup: false,
					WaitingPopup: {
						Text: ""
					}
				}
			],
			OnCancel: [
				{
					Id : 0,
					Type : "ApiCall",
					ResultAction: "Cancel",
					Target: "", 
					TargetStep: "",
					Mapping : { HasMapping: false }, 
					Params: { 
						Method: "PUT", 
						Url: "/quotations/{{quotation_id}}/cancel",
						VariableType: "FromService",
						VariableName: "quotation_id",
						HasVariable: true
					},
					HasWaitingPopup: false,
					WaitingPopup: {
						Text: ""
					}
				}
			],
			OnGetQuote: [],
			OnBack: [],
			HasSuccessMessage: false,
			OnSuccessMessage: {}	
		},
		{
			Name : "CoveredExtra",
			Label : "Emprunteur(s) : Informations professionnelles",
			InitActions : [
				// {
				// 	Id: 0, 
				// 	Type: "AfterInit", 
				// 	ResultAction : "SetOtherFieldValidators", 
				// 	Target: "JobType",
				// 	TargetStep: "CoveredExtra",
				// 	TargetModule: "quotation",
				// 	Mapping : {}, 
				// 	SetValidators: [
				// 		{ Type : "clearValidators", Params : "" },						
				// 	],
				// 	Params: [
				// 		{ 
				// 			Operator : "=",
				// 			CompareValueType: "fromCurrentStep",	
				// 			CompareValueForm: "CoveredExtra",							
				// 			CompareValueModule: "quotation",
				// 			CompareValueField: "dangerousJob_radio",				
				// 			CompareValue : "Non"
				// 		}
				// 	]
				// },
				// {
				// 	Id: 1, 
				// 	Type: "AfterInit", 
				// 	ResultAction : "SetProperty", 
				// 	Target: "JobType",
				// 	TargetStep: "CoveredExtra",
				// 	TargetModule: "quotation",
				// 	Property: "Value",
				// 	Value: "",
				// 	Mapping : {}, 
				// 	Params: [
				// 		{ 
				// 			Operator : "=",
				// 			Value : "",
				// 			CompareValueType: "Fixe",	
				// 			CompareValue : "Non",
				// 			CompareValueField: "dangerousJob_radio",				
				// 			CompareValueForm: "CoveredExtra",
				// 			CompareValueModule: "quotation",
				// 		}
				// 	] 
				// },
				// {
				// 	Id: 2, 
				// 	Type: "AfterInit", 
				// 	ResultAction : "SetProperty", 
				// 	Target: "JobType",
				// 	TargetStep: "CoveredExtra",
				// 	TargetModule: "quotation",
				// 	Property: "Disabled",
				// 	Value: true,
				// 	Mapping : {}, 
				// 	Params: [
				// 		{ 
				// 			Operator : "=",
				// 			Value : "",
				// 			CompareValueType: "Fixe",	
				// 			CompareValue : "Non",
				// 			CompareValueField: "dangerousJob_radio",				
				// 			CompareValueForm: "CoveredExtra",
				// 			CompareValueModule: "quotation",
				// 		}
				// 	] 
				// },
				{
					Id: 3, 
					Type: "AfterInit", 
					ResultAction : "ExecuteCSP", 
					Target: "Csp",
					TargetStep: "CoveredExtra",
					TargetModule: "quotation",
					Property: "Disabled",
					Value: true,
					Mapping : {}, 
					Params: [] 
				}
			],
			HasRightBar : true,
			RightBar : {
				GoToItemBtnCaption_First: "Emprunteur principal",				
				GoToItemBtnCaption: "Co-emprunteur",
				HasItemSubDetails: true,
				ItemSubDetails : {
					TargetStep: "Covered",
					TargetFields: [
						{ Name: "Gender", Type: "Short", Mapping: { "Monsieur" : "M.", "Madame" : "Mme"} },
						{ Name: "FirstName", Type: "Full", Mapping: {} },
						{ Name: "LastName", Type: "Full", Mapping: {} }						
					]
				},
				HasAddItemBtn: false,
				AddItemBtnCaption: "",
				ExtraActions : []
			},
			HasNavigationBar: false,	
			NavigationBarActions: [],
			Controls : [
				{
					Name : "BorrowerIndex",
					Type : "hidden",
					CustomClass : "",
					Label : "",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ReadOnly: false,
					ToHide: false,
					Multiple: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					HasTopRightButton: false,
					TopRightButtonLabel: "",
					TopRightButtonCtrlError: "",
					TopRightButtonAction: {},					
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []
				},
				{
					Name : "StatutProfessionnel",
					Type : "select2",
					CustomClass : "size-2",
					Label : "Statut professionnel",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Multiple: false,
					Disabled: false,
					Values : [
						{ Id : 0, Value : 0, Label : "Salarié cadre, fonctionnaire classe A"},
						{ Id : 1, Value : 1, Label : "Salarie non cadre, fonctionnaire hors classe A"},
						{ Id : 2, Value : 2, Label : "Profession libérale (hors médical et paramédical)"},
						{ Id : 3, Value : 3, Label : "Profession médicale"},
						{ Id : 4, Value : 4, Label : "Profession paramédicale"},
						{ Id : 5, Value : 5, Label : "Gérant, chef d’entreprise"},
						{ Id : 6, Value : 6, Label : "Artisan, commerçant"},
						{ Id : 7, Value : 7, Label : "Agriculteur exploitant / profession agricole"},
						{ Id : 8, Value : 8, Label : "Profession du transport de passagers ou de marchandises"},
						{ Id : 9, Value : 9, Label : "Professionnel du spectacle, forain"},
						{ Id : 10, Value : 10, Label : "Retraité, préretraité"},
						{ Id : 11, Value : 11, Label : "Intérimaire, intermittent, saisonnier"},
						{ Id : 12, Value : 12, Label : "Sans profession, au foyer"}
					],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "La nature du projet est requis"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions:[
						// {
						// 	Id: 0, 
						// 	Type: "OnChange", 
						// 	ResultAction : "SetOtherFieldValue", 
						// 	Target: "Csp",
						// 	TargetStep: "CoveredExtra",
						// 	Mapping : {}, 
						// 	Params: [
						// 		{ 
						// 			Operator : "=",
						// 			Value : "Amortissables",
						// 			SetValidators: [
						// 				{ Type : "required", Params : "" },
						// 				{ Type : "maxValue420", Params : "" }
						// 			]
						// 		}    
						// 	]
						// },
						{
							Id: 1, 
							Type: "OnChange", 
							ResultAction : "ExecuteCSP", 
							Target: "Csp",
							TargetStep: "CoveredExtra",
							Mapping : {}, 
							Params: {} 
						}
					]				
				},
				{
					Name : "JobType",
					Type : "select2",
					CustomClass : "size-2",
					Label : "Votre profession fait elle partie de la liste",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : { Id: 0, Value: 0, Label: "Non"},
					ToHide: false,
					Multiple: false,
					Disabled: false,
					Values : [
						{ Id: 0, Value: 0, Label: "Non"},
						{ Id: 1, Value: 1, Label: "Arts divinatoires"},
						{ Id: 2, Value: 2, Label: "Arts et spectacles (audiovisuel, information, cirque, forain,…)"},
						{ Id: 3, Value: 3, Label: "Professions avec activité en mer, docker, activité sur chantier naval"},
						{ Id: 4, Value: 4, Label: "Professions de sécurité, surveillance, convoyeurs de fonds"},
						{ Id: 5, Value: 5, Label: "Professions relatives au maintien de l'ordre, militaire, professions avec port ou manipulation d'armes"},
						{ Id: 6, Value: 6, Label: "Professions de secours, pompiers"},
						{ Id: 7, Value: 7, Label: "Travail souterrain ou en galerie, travail de la mine, spéléologue, travail en grande hauteur"},
						{ Id: 8, Value: 8, Label: "Sportifs professionnels et encadrants professionnels (entraineur, arbitre,…)"},
						{ Id: 9, Value: 9, Label: "Guide haute montagne, moniteur de ski"},
						{ Id: 10, Value: 10, Label: "Métiers de l'aviation (hors compagnie aérienne régulière)"},
						{ Id: 11, Value: 11, Label: "Métiers du pétrole/gaz/nucléaire"},
						{ Id: 12, Value: 12, Label: "Travail sur site dangereux"},
						{ Id: 13, Value: 13, Label: "Manipulation d’explosifs et/ou de matières dangereuses"},
						{ Id: 14, Value: 14, Label: "Guide de chasse, guide de tourisme"},
						{ Id: 15, Value: 15, Label: "Journaliste/reporter/photographe sans frontière"},
						{ Id: 16, Value: 16, Label: "Médecin/infirmier/vétérinaire sans frontière"},
						{ Id: 17, Value: 17, Label: "Professions ecclésiastiques ou professions liées à des missions humanitaires"}
					],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le choix du métier est requis"
					},
					HasHelper : true,
					HelperPosition: "", 
					HelperTitle: "",
					HelperBody: "La liste déroulante permet de préciser le type de métier à risque exercé.",
					Actions:[
						{
							Id: 0, 
							Type: "OnChange", 
							ResultAction : "ExecuteCSP", 
							Target: "Csp",
							TargetStep: "CoveredExtra",
							Mapping : {}, 
							Params: {} 
						}
					]				
				},
				{
					Name : "Profession",
					Type : "text",
					CustomClass : "size-2",
					Label : "Profession",
					UpperCase : true,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "La professions est requise"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions:[]		
				},
				{
					Name : "Deplacements",
					Type : "btn_radio",
					CustomClass : "size-2",
					Label : "Déplacements professionnels",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [
						{ Id: 0, Value: "0", Label: "-20 000 kms / an" },
						{ Id: 1, Value: "1", Label: "+20 000 kms / an" }
					],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "La nature du projet est requis"
					},
					HasHelper : true,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "L’emprunteur effectue des déplacements professionnels (hors trajet domicile/travail) au moyen d’un véhicule terrestre à moteur, hors trajet effectué en qualité de passager à bord d’un véhicule de transport de personnes pris en charge par une entreprise habilitée à cet effet.",
					Actions:[
						{
							Id: 0, 
							Type: "OnClick", 
							ResultAction : "ExecuteCSP", 
							Target: "Csp",
							TargetStep: "CoveredExtra",
							Mapping : {}, 
							Params: {} 
						}
					]				
				},
				{
					Name : "ActiviteMannuelle",
					Type : "btn_radio",
					CustomClass : "size-1",
					Label : "Activité manuelle",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [
						{ Id: 0, Value: "0", Label: "Travail manuel léger" },
						{ Id: 1, Value: "1", Label: "Travail manuel important" },
						{ Id: 2, Value: "2", Label: "Aucun travail manuel" }
					],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "La nature du projet est requis"
					},
					HasHelper : true,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "Par : <br />• « Travail manuel léger », il est entendu la manipulation régulière de charges de moins de 20kg. <br />• « Travail manuel important », il est entendu la manipulation régulière de charges de plus de 20kg ; et/ou l’utilisation de machines et/ou d’outils dangereux et/ou de produits dangereux (c’est-à-dire pour lesquels une protection de sécurité telle que chaussures, lunettes, gants, protection anti-bruit, casque, masque, harnais de sécurité, combinaison est recommandée ou obligatoire).",
					Actions:[
						{
							Id: 0, 
							Type: "OnClick", 
							ResultAction : "ExecuteCSP", 
							Target: "Csp",
							TargetStep: "CoveredExtra",
							Mapping : {}, 
							Params: {} 
						}
					]				
				},
				{
					Name : "TravailEnHauteur",
					Type : "btn_radio",
					CustomClass : "size-2",
					Label : "Travail en hauteur",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [
						{ Id: 0, Value: "0", Label: "Oui" },
						{ Id: 1, Value: "1", Label: "Non" }
					],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "La nature du projet est requis"
					},
					HasHelper : true,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "Un travail est considéré en hauteur si celui-ci implique des activités en hauteur à plus de 15 mètres du sol (toitures, passerelles, charpentes, échelles, échafaudages, plates-formes de travail, etc…).",
					Actions:[
						{
							Id: 0, 
							Type: "OnClick", 
							ResultAction : "ExecuteCSP", 
							Target: "Csp",
							TargetStep: "CoveredExtra",
							Mapping : {}, 
							Params: {} 
						}
					]				
				},															
				{
					Name : "Csp",
					Type : "text",
					CustomClass : "size-2",
					Label : "Catégorie socio-professionnelle",
					UpperCase : true,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: true,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [
						{ Type : "NotCSP4", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "La nature du projet est requis",
						"NotCSP4" : "NotCSP4-NotCSP4-NotCSP4"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions:[]		
				}
			],
			BtnLabels: {
				HasCancel: true,
				Cancel: "Abandonner",
				HasSaveAndQuit: false,
				SaveAndQuit: "Sauvegarder et quitter",
				HasGetAQuote: false,
				GetAQuote: "Obtenir un devis",
				HasPrevious: true,
				Previous: "Précédent",
				HasSubmit: true,
				Submit: "Suivant"
			},
			OnSubmit: [
				{
					Id : 0,
					Type : "ApiCall",
					ResultAction: "Next",
					Target: "", 
					TargetStep: "",
					Mapping : {
						HasMapping: true,
						MappingType: "Array",
						SourceStep : "CoveredExtra",
						SourceModule: "quotation",
						SubValues : [
							{ ResponseObjectName: "", Name : "BorrowerIndex", FormName: "BorrowerIndex", Mapping: "BorrowerIndex", FieldType: "FieldValue", Type: "integer", FixedValue: "" },							
							{ ResponseObjectName: "", Name : "StatutProfessionnel", FormName: "StatutProfessionnel", Mapping: "Value", FieldType: "ObjectValue", Type: "integer", FixedValue: "" },
							{ ResponseObjectName: "", Name : "Deplacements", FormName: "Deplacements", Mapping: "Deplacements", FieldType: "FieldValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "ActiviteMannuelle", FormName: "ActiviteMannuelle", Mapping: "ActiviteMannuelle", FieldType: "FieldValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "TravailEnHauteur", FormName: "TravailEnHauteur", Mapping: "TravailEnHauteur", FieldType: "FieldValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "JobType", FormName: "JobType", Mapping: "Value", FieldType: "ObjectValue", Type: "integer", FixedValue: "" },
							{ ResponseObjectName: "", Name : "Csp", FormName: "Csp", Mapping: "Csp", FieldType: "FieldValue", Type: "string", FixedValue: "" },							
							{ ResponseObjectName: "", Name : "Profession", FormName: "Profession", Mapping: "Profession", FieldType: "FieldValue", Type: "string", FixedValue: "" }							
						]
					}, 
					Params: { 
						Method: "PUT", 
						Url: "/quotations/{{quotation_id}}/covered_extra",
						VariableType: "FromService",
						VariableName: "quotation_id",
						HasVariable: true
					},
					HasWaitingPopup: false,
					WaitingPopup: {
						Text: ""
					}
				}
			],
			OnCancel: [
				{
					Id : 0,
					Type : "ApiCall",
					ResultAction: "Cancel",
					Target: "", 
					TargetStep: "",
					Mapping : { HasMapping: false }, 
					Params: { 
						Method: "PUT", 
						Url: "/quotations/{{quotation_id}}/cancel",
						VariableType: "FromService",
						VariableName: "quotation_id",
						HasVariable: true
					},
					HasWaitingPopup: false,
					WaitingPopup: {
						Text: ""
					}
				}
			],
			OnGetQuote: [],
			OnBack: [],
			HasSuccessMessage: false,
			OnSuccessMessage: {}	
		},
		{
			Name : "CoveredExtraOthers",
			Label : "Emprunteur(s) : Autres informations",
			InitActions : [
				{
					Id: 0, 
					Type: "AfterInit", 
					ResultAction : "SetProperty", 
					Target: "SportPratique",
					TargetStep: "CoveredExtraOthers",
					TargetModule: "quotation",
					Property: "Disabled",
					Value: true,
					Mapping : {}, 
					Params: [
						{ 
							Operator : "=",
							Value : "",
							CompareValueType: "Fixe",	
							CompareValue : "1",
							CompareValueField: "SportARisque",				
							CompareValueForm: "CoveredExtraOthers",
							CompareValueModule: "quotation",
						}
					] 
				},
				{
					Id: 1, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "SportPratique",
					TargetStep: "CoveredExtraOthers",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" }
					],
					Params: [
						{ 
							Operator : "=",
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "CoveredExtraOthers",							
							CompareValueModule: "quotation",
							CompareValueField: "SportARisque",				
							CompareValue : "0"
						}
					]
				}
			],
			HasRightBar : true,
			RightBar : {
				GoToItemBtnCaption_First: "Emprunteur principal",				
				GoToItemBtnCaption: "Co-emprunteur",
				HasItemSubDetails: true,
				ItemSubDetails : {
					TargetStep: "Covered",
					TargetFields: [
						{ Name: "Gender", Type: "Short", Mapping: { "Monsieur" : "M.", "Madame" : "Mme"} },
						{ Name: "FirstName", Type: "Full", Mapping: {} },
						{ Name: "LastName", Type: "Full", Mapping: {} }						
					]
				},
				HasAddItemBtn: false,
				AddItemBtnCaption: "",
				ExtraActions : []
			},
			HasNavigationBar: false,	
			NavigationBarActions: [],
			Controls : [
				{
					Name : "BorrowerIndex",
					Type : "hidden",
					CustomClass : "",
					Label : "",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ReadOnly: false,
					ToHide: false,
					Multiple: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					HasTopRightButton: false,
					TopRightButtonLabel: "",
					TopRightButtonCtrlError: "",
					TopRightButtonAction: {},					
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []
				},
				{
					Name: "Fumeur",
					Type: "btn_radio",
					CustomClass : "size-2 clearer",
					Label: "",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder: "",
					Default: "",
					ToHide: false,
					Disabled: false,
					Values: [
						{ Id: 0, Value: "0", Label: "Fumeur" },
						{ Id: 1, Value: "1", Label: "Non Fumeur" }
					],
					HasRightIndicator: false,
					RightIndicatorType: "",
					RightIndicator: "",
					Validators: [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "L'information Fumeur/Non Fumeur est requise"
					},
					HasHelper: true,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "Pour se déclarer « non fumeur » il faut, soit ne jamais avoir fumé (e-cigarettes comprises), soit ne plus fumer (sans discontinuer) depuis 24 mois.",
					Actions:[]
				},
				{
					Name: "SportARisque",
					Type: "btn_radio",
					CustomClass : "size-2",
					Label: "Pratique d'un sport à risque",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder: "",
					Default: "",
					ToHide: false,
					Disabled: false,
					Values: [
						{ Id: 0, Value: "0", Label: "Oui" },
						{ Id: 1, Value: "1", Label: "Non" }
					],
					HasRightIndicator: false,
					RightIndicatorType: "",
					RightIndicator: "",
					Validators: [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "L'information pratique d'un sport à risque est requise"
					},
					HasHelper: true,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "Les sports à risques sont ceux pour lesquels l’éventualité d’avoir un accident est importante.",
					Actions:[
						{
							Id: 0, 
							Type: "OnClick", 
							ResultAction : "SetOtherFieldValidators", 
							Target: "SportPratique",
							TargetStep: "CoveredExtraOthers",
							Mapping : {}, 
							Params: [
								{ 
									Operator : "=",
									CompareValue : "0",
									SetValidators: [
										{ Type : "required", Params : "" }
									]
								},   
								{ 
									Operator : "=",
									CompareValue : "1",
									SetValidators: [
										{ Type : "clearValidators", Params : "" }
									]
								}
							]
						},
						{
							Id: 1, 
							Type: "OnClick", 
							ResultAction : "DisableField_whenClick", 
							Target: "SportPratique",
							TargetStep: "CoveredExtraOthers",
							Mapping : {}, 
							Params: { 
								Operator : "=",
								Value : "1",
								CompareValueType: "Fixe",	
								CompareValue : ""
							} 
						}
					]
				},
				{
					Name: "SportPratique",
					Type: "select2",
					CustomClass : "size-2",
					Label: "Sport pratiqué",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder: "",
					Default: "",
					ToHide: false,
					Multiple: true,
					Disabled: false,
					Values: [
						{ Id: 0, Value: "0", Label: "Sport(s) pratiqué(s) à titre professionnel ou rémunéré"},
						{ Id: 1, Value: "1", Label: "Paris"},
						{ Id: 2, Value: "2", Label: "Défis"},
						{ Id: 3, Value: "3", Label: "Raids"},
						{ Id: 4, Value: "4", Label: "Tentatives de records"},
						{ Id: 5, Value: "5", Label: "Boxe(s)"},
						{ Id: 6, Value: "6", Label: "Sports de combat"},
						{ Id: 7, Value: "7", Label: "Sports équestres"},
						{ Id: 8, Value: "8", Label: "Sports avec usage d’engins à moteur"},
						{ Id: 9, Value: "9", Label: "Spéléologie"},
						{ Id: 10, Value: "10", Label: "Escalade"},
						{ Id: 11, Value: "11", Label: "Varappe"},
						{ Id: 12, Value: "12", Label: "Motonautisme"},
						{ Id: 13, Value: "13", Label: "Planche à voile"},
						{ Id: 14, Value: "14", Label: "Yachting"},
						{ Id: 15, Value: "15", Label: "Plongée sous-marine"},
						{ Id: 16, Value: "16", Label: "Alpinisme"},
						{ Id: 17, Value: "17", Label: "Sport(s) pratiqué(s) en montagne au-delà de 3000 mètres d’altitude"},
						{ Id: 18, Value: "18", Label: "Kitesurf"},
						{ Id: 19, Value: "19", Label: "Windboard"},
						{ Id: 20, Value: "20", Label: "Funboard"},
						{ Id: 21, Value: "21", Label: "Snowboard"},
						{ Id: 22, Value: "22", Label: "Saut à l’élastique"},
						{ Id: 23, Value: "23", Label: "Parachutisme"},
						{ Id: 24, Value: "24", Label: "Parapente"},
						{ Id: 25, Value: "25", Label: "Wingsuit"},
						{ Id: 26, Value: "26", Label: "Bobsleigh"},
						{ Id: 27, Value: "27", Label: "Toboggan"},
						{ Id: 28, Value: "28", Label: "Skeleton"},
						{ Id: 29, Value: "29", Label: "Saut à ski ou au tremplin"},
						{ Id: 30, Value: "30", Label: "Vol d’essai et vol sur engin non muni de certificat de navigabilité"},
						{ Id: 31, Value: "31", Label: "Acrobatie aérienne"},
						{ Id: 32, Value: "32", Label: "ULM"},
						{ Id: 33, Value: "33", Label: "Deltaplane"}
					],
					HasRightIndicator: false,
					RightIndicatorType: "",
					RightIndicator: "",
					Validators: [],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le choix du sport à risque est requis"
					},
					HasHelper: true,
					HelperPosition: "right",
					HelperTitle: "",
					HelperBody: "Les sports suivants ne sont pas considérés comme à risque : <br />• Boxes et autres sports de combat : dans le cas d’une pratique amateur et hors compétition.<br />• L’escalade et la varappe : dans le cas d’une pratique en salle.<br />• La plongée sous-marine : dans le cadre d’une plongée jusqu’à 30 mètres et pratiquée moins de 20 fois par an et toujours accompagnée. En revanche, les explorations de grottes ou épaves sont bien considérées comme à risque.<br />• Le ski alpin, de fond ou de snowboards sur pistes réglementées, ouvertes et accessibles en remontées mécaniques<br />• Dans le cadre d’une pratique ponctuelle, d’une initiation ou d’un baptême encadrés par un personnel qualifié titulaire des brevets ou autorisations réglementaires exigées, les exclusions sportives ci-dessus ne s’appliquent pas.<br />En revanche, les sports suivants sont considérés comme à risque :<br />• Alpinisme et tous les sports pratiqués en montagne au-delà de 3000 mètres d’altitude<br />• Planche à voile à plus d’un mille des côtes. ",
					Actions:[]
				},				
				{
					Name: "ResidentFiscalFrancais",
					Type: "btn_radio",
					CustomClass : "size-2 clearer",
					Label: "Résident fiscal français",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder: "",
					Default: "",
					ToHide: false,
					Disabled: false,
					Values: [
						{ Id: 0, Value: "0", Label: "Oui" },
						{ Id: 1, Value: "1", Label: "Non" }
					],
					HasRightIndicator: false,
					RightIndicatorType: "",
					RightIndicator: "",
					Validators: [
						{ Type : "required", Params : "" },
						{ 
							Type : "EqualsTo", 
							Params : {
								Unit: "", 
								Val: "0",
								CompareValueForm: "",
								CompareValueModule: "",
								CompareValueField: "",	

							} 
						}
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "L'information résident fiscal français est requise",
						"EqualsTo" : "Vous devez être résident fiscal français pour pouvoir souscrire."
					},
					HasHelper: false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "Une personne est considérée comme résident fiscal français si :<br />• Son foyer ou son lieu de séjour principal est en France. Il s'agit de la résidence habituelle de la personne ou de sa famille (enfant et conjoint). Une personne travaillant à l'étranger pourra être ainsi considérée comme résident fiscal français si sa famille habite en France. Il suffit de séjourner plus de 183 jours en France (y compris à l'hôtel) pour remplir cette condition.    <br />• Elle exerce en France une activité professionnelle, salariée ou non, à moins que cette activité y soit exercée à titre accessoire. Est considérée comme principale et donc non accessoire une activité à laquelle l'intéressé consacre plus de la moitié de son temps, ou, à défaut, qui lui procure plus de la moitié de ses revenus mondiaux.    <br />• Elle a en France le centre de ses intérêts économiques, par exemple : la destination des investissements, le siège social de sociétés, le lieu d'activité professionnelle, etc.<br />• Elle est agent de l'Etat et exerce ses fonctions ou est chargée de mission dans un pays étranger et n’est pas soumise dans ce pays à un impôt personnel sur l'ensemble de ses revenus.",
					Actions:[]
				},
				{
					Name: "PersonnePolitiquementExposee",
					Type: "btn_radio",
					CustomClass : "size-2",
					Label: "Personne politiquement exposée",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder: "",
					Default: "",
					ToHide: false,
					Disabled: false,
					Values: [
						{ Id: 0, Value: "0", Label: "Oui" },
						{ Id: 1, Value: "1", Label: "Non" }
					],
					HasRightIndicator: false,
					RightIndicatorType: "",
					RightIndicator: "",
					Validators: [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "L'information personne politiquement exposée est requise"
					},
					HasHelper: true,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "Une personne est considérée comme politiquement exposée si elle, ou l’un de ses proches, a exercé au cours des 12 derniers mois pour le compte de l'Etat français ou d'un Etat étranger des fonctions publiques, juridictionnelles ou administratives (parlementaire, ambassadeur, membre de direction d'une entreprise publique...).  Il est entendu par «proche», tout membre direct de sa famille ou personnes étroitement associées.",
					Actions:[]
				},
				{
					Name: "ProchePolitiquementExposee",
					Type: "btn_radio",
					CustomClass : "size-2",
					Label: "Proche politiquement exposé",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder: "",
					Default: "",
					ToHide: false,
					Disabled: false,
					Values: [
						{ Id: 0, Value: "0", Label: "Oui" },
						{ Id: 1, Value: "1", Label: "Non" }
					],
					HasRightIndicator: false,
					RightIndicatorType: "",
					RightIndicator: "",
					Validators: [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "L'information proche politiquement exposé est requise"
					},
					HasHelper: true,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "Une personne est considérée comme politiquement exposée si elle, ou l’un de ses proches, a exercé au cours des 12 derniers mois pour le compte de l'Etat français ou d'un Etat étranger des fonctions publiques, juridictionnelles ou administratives (parlementaire, ambassadeur, membre de direction d'une entreprise publique...).  Il est entendu par «proche», tout membre direct de sa famille ou personnes étroitement associées.",
					Actions:[]
				}
			],
			BtnLabels: {
				HasCancel: true,
				Cancel: "Abandonner",
				HasSaveAndQuit: false,
				SaveAndQuit: "Sauvegarder et quitter",
				HasGetAQuote: false,
				GetAQuote: "Obtenir un devis",
				HasPrevious: true,
				Previous: "Précédent",
				HasSubmit: true,
				Submit: "Suivant"
			},
			OnSubmit: [
				{
					Id : 0,
					Type : "ApiCall",
					ResultAction: "Next",
					Target: "", 
					TargetStep: "",
					Mapping : {
						HasMapping: true,
						MappingType: "Array",
						SourceStep : "CoveredExtraOthers",
						SourceModule: "quotation",
						SubValues : [
							{ ResponseObjectName: "", Name : "BorrowerIndex", FormName: "BorrowerIndex", Mapping: "BorrowerIndex", FieldType: "FieldValue", Type: "integer", FixedValue: "" },							
							{ ResponseObjectName: "", Name : "Fumeur", FormName: "Fumeur", Mapping: "Fumeur", FieldType: "FieldValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "SportARisque", FormName: "SportARisque", Mapping: "SportARisque", FieldType: "FieldValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "SportPratique", FormName: "SportPratique", Mapping: "Value", FieldType: "ArrayValue", Type: "array", FixedValue: "" },
							{ ResponseObjectName: "", Name : "PersonnePolitiquementExposee", FormName: "PersonnePolitiquementExposee", Mapping: "PersonnePolitiquementExposee", FieldType: "FieldValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "ProchePolitiquementExposee", FormName: "ProchePolitiquementExposee", Mapping: "ProchePolitiquementExposee", FieldType: "FieldValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "ResidentFiscalFrancais", FormName: "ResidentFiscalFrancais", Mapping: "ResidentFiscalFrancais", FieldType: "FieldValue", Type: "string", FixedValue: "" }							
						]
					}, 
					Params: { 
						Method: "PUT", 
						Url: "/quotations/{{quotation_id}}/covered_extra_others",
						VariableType: "FromService",
						VariableName: "quotation_id",
						HasVariable: true
					},
					HasWaitingPopup: false,
					WaitingPopup: {
						Text: ""
					}
				}
			],
			OnCancel: [
				{
					Id : 0,
					Type : "ApiCall",
					ResultAction: "Cancel",
					Target: "", 
					TargetStep: "",
					Mapping : { HasMapping: false }, 
					Params: { 
						Method: "PUT", 
						Url: "/quotations/{{quotation_id}}/cancel",
						VariableType: "FromService",
						VariableName: "quotation_id",
						HasVariable: true
					},
					HasWaitingPopup: false,
					WaitingPopup: {
						Text: ""
					}
				}
			],
			OnGetQuote: [],
			OnBack: [],
			HasSuccessMessage: false,
			OnSuccessMessage: {}	
		},
		{
			Name : "Garantees",
			Label : "Garanties",
			InitActions : [
				{
					Id: 0,
					Type: "AfterInit",
					ResultAction: "ReplaceVariable",
					TargetStep: "Garantees",
					Target: "comment1",					
					TargetType: "comment",
					Params: [
						{
							SourceStep: "Loan",
							SourceField: "Amount",
							Variable: "[X]",
							From: "SubStep"
						}
					]
				},
				{
					Id: 1, 
					Type: "AfterInit", 
					ResultAction : "SetPropertyFromPreviousStep", 
					Target: "Package", 
					TargetStep: "Garantees",
					Property : "Value",
					Value : "deces",	
					Mapping : {}, 
					ReplaceMethod: "WhenEmpty",
					Params: [
						{ 
							Operator : ">=",
							CompareValueType: "getAge",	
							CompareValueForm: "Covered",
							CompareValueModule: "quotation",
							CompareValueField: "BirthDate",				
							CompareValue : 65, 
						}
					] 
				},				
				{
					Id: 6, 
					Type: "AfterInit", 
					ResultAction : "SetPropertyFromPreviousStep", 
					Target: "Franchise", 
					TargetStep: "Garantees",
					Property : "Select",
					Value : [
						{ Id: 0, Value: 0, Label: "30 jours" },
						{ Id: 1, Value: 1, Label: "60 jours" },
						{ Id: 2, Value: 2, Label: "90 jours" },
						{ Id: 3, Value: 3, Label: "120 jours" },
						{ Id: 4, Value: 4, Label: "180 jours" },
					],	
					Mapping : {},  
					ReplaceMethod: "Always",
					Params: [
						{ 
							Operator : "IN",
							CompareValueType: "fromPreviousLoanStep",	
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
							CompareValueField: "Kind",				
							CompareValue : ["fixed_rate", "balloon", "intermediate"], 
						},
						{ 
							Operator : "=",
							CompareValueType: "fromPreviousStep",	
							CompareValueForm: "Covered",
							CompareValueModule: "quotation",
							CompareValueField: "Country",				
							CompareValue : "france", 
						}
					] 
				},
				{
					Id: 7, 
					Type: "AfterInit", 
					ResultAction : "SetPropertyFromPreviousStep", 
					Target: "Franchise", 
					TargetStep: "Garantees",
					Property : "Select",
					Value : [
						{ Id: 2, Value: 2, Label: "90 jours" },
						{ Id: 3, Value: 3, Label: "120 jours" },
						{ Id: 4, Value: 4, Label: "180 jours" },
					],	
					Mapping : {},  
					ReplaceMethod: "Always",
					Params: [
						{ 
							Operator : "IN",
							CompareValueType: "fromPreviousLoanStep",	
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
							CompareValueField: "Kind",				
							CompareValue : ["interest_free", "regroupementdecredits"], 
						},
						{ 
							Operator : "=",
							CompareValueType: "fromPreviousStep",	
							CompareValueForm: "Covered",
							CompareValueModule: "quotation",
							CompareValueField: "Country",				
							CompareValue : "france", 
						}
					] 
				},
				{
					Id: 8, 
					Type: "AfterInit", 
					ResultAction : "SetPropertyFromPreviousStep", 
					Target: "Franchise", 
					TargetStep: "Garantees",
					Property : "Select",
					Value : [
						{ Id: 2, Value: 2, Label: "90 jours" },
						{ Id: 3, Value: 3, Label: "120 jours" },
						{ Id: 4, Value: 4, Label: "180 jours" },
					],	
					Mapping : {},  
					ReplaceMethod: "Always",
					Params: [
						{ 
							Operator : "IN",
							CompareValueType: "fromPreviousLoanStep",	
							CompareValueForm: "Loan",
							CompareValueModule: "quotation",
							CompareValueField: "Kind",				
							CompareValue : ["fixed_rate", "balloon", "intermediate", "interest_free", "regroupementdecredits"], 
						},
						{ 
							Operator : ">",
							CompareValueType: "fromPreviousStep",	
							CompareValueForm: "Covered",
							CompareValueModule: "quotation",
							CompareValueField: "Postcode",				
							CompareValue : 97000, 
						}
					] 
				},							
				{
					Id: 10, 
					Type: "AfterInit", 
					ResultAction : "SetPropertyFromPreviousStep", 
					Target: "ReleaseDate", 
					TargetStep: "Garantees",
					Property : "Label",
					Value : "Date de déblocage des fonds",	
					Mapping : {},  
					ReplaceMethod: "Always",
					Params: [
						{ 
							Operator : "=",
							Value : "",
							CompareValueType: "fromPreviousStep",	
							CompareValueForm: "ContractExtra",
							CompareValueModule: "quotation",
							CompareValueField: "NatureProjet",				
							CompareValue : "adhesion", 
						}
					] 
				},
				// {
				// 	Id: 11, 
				// 	Type: "AfterInit", 
				// 	ResultAction : "SetPropertyFromPreviousStep", 
				// 	Target: "ReleaseDate", 
				// 	TargetStep: "Garantees",
				// 	Property : "Label",
				// 	Value : "Date d'édition de l'offre de prêt",	
				// 	Mapping : {},  
				// 	ReplaceMethod: "Always",
				// 	Params: [
				// 		{ 
				// 			Operator : "IN",
				// 			Value : "",
				// 			CompareValueType: "fromPreviousStep",	
				// 			CompareValueForm: "ContractExtra",
				// 			CompareValueModule: "quotation",
				// 			CompareValueField: "NatureProjet",				
				// 			CompareValue : ["substitution_hamon", "substitution_bourquin"] 
				// 		},
				// 		{ 
				// 			Operator : "IN",
				// 			CompareValueType: "fromPreviousStep",	
				// 			CompareValueForm: "Loan",
				// 			CompareValueModule: "quotation",
				// 			CompareValueField: "Lender",				
				// 			CompareValue : [423], //BOURSORAMA
				// 		}
				// 	] 
				// },
				// {
				// 	Id: 12, 
				// 	Type: "AfterInit", 
				// 	ResultAction : "SetPropertyFromPreviousStep", 
				// 	Target: "ReleaseDate", 
				// 	TargetStep: "Garantees",
				// 	Property : "Label",
				// 	Value : "Date d'effet du contrat d'assurance",	
				// 	Mapping : {},  
				// 	ReplaceMethod: "Always",
				// 	Params: [
				// 		{ 
				// 			Operator : "IN",
				// 			Value : "",
				// 			CompareValueType: "fromPreviousStep",	
				// 			CompareValueForm: "ContractExtra",
				// 			CompareValueModule: "quotation",
				// 			CompareValueField: "NatureProjet",				
				// 			CompareValue : ["substitution_hamon", "substitution_bourquin"] 
				// 		},
				// 		{ 
				// 			Operator : "IN",
				// 			CompareValueType: "fromPreviousStep",	
				// 			CompareValueForm: "Loan",
				// 			CompareValueModule: "quotation",
				// 			CompareValueField: "Lender",				
				// 			CompareValue : [1117], //FORTUNEO
				// 		}
				// 	] 
				// },
				{
					Id: 13, 
					Type: "ShowField_onInit", 
					ResultAction : "ShowField", 
					Target: "EffectiveDate",
					TargetStep: "Garantees",
					Mapping : {}, 
					Params: { 
						Operator : "IN",
						Value : "",
						CompareValueType: "fromPreviousStep",	
						CompareValueForm: "ContractExtra",
						CompareValueModule: "quotation",
						CompareValueField: "NatureProjet",				
						CompareValue : ["substitution_hamon", "substitution_bourquin"] 
					}
				},
				{
					Id: 14, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "EffectiveDate",
					TargetStep: "Garantees",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" },
						{ 
							Type : "DateGreaterThanDateDeblocage", 
							Params : {
								Unit: "year", 
								Val: 0,
								CompareValueForm: "Garantees",
								CompareValueModule: "quotation",
								CompareValueField: "ReleaseDate",	
							} 
						},
						{ 
							Type : "DateBourquin", 
							Params : {
								Unit: "month", 
								Val: 2,
								CompareValueForm: "Garantees",
								CompareValueModule: "quotation",
								CompareValueField: "ReleaseDate",	
							} 
						},
						{ 
							Type : "GreaterThan18YearsOld", 
							Params : {
								Unit: "year", 
								Val: 18,
								CompareValueForm: "Covered",
								CompareValueModule: "quotation",
								CompareValueField: "BirthDate",	
							} 
						},
						{ 
							Type : "LowerThan85YearsOld", 
							Params : {
								Unit: "year", 
								Val: 85,
								CompareValueForm: "Covered",
								CompareValueModule: "quotation",
								CompareValueField: "BirthDate",	
							} 
						},
						{
							Type : "IsDateValid",
							Params :""
						}
					],
					Params: [
						{ 
							Operator : "=",
							CompareValueType: "fromPreviousStep",	
							CompareValueForm: "ContractExtra",							
							CompareValueModule: "quotation",
							CompareValueField: "NatureProjet",				
							CompareValue : "substitution_bourquin"
						}
					]
				},
				{
					Id: 15, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "EffectiveDate",
					TargetStep: "Garantees",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" },
						{ 
							Type : "DateGreaterThanDateDeblocage", 
							Params : {
								Unit: "year", 
								Val: 0,
								CompareValueForm: "Garantees",
								CompareValueModule: "quotation",
								CompareValueField: "ReleaseDate",	
							} 
						},					
						// { 
						// 	Type : "DateHamon", 
						// 	Params : {
						// 		Unit: "day", 
						// 		Val: 15,
						// 		CompareValueForm: "Garantees",
						// 		CompareValueModule: "quotation",
						// 		CompareValueField: "ReleaseDate",	
						// 	}
						// },	
						{ 
							Type : "GreaterThan18YearsOld", 
							Params : {
								Unit: "year", 
								Val: 18,
								CompareValueForm: "Covered",
								CompareValueModule: "quotation",
								CompareValueField: "BirthDate",	
							} 
						},
						{ 
							Type : "LowerThan85YearsOld", 
							Params : {
								Unit: "year", 
								Val: 85,
								CompareValueForm: "Covered",
								CompareValueModule: "quotation",
								CompareValueField: "BirthDate",	
							} 
						},
						{ 
							Type : "LowerThanHamon", 
							Params : {
								Unit: "day", 
								Val: 365,
								CompareValueForm: "Garantees",
								CompareValueModule: "quotation",
								CompareValueField: "ReleaseDate",	
							} 
						},
						{ Type : "HamonEffectiveDateValidity", Params : { Unit: "day", Val: 14 } },
						{ Type : "IsDateValid", Params : "" }
					],
					Params: [
						{ 
							Operator : "=",
							CompareValueType: "fromPreviousStep",	
							CompareValueForm: "ContractExtra",
							CompareValueModule: "quotation",
							CompareValueField: "NatureProjet",				
							CompareValue : "substitution_hamon"
						}
					]
				},
				{
					Id: 16, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "Package",
					TargetStep: "Garantees",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" },
						{ 
							Type : "EqualsTo", 
							Params : {
								Unit: "", 
								Val: "deces",
								CompareValueForm: "",
								CompareValueModule: "",
								CompareValueField: "",	

							} 
						}
					],
					Params: [
						{ 
							Operator : ">=",
							CompareValueType: "getAge",	
							CompareValueForm: "Covered",
							CompareValueModule: "quotation",
							CompareValueField: "BirthDate",				
							CompareValue : 65, 
						}
					]
				},
				{
					Id: 17, 
					Type: "ShowField_onInit", 
					ResultAction : "ShowField", 
					Target: "TypeDePrestation",
					TargetStep: "Garantees",
					Mapping : {}, 
					Params: { 
						Operator : "=",
						Value : "",	
						CompareValueType: "fromCurrentStep",	
						CompareValueForm: "Garantees",
						CompareValueModule: "quotation",
						CompareValueField: "Package",				
						CompareValue : "deces/ptia/ipt/itt",

					} 
				},
				{
					Id: 18, 
					Type: "ShowField_onInit", 
					ResultAction : "ShowField", 
					Target: "TypeDePrestation",
					TargetStep: "Garantees",
					Mapping : {}, 
					Params: { 
						Operator : "=",
						Value : "",	
						CompareValueType: "fromCurrentStep",	
						CompareValueForm: "Garantees",
						CompareValueModule: "quotation",
						CompareValueField: "Package",				
						CompareValue : "deces/ptia/ipt/itt/ipp",

					}
				},
				{
					Id: 17, 
					Type: "ShowField_onInit", 
					ResultAction : "ShowField", 
					Target: "RachatDosEtPsy",
					TargetStep: "Garantees",
					Mapping : {}, 
					Params: { 
						Operator : "=",
						Value : "",	
						CompareValueType: "fromCurrentStep",	
						CompareValueForm: "Garantees",
						CompareValueModule: "quotation",
						CompareValueField: "Package",				
						CompareValue : "deces/ptia/ipt/itt",

					} 
				},
				{
					Id: 18, 
					Type: "ShowField_onInit", 
					ResultAction : "ShowField", 
					Target: "RachatDosEtPsy",
					TargetStep: "Garantees",
					Mapping : {}, 
					Params: { 
						Operator : "=",
						Value : "",	
						CompareValueType: "fromCurrentStep",	
						CompareValueForm: "Garantees",
						CompareValueModule: "quotation",
						CompareValueField: "Package",				
						CompareValue : "deces/ptia/ipt/itt/ipp",

					}
				},
				{
					Id: 19, 
					Type: "AfterInit", 
					ResultAction : "SetFieldWithIndex", 
					Target: "Loan", 
					TargetStep: "Garantees",
					Property : "Value",
					Value : "",	
					Mapping : {},  
					Params: [] 
				},
				{
					Id: 20, 
					Type: "AfterInit", 
					ResultAction : "SetProperty", 
					Target: "Franchise",
					TargetStep: "Garantees",
					TargetModule: "quotation",
					Property: "Value",
					Value: { Id: 2, Value: 2, Label: "90 jours" },
					Mapping : {}, 
					Params: [
						{ 
							Operator : "=",
							Value : "",
							CompareValueType: "Fixe",	
							CompareValue : null,
							CompareValueField: "Franchise",				
							CompareValueForm: "Garantees",
							CompareValueModule: "quotation",
						}
					] 
				},
				{
					Id: 20, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "ReleaseDate",
					TargetStep: "Garantees",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" },
						{ Type : "DateLowerThanNow", Params : "" },											
						{ Type : "IsDateValid", Params : "" },
						// { Type : "GreaterThan", Params : "01/01/2018"}											
					],
					Params: [
						{ 
							Operator : "=",
							CompareValueType: "fromPreviousStep",	
							CompareValueForm: "ContractExtra",
							CompareValueModule: "quotation",
							CompareValueField: "NatureProjet",				
							CompareValue : "substitution_hamon"
						}
					]
				},
				{
					Id: 20, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "ReleaseDate",
					TargetStep: "Garantees",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" },
						{ Type : "DateLowerThanNow", Params : "" },											
						{ Type : "IsDateValid", Params : "" }												
					],
					Params: [
						{ 
							Operator : "=",
							CompareValueType: "fromPreviousStep",	
							CompareValueForm: "ContractExtra",
							CompareValueModule: "quotation",
							CompareValueField: "NatureProjet",

							CompareValue : "substitution_bourquin"
						}
					]
				},
				{
					Id: 30, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "Share",
					TargetStep: "Garantees",
					TargetModule: "quotation",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" },
						{ Type : "CheckAllShare100", Params : "" }
					],
					Params: [
						{ 
							Operator : "NO",
						},   
					]
				},
				{
					Id: 30, 
					Type: "AfterInit", 
					ResultAction : "SetProperty", 
					Target: "EffectiveDate",
					TargetStep: "Garantees",
					TargetModule: "quotation",
					Property: "ReadOnly",
					Value: "0",
					Mapping : {}, 
					Params: [
						{ 
							Operator : "=",
							Value : "",
							CompareValueType: "Fixe",	
							CompareValue : "substitution_bourquin",
							CompareValueField: "NatureProjet",				
							CompareValueForm: "ContractExtra",
							CompareValueModule: "quotation",
						}
					] 
				},
			],
			HasRightBar : true,
			RightBar : {
				GoToItemBtnCaption_First: "Emprunteur principal",				
				GoToItemBtnCaption: "Co-emprunteur",
				HasItemSubDetails: true,
				ItemSubDetails : {
					TargetStep: "Covered",
					TargetFields: [
						{ Name: "Gender", Type: "Short", Mapping: { "Monsieur" : "M.", "Madame" : "Mme"} },
						{ Name: "FirstName", Type: "Full", Mapping: {} },
						{ Name: "LastName", Type: "Full", Mapping: {} }						
					]
				},
				HasAddItemBtn: false,
				AddItemBtnCaption: "",
				ExtraActions : []
			},
			HasNavigationBar: true,	
			NavigationBarActions: [
				{
					Id: 0,
					Type: "OnChange",
					ResultAction: "ReplaceVariable",
					TargetStep: "Garantees",
					Target: "comment1",
					TargetType: "comment",
					Params: [
						{
							SourceStep: "Loan",
							SourceField: "Amount",
							Variable: "[X]"
						}
					]
				}
			],
			Controls : [
				{
					Name : "LoanId",
					Type : "hidden",
					CustomClass : "",
					Label : "",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ReadOnly: false,
					ToHide: false,
					Multiple: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					HasTopRightButton: false,
					TopRightButtonLabel: "",
					TopRightButtonCtrlError: "",
					TopRightButtonAction: {},					
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []
				},
				{
					Name : "BorrowerIndex",
					Type : "hidden",
					CustomClass : "",
					Label : "",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ReadOnly: false,
					ToHide: false,
					Multiple: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					HasTopRightButton: false,
					TopRightButtonLabel: "",
					TopRightButtonCtrlError: "",
					TopRightButtonAction: {},					
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []
				},
				{
					Name : "Loan",
					Type : "btn_radio",
					CustomClass : "size-1",
					Label : "",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {},
					HasHelper : false,
					HelperPosition: "",
					Helper : "",
					Actions:[]				
				},
				{
					Name : "comment1",
					Type : "comment",
					CustomClass : "size-1",
					Comment: "Montant du prêt : [X] €, les garanties ci-dessous sont pré remplies au regard des exigences de l'organisme prêteur mais nous vous invitons tout de même à les vérifier.",
					Label : "",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {},
					HasHelper : false,
					HelperPosition: "",
					Helper : "",
					Actions:[]					
				},
				{
					Name : "comment2",
					Type : "comment",
					CustomClass : "size-1",
					Comment: "Prise d'effet des garanties",
					Label : "",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {},
					HasHelper : false,
					HelperPosition: "",
					Helper : "",
					Actions:[]					
				},				
				{
					Name : "ReleaseDate",
					Type : "datestring",
					CustomClass : "size-2",
					Label : "Date de signature de l'assurance emprunteur",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Mask : "00/00/0000",
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [
						{ Type : "required", Params : "" },						
						{ Type : "DateGreaterThanNow", Params : "" },
						{ Type : "IsDateValid", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "La date est requise",
						"DateGreaterThanNow" : "La date de déblocage des fonds ne peut être antérieure ou identique à la date du jour.",
						"DateLowerThanNow" : "La date de déblocage des fonds ne peut être supérieur à la date du jour dans le cas d'une reprise Hamon ou Bourquin.",
						"pattern" : "La date n'est pas valide.",
						"GreaterThan" : "La date de signature doit être supérieure à 01/01/2018 pour une reprise Hamon."
					},
					HasHelper : false,
					HelperPosition: "",
					Helper : "",
					Actions:[
						{
							Id: 0, 
							Type: "OnChange", 
							ResultAction : "SetAllSubStepDetailsFieldValue_whenChange", 
							Target: "EffectiveDate",
							TargetStep: "Garantees",
							TargetModule: "quotation",
							Mapping : {}, 
							Params: [
								{ 
									Operator : "=",
									CompareValueType: "fromPreviousStep",	
									CompareValueForm: "ContractExtra",
									CompareValueModule: "quotation",
									CompareValueField: "NatureProjet",				
									CompareValue : "substitution_bourquin"
								}
							],
							Action: {
								MinLengthToApply: 10,
								NotcePeriodValue: -2,
								NotcePeriodUnit: "month",
								Operator : "NO",
								Value : 1,
								CompareValueType: "Bourquin_Date+XYears",	
								CompareValue : ""
							} 
						},
						{
							Id: 1, 
							Type: "OnChange", 
							ResultAction : "SetAllSubStepDetailsFieldValue", 
							Target: "ReleaseDate",
							TargetStep: "Garantees",
							TargetModule: "quotation",
							Mapping : {}, 
							Params: {} 
						},
						{
							Id: 2, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidators", 
							Target: "EffectiveDate",
							TargetStep: "Garantees",
							Mapping : {}, 
							SetValidators: [
								{ Type : "required", Params : "" },
								{ 
									Type : "DateGreaterThanDateDeblocage", 
									Params : {
										Unit: "year", 
										Val: 0,
										CompareValueForm: "Garantees",
										CompareValueModule: "quotation",
										CompareValueField: "ReleaseDate",	
									} 
								},	
								{ 
									Type : "GreaterThan18YearsOld", 
									Params : {
										Unit: "year", 
										Val: 18,
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",	
									} 
								},
								{ 
									Type : "LowerThan85YearsOld", 
									Params : {
										Unit: "year", 
										Val: 85,
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",	
									} 
								},
								{ 
									Type : "LowerThanHamon", 
									Params : {
										Unit: "year", 
										Val: 1,
										CompareValueForm: "Garantees",
										CompareValueModule: "quotation",
										CompareValueField: "ReleaseDate",	
									} 
								},
								{ Type : "HamonEffectiveDateValidity", Params : { Unit: "day", Val: 14 } },
								{ Type : "IsDateValid", Params : "" }
							],
							Params: [
								{ 
									Operator : "=",
									CompareValueType: "fromPreviousStep",	
									CompareValueForm: "ContractExtra",
									CompareValueModule: "quotation",
									CompareValueField: "NatureProjet",				
									Value : "substitution_hamon"
								}
							]
						},
						{
							Id: 3, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidators", 
							Target: "EffectiveDate",
							TargetStep: "Garantees",
							Mapping : {}, 
							SetValidators: [
								{ Type : "required", Params : "" },
								{ 
									Type : "DateGreaterThanDateDeblocage", 
									Params : {
										Unit: "year", 
										Val: 0,
										CompareValueForm: "Garantees",
										CompareValueModule: "quotation",
										CompareValueField: "ReleaseDate",	
									} 
								},
								{ 
									Type : "DateBourquin", 
									Params : {
										Unit: "month", 
										Val: 2,
										CompareValueForm: "Garantees",
										CompareValueModule: "quotation",
										CompareValueField: "ReleaseDate",	
									} 
								},
								{ 
									Type : "GreaterThan18YearsOld", 
									Params : {
										Unit: "year", 
										Val: 18,
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",	
									} 
								},
								{ 
									Type : "LowerThan85YearsOld", 
									Params : {
										Unit: "year", 
										Val: 85,
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",	
									} 
								},
								{
									Type : "IsDateValid",
									Params :""
								}
							],
							Params: [
								{ 
									Operator : "=",
									CompareValueType: "fromPreviousStep",	
									CompareValueForm: "ContractExtra",							
									CompareValueModule: "quotation",
									CompareValueField: "NatureProjet",				
									Value : "substitution_bourquin"
								}
							]
						},
					]				
				},
				{
					Name : "EffectiveDate",
					Type : "datestring",
					CustomClass : "size-2",
					Label : "Date de prise d’effet des garanties",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: true,
					Disabled: false,
					Values : [],
					Mask : "00/00/0000",
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [
						{ Type : "IsDateValid", Params : "" }],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "La date est requise",
						"pattern" : "La date n'est pas valide.",
						"DateGreaterThanDateDeblocage" : "La date de prise d'effet doit être supérieure à celle de la signature",
						"DateBourquin" : "Dans le cadre de la Loi Bourquin : deux mois de préavis sont nécessaires pour résilier l’assurance en cours. Le délai étant trop court : nous ne pouvons donner suite à cette demande d’adhésion pour le moment.",
						"DateHamon" : "Dans le cadre de la Loi Hamon : 15 jours de préavis sont nécessaires pour résilier l’assurance en cours. Le délai étant trop court : nous ne pouvons donner suite à cette demande d’adhésion pour le moment.",
						"GreaterThan18YearsOld" : "L'assuré(e) doit être âgé(e) de 18 ans minimum. La souscription est impossible.",
						"LowerThan85YearsOld" : "L’âge de l'assuré(e) doit être inférieur à 85 ans. La souscription est impossible.",
						"LowerThanHamon": "Dans le cadre d'une loi Hamon, la souscription n'est autorisée que dans la première année.",
						"HamonEffectiveDateValidity" : "Dans le cadre de la Loi Hamon : 15 jours de préavis sont nécessaires pour résilier l’assurance en cours. Le délai étant trop court : nous ne pouvons donner suite à cette demande d’adhésion pour le moment.",
					},
					HasHelper : false,
					HelperPosition: "",
					Helper : "",
					Actions:[
						{
							Id: 0, 
							Type: "OnChange", 
							ResultAction : "SetAllSubStepDetailsFieldValue", 
							Target: "EffectiveDate",
							TargetStep: "Garantees",
							TargetModule: "quotation",
							Mapping : {}, 
							Params: {} 
						},
					]				
				},
				{
					Name : "Package",
					Type : "btn_radio",
					CustomClass : "size-1",
					Label : "Couverture ITT / IPT",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [
						{ Id: 0, Value: "deces", Label: "Décès" },
						{ Id: 1, Value: "deces/ptia", Label: "Décès / PTIA" },
						{ Id: 3, Value: "deces/ptia/ipt/itt", Label: "Décès / PTIA / IPT / ITT" },
						{ Id: 4, Value: "deces/ptia/ipt/itt/ipp", Label: "Décès / PTIA / IPT / ITT / IPP" }
					],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "La couverture est requise",
						"EqualsTo" : "L'assuré(e) à + de 64 ans il(elle) ne peut souscrire que la garantie « Décès »."
					},
					HasHelper : false,
					HelperPosition: "",
					Helper : "",
					Actions:[
						{
							Id: 0, 
							Type: "OnClick", 
							ResultAction : "ShowField", 
							Target: "TypeDePrestation",
							TargetStep: "Garantees",
							Mapping : {}, 
							Params: { 
								Operator : "IN",
								Value : "",
								CompareValueType: "fromCurrentStep",	
								CompareValueForm: "Garantees",
								CompareValueModule: "quotation",
								CompareValueField: "Package",	
								CompareValue : ["deces/ptia/ipt/itt", "deces/ptia/ipt/itt/ipp"],
								IsRequired : true
							} 
						},
						{
							Id: 1, 
							Type: "OnClick", 
							ResultAction : "ShowField", 
							Target: "RachatDosEtPsy",
							TargetStep: "Garantees",
							Mapping : {}, 
							Params: { 
								Operator : "IN",
								Value : "",
								CompareValueType: "fromCurrentStep",	
								CompareValueForm: "Garantees",
								CompareValueModule: "quotation",
								CompareValueField: "Package",	
								CompareValue : ["deces/ptia/ipt/itt", "deces/ptia/ipt/itt/ipp"],
								IsRequired : true
							} 
						}
					]				
				},
				{
					Name : "Share",
					Type : "text",
					CustomClass : "size-2",
					Label : "Quotité",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Mask : "000",
					Values : [],
					HasRightIndicator: true,
					RightIndicatorType : "text",
					RightIndicator : "%",
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "La couverture est requise",
						"CheckAllShare100" : "La somme des quotités par prêt doit faire au minimum 100%",
						"max" : "La quotité doit être au maximum de 100% par assuré et par contrat."
					},
					HasHelper : false,
					HelperPosition: "",
					Helper : "",
					Actions:[
						{
							Id: 0, 
							Type: "OnChange", 
							ResultAction : "SetAllStepDetailsFieldValue", 
							Target: "Share",
							TargetStep: "Garantees",
							TargetModule: "quotation",
							Mapping : {}, 
							Params: {} 
						},
						{
							Id: 0, 
							Type: "OnChange", 
							ResultAction : "SetOtherFieldValidators", 
							Target: "Share",
							TargetStep: "Garantees",
							TargetModule: "quotation",
							Mapping : {}, 
							Params: [
								{ 
									Operator : "NO",
								},   
							],
							SetValidators: [
								{ Type : "required", Params : "" },
								{ Type : "CheckAllShare100", Params : "" },
								{ Type : "maxValue100", Params : "" }
							]
						},
					]				
				},
				{
					Name : "Franchise",
					Type : "select2",
					CustomClass : "size-2",
					Label : "Franchise ITT",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : { Id: 2, Value: 2, Label: "90 jours" },
					ToHide: false,
					Multiple: false,
					Disabled: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "La franchise est requise"
					},
					HasHelper : false,
					HelperPosition: "",
					Helper : "",
					Actions:[]				
				},
				{
					Name : "RachatDosEtPsy",
					Type : "btn_radio",
					CustomClass : "size-2 clearer",
					Label : "Options psy / dos",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: true,
					Disabled: false,
					Values : [
						{ Id: 0, Value: "oui", Label: "Oui" },
						{ Id: 1, Value: "non", Label: "Non" }
					],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "L'optioon' est requise"
					},
					HasHelper : false,
					HelperPosition: "",
					Helper : "",
					Actions:[]				
				},
				{
					Name : "TypeDePrestation",
					Type : "btn_radio",
					CustomClass : "size-2",
					Label : "Versement",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: true,
					Disabled: false,
					Values : [
						{ Id: 0, Value: "0", Label: "Capital" },
						{ Id: 1, Value: "1", Label: "Rente" }
					],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le type de versement est requis"
					},
					HasHelper : false,
					HelperPosition: "",
					Helper : "",
					Actions:[]				
				}				
			],
			BtnLabels: {
				HasCancel: true,
				Cancel: "Abandonner",
				HasSaveAndQuit: false,
				SaveAndQuit: "Sauvegarder et quitter",
				HasGetAQuote: false,
				GetAQuote: "Obtenir un devis",
				HasPrevious: true,
				Previous: "Précédent",
				HasSubmit: true,
				Submit: "Suivant"
			},
			OnSubmit: [
				// {
				// 	Id : 0,
				// 	Type : "SetEarlierEffectDate",
				// 	ResultAction: "",
				// 	SourceField: "ReleaseDate",
				// 	Target: "subscription", 
				// 	TargetStep: "bank",
				// 	TargetField: "EffectDate",					
				// 	Mapping : {}, 
				// 	Params: {}
				// },
				{
					Id : 1,
					Type : "ApiCall",
					ResultAction: "Next",
					Target: "", 
					TargetStep: "",
					Mapping : {
						HasMapping: true,
						MappingType: "ArrayOfArray",
						SourceStep : "Garantees",
						SourceModule: "quotation",
						SubValues : [
							{ ResponseObjectName: "", Name : "LoanId", FormName: "LoanId", Mapping: "LoanId", FieldType: "FieldValue", Type: "integer", FixedValue: "" },							
							{ ResponseObjectName: "", Name : "BorrowerIndex", FormName: "BorrowerIndex", Mapping: "BorrowerIndex", FieldType: "FieldValue", Type: "integer", FixedValue: "" },							
							{ ResponseObjectName: "", Name : "ReleaseDate", FormName: "ReleaseDate", Mapping: "ReleaseDate", FieldType: "FieldValue", Type: "string", FixedValue: "" },							
							{ ResponseObjectName: "", Name : "EffectiveDate", FormName: "EffectiveDate", Mapping: "EffectiveDate", FieldType: "DateValue", Type: "string", FixedValue: "" },							
							{ ResponseObjectName: "", Name : "Package", FormName: "Package", Mapping: "Package", FieldType: "FieldValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "Share", FormName: "Share", Mapping: "Share", FieldType: "FieldValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "Franchise", FormName: "Franchise", Mapping: "Label", FieldType: "ObjectValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "RachatDosEtPsy", FormName: "RachatDosEtPsy", Mapping: "RachatDosEtPsy", FieldType: "FieldValue", Type: "string", FixedValue: "" },
							{ ResponseObjectName: "", Name : "TypeDePrestation", FormName: "TypeDePrestation", Mapping: "TypeDePrestation", FieldType: "FieldValue", Type: "string", FixedValue: "" }							
						]
					}, 
					Params: { 
						Method: "PUT", 
						Url: "/quotations/{{quotation_id}}/coverage",
						VariableType: "FromService",
						VariableName: "quotation_id",
						HasVariable: true
					},
					HasWaitingPopup: false,
					WaitingPopup: {
						Text: ""
					}
				}
			],
			OnCancel: [
				{
					Id : 0,
					Type : "ApiCall",
					ResultAction: "Cancel",
					Target: "", 
					TargetStep: "",
					Mapping : { HasMapping: false }, 
					Params: { 
						Method: "PUT", 
						Url: "/quotations/{{quotation_id}}/cancel",
						VariableType: "FromService",
						VariableName: "quotation_id",
						HasVariable: true
					},
					HasWaitingPopup: false,
					WaitingPopup: {
						Text: ""
					}
				}
			],
			OnGetQuote: [],
			OnBack: [],
			HasSuccessMessage: false,
			OnSuccessMessage: {}		
		},
		// {
		// 	Name : "Commissions",
		// 	Label : "Formule",
		// 	InitActions : [
		// 		{
		// 			Id: 20, 
		// 			Type: "AfterInit", 
		// 			ResultAction : "SetProperty", 
		// 			Target: "commissionnement",
		// 			TargetStep: "Commissions",
		// 			TargetModule: "quotation",
		// 			Property: "Value",
		// 			Value: "0",
		// 			Mapping : {}, 
		// 			Params: [
		// 				{ 
		// 					Operator : "NO",
		// 					Value : "",
		// 					CompareValueType: "Fixe",	
		// 					CompareValue : null,
		// 					CompareValueField: "commissionnement",				
		// 					CompareValueForm: "Commissions",
		// 					CompareValueModule: "quotation",
		// 				}
		// 			] 
		// 		},
		// 	],
		// 	HasRightBar : false,
		// 	RightBar : {},
		// 	HasNavigationBar: false,	
		// 	NavigationBarActions: [],
		// 	Controls : [
		// 		{
		// 			Name : "commissionnement",
		// 			Type : "btn_radio",
		// 			CustomClass : "size-4",
		// 			// CustomClass : "size-2", A remettre qd items décommentées
		// 			Label : "",
		// 			UpperCase : false,
		// 			Capitalize: false,
		// 			IgnoreCaseFormat: true,
		// 			PlaceHolder : "",
		// 			Default : "",
		// 			ToHide: false,					
		// 			Disabled: false,
		// 			ReadOnly: true,
		// 			Values : [
		// 				{ Id: 0, Value: "0", Label: "40 / 10" },
		// 				// { Id: 1, Value: "1", Label: "20 / 5" },
		// 				// { Id: 1, Value: "2", Label: "0 / 0" }

		// 			],
		// 			HasRightIndicator: false,
		// 			RightIndicator : "",
		// 			Validators : [
		// 				{ Type : "required", Params : "" }
		// 			],
		// 			ShowErrorsUnderItem: false,
		// 			Errors : {
		// 				"required" : "Le commissionnement est requis"
		// 			},
		// 			HasHelper : false,
		// 			HelperPosition: "",
		// 			HelperTitle: "",
		// 			HelperBody: "",
		// 			Actions : []				
		// 		}
		// 	],
		// 	BtnLabels: {
		// 		HasCancel: true,
		// 		Cancel: "Abandonner",
		// 		HasSaveAndQuit: false,
		// 		SaveAndQuit: "Sauvegarder et quitter",
		// 		HasGetAQuote: false,
		// 		GetAQuote: "Obtenir un devis",
		// 		HasPrevious: true,
		// 		Previous: "Précédent",
		// 		HasSubmit: true,
		// 		Submit: "Obtenir un coût"
		// 	},
		// 	OnSubmit: [
		// 		{
		// 			Id : 0,
		// 			Type : "ApiCall",
		// 			ResultAction: "Next",
		// 			Target: "", 
		// 			TargetStep: "",
		// 			Mapping : {
		// 				HasMapping: true,
		// 				MappingType: "Object",
		// 				SourceStep : "Commissions",
		// 				SourceModule: "quotation",
		// 				SubValues : [
		// 					{ 
		// 						ResponseObjectName: "", 
		// 						Name : "commissionnement",
		// 						FormName: "commissionnement" ,
		// 						Mapping: "commissionnement", 
		// 						FieldType: "FieldValue",
		// 						Type: "string"
		// 					}
		// 				]
		// 			}, 
		// 			Params: { 
		// 				Method: "PUT", 
		// 				Url: "/quotations/{{quotation_id}}/commission",
		// 				VariableType: "FromService",
		// 				VariableName: "quotation_id",
		// 				HasVariable: true
		// 			},
		// 			HasWaitingPopup: false,
		// 			WaitingPopup: {
		// 				Text: ""
		// 			}
		// 		}
		// 	],
		// 	OnCancel: [
		// 		{
		// 			Id : 0,
		// 			Type : "ApiCall",
		// 			ResultAction: "Cancel",
		// 			Target: "", 
		// 			TargetStep: "",
		// 			Mapping : { HasMapping: false }, 
		// 			Params: { 
		// 				Method: "PUT", 
		// 				Url: "/quotations/{{quotation_id}}/cancel",
		// 				VariableType: "FromService",
		// 				VariableName: "quotation_id",
		// 				HasVariable: true
		// 			},
		// 			HasWaitingPopup: false,
		// 			WaitingPopup: {
		// 				Text: ""
		// 			}
		// 		}
		// 	],
		// 	OnGetQuote: [],
		// 	OnBack: [],
		// 	HasSuccessMessage: false,
		// 	OnSuccessMessage: {}		
		// },
		{
			Name : "cotisations",
			Label : "Cotisations",
			InitActions : [
				{
					Id: 0,
					Type: "AfterInit",
					ResultAction: "ReplaceVariable",
					TargetStep: "cotisations",
					Target: "comment1",					
					TargetType: "comment",
					Params: [
						{
							SourceStep: "Current",
							SourceField: "GetBorrowerType",
							Variable: "[TypeEmprunteur]"
						},{
							SourceStep: "Covered",
							SourceField: "Gender",
							Variable: "[Civilité]"
						},
						{
							SourceStep: "Covered",
							SourceField: "FirstName",
							Variable: "[Prenom]"
						},
						{
							SourceStep: "Covered",
							SourceField: "LastName",
							Variable: "[Nom]"
						}
					]
				},
				{
					Id : 1, 
					Type : "ApiCall_init", 
					ResultAction : "FillTable", 
					Target: "cotisations_table", 
					TargetStep: "Commissions",
					ResponseField: "Cotisations",
					Mapping : [
						{ Type: "Value", FormField: "InitialAmountAccurateRate", ResponseField : "AverageRate"},
						{ Type: "Value", FormField: "taea", ResponseField : "TAEA"},
						{ 
							Type: "Object", 
							FormField: "cotisations_table", 
							ResponseField : "cotisations_table",
							Mapping : [
								{ 
									Type: "Object", 
									FormField: "", 
									ResponseField : "IPP",
									Mapping : [
										{ Type: "Value", FormField: "ittiptipp_year", ResponseField : "Annually"},
										{ Type: "Value", FormField: "ittiptipp_eigthyears", ResponseField : "EightYear"},
										{ Type: "Value", FormField: "ittiptipp_month", ResponseField : "Monthly"},
										{ Type: "Value", FormField: "ittiptipp_total", ResponseField : "Total"},
									]
								},
								{ 
									Type: "Object", 
									FormField: "", 
									ResponseField : "PTIA",
									Mapping : [
										{ Type: "Value", FormField: "decesptia_year", ResponseField : "Annually"},
										{ Type: "Value", FormField: "decesptia_eigthyears", ResponseField : "EightYear"},
										{ Type: "Value", FormField: "decesptia_month", ResponseField : "Monthly"},
										{ Type: "Value", FormField: "decesptia_total", ResponseField : "Total"},
									]
								},
								{ 
									Type: "Object", 
									FormField: "", 
									ResponseField : "Total",
									Mapping : [
										{ Type: "Value", FormField: "total_year", ResponseField : "Annually"},
										{ Type: "Value", FormField: "total_eigthyears", ResponseField : "EightYear"},
										{ Type: "Value", FormField: "total_month", ResponseField : "Monthly"},
										{ Type: "Value", FormField: "total_total", ResponseField : "Total"},
									]
								},
							]
						},
					], 
					Params: { 
						Method: "GET", Url: "/quotations/{{quotation_id}}/cotisations/{{BorrowerIndex}}",
						HasVariable: true,
						Variables : [
							{ Type: "FromService", Name: "quotation_id" },
							{ Type: "FromForm", Name: "BorrowerIndex" }
						]
					}
				},	
			],
			HasRightBar : true,
			RightBar : {
				GoToItemBtnCaption_First: "Emprunteur principal",				
				GoToItemBtnCaption: "Co-emprunteur",
				HasItemSubDetails: true,
				ItemSubDetails : {
					TargetStep: "Covered",
					TargetFields: [
						{ Name: "Gender", Type: "Short", Mapping: { "Monsieur" : "M.", "Madame" : "Mme"} },
						{ Name: "FirstName", Type: "Full", Mapping: {} },
						{ Name: "LastName", Type: "Full", Mapping: {} }						
					]
				},
				HasAddItemBtn: false,
				AddItemBtnCaption: "",
				ExtraActions : []
			},
			HasNavigationBar: false,	
			NavigationBarActions: [],
			Controls : [
				{
					Name : "BorrowerIndex",
					Type : "hidden",
					CustomClass : "",
					Label : "",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ReadOnly: false,
					ToHide: false,
					Multiple: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					HasTopRightButton: false,
					TopRightButtonLabel: "",
					TopRightButtonCtrlError: "",
					TopRightButtonAction: {},					
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []
				},
				{
					Name : "comment1",
					Type : "comment",
					CustomClass : "size-1",
					Comment: "[TypeEmprunteur] : [Civilité] [Prenom] [Nom]",
					Label : "",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions:[]					
				},				
				{
					Name : "cotisations_table",
					Type : "cotisations_table",
					CustomClass : "cotisations_table size-1",
					Label : "",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					ReadOnly: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : [],
					TableColumnLabel : [
						{
							Id : "empty",
							Label: "",
							HasHelper : false,
					HelperPosition: "",
							HelperTitle: "",
							HelperBody: ""

						},
						{
							Id : "month",
							Label: "Mensuelle",
							HasHelper : false,
					HelperPosition: "",
							HelperTitle: "",
							HelperBody: ""

						},
						{
							Id : "year",
							Label: "Annuelle",
							HasHelper : false,
					HelperPosition: "",
							HelperTitle: "",
							HelperBody: ""

						},
						{
							Id : "eightyears",
							Label: "Sur 8 ans",
							HasHelper : true,
							HelperPosition: "",
							HelperTitle: "",
							HelperBody: "En moyenne, la durée de vie d’une assurance emprunteur est de 8 ans"

						},
						{
							Id : "total",
							Label: "Total",
							HasHelper : false,
					HelperPosition: "",
							HelperTitle: "",
							HelperBody: ""

						}
					],
					TableLine : [
						{
							Id : "decesptia",
							Label: "Décès / PTIA",
							HasHelper : false,
					HelperPosition: "",
							HelperTitle: "",
							HelperBody: "",
							Controls: [
								{
									Name : "decesptia_month",
									Type : "text",
									CustomClass : "size-2",
									ReadOnly: true,
									Values : [],
									Validators: [],
									HasRightIndicator: true,
									RightIndicatorType : "text",
									RightIndicator : "€",
									HasHelper : true,
									HelperPosition: "",
									HelperTitle: "",
									HelperBody: ""	
								},
								{
									Name : "decesptia_year",
									Type : "text",
									CustomClass : "size-2",
									ReadOnly: true,
									Values : [],
									Validators: [],
									HasRightIndicator: true,
									RightIndicatorType : "text",
									RightIndicator : "€",
									HasHelper : true,
									HelperPosition: "",
									HelperTitle: "",
									HelperBody: ""	
								},
								{
									Name : "decesptia_eigthyears",
									Type : "text",
									CustomClass : "size-2",
									ReadOnly: true,
									Values : [],
									Validators: [],
									HasRightIndicator: true,
									RightIndicatorType : "text",
									RightIndicator : "€",
									HasHelper : true,
									HelperPosition: "",
									HelperTitle: "",
									HelperBody: ""
								},
								{
									Name : "decesptia_total",
									Type : "text",
									CustomClass : "size-2",
									ReadOnly: true,
									Values : [],
									Validators: [],
									HasRightIndicator: true,
									RightIndicatorType : "text",
									RightIndicator : "€",
									HasHelper : true,
									HelperPosition: "",
									HelperTitle: "",
									HelperBody: ""
								}
							]
						},
						{
							Id : "ittiptipp",
							Label: "ITT / IPT / IPP",
							HasHelper : false,
					HelperPosition: "",
							HelperTitle: "",
							HelperBody: "",
							Controls: [
								{
									Name : "ittiptipp_month",
									Type : "text",
									CustomClass : "size-2",
									ReadOnly: true,
									Values : [],
									Validators: [],
									HasRightIndicator: true,
									RightIndicatorType : "text",
									RightIndicator : "€",
									HasHelper : true,
									HelperPosition: "",
									HelperTitle: "",
									HelperBody: ""
								},
								{
									Name : "ittiptipp_year",
									Type : "text",
									CustomClass : "size-2",
									ReadOnly: true,
									Values : [],
									Validators: [],
									HasRightIndicator: true,
									RightIndicatorType : "text",
									RightIndicator : "€",
									HasHelper : true,
									HelperPosition: "",
									HelperTitle: "",
									HelperBody: ""	
								},
								{
									Name : "ittiptipp_eigthyears",
									Type : "text",
									CustomClass : "size-2",
									ReadOnly: true,
									Values : [],
									Validators: [],
									HasRightIndicator: true,
									RightIndicatorType : "text",
									RightIndicator : "€",
									HasHelper : true,
									HelperPosition: "",
									HelperTitle: "",
									HelperBody: ""
								},
								{
									Name : "ittiptipp_total",
									Type : "text",
									CustomClass : "size-2",
									ReadOnly: true,
									Values : [],
									Validators: [],
									HasRightIndicator: true,
									RightIndicatorType : "text",
									RightIndicator : "€",
									HasHelper : true,
									HelperPosition: "",
									HelperTitle: "",
									HelperBody: ""
								}
							]
						},
						{
							Id : "total",
							Label: "Total",
							HasHelper : false,
					HelperPosition: "",
							HelperTitle: "",
							HelperBody: "",
							Controls: [
								{
									Name : "total_month",
									Type : "text",
									CustomClass : "size-2",
									ReadOnly: true,
									Values : [],
									Validators: [],
									HasRightIndicator: true,
									RightIndicatorType : "text",
									RightIndicator : "€",
									HasHelper : true,
									HelperPosition: "",
									HelperTitle: "",
									HelperBody: ""	
								},
								{
									Name : "total_year",
									Type : "text",
									CustomClass : "size-2",
									ReadOnly: true,
									Values : [],
									Validators: [],
									HasRightIndicator: true,
									RightIndicatorType : "text",
									RightIndicator : "€",
									HasHelper : true,
									HelperPosition: "",
									HelperTitle: "",
									HelperBody: ""	
								},
								{
									Name : "total_eigthyears",
									Type : "text",
									CustomClass : "size-2",
									ReadOnly: true,
									Values : [],
									Validators: [],
									HasRightIndicator: true,
									RightIndicatorType : "text",
									RightIndicator : "€",
									HasHelper : true,
									HelperPosition: "",
									HelperTitle: "",
									HelperBody: ""	
								},
								{
									Name : "total_total",
									Type : "text",
									CustomClass : "size-2",
									ReadOnly: true,
									Values : [],
									Validators: [],
									HasRightIndicator: true,
									RightIndicatorType : "text",
									RightIndicator : "€",
									HasHelper : true,
									HelperPosition: "",
									HelperTitle: "",
									HelperBody: ""
								}
							]
						}
					]				
				},
				{
					Name : "InitialAmountAccurateRate",
					Type : "text",
					CustomClass : "size-2",
					Comment: "",
					Label : "Taux moyen d’assurance",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					ReadOnly: true,
					Values : [],
					HasRightIndicator: true,
					RightIndicatorType : "text",
					RightIndicator : "%",
					Validators : [
						// { Type : "minValue0+", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"minValue0+" : "Les cotisations ne sont pas valides."
					},
					HasHelper : true,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "Le taux moyen d’assurance correspond au coût de l’assurance emprunteur sur la durée totale du prêt rapporté au capital initial.",
					Actions:[]					
				},
				{
					Name : "taea",
					Type : "text",
					CustomClass : "size-2",
					Comment: "",
					Label : "TAEA",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					ReadOnly: true,
					Values : [],
					HasRightIndicator: true,
					RightIndicatorType : "text",
					RightIndicator : "%",
					Validators : [
						{ Type : "minValue0+", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"minValue0+" : "Les cotisations ne sont pas valides."
					},
					HasHelper : true,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "Le TAEA, pour taux annuel effectif d'assurance, permet aux emprunteurs d'évaluer le coût de l'assurance proposée par l'établissement bancaire dans le coût total du prêt. Il permet donc de mieux comparer les différentes offres de prêts et d'assurances.",
					Actions:[]					
				},
			],
			BtnLabels: {
				HasCancel: true,
				Cancel: "Abandonner",
				HasSaveAndQuit: true,
				SaveAndQuit: "Sauvegarder et quitter",
				HasGetAQuote: true,
				GetAQuote: "Obtenir un devis",
				HasSendAQuote: true,
				SendAQuote: "Envoyer par mail",
				HasPrevious: true,
				Previous: "Précédent",
				HasSubmit: true,
				Submit: "Souscrire"
			},
			OnSubmit: [],
			OnCancel: [
				{
					Id : 0,
					Type : "ApiCall",
					ResultAction: "Cancel",
					Target: "", 
					TargetStep: "",
					Mapping : { HasMapping: false }, 
					Params: { 
						Method: "PUT", 
						Url: "/quotations/{{quotation_id}}/cancel",
						VariableType: "FromService",
						VariableName: "quotation_id",
						HasVariable: true
					},
					HasWaitingPopup: false,
					WaitingPopup: {
						Text: ""
					}
				}
			],
			OnGetQuote: [
				{
					Id : 0,
					Type : "ApiCall",
					ResultAction: "OpenFileInNewTab",
					Target: "", 
					TargetStep: "",
					Mapping : {
						HasMapping: false
					}, 
					Params: { 
						Method: "GET", 
						Url: "/quotations/{{quotation_id}}/print",
						VariableType: "FromService",
						VariableName: "quotation_id",
						HasVariable: true
					},
					HasWaitingPopup: false,
					WaitingPopup: {
						Text: ""
					}
				}
			],
			onSendAQuote: [
				{
					Id : 0,
					Type : "ApiCall",
					ResultAction: "",
					Target: "", 
					TargetStep: "",
					Mapping : {
						HasMapping: false
					}, 
					Params: { 
						Method: "GET", 
						Url: "/quotations/{{quotation_id}}/quote/send",
						VariableType: "FromService",
						VariableName: "quotation_id",
						HasVariable: true
					},
					HasWaitingPopup: false,
					WaitingPopup: {
						Text: ""
					}
				}
			],
			OnBack: [],
			HasSuccessMessage: false,
			OnSuccessMessage: {}			
		}
	],

	subscription : [
		{
			Name : "subscriber",
			Label : "",
			InitActions : [
				{
					Id : 31, 
					Type : "ApiCall_init", 
					ResultAction : "FillSelect", 
					Target: "Country", 
					TargetStep: "Covered",
					Mapping : { Id: "code", Value: "Country", Label: "Country" },
					Params: { Method: "GET", Url: "/search/countries", } 
				},
				{
					Id: 0, 
					Type: "AfterInit", 
					ResultAction : "SetPropertyFromPreviousStep", 
					Target: "subscriber", 
					TargetStep: "subscriber",
					TargetModule: "subscription",
					Value : "[BorrowersList]",	
					Property : "Specific",
					Mapping : {},  
					ReplaceMethod: "Always",
					Params: [
						{ 
							Operator : "",
							CompareValueType: "steplength",	
							CompareValueForm: "Covered",
							CompareValueModule: "quotation",
							CompareValueField: "",				
							CompareValue : ""
						} 
					]
				},
				{
					Id: 1, 
					Type: "HideField_onInit", 
					ResultAction : "HideField", 
					Target: "subscriber",
					TargetStep: "subscriber",
					TargetModule: "subscription",
					Mapping : {}, 
					Params: { 
						Operator : "<=",
						Value : "",	
						CompareValueType: "steplength",	
						CompareValueForm: "Covered",
						CompareValueModule: "quotation",
						CompareValueField: "",				
						CompareValue : 1,

					} 
				},
				{
					Id: 10, 
					Type: "AfterInit", 
					ResultAction : "SetProperty", 
					Target: "subscriber",
					TargetStep: "subscriber",
					TargetModule: "subscription",
					Property: "FirstSelectValue",
					Mapping : {}, 
					Params: [
						{ 
							Operator : "<=",
							Value : "",	
							CompareValueType: "steplength",	
							CompareValueForm: "Covered",
							CompareValueModule: "quotation",
							CompareValueField: "",				
							CompareValue : 1,
	
						}
					]
				},
				{
					Id: 2, 
					Type: "HideField_onInit", 
					ResultAction : "HideField", 
					Target: "comment0",
					TargetStep: "subscriber",
					TargetModule: "subscription",
					Mapping : {}, 
					Params: { 
						Operator : "<=",
						Value : "",	
						CompareValueType: "steplength",	
						CompareValueForm: "Covered",
						CompareValueModule: "quotation",
						CompareValueField: "",				
						CompareValue : 1,

					} 
				},
				{
					Id: 3, 
					Type: "HideField_onInit", 
					ResultAction : "HideField", 
					Target: "comment1",
					TargetStep: "subscriber",
					TargetModule: "subscription",
					Mapping : {}, 
					Params: { 
						Operator : "IN",
						Value : "",	
						CompareValueType: "fromPreviousStep",	
						CompareValueForm: "ContractExtra",
						CompareValueModule: "quotation",
						CompareValueField: "TypeProjet",				
						CompareValue : ["secondaire", "locatif"],

					} 
				},
				{
					Id: 4, 
					Type: "HideField_onInit", 
					ResultAction : "HideField", 
					Target: "Address1",
					TargetStep: "subscriber",
					TargetModule: "subscription",
					Mapping : {}, 
					Params: { 
						Operator : "IN",
						Value : "",	
						CompareValueType: "fromPreviousStep",	
						CompareValueForm: "ContractExtra",
						CompareValueModule: "quotation",
						CompareValueField: "TypeProjet",				
						CompareValue : ["secondaire", "locatif"],

					} 
				},
				{
					Id: 5, 
					Type: "HideField_onInit", 
					ResultAction : "HideField", 
					Target: "Address2",
					TargetStep: "subscriber",
					TargetModule: "subscription",
					Mapping : {}, 
					Params: { 
						Operator : "IN",
						Value : "",	
						CompareValueType: "fromPreviousStep",	
						CompareValueForm: "ContractExtra",
						CompareValueModule: "quotation",
						CompareValueField: "TypeProjet",				
						CompareValue : ["secondaire", "locatif"],

					} 
				},
				{
					Id: 6, 
					Type: "HideField_onInit", 
					ResultAction : "HideField", 
					Target: "Postcode",
					TargetStep: "subscriber",
					TargetModule: "subscription",
					Mapping : {}, 
					Params: { 
						Operator : "IN",
						Value : "",	
						CompareValueType: "fromPreviousStep",	
						CompareValueForm: "ContractExtra",
						CompareValueModule: "quotation",
						CompareValueField: "TypeProjet",				
						CompareValue : ["secondaire", "locatif"],

					} 
				},
				{
					Id: 7, 
					Type: "HideField_onInit", 
					ResultAction : "HideField", 
					Target: "City",
					TargetStep: "subscriber",
					TargetModule: "subscription",
					Mapping : {}, 
					Params: { 
						Operator : "IN",
						Value : "",	
						CompareValueType: "fromPreviousStep",	
						CompareValueForm: "ContractExtra",
						CompareValueModule: "quotation",
						CompareValueField: "TypeProjet",				
						CompareValue : ["secondaire", "locatif"],

					} 
				},
				{
					Id: 7, 
					Type: "HideField_onInit", 
					ResultAction : "HideField", 
					Target: "Country",
					TargetStep: "subscriber",
					TargetModule: "subscription",
					Mapping : {}, 
					Params: { 
						Operator : "IN",
						Value : "",	
						CompareValueType: "fromPreviousStep",	
						CompareValueForm: "ContractExtra",
						CompareValueModule: "quotation",
						CompareValueField: "TypeProjet",				
						CompareValue : ["secondaire", "locatif"],

					} 
				},
				{
					Id: 8, 
					Type: "AfterInit", 
					ResultAction : "CheckForNextStep", 
					Target: "", 
					TargetStep: "subscriber",
					TargetModule: "subscription",
					Value : "",	
					Property : "",
					Mapping : {},  
					ReplaceMethod: "",
					Params: [
						{ 
							Operator : "<=",
							Value : "",	
							CompareValueType: "steplength",	
							CompareValueForm: "Covered",
							CompareValueModule: "quotation",
							CompareValueField: "",				
							CompareValue : 1,
						},
						{ 
							Operator : "IN",
							Value : "",	
							CompareValueType: "fromPreviousStep",	
							CompareValueForm: "ContractExtra",
							CompareValueModule: "quotation",
							CompareValueField: "TypeProjet",				
							CompareValue : ["secondaire", "locatif"],
						}  
					]
				},
				{
					Id: 0, 
					Type: "ApiCall_init", 
					ResultAction : "FillSelect", 
					Target: "City", 
					TargetStep: "subscriber",
					ValuesPropertyName: "cities",
					Mapping : { Id: "city", Value: "city", Label: "city", Postcode: "code", City: "city", Country: "country" }, 
					Params: { 
						Method: "GET", 
						Url: "/search/cities/{{Postcode}}",
						HasVariable: true,
						Variables: [
							{
								MinLength: 3,
								Type: "FromForm", 
								Name: "Postcode" 
							}
						]
					} 
				}
			],
			HasRightBar : false,
			RightBar : {},
			HasNavigationBar: false,	
			NavigationBarActions: [],
			Controls : [
				{
					Name : "comment0",
					Type : "comment",
					CustomClass : "size-1 comment-title",
					Comment: "Choix du souscripteur",
					Label : "",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {},
					HasHelper : false,
					HelperPosition: "",
					Helper : "",
					Actions:[]					
				},
				{
					Name : "subscriber",
					Type : "btn_radio",
					CustomClass : "size-1",
					Label : "",
					UpperCase : true,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					ReadOnly: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le choix du souscripteur de projet est requis"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []				
				},	
				{
					Name : "comment1",
					Type : "comment",
					CustomClass : "size-1 comment-title",
					Comment: "Adresse du bien immobilier acquis",
					Label : "",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {},
					HasHelper : false,
					HelperPosition: "",
					Helper : "",
					Actions:[]					
				},
				{
					Name : "Address1",
					Type : "text",
					CustomClass : "size-2",
					Label : "Adresse",
					UpperCase : true,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [],
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "L'adresse est requise"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: ""			
				},
				{
					Name : "Address2",
					Type : "text",
					CustomClass : "size-2",
					Label : "Complément d'adresse",
					UpperCase : true,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [],
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le complément d'adresse est requis"
					},
					HHasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: ""				
				},
				{
					Name : "Postcode",
					Type : "text",
					CustomClass : "size-3",
					Label : "Code postal",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [],
					Validators : [
						{ Type : "required", Params : "" },
						{ Type : "forbindenDOMTOMPostCode", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le code postal est requis",
						"pattern" : "Conformément à notre politique de risques, la souscription de l’offre est impossible pour les territoires suivants : Guyane, Mayotte et Polynésie Française."
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : [
						{
							Id: 0, 
							Type: "ApiCall_whenChange", 
							ResultAction : "FillSelect", 
							Target: "City", 
							TargetStep: "subscriber",
							MinLength: 3,
							ReplaceMethod: "Always",
							ValuesPropertyName: "cities",
							Mapping : { Id: "city", Value: "city", Label: "city", Postcode: "code", City: "city", Country: "country" }, 
							Params: { 
								Method: "GET", 
								Url: "/search/cities/{{Postcode}}",
								HasVariable: true,
								Variables: [
									{
										Type: "FromForm", 
										Name: "Postcode" 
									}
								]
							} 
						}
					]				
				},
				{
					Name : "City",
					Type : "select2",
					CustomClass : "size-3",
					Label : "Ville",
					UpperCase : true,
					Capitalize: false,
					IgnoreCaseFormat: false,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [],
					Searchable: true,
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "La ville est requise"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : [
						{
							Id: 0, 
							Type: "OnChange", 
							ResultAction : "SetValue_whenChange_fromSelect", 
							Target: "Postcode",
							TargetStep: "subscriber",
							Mapping : { Source: "Postcode" }, 
							Params: {} 
						},
						{
							Id: 1, 
							Type: "OnChange", 
							ResultAction : "SetValue_whenChange_fromSelect", 
							Target: "Country",
							TargetStep: "subscriber",
							Mapping : { Source: "Country" }, 
							Params: {} 
						}
					]							
				},
				{
					Name : "Country",
					Type : "select2",
					CustomClass : "size-3",
					Label : "Pays",
					UpperCase : true,
					Capitalize: false,
					IgnoreCaseFormat: false,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [],
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le pays est requis"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []	
				}							
			],
			BtnLabels: {
				HasCancel: true,
				Cancel: "Abandonner",
				HasSaveAndQuit: true,
				SaveAndQuit: "Sauvegarder et quitter",
				HasGetAQuote: false,
				GetAQuote: "Obtenir un devis",
				HasPrevious: true,
				Previous: "Précédent",
				HasSubmit: true,
				Submit: "Suivant"
			},
			OnSubmit: [
				{
					Id : 0,
					Type : "ApiCall",
					ResultAction: "Next",
					Target: "", 
					TargetStep: "",
					Mapping : {
						HasMapping: true,
						MappingType: "Object",
						SourceStep: "subscriber",
						SourceModule: "subscription",
						SubValues : [
							{ 
								ResponseObjectName: "", 
								Name : "BorrowerIndex",
								FormName: "subscriber" ,
								Mapping: "subscriber", 
								FieldType: "FieldValue",
								Type: "string"
							},
							{ 
								ResponseObjectName: "", 
								Name : "Address1",
								FormName: "Address1" ,
								Mapping: "Address1", 
								FieldType: "FieldValue",
								Type: "string"
							},
							{ 
								ResponseObjectName: "", 
								Name : "Address2",
								FormName: "Address2",
								Mapping: "Address2", 
								FieldType: "FieldValue",
								Type: "string"
							},
							{ 
								ResponseObjectName: "", 
								Name : "PostCode", 
								FormName: "Postcode",
								Mapping: "Postcode", 
								FieldType: "FieldValue",
								Type: "string"
							},
							// { 
							// 	ResponseObjectName: "", 
							// 	Name : "City", 
							// 	FormName: "City",
							// 	Mapping: "City", 
							// 	FieldType: "FieldValue",
							// 	Type: "string"
							// },
							// { 
							// 	ResponseObjectName: "", 
							// 	Name : "Country", 
							// 	FormName: "Country",
							// 	Mapping: "Country", 
							// 	FieldType: "FieldValue",
							// 	Type: "string"
							// },
							{ ResponseObjectName: "", Name : "City", FormName: "City", Mapping: "Value", FieldType: "ObjectValue", Type: "", FixedValue: "" },
							{ ResponseObjectName: "", Name : "Country", FormName: "Country", Mapping: "Value", FieldType: "ObjectValue", Type: "", FixedValue: "" }							
						]
					}, 
					Params: { 
						Method: "PUT", 
						Url: "/quotations/{{quotation_id}}/subscriber",
						VariableType: "FromService",
						VariableName: "quotation_id",
						HasVariable: true
					},
					HasWaitingPopup: false,
					WaitingPopup: {
						Text: ""
					}
				}
			],
			OnCancel: [
				{
					Id : 0,
					Type : "ApiCall",
					ResultAction: "Cancel",
					Target: "", 
					TargetStep: "",
					Mapping : { HasMapping: false }, 
					Params: { 
						Method: "PUT", 
						Url: "/quotations/{{quotation_id}}/cancel",
						VariableType: "FromService",
						VariableName: "quotation_id",
						HasVariable: true
					},
					HasWaitingPopup: false,
					WaitingPopup: {
						Text: ""
					}
				}
			],
			OnGetQuote: [],
			OnBack: [],
			HasSuccessMessage: false,
			OnSuccessMessage: {}				
		},
		{
			Name : "bank_infos",
			Label : "Informations bancaires",
			InitActions : [
				{
					Id: 1, 
					Type: "ShowField_onInit", 
					ResultAction : "ShowField", 
					Target: "dayofpayment",
					TargetStep: "bank_infos",
					TargetModule: "subscription",
					Mapping : {}, 
					Params: { 
						Operator : "IN",
						Value : "",	
						CompareValueType: "fromCurrentStep",	
						CompareValueForm: "bank_infos",
						CompareValueModule: "subscription",
						CompareValueField: "method",				
						CompareValue : ["prelevement"],

					} 
				},
				{
					Id: 2, 
					Type: "ShowField_onInit", 
					ResultAction : "ShowField", 
					Target: "iban",
					TargetStep: "bank_infos",
					TargetModule: "subscription",
					Mapping : {}, 
					Params: { 
						Operator : "IN",
						Value : "",	
						CompareValueType: "fromCurrentStep",	
						CompareValueForm: "bank_infos",
						CompareValueModule: "subscription",
						CompareValueField: "method",				
						CompareValue : ["prelevement"],

					} 
				},
				{
					Id: 3, 
					Type: "ShowField_onInit", 
					ResultAction : "ShowField", 
					Target: "bic",
					TargetStep: "bank_infos",
					TargetModule: "subscription",
					Mapping : {}, 
					Params: { 
						Operator : "IN",
						Value : "",	
						CompareValueType: "fromCurrentStep",	
						CompareValueForm: "bank_infos",
						CompareValueModule: "subscription",
						CompareValueField: "method",				
						CompareValue : ["prelevement"],

					} 
				},				
				{
					Id: 4, 
					Type: "AfterInit", 
					ResultAction : "SetProperty", 
					Target: "iban",
					TargetStep: "bank_infos",
					TargetModule: "subscription",
					Property: "Value",
					Value: null,
					Mapping : {}, 
					Params: [
						{ 
							Operator : "!IN",
							Value : "",	
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "bank_infos",
							CompareValueModule: "subscription",
							CompareValueField: "method",				
							CompareValue : ["prelevement"],
						}
					] 
				},
				{
					Id: 5, 
					Type: "AfterInit", 
					ResultAction : "SetProperty", 
					Target: "bic",
					TargetStep: "bank_infos",
					TargetModule: "subscription",
					Property: "Value",
					Value: null,
					Mapping : {}, 
					Params: [
						{ 
							Operator : "!=",
							Value : "",	
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "bank_infos",
							CompareValueModule: "subscription",
							CompareValueField: "method",				
							CompareValue : "prelevement",
						}
					] 
				},
				{
					Id: 6, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "bic",
					TargetStep: "bank_infos",
					TargetModule: "subscription",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" },
						{ Type : "bic", Params : "" },
					],
					Params: [
						{ 
							Operator : "IN",
							Value : "",	
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "bank_infos",
							CompareValueModule: "subscription",
							CompareValueField: "method",				
							CompareValue : ["prelevement"],
	
						} 
					]
				},
				{
					Id: 7, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "iban",
					TargetStep: "bank_infos",
					TargetModule: "subscription",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" },
						{ Type : "iban", Params : "" },
					],
					Params: [
						{ 
							Operator : "IN",
							Value : "",	
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "bank_infos",
							CompareValueModule: "subscription",
							CompareValueField: "method",				
							CompareValue : ["prelevement"],
	
						} 
					]
				},
				{
					Id: 8, 
					Type: "AfterInit", 
					ResultAction : "SetOtherFieldValidators", 
					Target: "dayofpayment",
					TargetStep: "bank_infos",
					TargetModule: "subscription",
					Mapping : {}, 
					SetValidators: [
						{ Type : "required", Params : "" }
					],
					Params: [
						{ 
							Operator : "IN",
							Value : "",	
							CompareValueType: "fromCurrentStep",	
							CompareValueForm: "bank_infos",
							CompareValueModule: "subscription",
							CompareValueField: "method",				
							CompareValue : ["prelevement"],
	
						} 
					]
				},
				{
					Id: 9, 
					Type: "AfterInit", 
					ResultAction : "SetPropertyFromPreviousStep", 
					Target: "frequency", 
					TargetStep: "bank_infos",
					Property : "Select",
					Value : [
						{ Id: 1, Value: "prelevement_annuel", Label: "Annuelle" }
					],	
					Mapping : {},  
					ReplaceMethod: "Always",
					Params: [
						{ 
							Operator : "=",
							CompareValueType: "fromPreviousStep",	
							CompareValueForm: "bank_infos",
							CompareValueModule: "subscription",
							CompareValueField: "method",				
							CompareValue : "cheque", 
						}
					] 
				},
				{
					Id: 10, 
					Type: "AfterInit", 
					ResultAction : "SetPropertyFromPreviousStep", 
					Target: "frequency", 
					TargetStep: "bank_infos",
					Property : "Select",
					Value : [
						{ Id: 0, Value: "prelevement_mensuel", Label: "Mensuelle" },
						{ Id: 1, Value: "prelevement_trimestriel", Label: "Trimestrielle" },
						{ Id: 1, Value: "prelevement_semestriel", Label: "Semestrielle" },
						{ Id: 1, Value: "prelevement_annuel", Label: "Annuelle" },
					],	
					Mapping : {},  
					ReplaceMethod: "Always",
					Params: [
						{ 
							Operator : "=",
							CompareValueType: "fromPreviousStep",	
							CompareValueForm: "bank_infos",
							CompareValueModule: "subscription",
							CompareValueField: "method",				
							CompareValue : "prelevement", 
						}
					] 
				},
				{
					Id: 11, 
					Type: "AfterInit", 
					ResultAction : "SetPropertyFromPreviousStep", 
					Target: "frequency", 
					TargetStep: "bank_infos",
					TargetModule: "subscription",
					Value : "size-1",
					Property : "CustomClass",
					Mapping : {},  
					ReplaceMethod: "Always",
					Params: [
						{ 
							Operator : "=",
							CompareValueType: "fromPreviousStep",	
							CompareValueForm: "bank_infos",
							CompareValueModule: "subscription",
							CompareValueField: "method",				
							CompareValue : "prelevement"
						} 
					]
				},
				{
					Id: 13, 
					Type: "AfterInit", 
					ResultAction : "SetPropertyFromPreviousStep", 
					Target: "frequency", 
					TargetStep: "bank_infos",
					TargetModule: "subscription",
					Value : "size-4",
					Property : "CustomClass",
					Mapping : {},  
					ReplaceMethod: "Always",
					Params: [
						{ 
							Operator : "=",
							CompareValueType: "fromPreviousStep",	
							CompareValueForm: "bank_infos",
							CompareValueModule: "subscription",
							CompareValueField: "method",				
							CompareValue : "cheque"
						} 
					]
				},
				{
					Id: 14, 
					Type: "AfterInit", 
					ResultAction : "SetPropertyFromPreviousStep", 
					Target: "frequency", 
					TargetStep: "bank_infos",
					TargetModule: "subscription",
					Value : "prelevement_annuel",
					Property : "Value",
					Mapping : {},  
					ReplaceMethod: "Always",
					Params: [
						{ 
							Operator : "=",
							CompareValueType: "fromPreviousStep",	
							CompareValueForm: "bank_infos",
							CompareValueModule: "subscription",
							CompareValueField: "method",				
							CompareValue : "cheque"
						} 
					]
				}
			],
			HasRightBar : false,
			RightBar : {},
			HasNavigationBar: false,	
			NavigationBarActions: [],
			Controls : [
				{
					Name : "loan_reference",
					Type : "text",
					CustomClass : "size-2 clearer",
					Label : "Référence du prêt",
					UpperCase : true,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [],
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "La référence du prêt principal est requise"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: ""			
				},
				{
					Name : "method",
					Type : "btn_radio",
					CustomClass : "size-2 clearer",
					Label : "Mode de paiement",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					ReadOnly: false,
					Values : [
						{ Id: 0, Value: "cheque", Label: "Chèque" },
						{ Id: 1, Value: "prelevement", Label: "Prélèvement" },
					],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le choix est requis"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : [
						{
							Id: 1, 
							Type: "OnClick", 
							ResultAction : "ShowField", 
							Target: "dayofpayment",
							TargetStep: "bank_infos",
							Mapping : {}, 
							Params: { 
								Operator : "IN",
								Value : "",	
								CompareValueType: "fromCurrentStep",	
								CompareValueForm: "bank_infos",
								CompareValueModule: "subscription",
								CompareValueField: "method",				
								CompareValue : ["prelevement"],

							} 
						},
						{
							Id: 2, 
							Type: "OnClick", 
							ResultAction : "ShowField", 
							Target: "iban",
							TargetStep: "bank_infos",
							Mapping : {}, 
							Params: { 
								Operator : "IN",
								Value : "",	
								CompareValueType: "fromCurrentStep",	
								CompareValueForm: "bank_infos",
								CompareValueModule: "subscription",
								CompareValueField: "method",				
								CompareValue : ["prelevement"],

							} 
						},
						{
							Id: 3, 
							Type: "OnClick", 
							ResultAction : "ShowField", 
							Target: "bic",
							TargetStep: "bank_infos",
							Mapping : {}, 
							Params: { 
								Operator : "IN",
								Value : "",	
								CompareValueType: "fromCurrentStep",	
								CompareValueForm: "bank_infos",
								CompareValueModule: "subscription",
								CompareValueField: "method",				
								CompareValue : ["prelevement"],

							} 
						},
						{
							Id: 4, 
							Type: "OnClick", 
							ResultAction : "SetOtherFieldValidators", 
							Target: "bic",
							TargetStep: "bank_infos",
							TargetModule: "subscription",
							Mapping : {},
							Params: [
								{ 
									Operator : "IN",
									Value : "",	
									CompareValueType: "fromCurrentStep",	
									CompareValueForm: "bank_infos",
									CompareValueModule: "subscription",
									CompareValueField: "method",				
									CompareValue : ["prelevement"], 
									SetValidators: [
										{ Type : "required", Params : "" },
										{ Type : "bic", Params : "" },
									]			
								} 
							]
						},
						{
							Id: 5, 
							Type: "OnClick", 
							ResultAction : "SetOtherFieldValidators", 
							Target: "dayofpayment",
							TargetStep: "bank_infos",
							TargetModule: "subscription",
							Mapping : {}, 
							SetValidators: [
								{ Type : "clearValidators", Params : "" },
							],
							Params: [
								{ 
									Operator : "IN",
									Value : "",	
									CompareValueType: "fromCurrentStep",	
									CompareValueForm: "bank_infos",
									CompareValueModule: "subscription",
									CompareValueField: "method",				
									CompareValue : ["cheque"],
			
								} 
							]
						},
						{
							Id: 6, 
							Type: "OnClick", 
							ResultAction : "SetOtherFieldValidators", 
							Target: "iban",
							TargetStep: "bank_infos",
							TargetModule: "subscription",
							Mapping : {},
							Params: [
								{ 
									Operator : "IN",
									Value : "",	
									CompareValueType: "fromCurrentStep",	
									CompareValueForm: "bank_infos",
									CompareValueModule: "subscription",
									CompareValueField: "method",				
									CompareValue : ["cheque"], 
									SetValidators: [
										{ Type : "clearValidators", Params : "" },
									]			
								} 
							]
						},
						{
							Id: 7, 
							Type: "OnClick", 
							ResultAction : "SetOtherFieldValidators", 
							Target: "bic",
							TargetStep: "bank_infos",
							TargetModule: "subscription",
							Mapping : {},
							Params: [
								{ 
									Operator : "IN",
									Value : "",	
									CompareValueType: "fromCurrentStep",	
									CompareValueForm: "bank_infos",
									CompareValueModule: "subscription",
									CompareValueField: "method",				
									CompareValue : ["cheque"], 
									SetValidators: [
										{ Type : "clearValidators", Params : "" },
									]			
								} 
							]
						},
						{
							Id: 4, 
							Type: "OnClick", 
							ResultAction : "SetOtherFieldValidators", 
							Target: "iban",
							TargetStep: "bank_infos",
							TargetModule: "subscription",
							Mapping : {},
							Params: [
								{ 
									Operator : "IN",
									Value : "",	
									CompareValueType: "fromCurrentStep",	
									CompareValueForm: "bank_infos",
									CompareValueModule: "subscription",
									CompareValueField: "method",				
									CompareValue : ["prelevement"], 
									SetValidators: [
										{ Type : "required", Params : "" },
										{ Type : "iban", Params : "" },
									]			
								} 
							]
						},
						{
							Id: 10, 
							Type: "OnClick", 
							ResultAction : "SetValue_fromPreviousStep", 
							Target: "frequency", 
							TargetStep: "bank_infos",
							TargetModule: "subscription",
							Value : [
								{ Id: 0, Value: "prelevement_mensuel", Label: "Mensuelle" },
								{ Id: 1, Value: "prelevement_trimestriel", Label: "Trimestrielle" },
								{ Id: 1, Value: "prelevement_semestriel", Label: "Semestrielle" },
								{ Id: 1, Value: "prelevement_annuel", Label: "Annuelle" },
							],
							Property : "Select",
							Mapping : {},  
							ReplaceMethod: "Always",
							Params: [
								{ 
									Operator : "=",
									CompareValueType: "fromCurrentStep",	
									CompareValueForm: "bank_infos",
									CompareValueModule: "subscription",
									CompareValueField: "method",				
									CompareValue : "prelevement"
								} 
							]
						},
						{
							Id: 11, 
							Type: "OnClick", 
							ResultAction : "SetValue_fromPreviousStep", 
							Target: "frequency", 
							TargetStep: "bank_infos",
							TargetModule: "subscription",
							Value : "size-1",
							Property : "CustomClass",
							Mapping : {},  
							ReplaceMethod: "Always",
							Params: [
								{ 
									Operator : "=",
									CompareValueType: "fromCurrentStep",	
									CompareValueForm: "bank_infos",
									CompareValueModule: "subscription",
									CompareValueField: "method",				
									CompareValue : "prelevement"
								} 
							]
						},
						{
							Id: 12, 
							Type: "OnClick", 
							ResultAction : "SetValue_fromPreviousStep", 
							Target: "frequency", 
							TargetStep: "bank_infos",
							TargetModule: "subscription",
							Value : [
								{ Id: 1, Value: "year", Label: "Annuelle" },
							],
							Property : "Select",
							Mapping : {},  
							ReplaceMethod: "Always",
							Params: [
								{ 
									Operator : "=",
									CompareValueType: "fromCurrentStep",	
									CompareValueForm: "bank_infos",
									CompareValueModule: "subscription",
									CompareValueField: "method",				
									CompareValue : "cheque"
								} 
							]
						},
						{
							Id: 13, 
							Type: "OnClick", 
							ResultAction : "SetValue_fromPreviousStep", 
							Target: "frequency", 
							TargetStep: "bank_infos",
							TargetModule: "subscription",
							Value : "size-4",
							Property : "CustomClass",
							Mapping : {},  
							ReplaceMethod: "Always",
							Params: [
								{ 
									Operator : "=",
									CompareValueType: "fromCurrentStep",	
									CompareValueForm: "bank_infos",
									CompareValueModule: "subscription",
									CompareValueField: "method",				
									CompareValue : "cheque"
								} 
							]
						},
						{
							Id: 14, 
							Type: "OnClick", 
							ResultAction : "SetValue_fromPreviousStep", 
							Target: "frequency", 
							TargetStep: "bank_infos",
							TargetModule: "subscription",
							Value : "year",
							Property : "radioValue",
							Mapping : {}, 
							ReplaceMethod: "Always",
							Params: [
								{ 
									Operator : "=",
									CompareValueType: "fromCurrentStep",	
									CompareValueForm: "bank_infos",
									CompareValueModule: "subscription",
									CompareValueField: "method",				
									CompareValue : "cheque"
								} 
							]
						}
					]				
				},
				{
					Name : "frequency",
					Type : "btn_radio",
					CustomClass : "size-1",
					Label : "Périodicité",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					ReadOnly: false,
					Values : [
						{ Id: 0, Value: "prelevement_mensuel", Label: "Mensuelle" },
						{ Id: 1, Value: "prelevement_trimestriel", Label: "Trimestrielle" },
						{ Id: 2, Value: "prelevement_semestriel", Label: "Semestrielle" },
						{ Id: 3, Value: "prelevement_annuel", Label: "Annuelle" },
					],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [
						{ Type : "required", Params : "" }
					],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "Le choix est requis"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []				
				},
				{
					Name : "dayofpayment",
					Type : "text",
					CustomClass : "size-2 clearer",
					Label : "Jour de prélèvement",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : 5,
					ToHide: true,
					Disabled: false,					
					ReadOnly: true,
					Values : [],
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "La raison sociale est requise"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: ""			
				},
				{
					Name : "iban",
					Type : "text",
					CustomClass : "size-2 uppercase",
					Label : "IBAN",
					UpperCase : true,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: true,
					Disabled: false,
					Mask : "AAAA AAAA AAAA AAAA AAAA AAAA AAA",
					Values : [],
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "L'adresse est requise",
						"pattern" : "L'IBAN n'est pas valide"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: ""			
				},
				{	
					Name : "bic",
					Type : "text",
					CustomClass : "size-2 uppercase",
					Label : "BIC",
					UpperCase : true,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					Mask : "AAAAAAAAAAA",
					ToHide: true,
					Disabled: false,
					Values : [],
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {
						"required" : "L'adresse est requise",
						"pattern" : "Le BIC n'est pas valide"
					},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: ""			
				}												
			],
			BtnLabels: {
				HasCancel: true,
				Cancel: "Abandonner",
				HasSaveAndQuit: true,
				SaveAndQuit: "Sauvegarder et quitter",
				HasGetAQuote: false,
				GetAQuote: "Obtenir un devis",
				HasPrevious: true,
				Previous: "Précédent",
				HasSubmit: true,
				Submit: "Suivant"
			},
			OnSubmit: [
				{
					Id : 0,
					Type : "ApiCall",
					ResultAction: "Next",
					Target: "", 
					TargetStep: "",
					Mapping : {
						HasMapping: true,
						MappingType: "Object",
						SourceStep: "bank_infos",
						SourceModule: "subscription",
						SubValues : [
							{ 
								ResponseObjectName: "", 
								Name : "PaymentMethod",
								FormName: "method" ,
								Mapping: "method", 
								FieldType: "FieldValue",
								Type: "string"
							},
							{ 
								ResponseObjectName: "", 
								Name : "Periodicity",
								FormName: "frequency" ,
								Mapping: "frequency", 
								FieldType: "FieldValue",
								Type: "string"
							},
							{ 
								ResponseObjectName: "", 
								Name : "PaymentDay", 
								FormName: "dayofpayment",
								Mapping: "dayofpayment", 
								FieldType: "FieldValue",
								Type: "integer"
							},
							{ 
								ResponseObjectName: "", 
								Name : "IBAN",
								FormName: "iban",
								Mapping: "iban", 
								FieldType: "FieldValue",
								Type: "string"
							},
							{ 
								ResponseObjectName: "", 
								Name : "BIC", 
								FormName: "bic",
								Mapping: "bic", 
								FieldType: "FieldValue",
								Type: "string"
							},
							{ 
								ResponseObjectName: "", 
								Name : "LoanReference", 
								FormName: "loan_reference",
								Mapping: "loan_reference", 
								FieldType: "FieldValue",
								Type: "string"
							}
						]
					}, 
					Params: { 
						Method: "PUT", 
						Url: "/quotations/{{quotation_id}}/bank/infos",
						VariableType: "FromService",
						VariableName: "quotation_id",
						HasVariable: true
					},
					HasWaitingPopup: true,
					WaitingPopup: {
						Text: "Merci de bien vouloir patienter...<BR /><BR />Nous traitons votre dossier et générons les documents."
					}
				}
			],
			OnCancel: [
				{
					Id : 0,
					Type : "ApiCall",
					ResultAction: "Cancel",
					Target: "", 
					TargetStep: "",
					Mapping : { HasMapping: false }, 
					Params: { 
						Method: "PUT", 
						Url: "/quotations/{{quotation_id}}/cancel",
						VariableType: "FromService",
						VariableName: "quotation_id",
						HasVariable: true
					},
					HasWaitingPopup: false,
					WaitingPopup: {
						Text: ""
					}
				}
			],
			OnGetQuote: [],
			OnBack: [],
			HasSuccessMessage: true,
			OnSuccessMessage: {
				Messages: [
					{
						Text: "Le dossier de demande d'adhésion a été envoyé par mail à l'emprunteur principal et au(x) co-emprunteur(s) pour qu'ils puissent signer électroniquement.",
						Visible: true,
						Actions : []
					}
				]
			}			
		},
		{
			Name : "medicaland_financial_infos",
			Label : "Formalités médicales et financières",
			InitActions : [
				{
					Id: 1, 
					Type: "AfterInit", 
					ResultAction : "getMedicalFormalities", 
					Target: "documents_list",
					TargetStep: "medicaland_financial_infos",
					Mapping : {}, 
					Params: {} 
				},
			],
			HasRightBar : true,
			RightBar : {
				GoToItemBtnCaption_First: "Emprunteur principal",				
				GoToItemBtnCaption: "Co-emprunteur",
				HasItemSubDetails: true,
				ItemSubDetails : {
					TargetStep: "Covered",
					TargetFields: [
						{ Name: "Gender", Type: "Short", Mapping: { "Monsieur" : "M.", "Madame" : "Mme"} },
						{ Name: "FirstName", Type: "Full", Mapping: {} },
						{ Name: "LastName", Type: "Full", Mapping: {} }						
					]
				},
				HasAddItemBtn: false,
				AddItemBtnCaption: "",
				ExtraActions : []
			},
			HasNavigationBar: false,	
			NavigationBarActions: [],
			Controls : [
				{
					Name : "BorrowerIndex",
					Type : "hidden",
					CustomClass : "",
					Label : "",
					UpperCase : false,
					Capitalize: false,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ReadOnly: false,
					ToHide: false,
					Multiple: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					HasTopRightButton: false,
					TopRightButtonLabel: "",
					TopRightButtonCtrlError: "",
					TopRightButtonAction: {},					
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {},
					HasHelper : false,
					HelperPosition: "",
					HelperTitle: "",
					HelperBody: "",
					Actions : []
				},
				{
					Name : "comment1",
					Type : "comment",
					CustomClass : "size-1",
					Comment: "Afin de finaliser l'adhésion, il est nécessaire que l'assuré(e) effectue les formalités suivantes :",
					Label : "",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {},
					HasHelper : false,
					HelperPosition: "",
					Helper : "",
					Actions:[]					
				},	
				{
					Name : "documents_list",
					Type : "listing",
					CustomClass : "size-1",
					Comment: "",
					Label : "",
					UpperCase : false,
					Capitalize: true,
					IgnoreCaseFormat: true,
					PlaceHolder : "",
					Default : "",
					ToHide: false,
					Disabled: false,
					Values : [],
					HasRightIndicator: false,
					RightIndicatorType : "",
					RightIndicator : "",
					Validators : [],
					ShowErrorsUnderItem: false,
					Errors : {},
					HasHelper : false,
					HelperPosition: "",
					Helper : "",
					Actions:[]					
				},												
			],
			BtnLabels: {
				HasCancel: true,
				Cancel: "Abandonner",
				HasSaveAndQuit: true,
				SaveAndQuit: "Sauvegarder et quitter",
				HasGetAQuote: false,
				GetAQuote: "Obtenir un devis",
				HasPrevious: true,
				Previous: "Précédent",
				HasSubmit: true,
				Submit: "Valider"
			},
			OnSubmit: [				
				{
					Id : 0,
					Type : "ApiCall",
					ResultAction: "redirectToContract",
					Target: "", 
					TargetStep: "",
					Mapping : {
						HasMapping: true,
						MappingType: "Array",
						SourceStep : "medicaland_financial_infos",
						SourceModule: "subscription",
						SubValues : [
							{ 
								ResponseObjectName: "", 
								Name : "BorrowerIndex", 
								FormName: "BorrowerIndex", 
								Mapping: "BorrowerIndex", 
								FieldType: "FieldValue", 
								Type: "integer", 
								FixedValue: "" 
							},
							{ 
								ResponseObjectName: "", 
								Name : "Documents", 
								FormName: "documents_list", 
								Mapping: "documents_list", 
								FieldType: "ArrayValue", 
								Type: "", 
								FixedValue: "" 
							},
						]
					}, 
					Params: { 
						Method: "PUT", 
						Url: "/quotations/{{quotation_id}}/formalities",
						VariableType: "FromService",
						VariableName: "quotation_id",
						HasVariable: true
					},
					HasWaitingPopup: false,
					WaitingPopup: {
						Text: ""
					}
				}
			],
			OnCancel: [
				{
					Id : 0,
					Type : "ApiCall",
					ResultAction: "Cancel",
					Target: "", 
					TargetStep: "",
					Mapping : { HasMapping: false }, 
					Params: { 
						Method: "PUT", 
						Url: "/quotations/{{quotation_id}}/cancel",
						VariableType: "FromService",
						VariableName: "quotation_id",
						HasVariable: true
					},
					HasWaitingPopup: false,
					WaitingPopup: {
						Text: ""
					}
				}
			],
			OnGetQuote: [],
			OnBack: [],
			HasSuccessMessage: true,
			OnSuccessMessage: {
				Messages: [
					{
						Text: "",
						Visible: true,
						Actions : [

							{ // <46ans and <=100000
								Id: 2,
								Type: "PopUp",
								ResultAction: "AddText",
								TargetStep: "",
								Target: "text",					
								TargetType: "popup",
								TextToAdd: "Le lien permettant d'accéder au <B>questionnaire de santé simplifié</B> en ligne a été envoyé par mail à l'emprunteur principal et au(x) co-emprunteur(s).<BR /><BR />",
								Params: [
									{ 
										Operator : "<",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 46, 
									},
									{ 
										Operator : "<=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 100000, 
									}
								]
							},
							{ // <46ans and >=100001 and <=500000
								Id: 2,
								Type: "PopUp",
								ResultAction: "AddText",
								TargetStep: "",
								Target: "text",					
								TargetType: "popup",
								TextToAdd: "Le lien permettant d'accéder au <B>questionnaire de santé simplifié</B> en ligne a été envoyé par mail à l'emprunteur principal et au(x) co-emprunteur(s).<BR /><BR />",
								Params: [
									{ 
										Operator : "<",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 46, 
									},
									{ 
										Operator : ">=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 100001, 
									},
									{ 
										Operator : "<=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 500000, 
									}
								]
							},
							{ // <46ans and >=500001 and <=1000000
								Id: 2,
								Type: "PopUp",
								ResultAction: "AddText",
								TargetStep: "",
								Target: "text",					
								TargetType: "popup",
								TextToAdd: "Le lien permettant d'accéder au <B>questionnaire de santé</B> en ligne a été envoyé par mail à l'emprunteur principal et au(x) co-emprunteur(s).<BR /><BR />",
								Params: [
									{ 
										Operator : "<",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 46, 
									},
									{ 
										Operator : ">=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 500001, 
									},
									{ 
										Operator : "<=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 1000000, 
									}
								]
							},
							{ // <46ans and >=1000001 and <=1700000
								Id: 3,
								Type: "PopUp",
								ResultAction: "AddText",
								TargetStep: "",
								Target: "text",					
								TargetType: "popup",
								TextToAdd: "Le lien permettant d'accéder au <B>questionnaire de santé</B> en ligne a été envoyé par mail à l'emprunteur principal et au(x) co-emprunteur(s).<BR /><BR />Les documents suivants leur ont également été envoyés :<BR /><BR />- Profil sanguin<BR />",
								Params: [
									{ 
										Operator : "<",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 46, 
									},
									{ 
										Operator : ">=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 1000001, 
									},
									{ 
										Operator : "<=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 1700000, 
									}
								]
							},
							{ // <46ans and >=1700001 and <=2500000
								Id: 4,
								Type: "PopUp",
								ResultAction: "AddText",
								TargetStep: "",
								Target: "text",					
								TargetType: "popup",
								TextToAdd: "Les documents suivants leur ont été envoyés :<BR /><BR />- Dossier médical standard<BR />- Copie de l'offre de prêt<BR />",
								Params: [
									{ 
										Operator : "<",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 46, 
									},
									{ 
										Operator : ">=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 1700001, 
									},
									{ 
										Operator : "<=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 2500000, 
									}
								]
							},
							{ // <46ans and >=2500001 and <=5000000
								Id: 4,
								Type: "PopUp",
								ResultAction: "AddText",
								TargetStep: "",
								Target: "text",					
								TargetType: "popup",
								TextToAdd: "Les documents suivants leur ont été envoyés :<BR /><BR />- Dossier médical standard<BR />- Copie de l'offre de prêt<BR />- Questionnaire financier<BR />",
								Params: [
									{ 
										Operator : "<",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 46, 
									},
									{ 
										Operator : ">=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 2500001, 
									},
									{ 
										Operator : "<=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 5000000, 
									}
								]
							},


							{ // >=46ans and <55ans and <=100000
								Id: 5,
								Type: "PopUp",
								ResultAction: "AddText",
								TargetStep: "",
								Target: "text",					
								TargetType: "popup",
								TextToAdd: "Le lien permettant d'accéder au <B>questionnaire de santé simplifié</B> en ligne a été envoyé par mail à l'emprunteur principal et au(x) co-emprunteur(s).<BR /><BR />",
								Params: [
									{ 
										Operator : ">=",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 46, 
									},
									{ 
										Operator : "<",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 55, 
									},
									{ 
										Operator : "<=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 100000, 
									}
								]
							},							
							{ // >=46ans and <55ans and >=100001 and <=500000
								Id: 6,
								Type: "PopUp",
								ResultAction: "AddText",
								TargetStep: "",
								Target: "text",					
								TargetType: "popup",
								TextToAdd: "Le lien permettant d'accéder au <B>questionnaire de santé simplifié</B> en ligne a été envoyé par mail à l'emprunteur principal et au(x) co-emprunteur(s).<BR /><BR />",
								Params: [
									{ 
										Operator : ">=",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 46, 
									},
									{ 
										Operator : "<",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 55, 
									},
									{ 
										Operator : ">=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 100001, 
									},
									{ 
										Operator : "<=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 500000, 
									}
								]
							},
							{ // >=46ans and <55ans and >=500001 and <=1000000
								Id: 6,
								Type: "PopUp",
								ResultAction: "AddText",
								TargetStep: "",
								Target: "text",					
								TargetType: "popup",
								TextToAdd: "Le lien permettant d'accéder au <B>questionnaire de santé</B> en ligne a été envoyé par mail à l'emprunteur principal et au(x) co-emprunteur(s).<BR /><BR />",
								Params: [
									{ 
										Operator : ">=",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 46, 
									},
									{ 
										Operator : "<",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 55, 
									},
									{ 
										Operator : ">=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 500001, 
									},
									{ 
										Operator : "<=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 1000000, 
									}
								]
							},
							{ // >=46ans and <55ans and >=1000001 and <=1700000
								Id: 7,
								Type: "PopUp",
								ResultAction: "AddText",
								TargetStep: "",
								Target: "text",					
								TargetType: "popup",
								TextToAdd: "Les documents suivants leur ont été envoyés :<BR /><BR />- Dossier médical standard<BR />",
								Params: [
									{ 
										Operator : ">=",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 46, 
									},
									{ 
										Operator : "<",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 55, 
									},
									{ 
										Operator : ">=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 1000001, 
									},
									{ 
										Operator : "<=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 1700000, 
									}
								]
							},
							{ // >=46ans and <55ans and >=1700001 and <=2500000
								Id: 7,
								Type: "PopUp",
								ResultAction: "AddText",
								TargetStep: "",
								Target: "text",					
								TargetType: "popup",
								TextToAdd: "Les documents suivants leur ont été envoyés :<BR /><BR />- Dossier médical standard<BR />- Copie de l'offre de prêt<BR />",
								Params: [
									{ 
										Operator : ">=",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 46, 
									},
									{ 
										Operator : "<",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 55, 
									},
									{ 
										Operator : ">=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 1700001, 
									},
									{ 
										Operator : "<=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 2500000, 
									}
								]
							},
							{ // >=46ans and <55ans and >=2500001 and <=5000000
								Id: 7,
								Type: "PopUp",
								ResultAction: "AddText",
								TargetStep: "",
								Target: "text",					
								TargetType: "popup",
								TextToAdd: "Les documents suivants leur ont été envoyés :<BR /><BR />- Dossier médical standard<BR />- Copie de l'offre de prêt<BR />- Questionnaire financier<BR />- ECG d'effort et rapport du cardiologue<BR />",
								Params: [
									{ 
										Operator : ">=",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 46, 
									},
									{ 
										Operator : "<",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 55, 
									},
									{ 
										Operator : ">=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 2500001, 
									},
									{ 
										Operator : "<=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 5000000, 
									}
								]
							},


							{ // >=56ans and <70ans and <=100000
								Id: 5,
								Type: "PopUp",
								ResultAction: "AddText",
								TargetStep: "",
								Target: "text",					
								TargetType: "popup",
								TextToAdd: "Le lien permettant d'accéder au <B>questionnaire de santé</B> en ligne a été envoyé par mail à l'emprunteur principal et au(x) co-emprunteur(s).<BR /><BR />",
								Params: [
									{ 
										Operator : ">=",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 56, 
									},
									{ 
										Operator : "<",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 70, 
									},
									{ 
										Operator : "<=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 100000, 
									}
								]
							},
							{ // >=56ans and <70ans and >=100001 and <=500000
								Id: 8,
								Type: "PopUp",
								ResultAction: "AddText",
								TargetStep: "",
								Target: "text",					
								TargetType: "popup",
								TextToAdd: "Le lien permettant d'accéder au <B>questionnaire de santé</B> en ligne a été envoyé par mail à l'emprunteur principal et au(x) co-emprunteur(s).<BR /><BR />Les documents suivants leur ont également été envoyés :<BR /><BR />- Rapport de visite médicale<BR />- Profil sanguin<BR />",
								Params: [
									{ 
										Operator : ">=",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 56, 
									},
									{ 
										Operator : "<",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 70, 
									},
									{ 
										Operator : ">=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 100001, 
									},
									{ 
										Operator : "<=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 500000, 
									}
								]
							},
							{ // >=56ans and <70ans and >=500001 and <=1000000
								Id: 8,
								Type: "PopUp",
								ResultAction: "AddText",
								TargetStep: "",
								Target: "text",					
								TargetType: "popup",
								TextToAdd: "Les documents suivants leur ont été envoyés :<BR /><BR />- Dossier médical standard<BR />",
								Params: [
									{ 
										Operator : ">=",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 56, 
									},
									{ 
										Operator : "<",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 70, 
									},
									{ 
										Operator : ">=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 500001, 
									},
									{ 
										Operator : "<=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 1000000, 
									}
								]
							},
							{ // >=56ans and <70ans and >=1000001 and <=1700000
								Id: 9,
								Type: "PopUp",
								ResultAction: "AddText",
								TargetStep: "",
								Target: "text",					
								TargetType: "popup",
								TextToAdd: "Les documents suivants leur ont été envoyés :<BR /><BR />- Dossier médical standard<BR />",
								Params: [
									{ 
										Operator : ">=",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 56, 
									},
									{ 
										Operator : "<",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 70, 
									},
									{ 
										Operator : ">=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 1000001, 
									},
									{ 
										Operator : "<=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 1700000, 
									}
								]
							},
							{ // >=56ans and <70ans and >=1700001 and <=2500000
								Id: 10,
								Type: "PopUp",
								ResultAction: "AddText",
								TargetStep: "",
								Target: "text",					
								TargetType: "popup",
								TextToAdd: "Les documents suivants leur ont été envoyés :<BR /><BR />- Dossier médical standard<BR />- Copie de l'offre de prêt<BR />- Questionnaire financier<BR />",
								Params: [
									{ 
										Operator : ">=",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 56, 
									},
									{ 
										Operator : "<",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 70, 
									},
									{ 
										Operator : ">=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 1700001, 
									},
									{ 
										Operator : "<=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 2500000, 
									}
								]
							},
							{ // >=56ans and <70ans and >=2500001 and <=5000000
								Id: 10,
								Type: "PopUp",
								ResultAction: "AddText",
								TargetStep: "",
								Target: "text",					
								TargetType: "popup",
								TextToAdd: "Consulter le réassureur avant de constitution du dossier.<BR /><BR />",
								Params: [
									{ 
										Operator : ">=",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 56, 
									},
									{ 
										Operator : "<",
										CompareValueType: "getAge",	
										CompareValueForm: "Covered",
										CompareValueModule: "quotation",
										CompareValueField: "BirthDate",				
										CompareValue : 70, 
									},
									{ 
										Operator : ">=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 2500001, 
									},
									{ 
										Operator : "<=",
										CompareValueType: "fromPreviousStep",	
										CompareValueForm: "Loan",
										CompareValueModule: "quotation",
										CompareValueField: "Amount",				
										CompareValue : 5000000, 
									}
								]
							},
						]
					},
				]
			}					
		},
	]
}

