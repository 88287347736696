export const CONFIG = {

	ENV: "PROD",

	API: {
		DEV: "http://localhost:4200/api",
		TEST: "https://api-extranet-preprod.iki-assurances.com/api",
		PROD: "https://api-extranet.iki-assurances.com/api",
		WESH: "http://dev.iki.fr/api"
	},

	UNIVERSIGN : {
		DEV: "https://sign.test.universign.eu",
		// TEST: "https://sign.test.universign.eu",
		TEST: "https://app.universign.com",
		PROD: "https://app.universign.com",
		// WESH: "https://sign.test.universign.eu"
		WESH: "https://app.universign.com"
	},

	VERSION: "1.0.8"
}